import React, { useCallback } from "react";
import styled from "styled-components";
import AutoComplete, { AutoCompleteProps } from "./AutoComplete";
import Icon, { Icons } from "./Icon";
import Spinner from "./Spinner";
import { TagProps } from "./Tag";
import TagAutoComplete, {
  TagAutoCompleteMatch,
  TagAutoCompleteScrollableArea,
  TagInput,
  TagInputContainer,
  TagItem,
} from "./TagAutoComplete";
import { DefaultTheme } from "./Theme";

export interface SearchProps {
  className?: string;
  closeIcon?: React.ComponentType<SearchCloseIconProps>;
  disabled?: boolean;
  id?: string;
  input?: React.ComponentType<AutoCompleteProps>;
  isActive?: boolean;
  loading?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  suggestions?: string[];
  tags?: string[];
  tagComponent?: React.ComponentType<TagProps>;
  value?: string;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
  onBlurOut?(e: React.FocusEvent<HTMLDivElement>): void;
  onChange?(value: string, tags?: string[]): void;
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;
  onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
  onFocusIn?(e: React.FocusEvent<HTMLDivElement>): void;
  onMouseEnter?(e: React.MouseEvent<HTMLDivElement>): void;
  onMouseLeave?(e: React.MouseEvent<HTMLDivElement>): void;
  onMouseDown?(e: React.MouseEvent<HTMLDivElement>): void;
  onMouseUp?(e: React.MouseEvent<HTMLDivElement>): void;
  onSearch?(value: string, tags?: string[]): void;
  after?: React.ReactNode;
  before?: React.ReactNode;
  children?: React.ReactNode;
}

export interface SearchCloseIconProps {
  onClick(e: React.MouseEvent): void;
}

const Search: React.FC<SearchProps> = React.memo(function Search({
  className,
  closeIcon: CloseIcon = SearchCloseIcon,
  disabled = false,
  input: SearchAutoCompleteComponent = SearchAutoComplete,
  isActive,
  loading,
  tagComponent: SearchTagComponent,
  tags = [],
  value: text = "",
  onChange,
  onSearch,
  after,
  before,
  ...props
}) {
  const handleChange = useCallback(
    (tags: string[], text: string = "") => {
      if (!disabled) {
        onChange?.(text, tags);
      }
    },
    [disabled, onChange]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (disabled) {
        return;
      }

      if (e.key === "Enter") {
        onSearch?.(text, tags);
      }
    },
    [disabled, text, tags, onSearch]
  );

  const handleTextChange = useCallback(
    (text: string) => {
      if (!disabled) {
        onChange?.(text);
      }
    },
    [disabled, onChange]
  );

  const clear = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (!disabled) {
        onSearch?.("", []);
      }
    },
    [disabled, onSearch]
  );

  if (typeof isActive === "undefined") {
    isActive = Boolean(text || (tags && tags.length > 0));
  }

  if (typeof before === "undefined") {
    before = loading ? <SearchSpinner /> : <SearchIcon disabled={disabled} />;
  }

  return (
    <StyledSearch
      autocompleteComponent={SearchAutoCompleteComponent as React.ComponentType<AutoCompleteProps>}
      className={className}
      disabled={disabled}
      spellCheck={false}
      tagComponent={SearchTagComponent}
      text={text}
      value={tags}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onTextChange={handleTextChange}
      before={before}
      after={
        <SearchToolbar>
          {isActive && !disabled && <CloseIcon onClick={clear} />}
          {after}
        </SearchToolbar>
      }
      {...props}
    />
  );
});

const StyledSearch = styled(TagAutoComplete)`
  border: 1px solid ${({ theme }) => theme.colors.toolbarBorder};
  background: ${({ disabled, theme }) =>
    disabled ? theme.colors.toolbarDisabledBackground : theme.colors.toolbarBackground};
  color: ${({ disabled, theme }) => (disabled ? theme.colors.toolbarDisabledText : theme.colors.toolbarForeground)};
`;
StyledSearch.defaultProps = {
  theme: DefaultTheme,
};

export const SearchIcon = styled(Icon).attrs({
  icon: Icons.Search,
})`
  font-size: 12pt;
  color: ${({ theme }) => theme.colors.placeholders};
  margin-right: 5px;
`;
SearchIcon.defaultProps = {
  theme: DefaultTheme,
};

export const SearchSpinner = styled(Spinner)`
  font-size: 12pt;
  color: ${({ theme }) => theme.colors.placeholders};
  margin-right: 5px;
`;
SearchSpinner.defaultProps = {
  theme: DefaultTheme,
};

export const SearchToolbar = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 12pt;
  color: ${({ theme }) => theme.colors.foreground};
`;
SearchToolbar.defaultProps = {
  theme: DefaultTheme,
};

export const SearchCloseIcon = styled(Icon).attrs({
  icon: Icons.Close,
  tabIndex: 0,
  title: "Clear search",
})`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.toolbarForeground};
`;
SearchCloseIcon.defaultProps = {
  theme: DefaultTheme,
};

export const SearchRunQueryIcon = styled(Icon).attrs({
  icon: Icons.Search,
  clickable: true,
  tabIndex: 0,
  title: "Search",
})`
  color: ${({ theme }) => theme.colors.toolbarForeground};
`;
SearchRunQueryIcon.defaultProps = {
  theme: DefaultTheme,
};

export const SearchTag = TagItem;

export const SearchInput = styled(TagInput)`
  margin-left: 5px;
  height: 23px;
  background: ${({ disabled, theme }) =>
    disabled ? theme.colors.toolbarDisabledBackground : theme.colors.toolbarBackground};
  color: ${({ disabled, theme }) => (disabled ? theme.colors.toolbarDisabledText : theme.colors.toolbarForeground)};
`;

export const SearchInputContainer = TagInputContainer;
export const SearchAutoCompleteMatch = TagAutoCompleteMatch;
export const SearchWrapper = TagAutoCompleteScrollableArea;

export const SearchAutoComplete = styled(AutoComplete).attrs({
  containerComponent: SearchInputContainer,
  inputComponent: SearchInput,
  matchComponent: SearchAutoCompleteMatch,
})`
  height: 23px;
`;

export default Search;
