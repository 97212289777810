import { StatusType } from "@omniverse/api/data";
import { RemoveUserFromGroupError } from "../../../util/UserErrors";
import { Commands } from "../Provider";
import { IRemoveUserFromGroupCommandArguments } from "../types/RemoveUserFromGroupCommand";
import { NucleusCommand } from "./index";

export default class NucleusRemoveUserFromGroupCommand extends NucleusCommand<IRemoveUserFromGroupCommandArguments> {
  public readonly name = Commands.RemoveUserFromGroup;

  public async allowed(): Promise<boolean> {
    return Boolean(this.provider.session.isSuperUser);
  }

  public async execute({ user, group }: IRemoveUserFromGroupCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Remove user "${user.name}" from group "${group.name}`);

    const connection = await this.provider.getConnection();
    const result = await connection.removeUserFromGroup({ username: user.name, group_name: group.name });
    if (result.status !== StatusType.OK) {
      throw new RemoveUserFromGroupError(result);
    }

    user.removeGroup(group);
    group.removeUser(user);
  }
}
