import Schema from "@omniverse/idl/schema";
import * as _data from "./data";

export class NGSearchService {
  constructor(transport) {
    this.transport = transport;
  }

  /*
    Test that the service is connected.
   */
  async demoTest({input, } = {}) {
    const _request = {input, };
    if (!_data.NGSearchServiceDemoTestRequest.validate(_request)) {
      throw new Error("Invalid NGSearchServiceDemoTestRequest.");
    }
    return await this.transport.call({ interfaceName: "NGSearchService", methodName: "demo_test", request: _request, schemas: { request: _data.NGSearchServiceDemoTestRequest, response: _data.DataContent }});
  }

  /*
    Return the debuging information about the database
   */
  async datasourceInfo() {
    const _request = {};
    if (!_data.NGSearchServiceDatasourceInfoRequest.validate(_request)) {
      throw new Error("Invalid NGSearchServiceDatasourceInfoRequest.");
    }
    return await this.transport.call({ interfaceName: "NGSearchService", methodName: "datasource_info", request: _request, schemas: { request: _data.NGSearchServiceDatasourceInfoRequest, response: _data.Datasource }});
  }

  /*
    Return a list of Paths (with file metadata) matching the search

    @param {ClientVersion} version left over from search service api
    @param {SearchQuery} query search terms, tag list (not used), and parent
    folder of search.
    @param {string} [token] user authentication token
   */
  async find({query, token, }) {
    const _request = {version: _data.NGSearchServiceFindClientVersion.value, query, token, };
    if (!_data.NGSearchServiceFindRequest.validate(_request)) {
      throw new Error("Invalid NGSearchServiceFindRequest.");
    }
    return await this.transport.callMany({ interfaceName: "NGSearchService", methodName: "find", request: _request, schemas: { request: _data.NGSearchServiceFindRequest, response: _data.Path }});
  }

  /*

   */
  async find2({query, token, }) {
    const _request = {version: _data.NGSearchServiceFind2ClientVersion.value, query, token, };
    if (!_data.NGSearchServiceFind2Request.validate(_request)) {
      throw new Error("Invalid NGSearchServiceFind2Request.");
    }
    return await this.transport.call({ interfaceName: "NGSearchService", methodName: "find2", request: _request, schemas: { request: _data.NGSearchServiceFind2Request, response: _data.SearchResult }});
  }

  /*

   */
  async findPagedCursor({query, token, size, cursor_id, }) {
    const _request = {version: _data.NGSearchServiceFindPagedCursorClientVersion.value, query, token, size, cursor_id, };
    if (!_data.NGSearchServiceFindPagedCursorRequest.validate(_request)) {
      throw new Error("Invalid NGSearchServiceFindPagedCursorRequest.");
    }
    return await this.transport.call({ interfaceName: "NGSearchService", methodName: "find_paged_cursor", request: _request, schemas: { request: _data.NGSearchServiceFindPagedCursorRequest, response: _data.SearchResultCursor }});
  }

  /*

   */
  async findPagedSkip({query, token, size, skip_first, }) {
    const _request = {version: _data.NGSearchServiceFindPagedSkipClientVersion.value, query, token, size, skip_first, };
    if (!_data.NGSearchServiceFindPagedSkipRequest.validate(_request)) {
      throw new Error("Invalid NGSearchServiceFindPagedSkipRequest.");
    }
    return await this.transport.call({ interfaceName: "NGSearchService", methodName: "find_paged_skip", request: _request, schemas: { request: _data.NGSearchServiceFindPagedSkipRequest, response: _data.SearchResult }});
  }

  /*

   */
  async getPrefixes() {
    const _request = {version: _data.NGSearchServiceGetPrefixesClientVersion.value, };
    if (!_data.NGSearchServiceGetPrefixesRequest.validate(_request)) {
      throw new Error("Invalid NGSearchServiceGetPrefixesRequest.");
    }
    return await this.transport.call({ interfaceName: "NGSearchService", methodName: "get_prefixes", request: _request, schemas: { request: _data.NGSearchServiceGetPrefixesRequest, response: _data.PrefixResult }});
  }

  /*
    Return the list of paths for a given input tag.

    @param {string} [query] Input text query that can be text or base64 encoded
    image
    @param {number} [n] Number of paths that need to be returned (if set to -1
    will return all the paths)
    @param {string} [filter_by_path] path by which the response should be
    filtered (default: None)
    @param {string[]} [filter_by_ext] filter by path extension (default: None)
    @param {string[]} [kw_must] must include keyword (default: None)
    @param {string[]} [kw_must_not] must not include keyword (default: None)
    @param {boolean} [return_predictions] if true - additional return
    prediction (default: false)
    @param {boolean} [return_images] if true - additional return images
    (default: false)
    @param {number} [similarity_threshold] filter out similar results (default:
    0.0)
    @param {string} [token] user authentication token
   */
  async search({query, n, filter_repeating, filter_by_path, filter_by_ext, kw_must, kw_must_not, return_predictions, return_images, similarity_threshold, search_config, scroll, token, only_key, } = {}) {
    const _request = {query, n, filter_repeating, filter_by_path, filter_by_ext, kw_must, kw_must_not, return_predictions, return_images, similarity_threshold, search_config, scroll, token, only_key, };
    if (!_data.NGSearchServiceSearchRequest.validate(_request)) {
      throw new Error("Invalid NGSearchServiceSearchRequest.");
    }
    return await this.transport.call({ interfaceName: "NGSearchService", methodName: "search", request: _request, schemas: { request: _data.NGSearchServiceSearchRequest, response: _data.Results }});
  }

  /*
    Continue retrieving items from search results

    @param {string} [scroll_id] ID of the search that was already executed
    @param {number} [n] Number of paths that need to be returned (if set to -1
    will return all the paths)
   */
  async scroll({scroll_id, n, }) {
    const _request = {scroll_id, n, };
    if (!_data.NGSearchServiceScrollRequest.validate(_request)) {
      throw new Error("Invalid NGSearchServiceScrollRequest.");
    }
    return await this.transport.call({ interfaceName: "NGSearchService", methodName: "scroll", request: _request, schemas: { request: _data.NGSearchServiceScrollRequest, response: _data.Results }});
  }

  /*
    Same functionality as search, but returns an async generator

    @param {string} [query] Input text query that can be text or base64 encoded
    image
    @param {number} [n] Number of paths that need to be returned (if set to -1
    will return all the paths)
    @param {string} [filter_by_path] path by which the response should be
    filtered (default: None)
    @param {string[]} [filter_by_ext] filter by path extension (default: None)
    @param {string[]} [kw_must] must include keyword (default: None)
    @param {string[]} [kw_must_not] must not include keyword (default: None)
    @param {boolean} [return_predictions] if true - additional return
    prediction (default: false)
    @param {boolean} [return_images] if true - additional return images
    (default: false)
    @param {number} [similarity_threshold] filter out similar results (default:
    0.0)
    @param {string} [token] user authentication token
   */
  async searchGen({query, n, filter_repeating, filter_by_path, filter_by_ext, kw_must, kw_must_not, return_predictions, return_images, similarity_threshold, search_config, token, only_key, } = {}) {
    const _request = {query, n, filter_repeating, filter_by_path, filter_by_ext, kw_must, kw_must_not, return_predictions, return_images, similarity_threshold, search_config, token, only_key, };
    if (!_data.NGSearchServiceSearchGenRequest.validate(_request)) {
      throw new Error("Invalid NGSearchServiceSearchGenRequest.");
    }
    return await this.transport.callMany({ interfaceName: "NGSearchService", methodName: "search_gen", request: _request, schemas: { request: _data.NGSearchServiceSearchGenRequest, response: _data.Item }});
  }

  /*
    Get list of keywords that can be searched for

    @param {string} [filter_by_path] path by which the response should be
    filtered (default: None)
    @param {string[]} [filter_by_ext] filter by path extension (default: None)
    @param {string[]} [kw_must] must include keyword (default: None)
    @param {string[]} [kw_must_not] must not include keyword (default: None)
   */
  async listSearchableKeywords({filter_by_path, filter_by_ext, kw_must, kw_must_not, } = {}) {
    const _request = {filter_by_path, filter_by_ext, kw_must, kw_must_not, };
    if (!_data.NGSearchServiceListSearchableKeywordsRequest.validate(_request)) {
      throw new Error("Invalid NGSearchServiceListSearchableKeywordsRequest.");
    }
    return await this.transport.call({ interfaceName: "NGSearchService", methodName: "list_searchable_keywords", request: _request, schemas: { request: _data.NGSearchServiceListSearchableKeywordsRequest, response: _data.KeywordList }});
  }

  /*
    Get predictions for a input query
    @param {string} query: input query that needs to be converted to embedding
    and for which predictions need to be computed
   */
  async getPredictions({query, }) {
    const _request = {query, };
    if (!_data.NGSearchServiceGetPredictionsRequest.validate(_request)) {
      throw new Error("Invalid NGSearchServiceGetPredictionsRequest.");
    }
    return await this.transport.call({ interfaceName: "NGSearchService", methodName: "get_predictions", request: _request, schemas: { request: _data.NGSearchServiceGetPredictionsRequest, response: _data.PredictionList }});
  }

  /*
    Get list of embeddings for a list input query
    @param {string[]} query: list of input querries that need to be converted
    to embeddings
   */
  async getEmbeddings({query_list, }) {
    const _request = {query_list, };
    if (!_data.NGSearchServiceGetEmbeddingsRequest.validate(_request)) {
      throw new Error("Invalid NGSearchServiceGetEmbeddingsRequest.");
    }
    return await this.transport.call({ interfaceName: "NGSearchService", methodName: "get_embeddings", request: _request, schemas: { request: _data.NGSearchServiceGetEmbeddingsRequest, response: _data.DataItems }});
  }

}
NGSearchService[Schema.InterfaceName] = "NGSearchService";
NGSearchService[Schema.InterfaceOrigin] = "Search.idl.ts";
NGSearchService[Schema.InterfaceCapabilities] = _data.NGSearchServiceClientLocalCapabilities.value;

export class Search {
  constructor(transport) {
    this.transport = transport;
  }

  /*

   */
  async find({query, token, }) {
    const _request = {version: _data.SearchFindClientVersion.value, query, token, };
    if (!_data.SearchFindRequest.validate(_request)) {
      throw new Error("Invalid SearchFindRequest.");
    }
    return await this.transport.callMany({ interfaceName: "Search", methodName: "find", request: _request, schemas: { request: _data.SearchFindRequest, response: _data.Path }});
  }

  /*

   */
  async find2({query, token, }) {
    const _request = {version: _data.SearchFind2ClientVersion.value, query, token, };
    if (!_data.SearchFind2Request.validate(_request)) {
      throw new Error("Invalid SearchFind2Request.");
    }
    return await this.transport.call({ interfaceName: "Search", methodName: "find2", request: _request, schemas: { request: _data.SearchFind2Request, response: _data.SearchResult }});
  }

  /*

   */
  async findPagedCursor({query, token, size, cursor_id, }) {
    const _request = {version: _data.SearchFindPagedCursorClientVersion.value, query, token, size, cursor_id, };
    if (!_data.SearchFindPagedCursorRequest.validate(_request)) {
      throw new Error("Invalid SearchFindPagedCursorRequest.");
    }
    return await this.transport.call({ interfaceName: "Search", methodName: "find_paged_cursor", request: _request, schemas: { request: _data.SearchFindPagedCursorRequest, response: _data.SearchResultCursor }});
  }

  /*

   */
  async findPagedSkip({query, token, size, skip_first, }) {
    const _request = {version: _data.SearchFindPagedSkipClientVersion.value, query, token, size, skip_first, };
    if (!_data.SearchFindPagedSkipRequest.validate(_request)) {
      throw new Error("Invalid SearchFindPagedSkipRequest.");
    }
    return await this.transport.call({ interfaceName: "Search", methodName: "find_paged_skip", request: _request, schemas: { request: _data.SearchFindPagedSkipRequest, response: _data.SearchResult }});
  }

  /*

   */
  async getPrefixes() {
    const _request = {version: _data.SearchGetPrefixesClientVersion.value, };
    if (!_data.SearchGetPrefixesRequest.validate(_request)) {
      throw new Error("Invalid SearchGetPrefixesRequest.");
    }
    return await this.transport.call({ interfaceName: "Search", methodName: "get_prefixes", request: _request, schemas: { request: _data.SearchGetPrefixesRequest, response: _data.PrefixResult }});
  }

}
Search[Schema.InterfaceName] = "Search";
Search[Schema.InterfaceOrigin] = "Search.idl.ts";
Search[Schema.InterfaceCapabilities] = _data.SearchClientLocalCapabilities.value;
