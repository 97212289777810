import { observer } from "mobx-react";
import React from "react";
import ContentBrowserLoader from "../components/ContentBrowserLoader";
import ContentBrowserRightPanel from "../components/ContentBrowserRightPanel";
import ContentBrowserSearch from "../components/ContentBrowserSearch";
import { ContentBrowserView } from "../components/ContentBrowserViewToggle";
import Header from "../components/ContentHeader";
import ErrorMessage from "../components/ErrorMessage";
import Footer from "../components/Footer";
import PathContent from "../components/PathContent";
import useContentBrowserNavigation from "../hooks/useContentBrowserNavigation";
import useError from "../hooks/useError";
import usePathParam from "../hooks/usePathParam";
import usePreference from "../hooks/usePreference";
import useServerParam from "../hooks/useServerParam";
import { Commands } from "../state/commands/Provider";
import { ISearchCommand } from "../state/commands/types/SearchCommand";
import { Preferences } from "../state/Preferences";

export interface ContentBrowserProps {}

const ContentBrowser: React.FC<ContentBrowserProps> = () => {
  const serverName = useServerParam();
  const path = usePathParam();
  const [error, setError, clearError] = useError();

  const [rightPanelOpened] = usePreference<boolean>(Preferences.RightPanelOpened, true);
  const [view] = usePreference<ContentBrowserView>(Preferences.ContentView, ContentBrowserView.Grid);

  const { storage, loading } = useContentBrowserNavigation(serverName, path, {
    onSubmit: clearError,
    onError: setError,
  });

  const supportsSearch = storage?.commands.allowed<ISearchCommand>(Commands.Search);
  return (
    <>
      <Header>{supportsSearch && storage && <ContentBrowserSearch storage={storage} onError={setError} />}</Header>

      <PathContent view={view} column={rightPanelOpened ? "" : "2 / 4"}>
        {loading ? <ContentBrowserLoader /> : error && <ErrorMessage>{error}</ErrorMessage>}
      </PathContent>

      <Footer />
      <ContentBrowserRightPanel storageName={serverName} />
    </>
  );
};

export default observer(ContentBrowser);
