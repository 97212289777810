import Schema from "@omniverse/idl/schema";


export const Capabilities = Schema.Map(Schema.Number);

export const SearchServerRemoteCapabilities = Capabilities;

export const SearchServerLocalCapabilities = Schema.Literal({'find': 3, 'find2': 2, 'find_paged_cursor': 0, 'find_paged_skip': 0, 'get_prefixes': 0});

export const SearchServerCapabilities = SearchServerRemoteCapabilities;

export const SearchClientRemoteCapabilities = Capabilities;

export const SearchClientLocalCapabilities = Schema.Literal({'find': 3, 'find2': 2, 'find_paged_cursor': 0, 'find_paged_skip': 0, 'get_prefixes': 0});

export const SearchClientCapabilities = SearchClientLocalCapabilities;

export const SearchGetPrefixesServerRemoteVersion = Schema.Number;

export const SearchGetPrefixesServerLocalVersion = Schema.Literal(0);

export const SearchGetPrefixesServerVersion = SearchGetPrefixesServerRemoteVersion;

export const SearchGetPrefixesClientRemoteVersion = Schema.Number;

export const SearchGetPrefixesClientLocalVersion = Schema.Literal(0);

export const SearchFindPagedSkipServerRemoteVersion = Schema.Number;

export const SearchFindPagedSkipServerLocalVersion = Schema.Literal(0);

export const SearchFindPagedSkipServerVersion = SearchFindPagedSkipServerRemoteVersion;

export const SearchFindPagedSkipClientRemoteVersion = Schema.Number;

export const SearchFindPagedSkipClientLocalVersion = Schema.Literal(0);

export const SearchFindPagedCursorServerRemoteVersion = Schema.Number;

export const SearchFindPagedCursorServerLocalVersion = Schema.Literal(0);

export const SearchFindPagedCursorServerVersion = SearchFindPagedCursorServerRemoteVersion;

export const SearchFindPagedCursorClientRemoteVersion = Schema.Number;

export const SearchFindPagedCursorClientLocalVersion = Schema.Literal(0);

export const SearchFind2ServerRemoteVersion = Schema.Number;

export const SearchFind2ServerLocalVersion = Schema.Literal(2);

export const SearchFind2ServerVersion = SearchFind2ServerRemoteVersion;

export const SearchFind2ClientRemoteVersion = Schema.Number;

export const SearchFind2ClientLocalVersion = Schema.Literal(2);

export const SearchFindServerRemoteVersion = Schema.Number;

export const SearchFindServerLocalVersion = Schema.Literal(3);

export const SearchFindServerVersion = SearchFindServerRemoteVersion;

export const SearchFindClientRemoteVersion = Schema.Number;

export const SearchFindClientLocalVersion = Schema.Literal(3);

export const PathEvent = Schema.Enum({
  Full: "full",
  Create: "create",
  Update: "update",
  Delete: "delete",
  ChangeAcl: "change_acl",
  Options: "set_path_options",
  Locked: "lock",
  Unlocked: "unlock",
  Rename: "rename",
  Copy: "copy",
  VersionReplaced: "replace_version",
});

export const PathPermission = Schema.Enum({
  Read: "read",
  Write: "write",
  Admin: "admin",
});

export const PathType = Schema.Enum({
  All: "",
  Asset: "asset",
  Folder: "folder",
  Channel: "channel",
  Mount: "mount",
  Object: "object",
  Empty: "none",
});

export const Path = Schema.Object({
  version: Schema.Optional(Schema.Number),
  type: Schema.Optional(PathType),
  uri: Schema.Optional(Schema.String),
  acl: Schema.Optional(Schema.Array(PathPermission)),
  created: Schema.Optional(Schema.String),
  created_by: Schema.Optional(Schema.String),
  modified: Schema.Optional(Schema.String),
  modified_by: Schema.Optional(Schema.String),
  size: Schema.Optional(Schema.Number),
  etag: Schema.Optional(Schema.String),
  event: Schema.Optional(PathEvent),
  mounted: Schema.Optional(Schema.Boolean),
  transaction_id: Schema.Optional(Schema.String),
  destination: Schema.Optional(Schema.String),
});

export const StatusType = Schema.Enum({
  OK: "OK",
  Denied: "DENIED",
  TokenExpired: "TOKEN_EXPIRED",
  UnknownError: "UNKNOWN_ERROR",
});

export const PrefixResult = Schema.Object({
  prefixes: Schema.Array(Schema.String),
  version: Schema.Number,
});

export const SearchGetPrefixesClientVersion = SearchGetPrefixesClientLocalVersion;

export const SearchResult = Schema.Object({
  status: StatusType,
  paths: Schema.Array(Path),
  version: Schema.Number,
});

export const SearchQuery2 = Schema.Object({
  query: Schema.Optional(Schema.String),
  parent: Schema.String,
});

export const SearchFindPagedSkipClientVersion = SearchFindPagedSkipClientLocalVersion;

export const SearchResultCursor = Schema.Object({
  paths: Schema.Array(Path),
  version: Schema.Number,
  cursor_id: Schema.String,
});

export const SearchFindPagedCursorClientVersion = SearchFindPagedCursorClientLocalVersion;

export const SearchQuery = Schema.Object({
  name: Schema.Optional(Schema.String),
  tags: Schema.Optional(Schema.Array(Schema.String)),
  parent: Schema.String,
});

export const SearchFind2ClientVersion = SearchFind2ClientLocalVersion;

export const SearchFindClientVersion = SearchFindClientLocalVersion;



export const SearchFindRequest = Schema.Object({
  version: SearchFindClientVersion,
  query: SearchQuery,
  token: Schema.String,
});

export const SearchFind2Request = Schema.Object({
  version: SearchFind2ClientVersion,
  query: SearchQuery,
  token: Schema.String,
});

export const SearchFindPagedCursorRequest = Schema.Object({
  version: SearchFindPagedCursorClientVersion,
  query: SearchQuery2,
  token: Schema.Optional(Schema.String),
  size: Schema.Optional(Schema.Number),
  cursor_id: Schema.Optional(Schema.String),
});

export const SearchFindPagedSkipRequest = Schema.Object({
  version: SearchFindPagedSkipClientVersion,
  query: SearchQuery2,
  token: Schema.Optional(Schema.String),
  size: Schema.Optional(Schema.Number),
  skip_first: Schema.Optional(Schema.Number),
});

export const SearchGetPrefixesRequest = Schema.Object({
  version: SearchGetPrefixesClientVersion,
});
