import React, { useCallback } from "react";
import styled, { css } from "styled-components";
import { Omit, StyledRenderer } from "./index";
import { DefaultTheme, ThemedComponentProps } from "./Theme";

export interface TableProps<TRow = any, TCellProps = any, TRowProps = any> {
  columns: TableColumn[];
  rows?: TRow[];
  rowKey?: keyof TRow;
  selectedRows?: TRow[];
  highlightedRow?: TRow;
  style?: React.CSSProperties;
  showHeaders?: boolean;
  cellProps?: TCellProps;
  rowProps?: TRowProps;
  tableComponent?: StyledRenderer<TableComponentProps>;
  headComponent?: StyledRenderer<TableHeadComponentProps>;
  bodyComponent?: StyledRenderer<TableBodyComponentProps<TRow>>;
  cellComponent?: StyledRenderer<TableCellComponentProps<TRow> & TCellProps>;
  headerComponent?: StyledRenderer<TableHeaderComponentProps>;
  rowComponent?: StyledRenderer<TableRowComponentProps<TRow> & TRowProps>;
  onSelect?(selectedRow: TRow): void;
  onHeaderSelect?(column: TableColumn): void;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  onContextMenu?(e: React.MouseEvent<HTMLElement>): void;
}

export interface TableColumn {
  name: string;
  title: string;

  // For auto set width to null.
  width?: number;
}

export interface TableComponentProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export interface TableHeadComponentProps {
  children: React.ReactNode;
  className?: string;
}

export interface TableBodyComponentProps<TRow> {
  rows: TRow[];
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export interface TableHeaderComponentProps {
  column: TableColumn;
  index: number;
  style: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
  onClick(column: TableColumn): void;
}

export interface TableRowComponentProps<TRow> {
  row: TRow;
  rowIndex: number;
  selected?: boolean;
  highlighted: boolean;
  className?: string;
  children?: React.ReactNode;
  onClick(): void;
}

export interface TableCellComponentProps<TRow> {
  column: TableColumn;
  row: TRow;
  rowIndex: number;
  value?: any;
  className?: string;
  style: React.CSSProperties;
  children?: React.ReactNode;
}

export default class Table<TRow extends any, TCellProps = any, TRowProps = any> extends React.PureComponent<
  TableProps<TRow, TCellProps, TRowProps>
> {
  public static defaultProps: Partial<TableProps> = {
    showHeaders: true,
  };

  public render() {
    const TableComponent = this.props.tableComponent || StyledTable;
    const HeadComponent = this.props.headComponent || TableHead;
    const HeaderComponent = this.props.headerComponent || TableHeader;
    const BodyComponent = this.props.bodyComponent || TableBody;
    const CellComponent = this.props.cellComponent || TableCell;
    const RowComponent = this.props.rowComponent || TableRow;

    const {
      selectedRows,
      style,
      rows,
      rowKey,
      columns,
      showHeaders,
      highlightedRow,
      cellProps,
      rowProps,
      onSelect,
      onHeaderSelect,
      ...props
    } = this.props;

    return (
      <TableComponent style={style} {...props}>
        {showHeaders && (
          <HeadComponent>
            {columns.map((column, index) => {
              const columnStyle = { width: column.width, flexShrink: 0, flexGrow: 0, flexBasis: "auto" };
              return (
                <HeaderComponent
                  key={column.name}
                  style={columnStyle}
                  column={column}
                  index={index}
                  children={column.title}
                  onClick={this.handleHeaderClick}
                />
              );
            })}
          </HeadComponent>
        )}

        {rows && rows.length > 0 && (
          <BodyComponent rows={rows}>
            {rows.map((row, index) => {
              const selected = selectedRows && selectedRows.includes(row);
              const highlighted = highlightedRow === row;
              const key = rowKey ? row[rowKey] : index;
              if (typeof key !== "string" && typeof key !== "number") {
                throw new Error(
                  `You specified a 'rowKey' prop ${rowKey} which resolved a non-numeric nor string key for the table: ${key}.`
                );
              }

              return (
                <MemoizedTableRow<TRow, TCellProps, TRowProps>
                  key={key}
                  selected={selected}
                  highlighted={highlighted}
                  columns={columns}
                  row={row}
                  rowIndex={index}
                  cellProps={cellProps}
                  rowProps={rowProps}
                  cellComponent={CellComponent as any}
                  rowComponent={RowComponent as any}
                  onClick={this.selectRow}
                />
              );
            })}
          </BodyComponent>
        )}
      </TableComponent>
    );
  }

  protected handleHeaderClick = (column: TableColumn) => {
    if (this.props.onHeaderSelect) {
      this.props.onHeaderSelect(column);
    }
  };

  protected selectRow = (row: TRow) => {
    if (this.props.onSelect) {
      this.props.onSelect(row);
    }
  };
}

export const StyledTable = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.foreground};
  font-size: 10pt;
  will-change: transform;
`;
StyledTable.defaultProps = {
  theme: DefaultTheme,
};

export const TableHead: React.FC<TableHeadComponentProps> = ({ className, children }) => (
  <StyledTableHead className={className}>
    <tr>{children}</tr>
  </StyledTableHead>
);

const StyledTableHead = styled.thead`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;
StyledTableHead.defaultProps = {
  theme: DefaultTheme,
};

export const TableHeader: React.FC<
  TableHeaderComponentProps & Omit<React.DetailsHTMLAttributes<HTMLTableHeaderCellElement>, "onClick">
> = ({ children, column, onClick, ...props }) => {
  const click = useCallback(() => onClick(column), [column, onClick]);
  return (
    <StyledTableHeader {...props} onClick={click}>
      {children}
    </StyledTableHeader>
  );
};

export const TableHeaderStyle = css`
  font-weight: 600;
  font-size: 10pt;
  color: ${({ theme }) => theme.colors.importantText};
  line-height: 15px;
  text-align: left;
  border-right: 1px solid ${({ theme }) => theme.colors.border};
  overflow: hidden;
  user-select: none;
  width: auto;
  padding: 10px;
`;

const StyledTableHeader = styled.th`
  ${TableHeaderStyle};
`;
StyledTableHeader.defaultProps = {
  theme: DefaultTheme,
};

export const TableBody = styled.tbody``;

interface MemoizedTableRowProps<T, TCellProps, TRowProps>
  extends Omit<TableRowComponentProps<T>, "onClick" | "children"> {
  columns: TableColumn[];
  cellProps?: Partial<TCellProps>;
  rowProps?: Partial<TRowProps>;
  cellComponent: React.ComponentType<TableCellComponentProps<T>>;
  rowComponent: StyledRenderer<TableRowComponentProps<T>>;
  onClick(row: T): void;
}

class MemoizedTableRow<TRow, TCellProps, TRowProps> extends React.PureComponent<
  MemoizedTableRowProps<TRow, TCellProps, TRowProps>
> {
  public render() {
    const {
      rowComponent: Row,
      cellComponent: Cell,
      columns,
      row,
      rowIndex,
      cellProps,
      rowProps,
      ...props
    } = this.props;
    const children = row
      ? columns.map((column) => {
          const cellStyle = { width: column.width, flexShrink: 0, flexGrow: 0, flexBasis: "auto" };
          const value = row[column.name as keyof TRow];
          return (
            <Cell
              key={column.name}
              {...cellProps}
              column={column}
              row={row}
              rowIndex={rowIndex}
              style={cellStyle}
              value={value}
            >
              {value}
            </Cell>
          );
        })
      : null;

    return (
      <Row {...props} {...rowProps} row={row} rowIndex={rowIndex} onClick={this.handleRowClick}>
        {children}
      </Row>
    );
  }

  private handleRowClick = () => {
    this.props.onClick(this.props.row);
  };
}

export const DefaultRowHeight = 20;

export const TableRow = styled.tr<TableRowComponentProps<any>>`
  user-select: none;
  width: 100%;
  height: ${DefaultRowHeight}px;

  background: ${({ theme, selected, highlighted }: StyledTableRowProps) =>
    selected ? theme!.colors.selectedItemBackground : highlighted ? theme!.colors.hoverItemBackground : "inherit"};

  color: ${({ theme, selected }: StyledTableRowProps) =>
    selected ? theme!.colors.selectedItemForeground : theme!.colors.foreground};

  &:hover {
    background: ${({ theme, selected }: StyledTableRowProps) =>
      selected ? theme!.colors.selectedItemHover : theme!.colors.hoverItemBackground};
  }
`;
TableRow.defaultProps = {
  theme: DefaultTheme,
};

export interface StyledTableRowProps extends ThemedComponentProps {
  selected?: boolean;
  highlighted: boolean;
}

export const TableCell: React.FC<
  TableCellComponentProps<any> & React.DetailsHTMLAttributes<HTMLTableDataCellElement>
> = React.memo(({ value, children, ...props }) => {
  if (!children) {
    children = value ? value.toString() : value;
  }
  return <StyledTableCell {...props}>{children}</StyledTableCell>;
});

const StyledTableCell = styled.td`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0;
`;
