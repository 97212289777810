import AuthForm, { AuthFormProps } from "@omniverse/auth/react/AuthForm";
import React from "react";
import useApp from "../hooks/useApp";
import useNucleusSignIn from "../hooks/useNucleusSignIn";
import { isElectron } from "../util/Electron";
import { joinURL } from "../util/Path";
import { getBaseURL } from "../util/URL";

const path = window.path;
const url = window.url;
const __dirname = window.__dirname;

export interface LoginFormProps extends AuthFormProps {}

const NucleusLoginForm: React.FC<LoginFormProps> = ({ onSuccess, ...props }) => {
  const app = useApp();
  const signIn = useNucleusSignIn({ onSuccess });
  const electron = isElectron();

  let ssoRedirectBackTo: string | undefined;
  if (electron && process.env.NODE_ENV === "production") {
    ssoRedirectBackTo = url.pathToFileString(path.join(__dirname, "..", "build", "public", "index.html"));
    ssoRedirectBackTo += "#/sso";
  } else {
    ssoRedirectBackTo = joinURL(window.location.origin, getBaseURL(), app.href("/sso"));
  }

  return <AuthForm {...props} ssoRedirectBackTo={ssoRedirectBackTo} onSuccess={signIn} />;
};

export default NucleusLoginForm;
