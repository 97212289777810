import { Credentials } from "@omniverse/auth/client";
import { CredentialSettings } from "@omniverse/auth/data";
import { useCallback, useEffect, useState } from "react";
import connect from "../Connection";

export default function useCredentialSettings(server: string): {
  settings: CredentialSettings | null;
  errors: string[];
  retry: () => unknown;
} {
  const [settings, setSettings] = useState<CredentialSettings | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [retryCount, setRetryCount] = useState(0);
  const retry = useCallback(() => {
    setRetryCount((count) => count + 1);
  }, []);

  useEffect(() => {
    let subscribed = true;
    setSettings(null);

    let debounced: number;
    if (server) {
      debounced = window.setTimeout(() => {
        let credentials: Credentials | null = null;
        setErrors([]);
        connect(server, Credentials, { "get_settings": 0 })
          .then((conn) => (credentials = conn))
          .then((credentials) => credentials.getSettings({}))
          .then((settings) => {
            if (subscribed) {
              setSettings(settings);
            }
          })
          .catch((err) => {
            setErrors(["Failed to connect to the server."]);
            console.warn(err);
          })
          .finally(() => {
            if (credentials) {
              credentials.transport.close();
            }
          });
      }, 300);
    }

    return () => {
      subscribed = false;
      if (debounced) {
        clearTimeout(debounced);
      }
    };
  }, [server, retryCount]);

  return { settings, errors, retry };
}
