import React from "react";
import PathGhost from "../components/PathGhost";
import PathListGhost from "../components/PathListGhost";
import dnd from "../state/DND";


export default function useDND() {
  function onMouseUp(e: React.MouseEvent) {
    dnd.reset();
  }

  let ghost = null;
  if (dnd.type === "path") {
    ghost = <PathGhost path={dnd.data} allowed={dnd.allowed} />;
  }

  if (dnd.type === "paths") {
    ghost = <PathListGhost pathCount={dnd.data.length} allowed={dnd.allowed} />;
  }

  return {
    ghost,
    onMouseUp
  }
}