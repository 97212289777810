import React from "react";
import styled from "styled-components";
import { DropStyle } from "../hooks/useDrop";
import { DefaultTheme } from "./Theme";

export type TreeItemProps = React.PropsWithoutRef<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> &
  TreeItemStyledProps;

export interface TreeItemStyledProps {
  selected?: boolean;
  highlighted?: boolean;
  level?: number;
}

const TreeItem: React.FC<TreeItemProps> = ({ children, ...props }) => {
  return (
    <StyledTreeItem {...props}>
      <TreeItemContent>{children}</TreeItemContent>
    </StyledTreeItem>
  );
};

const StyledTreeItem = styled.div<TreeItemStyledProps>`
  height: 30px;
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;
  background: ${({ selected, highlighted, theme }) =>
    selected
      ? theme.colors.selectedItemBackground
      : highlighted
      ? theme.colors.hoverItemBackground
      : theme.colors.background};
  color: ${({ selected, theme }) => (selected ? theme.colors.selectedItemForeground : theme.colors.foreground)};

  &:hover {
    background: ${({ selected, theme }) =>
      selected ? theme.colors.selectedItemHover : theme.colors.hoverItemBackground};
  }

  padding-left: ${({ level = 0 }) => level * 30 + 10}px;
  ${DropStyle};
`;
StyledTreeItem.defaultProps = {
  theme: DefaultTheme,
};

const TreeItemContent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  font-size: 10pt;
`;

TreeItem.defaultProps = {
  level: 0,
};

export default TreeItem;
