import styled from "styled-components";
import { DefaultTheme } from "./Theme";

const RightPanelContainer = styled.div`
  color: ${({ theme }) => theme.colors.foreground};
  padding: 0 15px 15px 15px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;
RightPanelContainer.defaultProps = {
  theme: DefaultTheme,
};

export default RightPanelContainer;
