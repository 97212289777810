import {useMemo} from "react";
import useApp from "./useApp";


const useStorageList = () => {
  const app = useApp();
  return useMemo(() => app.storages, [app]);
}

export default useStorageList;
