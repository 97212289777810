import React from "react";
import styled from "styled-components";
import Path, { PathType } from "../state/Path";
import { ReactComponent as FileImage } from "../static/file.svg";
import FolderImage from "../static/folder.png";
import Icon, { Icons } from "./Icon";
import Picture from "./Picture";

export interface PathTransferWidgetProps {
  source: Path[];
  destination: Path;
}

const PathTransfer: React.FC<PathTransferWidgetProps> = ({ source, destination }) => (
  <PathTransferWidgetContainer>
    {source.length === 1 ? <PathIconView path={source[0]} /> : <PathsIconView count={source.length} />}
    <TransferIcon />
    <PathIconView path={destination} />
  </PathTransferWidgetContainer>
);

const PathTransferWidgetContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto 15px auto;
`;

const PathIconView: React.FC<{ path: Path }> = ({ path }) => {
  const image = path.type === PathType.Folder ? FolderImage : FileImage;

  return (
    <PathIconViewContainer>
      <PathIcon src={image} title={path.link} />
      <PathName title={path.link}>{path.name}</PathName>
    </PathIconViewContainer>
  );
};

const PathIconViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 125px;
`;

const PathIcon = styled(Picture)`
  width: 100%;
  height: 100px;
  fill: ${({ theme }) => theme.colors.selectedIcon};
`;

const PathName = styled.div`
  text-align: center;
  font-size: 9pt;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: -5px;
`;

const PathsIconView: React.FC<{ count: number }> = ({ count }) => {
  const title = `Multiple files (${count})`;
  return (
    <PathIconViewContainer>
      <PathIcon src={FileImage} title={title} />
      <PathName title={title}>{title}</PathName>
    </PathIconViewContainer>
  );
};

export const TransferIcon = styled(Icon).attrs({
  icon: Icons.ArrowRight,
})`
  margin: 0 10px 5px 10px;
  font-size: 24pt;
  color: ${({ theme }) => theme.colors.foreground};
`;

export default PathTransfer;
