const Kilobyte = 1024;
const Megabyte = 1024 * 1024;
const Gigabyte = 1024 * 1024 * 1024;

export function toArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => resolve(reader.result as ArrayBuffer);
    reader.readAsArrayBuffer(blob);
  });
}

export function formatFileSize(size: number): string {
  let sizeText: string;
  if (size < Kilobyte) {
    sizeText = `${size}B`;
  } else if (size < Megabyte) {
    sizeText = `${(size / Kilobyte).toFixed(2)} KB`;
  } else if (size < Gigabyte) {
    sizeText = `${(size / Megabyte).toFixed(2)} MB`;
  } else {
    sizeText = `${(size / Gigabyte).toFixed(2)} GB`;
  }
  return sizeText;
}

export function parseFileSize(size: string, defaultUnits: string = ""): number {
  const regex = /([\d.]+)\s*([A-Za-z]+)?/;
  const match = regex.exec(size);
  if (match) {
    let [, value, units] = match;
    if (!units) {
      units = defaultUnits;
    }
    units = units.toUpperCase();

    if (units === "B") {
      return Number(value);
    } else if (units === "KB") {
      return Number(value) * Kilobyte;
    } else if (units === "MB") {
      return Number(value) * Megabyte;
    } else if (units === "GB") {
      return Number(value) * Gigabyte;
    } else {
      return Number.NaN;
    }
  } else {
    return Number.NaN;
  }
}

export function toBase64(value: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      let base64 = reader.result as string;
      base64 = base64.replace(/data:.*\/.*;base64,/, "");
      resolve(base64);
    };
    reader.readAsDataURL(value);
  });
}
