import React, { SetStateAction, useEffect } from "react";
import { FileError, useDropzone } from "react-dropzone";
import styled from "styled-components";
import useForceUpdate from "../hooks/useForceUpdate";
import { useStateOrProp } from "../hooks/useStateOrProp";
import { ReactComponent as DropAreaArrow } from "../static/dropzone-arrow.svg";
import Icon, { Icons } from "./Icon";
import { DefaultTheme } from "./Theme";

export interface DropAreaProps {
  accept?: string | string[];
  className?: string;
  disabled?: boolean;
  files?: DropAreaFile[];
  placeholder?: React.ReactNode;
  maxFiles?: number;
  maxSize?: number;
  replace?: boolean;
  tabIndex?: number;
  validator?: DropAreaValidator;
  onDrop(files: DropAreaFile[] | SetStateAction<DropAreaFile[]>): void;
  onError(error: string): void;
}

export type DropAreaFile = File & {
  preview?: string;
};

export type DropAreaValidator = (file: File) => FileError | FileError[] | null;

/**
 * Using react-dropzone module, read more here:
 * https://react-dropzone.js.org/
 */
const DropArea: React.FC<DropAreaProps> = ({
  accept,
  className,
  files,
  placeholder = "Drag and drop some files here, or click to select files",
  disabled = false,
  maxFiles = 6,
  maxSize,
  replace,
  tabIndex = 0,
  validator,
  onDrop,
  onError,
}) => {
  const [currentFiles, setCurrentFiles, controlled] = useStateOrProp(files, onDrop, []);
  const forceUpdate = useForceUpdate();

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    accept,
    disabled,
    maxFiles,
    maxSize,
    noDragEventsBubbling: true,
    onDrop: (acceptedFiles, fileRejections) => {
      const currentEntries = replace ? [] : currentFiles;
      const totalEntriesCount = currentEntries.length + acceptedFiles.length + fileRejections.length;
      if (totalEntriesCount > maxFiles) {
        return onError(`You can only upload ${maxFiles} files.`);
      }

      if (fileRejections.length) {
        let message = "You cannot upload these files";
        message += ": \n";
        message += fileRejections
          .map(
            (rejection) =>
              `- ${rejection.file.name}:\n${rejection.errors.map((error) => `\t- ${error.message}`).join("\n")}`
          )
          .join("\n");
        return onError(message);
      }

      const updatedFiles = currentEntries.concat(acceptedFiles);
      setCurrentFiles(updatedFiles);
      onError("");

      if (!controlled) {
        onDrop(updatedFiles);
      }
    },
    validator,
  });

  useEffect(() => {
    for (const file of currentFiles) {
      if (file.type.startsWith("image/")) {
        file.preview = URL.createObjectURL(file);
      }
    }

    forceUpdate();

    return () => {
      for (const file of currentFiles) {
        if (file.preview) {
          URL.revokeObjectURL(file.preview);
        }
      }
    };
  }, [currentFiles]);

  return (
    <StyledDropArea className={className}>
      <DropZone {...getRootProps()} disabled={disabled} isDragActive={isDragActive} tabIndex={tabIndex}>
        <input {...getInputProps()} />
        {currentFiles.length > 0 ? (
          <DropAreaPreviews>
            {currentFiles.map((file) => (
              <DropAreaPreview key={file.name}>
                <DropAreaPreviewDeleteIcon
                  disabled={disabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    if (disabled) return;
                    setCurrentFiles((files) => files.filter((f) => f !== file));
                  }}
                />
                {file.preview ? (
                  <DropAreaThumbnail src={file.preview} alt={file.name} />
                ) : (
                  <UploadedFile>
                    <Icon icon={Icons.File} />
                    <UploadedFileName title={file.name}>{file.name}</UploadedFileName>
                  </UploadedFile>
                )}
              </DropAreaPreview>
            ))}
          </DropAreaPreviews>
        ) : (
          placeholder
        )}
      </DropZone>
    </StyledDropArea>
  );
};

const StyledDropArea = styled.section`
  display: flex;
  flex-direction: column;
`;

const DropZone = styled.div<{ disabled: boolean; isDragActive: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 8pt;
  text-align: center;
  color: ${({ theme }) => theme.colors.hint};
  border: 2px dashed ${({ theme }) => theme.colors.border};
  background: ${({ isDragActive }) => (isDragActive ? "rgba(255, 255, 255, 0.1)" : "inherit")};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  transition: 0.3s background-color;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? "inherit" : "rgba(255, 255, 255, 0.1)")};
  }

  &:focus {
    outline: 2px solid #6ba6f6;
  }
`;
DropZone.defaultProps = {
  theme: DefaultTheme,
};

export const DropAreaPreviews = styled.aside`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

export const DropAreaPreview = styled.div`
  display: inline-flex;
  width: 110px;
  height: 110px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.border};
  position: relative;
  box-sizing: border-box;
  padding: 10px;
`;
DropAreaPreview.defaultProps = {
  theme: DefaultTheme,
};

export const DropAreaThumbnail = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-size: 8pt;
  vertical-align: middle;
  text-align: center;
`;

const UploadedFile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  i {
    font-size: 24pt;
    flex: 0 0 60px;
  }
`;

const UploadedFileName = styled.span`
  display: -webkit-box;
  width: 100%;
  font-size: 8pt;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 0 0 28px;
  height: 28px;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const DropAreaPreviewDeleteIcon = styled(Icon).attrs({
  icon: Icons.Close,
  title: "Delete",
  clickable: true,
  tabIndex: 0,
})<{
  disabled?: boolean;
}>`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

export { DropAreaArrow };

export default DropArea;
