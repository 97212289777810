import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import Icon, { Icons } from "./Icon";
import Menu, { MenuItemEntry } from "./Menu";
import MenuItem, { MenuItemProps } from "./MenuItem";

export interface MenuItemGroupProps extends MenuItemProps {
  disabled?: boolean;
  label: React.ReactNode;
}

const MenuItemGroup = React.forwardRef(
  ({ disabled, label, children, onMouseOver, ...props }: MenuItemGroupProps, ref) => {
    const [visible, setVisible] = useState(false);
    const labelRef = useRef<HTMLSpanElement>(null);
    const submenuRef = useRef<HTMLDivElement>(null);

    const show = useCallback((e?: React.MouseEvent<HTMLElement>) => {
      if (e && !submenuRef.current?.contains(e.target as HTMLElement)) {
        e.preventDefault();
        e.stopPropagation();
      }
      setVisible(true);
    }, []);

    const hide = useCallback((e?: React.MouseEvent) => {
      setVisible(false);
    }, []);

    const handleMouseOver = useCallback(
      (e: React.MouseEvent<HTMLLIElement>) => {
        onMouseOver?.(e);
        show();
      },
      [onMouseOver, show]
    );

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent) => {
        if (e.key === "Enter" || e.key === " ") {
          e.stopPropagation();

          if (visible) {
            hide();
          } else {
            labelRef.current?.click();
          }
        }
      },
      [visible, hide]
    );

    const attachGroupEntry = useCallback(
      (entry: MenuItemEntry) => {
        entry.onExpand = show;
        entry.onCollapse = hide;
        entry.onSelect = show;
      },
      [show, hide]
    );

    const addSubItem = useCallback(
      (entry: MenuItemEntry) => {
        entry.onCollapse = hide;
      },
      [hide]
    );

    return (
      <StyledMenuItemGroup
        ref={ref as any}
        {...props}
        onMouseDown={show}
        onClick={show}
        onMouseOver={handleMouseOver}
        onMouseLeave={hide}
        onKeyDown={handleKeyDown}
        onEntryAttach={attachGroupEntry}
      >
        <MenuItemGroupLabel ref={labelRef as any}>{label}</MenuItemGroupLabel>
        <MenuItemGroupIcon icon={Icons.AngleRight} />
        <MenuItemGroupChildren>
          {visible && (
            <Menu ref={submenuRef} onItemAdded={addSubItem} onClick={hide} onHide={hide}>
              {children}
            </Menu>
          )}
        </MenuItemGroupChildren>
      </StyledMenuItemGroup>
    );
  }
);

const MenuItemGroupLabel = styled.div`
  flex: 1;
  display: flex;
`;

const MenuItemGroupIcon = styled(Icon)`
  margin-left: auto;
  margin-right: -5px;
`;

const MenuItemGroupChildren = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  padding-left: 0;
`;

const StyledMenuItemGroup = styled(MenuItem)``;

export default MenuItemGroup;
