import React, { useCallback, useState } from "react";

export default function useInput<T extends string>(initialState: T | (() => T)) {
  const [value, setValue] = useState<T>(initialState);
  const setInputValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value as T);
    },
    [setValue]
  );
  return [value, setInputValue, setValue] as const;
}
