import { useCallback } from "react";
import Path from "../state/Path";
import useAppLocation from "./useAppLocation";
import useAppNavigate from "./useAppNavigate";

export interface UsePathNavigateOptions {
  includeSearch?: boolean;
}

function usePathNavigate({ includeSearch = true }: UsePathNavigateOptions = {}) {
  const navigate = useAppNavigate();
  const location = useAppLocation();

  return useCallback(
    (path: Path) => {
      if (!path.link) {
        path.invalidateLink();
      }

      if (!path.link) {
        return;
      }

      let url = `/${path.link}`;
      if (includeSearch) {
        url += location.search;
      }

      navigate(url);
    },
    [navigate, location, includeSearch]
  );
}

export default usePathNavigate;
