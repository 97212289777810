import { AuthStatus } from "@omniverse/auth/data";
import { GetProfileError } from "../../../util/UserErrors";
import { Profile } from "../../User";
import { Commands } from "../Provider";
import { IGetUserProfileCommandArguments } from "../types/GetUserProfileCommand";
import { NucleusCommand } from "./index";
import { parseNucleusProfile } from "./Nucleus";

export default class NucleusGetUserProfileCommand extends NucleusCommand<
  IGetUserProfileCommandArguments,
  void,
  Profile
> {
  name = Commands.GetUserProfile;

  public async allowed(): Promise<boolean> {
    return true;
  }

  public async execute({ user }: IGetUserProfileCommandArguments): Promise<Profile> {
    console.log(`[${this.provider.name}] Get profile for user "${user.name}"`);
    let profiles;
    try {
      profiles = await this.provider.createProfilesClient();

      const result = await profiles.get({ username: user.name });
      if (result.status !== AuthStatus.OK) {
        throw new GetProfileError(result);
      }

      const profile = parseNucleusProfile(result.profile!);
      user.setProfile(profile!);
      return profile!;
    } finally {
      if (profiles) {
        await profiles.transport.close();
      }
    }
  }
}
