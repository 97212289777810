import React, { AriaAttributes, useCallback } from "react";
import styled, { css } from "styled-components";

export interface IconProps extends AriaAttributes {
  icon: string;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  draggable?: boolean;
  disabled?: boolean;
  tabIndex?: number;
  onClick?(e: React.MouseEvent): void;
  onMouseDown?(e: React.MouseEvent): void;
  onDragStart?(e: React.DragEvent): void;
}

export const Icon = ({
  icon,
  title,
  className,
  style,
  draggable,
  disabled,
  tabIndex,
  onClick,
  onMouseDown,
  onDragStart,
  ...props
}: IconProps) => {
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === " ") {
        e.stopPropagation();
        e.currentTarget.click();
      }
    },
    []
  );

  return (
    <IconContainer
      {...props}
      className={className}
      style={style}
      title={title}
      draggable={draggable && !disabled}
      tabIndex={tabIndex}
      role={"img"}
      aria-label={title}
      onClick={disabled ? undefined : onClick}
      onKeyDown={disabled ? undefined : onKeyDown}
      onMouseDown={disabled ? undefined : onMouseDown}
      onDragStart={disabled ? undefined : onDragStart}
    >
      <i className={icon}/>
    </IconContainer>
  );
};

Icon.displayName = "Icon";

Icon.defaultProps = {
  tabIndex: -1,
};

const IconContainer = styled.span`
  display: inline-block;
  line-height: 1;
  ${({ tabIndex }) => tabIndex === -1 && css`
    outline: none;
  `};
  ${({ onClick, onMouseDown, onKeyDown }) => (onClick || onMouseDown || onKeyDown) && css`
    cursor: pointer;
  `};
`;

export default Icon;