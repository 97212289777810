import React, { AriaAttributes } from "react";
import styled from "styled-components";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import Dialog from "./Dialog";
import DialogSpinner from "./DialogSpinner";
import DialogTextWrapper from "./DialogTextWrapper";
import DialogTitle from "./DialogTitle";
import { PictureSource } from "./Picture";

export interface ConfirmationDialogProps extends AriaAttributes {
  title: string;
  loading?: boolean;
  image?: PictureSource;
  imageStyle?: React.CSSProperties;
  overlay?: boolean;
  onConfirm(): void;
  onCancel(): void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  image,
  imageStyle,
  loading = false,
  overlay,
  children,
  onConfirm,
  onCancel,
  ...props
}) => {
  return (
    <Dialog {...props} aria-label={title} disabled={loading} overlay={overlay} onConfirm={onConfirm} onClose={onCancel}>
      <DialogTitle title={title} image={image} imageStyle={imageStyle} disabled={loading} onClose={onCancel} />
      <DialogSpinner loading={loading} />
      <DialogTextWrapper>
        <ConfirmationText>{children}</ConfirmationText>

        <ButtonGroup>
          <Button name={"confirm"} kind={"attention"} disabled={loading} onClick={onConfirm}>
            Confirm
          </Button>
          <Button name={"cancel"} disabled={loading} onClick={onCancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </DialogTextWrapper>
    </Dialog>
  );
};

export const ConfirmationText = styled.div`
  margin: 15px 0;
  word-break: break-word;
`;

export default ConfirmationDialog;
