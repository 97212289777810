import styled, { keyframes } from "styled-components";
import { DefaultTheme } from "./Theme";

export const PopupAppearKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
`;

export const PopupDisappearKeyframes = keyframes`
  0% {
    opacity: 1;
  }
  
  100% {
    opacity: 0;
  }
`;

export const PopupAnimationTime = 300;

const Popup = styled.div<{ visible?: boolean }>`
  position: relative;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: ${({ theme }) => theme.shadows.default};
  padding: 5px;
  align-items: center;
  min-width: 150px;
  min-height: 15px;
  animation: ${({ visible }) => (visible ? PopupAppearKeyframes : PopupDisappearKeyframes)} ${PopupAnimationTime}ms
    ease-in;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  flex: 0 0 auto;
`;

Popup.defaultProps = {
  theme: DefaultTheme,
  visible: true,
};

export default Popup;
