import { observer } from "mobx-react";
import React, { useState } from "react";
import styled from "styled-components";
import usePermission from "../hooks/usePermission";
import { Commands, TagCommands } from "../state/commands/Provider";
import { IGetACLCommand } from "../state/commands/types/GetACLCommand";
import { IGetCheckpointsCommand } from "../state/commands/types/GetCheckpointsCommand";
import { ISetACLCommand, ISetACLCommandAllowedArguments } from "../state/commands/types/SetACLCommand";
import { IGetTagsCommand, IGetTagsCommandAllowedArguments } from "../state/commands/types/tags/GetTagsCommand";
import { ISetTagsCommand } from "../state/commands/types/tags/SetTagsCommand";
import Path, { PathType } from "../state/Path";
import search from "../state/PathSearch";
import { getDefaultPathThumbnail } from "../util/PathThumbnail";
import Button from "./Button";
import CheckpointList from "./CheckpointList";
import Icon, { Icons } from "./Icon";
import PathAttributes from "./PathAttributes";
import PathCopyURLButton from "./PathCopyURLButton";
import PathNavigateButton from "./PathNavigateButton";
import PathOpenButton from "./PathOpenButton";
import PathPermissionList from "./PathPermissionList";
import Picture from "./Picture";
import RightPanel from "./RightPanel";
import RightPanelHeader from "./RightPanelHeader";
import RightPanelHeaderIcon from "./RightPanelHeaderIcon";
import RightPanelPath from "./RightPanelPath";
import RightPanelRow from "./RightPanelRow";
import RightPanelScrollable from "./RightPanelScrollable";
import RightPanelTabs, { RightPanelTab } from "./RightPanelTabs";
import RightPanelTitle from "./RightPanelTitle";
import RightPanelToggleable from "./RightPanelToggleable";
import TagList from "./TagList";

export interface PathPanelProps {
  path: Path;
  initial?: {
    showCheckpoints?: boolean;
    showTags?: boolean;
  };
  onToggleCheckpoints?(visible: boolean): void;
  onToggleTags?(visible: boolean): void;
}

const PathPanel: React.FC<PathPanelProps> = ({
  path,
  initial = { showCheckpoints: true, tags: true },
  onToggleCheckpoints,
  onToggleTags,
}) => {
  const [attributesVisible, setAttributesVisible] = useState(true);
  const defaultThumbnail = getDefaultPathThumbnail(path);

  function toggleAttributes() {
    setAttributesVisible((value) => !value);
  }

  function download() {
    return path.download();
  }

  const canSeeTags = usePermission(
    () =>
      path.storage.commands.allowed<IGetTagsCommand, IGetTagsCommandAllowedArguments>(TagCommands.Get, {
        path,
      }),
    [path]
  );
  const canChangeTags = usePermission(
    () =>
      path.storage.commands.allowed<ISetTagsCommand>(TagCommands.Set, {
        path,
      }),
    [path]
  );

  const canSeePermissions = usePermission(() => path.storage.commands.allowed<IGetACLCommand>(Commands.GetACL), []);
  const canChangePermissions = usePermission(
    () => path.storage.commands.allowed<ISetACLCommand, ISetACLCommandAllowedArguments>(Commands.SetACL, { path }),
    [path]
  );

  const canSeeCheckpoints = usePermission(
    () =>
      path.storage.commands.allowed<IGetCheckpointsCommand>(Commands.GetCheckpoints, {
        path,
        checkpoints: path.checkpoints,
      }),
    [path]
  );

  return (
    <RightPanel>
      <RightPanelTabs>
        <RightPanelTab name={"details"} label={"Details"}>
          <RightPanelHeader>
            <RightPanelRow>
              <PathPanelThumbnail
                key={path.path}
                src={path.thumbnail}
                alt={path.name}
                fallbacks={[path.generatedThumbnail, defaultThumbnail]}
              />
              <RightPanelTitle title={path.name}>{path.name}</RightPanelTitle>
              <RightPanelHeaderIcon
                icon={attributesVisible ? Icons.Minus : Icons.Plus}
                title={"Toggle details"}
                onClick={toggleAttributes}
              />
            </RightPanelRow>

            {attributesVisible && <PathPanelAttributes path={path} />}
          </RightPanelHeader>

          <RightPanelScrollable>
            <RightPanelPath>{path.path}</RightPanelPath>

            <PathPanelControls>
              {path.canDownload() && (
                <Button onClick={download}>
                  <Icon icon={Icons.Download} /> Download
                </Button>
              )}

              <PathCopyURLButton path={path} />

              {path.type === PathType.File && <PathOpenButton path={path} />}
              {search.active && <PathNavigateButton path={path} />}
            </PathPanelControls>

            <RightPanelToggleable
              label={"Checkpoints"}
              icon={Icons.Camera}
              visible={canSeeCheckpoints === "allowed"}
              initial={initial?.showCheckpoints}
              onToggle={onToggleCheckpoints}
            >
              <CheckpointList path={path} />
            </RightPanelToggleable>

            <RightPanelToggleable
              label={"Tags"}
              icon={Icons.Tags}
              visible={canSeeTags === "allowed"}
              initial={initial?.showTags}
              onToggle={onToggleTags}
            >
              <TagList tags={path.tags} readOnly={canChangeTags !== "allowed"} />
            </RightPanelToggleable>
          </RightPanelScrollable>
        </RightPanelTab>

        {canSeePermissions === "allowed" && (
          <RightPanelTab name={"permissions"} label={"Permissions"}>
            <RightPanelHeader>
              <RightPanelRow>
                <PathPanelThumbnail
                  key={path.path}
                  src={path.thumbnail}
                  alt={path.name}
                  fallbacks={[path.generatedThumbnail, defaultThumbnail]}
                />
                <RightPanelTitle title={path.name}>{path.name}</RightPanelTitle>
              </RightPanelRow>
            </RightPanelHeader>

            <PathPermissionList acl={path.acl} readOnly={canChangePermissions !== "allowed"} />
          </RightPanelTab>
        )}
      </RightPanelTabs>
    </RightPanel>
  );
};

const PathPanelThumbnail = styled(Picture)`
  display: inline-block;
  width: 32px;
  max-height: 100%;
  box-sizing: border-box;
  vertical-align: middle;
  margin-right: 15px;
  fill: ${({ theme }) => theme.colors.selectedIcon};
`;

const PathPanelAttributes = styled(PathAttributes)`
  margin-top: 10px;
  padding: 10px 15px 0 15px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  font-size: 10pt;
`;

const PathPanelControls = styled(RightPanelRow)`
  padding: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  gap: 10px;
`;

export default observer(PathPanel);
