import { Credentials, SSO } from "@omniverse/auth";
import { AuthStatus } from "@omniverse/auth/data";
import { OfflineModeError } from "../../../util/SessionErrors";
import { ManageUsersError } from "../../../util/UserErrors";
import User from "../../User";
import { Commands } from "../Provider";
import { ICreateUserCommandArguments } from "../types/CreateUserCommand";
import { NucleusCommand } from "./index";
import { parseNucleusProfile } from "./Nucleus";

export default class NucleusCreateUserCommand extends NucleusCommand<ICreateUserCommandArguments, void, User> {
  name = Commands.CreateUser;

  public async allowed(): Promise<boolean> {
    return Boolean(this.provider.session.isSuperUser);
  }

  public async execute({ users, user }: ICreateUserCommandArguments): Promise<User> {
    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }

    console.log(`[${this.provider.name}] Create user "${user.name}"`);
    const [canUseCredentials, canUseSSO] = await Promise.all([this.canUseCredentials(), this.canUseSSO()]);
    if (user.name.includes("@")) {
      if (!canUseSSO) {
        throw new Error("SSO is not supported on this server. Please specify a username without @ symbol.");
      }
    } else {
      if (!canUseCredentials) {
        throw new Error("Internal accounts are not supported on this server. Please specify a username with @ symbol.");
      }
    }

    const profiles = await this.provider.createProfilesClient();
    try {
      const response = await profiles.add({
        username: user.name,
        first_name: user.profile?.firstName,
        last_name: user.profile?.lastName,
        email: user.profile?.email,
        token: this.provider.session.accessToken!,
      });

      if (response.status === AuthStatus.Expired) {
        await this.provider.session.refresh();
        return this.execute({ users, user });
      }

      if (response.status === AuthStatus.OK) {
        const profile = parseNucleusProfile(response.profile);
        const user = new User(response.username!, users.storage, profile);
        users.add(user);
        return user;
      } else {
        throw new ManageUsersError(response);
      }
    } finally {
      await profiles.transport.close();
    }
  }

  private async canUseCredentials(): Promise<boolean> {
    const credentials = await this.provider.createServiceClient(Credentials, "AUTH");
    try {
      const settings = await credentials.getSettings();
      return Boolean(settings.is_ui_visible);
    } finally {
      await credentials.transport.close();
    }
  }

  private async canUseSSO(): Promise<boolean> {
    const sso = await this.provider.createServiceClient(SSO, "AUTH");
    try {
      const stream = await sso.getSettings();
      const settings = await stream.readAll();
      return Boolean(settings.length > 0);
    } finally {
      await sso.transport.close();
    }
  }
}
