import "focus-visible";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./static/css/index.css";

if (process.env.NODE_ENV === "development") {
  const publicURL = document.getElementById("public-url") as HTMLBaseElement;
  if (publicURL) {
    publicURL.setAttribute("href", "/");
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
