import React, { useCallback, useEffect, useMemo, useState } from "react";
import useContextMenu from "../hooks/useContextMenu";
import { Icons } from "./Icon";
import { StyledRenderer } from "./index";
import Menu from "./Menu";
import { MenuIconItem } from "./MenuItem";
import { TableColumn, TableHeadComponentProps } from "./Table";

export interface TableColumnVisibilityProps {
  columns: TableColumn[];
  children(data: TableColumnVisibilityData): JSX.Element;
  headComponent?: StyledRenderer<TableHeadComponentProps>;
}

export interface TableColumnVisibilityData {
  columns: TableColumn[];
  headComponent: StyledRenderer<TableHeadComponentProps>;
}

interface ColumnVisibilityMap {
  [columnName: string]: {
    column: TableColumn;
    visible: boolean;
  };
}

const TableColumnVisibility: React.FC<TableColumnVisibilityProps> = ({ columns, headComponent, children }) => {
  const [columnVisibility, setColumnVisibility] = useState(getVisibility(columns));
  const toggleColumn = useCallback((column: TableColumn) => {
    setColumnVisibility((visibility) => ({
      ...visibility,
      [column.name]: {
        column,
        visible: !visibility[column.name].visible,
      },
    }));
  }, []);

  const [menu, onContextMenu] = useContextMenu(TableColumnVisibilityMenu, {
    props: {
      columns,
      visibility: columnVisibility,
      onToggle: toggleColumn,
    },
  });

  useEffect(() => {
    setColumnVisibility((visibility) => getVisibility(columns, visibility));
  }, [columns]);

  const head = useCallback(
    (headProps: TableHeadComponentProps) => {
      const HeadComponent = headComponent;
      return (
        <div onContextMenu={onContextMenu}>{HeadComponent ? <HeadComponent {...headProps} /> : headProps.children}</div>
      );
    },
    [headComponent, onContextMenu]
  );

  const visibleColumns = useMemo(() => {
    return Object.values(columnVisibility)
      .filter((columnVisibility) => columnVisibility.visible)
      .map((columnVisibility) => columnVisibility.column);
  }, [columnVisibility]);

  return (
    <>
      {children({
        columns: visibleColumns,
        headComponent: head,
      })}

      {menu}
    </>
  );
};

const getVisibility = (columns: TableColumn[], previousVisibility: ColumnVisibilityMap = {}): ColumnVisibilityMap => {
  return columns.reduce<ColumnVisibilityMap>((visibility, column) => {
    visibility[column.name] = {
      column,
      visible: typeof previousVisibility[column.name] === "undefined" || previousVisibility[column.name].visible,
    };
    return visibility;
  }, {});
};

const TableColumnVisibilityMenu: React.FC<{
  columns: TableColumn[];
  visibility: ColumnVisibilityMap;
  onToggle(column: TableColumn): void;
}> = ({ columns, visibility, onToggle }) => {
  return (
    <Menu>
      {columns.map((column) => (
        <MenuIconItem
          key={column.name}
          icon={visibility[column.name].visible ? Icons.Checked : Icons.Empty}
          onClick={() => onToggle(column)}
        >
          {column.title}
        </MenuIconItem>
      ))}
    </Menu>
  );
};

export default TableColumnVisibility;
