import { useEffect, useRef, useState } from "react";

export type CommandPermission = () => Promise<boolean>;

export default function usePermission(permission: CommandPermission, keys: readonly unknown[]) {
  const [state, setState] = useState<"loading" | "allowed" | "denied">("loading");
  const permissionRef = useRef(permission);
  permissionRef.current = permission;

  useEffect(() => {
    setState("loading");

    const permission = permissionRef.current;
    if (permission) {
      permission()
        .then((allowed) => {
          setState(allowed ? "allowed" : "denied");
        })
        .catch((err) => {
          console.error(err);
          setState("denied");
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, keys);

  return state;
}
