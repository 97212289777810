import React from "react";
import styled from "styled-components";
import FormError from "./FormError";

export interface FormErrorListProps {
  errors: string[];
}

export const FormErrorList = ({ errors, ...props }: FormErrorListProps) => {
  if (!errors || !errors.length) {
    return null;
  }

  return (
    <StyledFormErrorList {...props} as={"ul"}>
      {errors.map((error, index) => (
        <FormErrorListItem key={index}>{error}</FormErrorListItem>
      ))}
    </StyledFormErrorList>
  );
};

FormErrorList.defaultProps = {
  errors: [],
};

const StyledFormErrorList = styled(FormError)`
  list-style: none;
`;

const FormErrorListItem = styled.li``;


export default FormErrorList;
