import { observer } from "mobx-react";
import React, { useState } from "react";
import styled from "styled-components";
import { TagCommands } from "../state/commands/Provider";
import { ISetTagsCommand } from "../state/commands/types/tags/SetTagsCommand";
import Tags from "../state/Tags";
import Icon, { Icons } from "./Icon";
import Loader from "./Loader";
import { default as TagList, TagAutoCompleteMatch, TagMode } from "./Tags";

export interface PathTagEditorProps {
  tags: Tags;
  readOnly?: boolean;
}

const TagEditor: React.FC<PathTagEditorProps> = ({ tags, readOnly = false }) => {
  const [mode, setMode] = useState(TagMode.View);
  const [value, setValue] = useState("");

  function submit(names: string[]) {
    const values = tags.getTagsFromNames(names);
    const command = tags.storage.commands.get<ISetTagsCommand>(TagCommands.Set);
    if (command) {
      return command.execute({ tags, values });
    } else {
      throw new Error("Tags are not supported.");
    }
  }

  function toggleMode() {
    if (mode === TagMode.Edit) {
      setMode(TagMode.View);
      submit(Tags.parseNames(value));
    } else {
      setMode(TagMode.Edit);
      setValue(Tags.formatNames(tags.names));
    }
  }

  function handleBlur(e: React.FocusEvent) {
    e.preventDefault();
  }

  return (
    <StyledPathTagEditor>
      {!readOnly && <EditIcon title={"Submit tags"} onClick={toggleMode} />}
      {tags.loading && <StyledLoader>Loading...</StyledLoader>}

      <StyledTagList
        tags={tags.names}
        value={value}
        mode={mode}
        suggestions={tags.suggestions}
        placeholder={"No tags added."}
        loading={tags.loading}
        disabled={readOnly}
        onChange={setValue}
        onSubmit={submit}
        onModeChange={setMode}
        onBlur={handleBlur}
      />
    </StyledPathTagEditor>
  );
};

const StyledPathTagEditor = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.inputBackground};
`;

const StyledPathTagMatch = styled(TagAutoCompleteMatch)`
  position: relative;
`;

const StyledTagList = styled(TagList).attrs({
  matchComponent: StyledPathTagMatch,
})`
  padding: 5px 50px 5px 5px;
  min-height: 20px;
  z-index: 1;
`;

const StyledLoader = styled(Loader)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  z-index: 10;
  background: rgba(255, 255, 255, 0.75);
  padding: 0 10px;
`;

const EditIcon = styled(Icon).attrs({ icon: Icons.Edit, clickable: true })`
  position: absolute;
  top: 8px;
  right: 25px;
  z-index: 5;
  color: ${({ theme }) => theme.colors.icon};
`;

export default observer(TagEditor);
