import Path from "../Path";
import Storage from "../Storage";
import Nucleus from "./nucleus/Nucleus";
import NucleusSession from "./nucleus/NucleusSession";

export interface IProvider {
  readonly type: string;
  readonly name: string;
  readonly publicName: string;
  readonly session: ISession;
  readonly linkGenerator: ILinkGenerator;

  commands: CommandRegistry<any>;

  init?(): Promise<void>;
  close?(): void;
  getRoot?(storage: Storage): Promise<Path>;
  toJSON(): any;
}

export class CommandRegistry<TProvider extends IProvider = IProvider> {
  private readonly provider: TProvider;
  private readonly commands: Map<string, new (provider: TProvider) => ICommand<any, any, any>>;

  public constructor(provider: TProvider) {
    this.provider = provider;
    this.commands = new Map();
  }

  public has<T extends ICommand<any, any, any>>(commandName: T["name"]): boolean {
    return this.commands.has(commandName);
  }

  public get<T extends ICommand<any, any, any>>(commandName: T["name"]): T | undefined {
    const command = this.commands.get(commandName);
    if (command) {
      return new command(this.provider) as T;
    }
  }

  public set<T extends ICommand<any, any, any>>(commandName: T["name"], command: new (provider: TProvider) => T): void {
    this.commands.set(commandName, command);
  }

  public allowed<T extends ICommand<any, void, any>>(commandName: T["name"]): Promise<boolean>;
  public allowed<T extends ICommand<any, AllowedArguments, any>, AllowedArguments = any>(
    commandName: T["name"],
    args: AllowedArguments
  ): Promise<boolean>;
  public allowed(commandName: string, args?: unknown): Promise<boolean> {
    const command = this.get(commandName);
    if (command) {
      return command.allowed(args);
    } else {
      return Promise.resolve(false);
    }
  }

  public clear(): void {
    this.commands.clear();
  }
}

export interface ICommand<Arguments = void, AllowedArguments = void, Result = void> {
  readonly name: string;
  readonly provider: IProvider;
  allowed(args: AllowedArguments): Promise<boolean>;
  execute(args: Arguments): Promise<Result>;
}

export interface ISession {
  readonly username?: string;
  readonly established: boolean;
  refresh(): Promise<void>;
  disconnect(): void;
}

export interface IExternalAuth {
  run(): Promise<void>;
  cancel(): void;
}

export enum Commands {
  List = "list",
  Subscribe = "subscribe",
  Unsubscribe = "unsubscribe",
  CreateFolder = "create-folder",
  Copy = "copy",
  Move = "move",
  Delete = "delete",
  Search = "search",
  FindSimilar = "search-similar",
  GetSearchPrefixes = "get-search-prefixes",
  Upload = "upload",
  GetResolvedACL = "get-resolved-acl",
  GetACL = "get-acl",
  SetACL = "set-acl",
  Mount = "mount",
  Unmount = "unmount",

  DownloadCheckpoint = "download-checkpoint",
  CreateCheckpoint = "create-checkpoint",
  GetCheckpoints = "get-checkpoints",
  DeleteCheckpoint = "delete-checkpoint",
  RestoreCheckpoint = "restore-checkpoint",
  GetUsers = "get-users",
  GetGroups = "get-groups",
  GetAPITokens = "get-api-tokens",
  CreateGroup = "create-group",
  RenameGroup = "rename-group",
  DeleteGroup = "delete-group",
  AddUserToGroup = "add-user-to-group",
  GetGroupUsers = "get-group-users",
  GetUserGroups = "get-user-groups",
  RemoveUserFromGroup = "remove-user-from-group",
  CreateUser = "create-user",
  CreateAPIToken = "create-api-token",
  DeleteAPIToken = "delete-api-token",
  GetUserProfile = "get-user-profile",
  SetUserProfile = "set-user-profile",
  SetUserEnabled = "set-user-profile-enabled",
  SetUserAdminAccess = "set-user-admin-access",
  SetUserReadOnlyAccess = "set-user-read-only-access",
  GenerateResetPasswordLink = "generate-reset-password-link",
  ResetPassword = "reset-password",
  GenerateInvitationLink = "generate-invitation-link",
  ActivateUser = "activate-user",
}

export enum TagCommands {
  Get = "tags/get",
  GetSuggestions = "tags/get-suggestions",
  Set = "tags/set",
  Add = "tags/add",
  Edit = "tags/edit",
  Delete = "tags/delete",
}

export interface ILinkGenerator {
  parseLink(value: string): ILinkParams | null;
  createLink(params: ILinkParams): string;
  createDownloadLink(params: ILinkParams): Promise<string>;
  createThumbnailLink(params: ILinkParams): Promise<string>;
  createGeneratedThumbnailLink(params: ILinkParams): Promise<string>;
}

export interface ILinkParams {
  storage: string;
  path: string;
}

export function parseProvider(name: string, data: any): IProvider {
  // TODO: Registry pattern
  if (data.type === "nucleus") {
    if (!data.server) {
      data.server = name;
    }

    const authentication = data.session ? NucleusSession.fromJSON(data.session) : new NucleusSession(data.server);
    return new Nucleus(data.server, authentication);
  }
  throw new Error(`Unknown provider ${data.type}.`);
}

export function parsePublicName(name: string): string {
  if (!name) {
    name = "";
  }

  if (name === "localhost") {
    name = "127.0.0.1";
  }
  return name.toLowerCase();
}

export function toPublicName(name: string): string {
  if (name === "127.0.0.1") {
    name = "localhost";
  }
  return name;
}

export function parseLink(link: string, protocol: string) {
  const regex = new RegExp(`${protocol}://(.*?)(/.*)`);
  const match = regex.exec(link);
  if (match) {
    const storage = match[1];
    const path = match[2];
    return {
      storage,
      path,
    };
  } else {
    return null;
  }
}
