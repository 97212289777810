import styled from "styled-components";
import ScrollBar from "./ScrollBar";
import { DefaultTheme } from "./Theme";

export interface ScrollableProps {
  height?: number;
}

const Scrollable = styled.div<ScrollableProps>`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${({ height }) => height ? `${height}px` : "auto"};
  ${ScrollBar};
`;
Scrollable.defaultProps = {
  theme: DefaultTheme
};

export default Scrollable;
