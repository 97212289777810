import React from "react";
import {formatDateTime} from "../util/DateTime";


export interface DateTimeProps {
  date: Date;
  className?: string;
}

const DateTime: React.FC<DateTimeProps> = React.memo(({ date, className }) => {
  return <span className={className}>{formatDateTime(date)}</span>
});

export default DateTime;