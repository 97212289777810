import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import ApplicationContext from "../context/ApplicationContext";
import useBrowserClipboard from "../hooks/useBrowserClipboard";
import useContextMenu from "../hooks/useContextMenu";
import { Checkpoint } from "../state/Checkpoints";
import Path from "../state/Path";
import { formatDateTime } from "../util/DateTime";
import { formatFileSize } from "../util/File";
import CheckpointMenu from "./CheckpointMenu";
import Icon, { Icons } from "./Icon";

export interface CheckpointViewProps {
  checkpoint: Checkpoint;
  selectedCheckpointId?: number | null;
  path: Path;
  onSelect(id: number | null): void;
}

const CheckpointView = ({ checkpoint, path, onSelect, selectedCheckpointId }: CheckpointViewProps) => {
  const clipboard = useBrowserClipboard();
  const { app } = useContext(ApplicationContext);

  const copyClipboardURL = useCallback(async () => {
    const link = `${app.href(path.link, { absolute: true })}?checkpoint=${checkpoint.id}`;
    await clipboard.copy(link);
  }, [app, checkpoint.id, clipboard, path.link]);

  const [menu, setMenu] = useContextMenu(StyledCheckpointMenu, { props: { checkpoint, path, copyClipboardURL } });

  const onCheckpointSelect = useCallback(() => onSelect(checkpoint.id ?? null), [checkpoint.id, onSelect]);
  const onMenu = useCallback<React.MouseEventHandler>(
    (e) => {
      e.stopPropagation();
      setMenu(e);
    },
    [setMenu]
  );
  const isSelected = selectedCheckpointId === checkpoint.id;
  return (
    <StyledCheckpointView selected={isSelected} onClick={onCheckpointSelect}>
      <CheckpointInfo>
        <CheckpointMessage selected={isSelected}>
          #{checkpoint.id} {checkpoint.message}
        </CheckpointMessage>
        <CheckpointField>{formatDateTime(checkpoint.date)}</CheckpointField>
        <CheckpointField>{checkpoint.author}</CheckpointField>

        {isSelected && (
          <>
            <CheckpointField>
              {path.link} <Icon icon={Icons.Link} title={"Copy URL"} onClick={copyClipboardURL} />
            </CheckpointField>

            <CheckpointField>{checkpoint.size && formatFileSize(checkpoint.size)}</CheckpointField>
          </>
        )}
      </CheckpointInfo>
      <CheckpointMenuIconContainer>
        <CheckpointMenuIcon selected={isSelected} clickable onClick={onMenu} />
        {menu}
      </CheckpointMenuIconContainer>
    </StyledCheckpointView>
  );
};

const StyledCheckpointView = styled.div<{ selected: boolean }>`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 8px 0;
  background: ${({ theme, selected }) => (selected ? theme.colors.frontBackground : theme.colors.background)};
  color: ${({ theme }) => theme.colors.foreground};
  cursor: pointer;
  box-sizing: border-box;

  &:last-of-type {
    border-bottom: none;
  }
`;

const CheckpointInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding: 0 22px;
`;

const CheckpointMessage = styled.div<{ selected: boolean }>`
  flex: 0 0 auto;
  font-size: 10pt;
  text-overflow: ellipsis;
  white-space: ${({ selected }) => (selected ? "break-word" : "nowrap")};
  overflow: hidden;
`;

const CheckpointField = styled.div`
  flex: 0 0 auto;
  color: ${({ theme }) => theme.colors.hint};
  font-size: 9pt;
`;

const CheckpointMenuIconContainer = styled.div`
  margin-right: 12px;
`;

const CheckpointMenuIcon = styled(Icon).attrs({ icon: Icons.Bars })<{ selected: boolean }>`
  flex: 0 0 24px;
  height: 20px;
  cursor: pointer;
  opacity: ${({ selected }) => (selected ? "1" : "0")};
  transition: 0.3s opacity;

  ${StyledCheckpointView}:hover & {
    opacity: 1;
  }
`;

const StyledCheckpointMenu = styled(CheckpointMenu)`
  margin-left: -200px;
`;

export default CheckpointView;
