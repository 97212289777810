import { observer } from "mobx-react";
import React from "react";
import Path, { PathType } from "../state/Path";
import Attribute from "./Attribute";
import AttributeList from "./AttributeList";
import DateTime from "./DateTime";
import FileSize from "./FileSize";

export interface PathAttributesProps {
  path: Path;
  className?: string;
}

const PathAttributes: React.FC<PathAttributesProps> = (props) => {
  const { path } = props;
  switch (path.type) {
    case PathType.Folder:
    case PathType.Mount:
      return <FolderInfo {...props} />;
    default:
      return <FileInfo {...props} />;
  }
};

export const FolderInfo: React.FC<PathAttributesProps> = ({ path: directory, className }) => (
  <AttributeList className={className}>
    <Attribute
      name={"Date modified"}
      value={directory.dateModified ? <DateTime date={directory.dateModified} /> : ""}
    />
    <Attribute name={"Created by"} value={directory.author} />
    <Attribute name={"Modified by"} value={directory.modifiedBy} />
  </AttributeList>
);

export const FileInfo: React.FC<PathAttributesProps> = ({ path, className }) => (
  <AttributeList className={className}>
    <Attribute name={"Date modified"} value={path.dateModified ? <DateTime date={path.dateModified} /> : ""} />
    <Attribute name={"Created by"} value={path.author} />
    <Attribute name={"Modified by"} value={path.modifiedBy} />
    <Attribute name={"File size"} value={path.size ? <FileSize size={path.size} /> : ""} />
  </AttributeList>
);

export default observer(PathAttributes);
