import { observer } from "mobx-react";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ApplicationContext from "../context/ApplicationContext";
import useBrowserClipboard from "../hooks/useBrowserClipboard";
import Path from "../state/Path";
import Button from "./Button";
import Icon, { Icons } from "./Icon";

const PathCopyURLButton: React.FC<{
  path: Path;
}> = ({ path }) => {
  const [copied, setCopied] = useState(false);
  const {app} = useContext(ApplicationContext);

  const link = useMemo(() => {
    if (!path.link) {
      path.invalidateLink();
    }
    return app.href(path.link, { absolute: true });
  }, [path.link, app]);

  const clipboard = useBrowserClipboard();
  const copyURL = useCallback(async () => {
    await clipboard.copy(link);
    setCopied(true);
  }, [link, clipboard]);

  useEffect(() => {
    setCopied(false);
  }, [path]);

  return (
    <Button name={"copy"} onClick={copyURL}>
      <Icon icon={Icons.Clipboard} /> {copied ? "URL copied" : "Copy URL"}
    </Button>
  );
};

export default observer(PathCopyURLButton);
