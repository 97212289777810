import styled from "styled-components";
import Button from "./Button";

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin: 0 15px;
  position: relative;
  
  & > ${Button}:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

export default ButtonGroup;
