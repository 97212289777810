import ResizeSensor from "css-element-queries/src/ResizeSensor";
import React from "react";
import styled from "styled-components";
import { StyledRenderer } from "./index";
import ScrollBar from "./ScrollBar";
import { DefaultTheme } from "./Theme";

export interface GridProps<TItem = any, TItemProps extends {} = {}> {
  /**
   * Specifies if scrollTop must be reset when cells are changed (`true` by default).
   */
  autoScroll?: boolean;
  cellWidth: number;
  cellHeight: number;
  cells: TItem[];
  cellRenderer: StyledRenderer<GridCellProps<TItem, TItemProps>>;
  cellProps?: TItemProps;
  width?: number;
  height?: number;
  className?: string;
  gap?: number;
  /**
   * Defines if gap needs to be used as a border.
   */
  gapBorder?: boolean;
  emptyRows?: number;
  style?: React.CSSProperties;
  gridRenderer?: StyledRenderer<GridContainerProps<TItem, TItemProps>>;
  cellMeasurer?(measurement: GridCellMeasurement<TItem>): GridCellMeasurement<TItem>;
  onClick?(e: React.MouseEvent): void;
}

export type GridCellProps<TItem, TItemProps extends {} = {}> = GridCellMeasurement<TItem> &
  TItemProps & {
    style: React.CSSProperties;
  };

export interface GridContainerProps<TItem, TItemProps extends {} = {}> {
  style: React.CSSProperties;
  cells: Array<GridCellProps<TItem, TItemProps>>;
  offsetTop: number;
  children: React.ReactNode;
}

export interface GridCellMeasurement<T = any> {
  index: number;
  data: T;
  top: number;
  left: number;
  width: number;
  height: number;
}

interface GridState {
  initialized: boolean;
  actualWidth?: number;
  actualHeight?: number;
  offsetTop?: number;
}

interface GridMeasurements {
  [row: number]: { height: number; top: number; cells: GridCellMeasurementInfo[] };
}

interface GridCellMeasurementInfo {
  width: number;
  height: number;
  index: number;
}

export const GridWrapper = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  overflow-y: auto;
  position: relative;
  will-change: transform;

  ${ScrollBar};
`;
GridWrapper.defaultProps = {
  theme: DefaultTheme,
};

export const GridContainer = styled.div``;

export default class Grid<TItem, TItemProps = {}> extends React.Component<GridProps<TItem, TItemProps>, GridState> {
  public static defaultProps: Partial<GridProps> = {
    autoScroll: true,
    emptyRows: 0,
    gap: 0,
    gapBorder: true,
    gridRenderer: GridContainer,
  };

  private scrollTimeout?: number;
  private resizeTimeout?: number;

  protected handleScroll = () => {
    this.scrollTimeout = requestAnimationFrame(() => {
      this.recalculateState({
        measureRows: !!this.containerRef.current && this.state.actualWidth !== this.containerRef.current.clientWidth,
      });
    });
  };

  protected handleResize = () => {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = window.setTimeout(() => {
      this.recalculateState({ measureRows: true });
    }, 500);
  };

  private readonly containerRef: React.RefObject<HTMLDivElement>;
  private resizeSensor?: ResizeSensor;
  private rowMeasurements?: GridMeasurements;

  constructor(props: GridProps<TItem, TItemProps>) {
    super(props);
    this.state = {
      initialized: false,
    };

    this.containerRef = React.createRef();
  }

  public componentDidMount() {
    this.recalculateState({ measureRows: true });
    this.resizeSensor = new ResizeSensor(this.containerRef.current!, () => {
      this.handleResize();
    });
  }

  public componentDidUpdate({
    cells: previousCells,
    cellWidth: previousCellWidth,
    cellHeight: previousCellHeight,
    gapBorder: previousGapBorder,
  }: GridProps<TItem, TItemProps>) {
    const { autoScroll, cells, cellWidth, cellHeight, gapBorder } = this.props;
    if (cells !== previousCells) {
      if (autoScroll) {
        this.containerRef.current!.scrollTop = 0;
      }
      this.recalculateState({ measureRows: true });
    } else if (
      cellWidth !== previousCellWidth ||
      cellHeight !== previousCellHeight ||
      gapBorder !== previousGapBorder
    ) {
      this.recalculateState({ measureRows: true });
    }
  }

  public componentWillUnmount() {
    if (this.resizeSensor) {
      this.resizeSensor.detach();
    }

    clearTimeout(this.scrollTimeout);
    clearTimeout(this.resizeTimeout);
  }

  public scrollToCell = (index: number): void => {
    if (!this.containerRef.current) {
      return;
    }

    const width = this.containerRef.current.clientWidth;
    const { cellWidth, cellHeight, gap = 0 } = this.props;

    const totalCellWidth = cellWidth + gap;
    const columnCount = Math.trunc(width / totalCellWidth);
    const row = Math.trunc(index / columnCount);
    const top = row * cellHeight + row * gap;

    this.containerRef.current.scroll?.({ top, behavior: "smooth" });
  };

  public measureRows = (actualWidth?: number | null) => {
    const { cellWidth, cellHeight, cells, gap = 0, gapBorder, cellMeasurer, emptyRows = 0 } = this.props;
    actualWidth = actualWidth || this.state.actualWidth || 0;
    const cellCount = cells.length;

    const result: GridMeasurements = {};
    const totalCellWidth = cellWidth + gap;
    const totalCellHeight = cellHeight + gap;

    if (!cellMeasurer) {
      let top = gapBorder ? gap : 0;
      const columnCount = Math.trunc(actualWidth / totalCellWidth);
      const rowCount = columnCount ? Math.floor(cellCount / columnCount) + emptyRows + 1 : 0;
      for (let row = 0; row < rowCount; row++) {
        const cells: GridCellMeasurementInfo[] = [];
        for (let column = 0; column < columnCount; column++) {
          cells.push({
            width: cellWidth,
            height: cellHeight,
            index: row * columnCount + column,
          });
        }

        result[row] = {
          height: cellHeight,
          top,
          cells,
        };

        top += totalCellHeight + gap;
      }
    } else {
      let currentTop = gapBorder ? gap : 0;
      let currentLeft = gapBorder ? gap : 0;
      let row = 0;
      let rowHeight = totalCellHeight;
      let rowCells: GridCellMeasurementInfo[] = [];

      for (let index = 0; index < cellCount; index++) {
        const measurement: GridCellMeasurement = {
          index,
          data: cells[index],
          width: cellWidth,
          height: cellHeight,
          left: currentLeft,
          top: currentTop,
        };

        const { width, height } = cellMeasurer(measurement);
        currentLeft += width + gap;
        if (currentLeft > actualWidth) {
          result[row] = { height: rowHeight, top: currentTop, cells: rowCells };
          currentLeft = gap + width;
          if (gapBorder) {
            currentLeft += gap;
          }
          currentTop += rowHeight + gap;
          row++;
          rowCells = [{ width, height, index }];
          rowHeight = totalCellHeight;
        } else {
          rowCells.push({ width, height, index });
        }

        if (height > rowHeight) {
          rowHeight = height;
        }
      }

      if (rowCells.length) {
        result[row] = { height: rowHeight, top: currentTop, cells: rowCells };
      }
    }
    this.rowMeasurements = result;
  };

  public render() {
    const { gridRenderer: Grid = GridContainer, className, style, width = 0, height = 0, ...props } = this.props;
    const { offsetTop = 0, actualWidth = 0, initialized } = this.state;

    let grid: React.ReactNode | null;
    if (initialized) {
      if (!this.rowMeasurements) {
        this.measureRows(actualWidth);
      }

      const fullHeight = this.calculateFullHeight(this.rowMeasurements!);
      const gridStyle: React.CSSProperties = {
        position: "relative",
        boxSizing: "border-box",
        height: fullHeight,
        minHeight: "100%",
      };

      const { cells, renderedCells } = this.renderCells(this.rowMeasurements!);
      grid = (
        <Grid cells={cells} offsetTop={offsetTop} style={gridStyle} onClick={this.props.onClick}>
          {renderedCells}
        </Grid>
      );
    } else {
      grid = null;
    }

    return (
      <GridWrapper
        ref={this.containerRef}
        {...props}
        className={className}
        style={style}
        width={width}
        height={height}
        onScroll={this.handleScroll}
      >
        {grid}
      </GridWrapper>
    );
  }

  public recalculateState({ measureRows }: { measureRows: boolean }) {
    const { width, height } = this.props;

    if (!this.containerRef.current) {
      return;
    }

    const offsetTop = this.containerRef.current.scrollTop;
    const actualWidth = typeof width !== "undefined" ? width : this.containerRef.current.clientWidth;
    const actualHeight = typeof height !== "undefined" ? height : this.containerRef.current.clientHeight;

    if (measureRows) {
      this.measureRows(actualWidth);
    }

    this.setState({
      actualWidth,
      actualHeight,
      offsetTop,
      initialized: true,
    });
  }

  protected renderCells = (
    measurements: GridMeasurements
  ): { cells: Array<GridCellProps<TItem, TItemProps>>; renderedCells: JSX.Element[] } => {
    const { cellRenderer: Cell, gap = 0, gapBorder, cells, cellProps = {} as TItemProps, emptyRows = 0 } = this.props;
    const cellCount = cells.length;
    const { initialized } = this.state;

    if (!initialized || !measurements) {
      return {
        cells: [],
        renderedCells: [],
      };
    }

    const startRow = this.getStartRow(measurements);
    const screenRowCount = this.getScreenRowCount(measurements, startRow);
    const extraRowCount = emptyRows;

    const totalScreenRowCount = screenRowCount + extraRowCount;

    const renderedProps: Array<GridCellProps<TItem, TItemProps>> = [];
    const renderedCells = [];

    let screenStartRow = startRow - Math.round(extraRowCount / 2);
    if (screenStartRow < 0) {
      screenStartRow = 0;
    }

    for (let row = screenStartRow; row <= screenStartRow + totalScreenRowCount; row++) {
      const measurement = measurements[row];
      if (!measurement) {
        continue;
      }

      const columnCount = measurement.cells.length;
      let left = gapBorder ? gap : 0;
      for (let column = 0; column < columnCount; column++) {
        const index = measurements[row].cells[column].index;
        if (index >= cellCount) {
          break;
        }

        const width = measurement.cells[column].width;
        const height = measurement.cells[column].height;
        const top = measurement.top;
        const props: GridCellProps<TItem, TItemProps> = {
          index,
          data: cells[index],
          width,
          height,
          top,
          left,
          style: {
            position: "absolute",
            boxSizing: "border-box",
            width,
            height,
            top,
            left,
          },
          ...cellProps,
        };

        left += width + gap;

        renderedProps.push(props);
        const cell = <Cell key={index} {...props} />;
        if (cell) {
          renderedCells.push(cell);
        }
      }
    }

    return {
      cells: renderedProps,
      renderedCells,
    };
  };

  private calculateFullHeight = (measurements: GridMeasurements): number => {
    const { gap = 0 } = this.props;
    const lastRow = Object.values(measurements).length - 1;
    const measurement = measurements[lastRow];
    return measurement ? measurement.top + measurement.height + gap : 0;
  };

  private getStartRow = (measurements: GridMeasurements) => {
    const { offsetTop = 0 } = this.state;

    for (let row = 1; row < Object.values(measurements).length; row++) {
      const measurement = measurements[row];
      if (measurement && measurement.top > offsetTop) {
        return row - 1;
      }
    }

    return 0;
  };

  private getScreenRowCount = (rowMeasurements: GridMeasurements, startRow: number) => {
    const { offsetTop = 0, actualHeight = 0 } = this.state;

    const rowCount = Object.values(rowMeasurements).length;
    for (let row = startRow; row < rowCount; row++) {
      const measurement = rowMeasurements[row];
      if (measurement && measurement.top >= offsetTop + actualHeight) {
        return row - startRow;
      }
    }

    return rowCount - startRow;
  };
}
