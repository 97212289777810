import { PathTypeCode, StatusType } from "@omniverse/api/data";
import { join } from "../../../util/Path";
import { NewFolderError } from "../../../util/PathErrors";
import Path from "../../Path";
import { Commands } from "../Provider";
import { ICreateFolderAllowedArguments, ICreateFolderArguments } from "../types/CreateFolderCommand";
import { NucleusCommand } from "./index";
import { parseNucleusPath } from "./Nucleus";

export default class NucleusCreateFolderCommand extends NucleusCommand<
  ICreateFolderArguments,
  ICreateFolderAllowedArguments,
  Path
> {
  public name: string = Commands.List;

  public async allowed({ path }: ICreateFolderAllowedArguments): Promise<boolean> {
    if (path === path.storage.root) {
      return Boolean(this.provider.session.isSuperUser);
    }
    return path.canWrite();
  }

  public async execute({ path, name }: ICreateFolderArguments): Promise<Path> {
    console.log(`[${this.provider.name}] Create folder "${name}" in ${path.path}`);

    const connection = await this.provider.getConnection();
    const result = await connection.create({ uri: join(path.path, name), type: PathTypeCode.Folder });
    if (result.status !== StatusType.OK) {
      throw new NewFolderError(result);
    }

    if (!result.uri!.endsWith("/")) {
      result.uri = result.uri + "/";
    }

    const uri = result.uri!;
    const stat = await connection.stat2({ path: { path: uri } });
    if (stat.status !== StatusType.OK) {
      throw new NewFolderError(stat);
    }

    const folder = await parseNucleusPath(stat, path, this.provider.linkGenerator);
    path.add(folder);

    return folder;
  }
}
