import React, { useCallback } from "react";

export interface DragOptions {
  onDragStart(e: React.DragEvent): void;
  onDragEnd?(e: React.DragEvent): void;
  draggable?: boolean;
}

export function useDrag<T>(target: T, { onDragStart, onDragEnd, draggable = true }: DragOptions) {
  const dragStart = useCallback(
    (e: React.DragEvent) => {
      if (draggable) {
        onDragStart(e);
      }
    },
    [draggable, onDragStart]
  );

  return {
    draggable,
    onDragStart: dragStart,
    onDragEnd,
  };
}
