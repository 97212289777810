import React from "react";
import useDialogForm from "../hooks/useDialogForm";
import { Commands } from "../state/commands/Provider";
import { ISetUserEnabledCommand } from "../state/commands/types/SetUserEnabledCommand";
import User from "../state/User";
import { ReactComponent as UserSVG } from "../static/user.svg";
import ConfirmationDialog from "./ConfirmationDialog";
import FormErrorList from "./FormErrorList";
import ImportantText from "./ImportantText";

export interface UserEnableConfirmationProps {
  user: User;
}

const UserEnableConfirmation: React.FC<UserEnableConfirmationProps> = ({ user }) => {
  const form = useDialogForm({
    fields: {},
    onSubmit: enableUser,
  });

  function enableUser() {
    const command = user.storage.commands.get<ISetUserEnabledCommand>(Commands.SetUserEnabled);
    if (command) {
      return command.execute({ user, enabled: true });
    } else {
      throw new Error("User management is not supported.");
    }
  }

  return (
    <ConfirmationDialog
      title={"Enable Account"}
      loading={form.loading}
      image={UserSVG}
      onConfirm={form.submit}
      onCancel={form.cancel}
    >
      <FormErrorList errors={form.errors} />
      Are you sure you want to re-enable access for <br /> <ImportantText>{user.name}</ImportantText> account?
    </ConfirmationDialog>
  );
};

export default UserEnableConfirmation;
