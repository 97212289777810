export function resizeImage(file: Blob, maxWidth: number, maxHeight: number): Promise<Blob | null> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      let width = image.width;
      let height = image.height;

      if (width <= maxWidth && height <= maxHeight) {
        resolve(file);
      }

      let newWidth;
      let newHeight;

      if (width > height) {
        newHeight = height * (maxWidth / width);
        newWidth = maxWidth;
      } else {
        newWidth = width * (maxHeight / height);
        newHeight = maxHeight;
      }

      let canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;

      const context = canvas.getContext("2d");
      if (context) {
        context.drawImage(image, 0, 0, newWidth, newHeight);
      }
      canvas.toBlob(resolve, file.type);

      canvas.remove();
      image.remove();
    };
    image.onerror = reject;
  });
}

export const acceptedImageFormats = ".jpg,.jpeg,.png,.gif,.tiff,.bmp";
