import { SSOSettings } from "@omniverse/auth/data";
import { useCallback } from "react";
import { redirectToSSO, SSOExtras } from "../SSO";

export default function useSSORedirect(
  server: string,
  redirectBackTo: string,
  extras: SSOExtras = {},
  nonce?: string,
) {
  return useCallback(
    async (settings: SSOSettings) => {
      if (!server) {
        return;
      }

      await redirectToSSO({
        settings,
        server,
        redirectBackTo,
        extras,
        nonce
      });
    },
    [server, extras, redirectBackTo, nonce]
  );
}
