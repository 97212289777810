import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import useDialogForm from "../hooks/useDialogForm";
import useInput from "../hooks/useInput";
import { Commands } from "../state/commands/Provider";
import { IMountCommand } from "../state/commands/types/MountCommand";
import Path from "../state/Path";
import { ReactComponent as MountSVG } from "../static/mount.svg";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import Checkbox from "./Checkbox";
import Dialog from "./Dialog";
import DialogForm from "./DialogForm";
import DialogTitle from "./DialogTitle";
import FormErrorList from "./FormErrorList";
import FormGroup from "./FormGroup";
import Input from "./Input";
import KeyValueInput, { KeyValue } from "./KeyValueInput";
import Label from "./Label";
import Select, { Option } from "./Select";

export interface MountDialogProps {
  path: Path;
}

interface MountDialogFormFields {
  name: string;
  redirectURL: string;
  resolver: string;
  isPublic: boolean;
  options: KeyValue[];
}

const MountDialog: React.FC<MountDialogProps> = ({ path }) => {
  const [name, setName] = useInput("");
  const [redirectURL, setRedirectURL] = useInput("");
  const [resolver, setResolver] = useState<string>("s3");
  const [isPublic, setPublic] = useState(true);
  const [options, setOptions] = useState<KeyValue[]>(S3PublicOptions);

  const form = useDialogForm({
    fields: {
      name,
      redirectURL,
      resolver,
      isPublic,
      options,
    },
    onSubmit: submit,
  });

  async function submit({ name, options, resolver, isPublic, redirectURL }: MountDialogFormFields) {
    if (!name) {
      throw new Error("Name is required.");
    }

    for (const option of options) {
      if (!option.key) {
        throw new Error("All options must have key.");
      }
    }

    validateResolver(resolver, { options, isPublic });

    const command = await path.storage.commands.get<IMountCommand>(Commands.Mount);
    if (command) {
      await command.execute({ parent: path, name, resolver, options, isPublic, redirectURL });
    } else {
      throw new Error(`${Commands.Mount} command is not supported.`);
    }
  }

  const changeResolver = useCallback(
    (selectedResolver: string) => {
      if (selectedResolver === "s3") {
        if (isPublic) {
          setOptions(S3PublicOptions);
        } else {
          setOptions(S3PrivateOptions.concat(S3PublicOptions));
        }
      } else {
        setOptions([]);
      }

      setResolver(selectedResolver);
    },
    [isPublic]
  );

  const changePublicity = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const isPublicNow = e.target.checked;
    setPublic(isPublicNow);
    setOptions((resolverOptions) =>
      isPublicNow
        ? resolverOptions.filter((option) => !S3PrivateOptionNames.includes(option.key))
        : S3PrivateOptions.concat(resolverOptions)
    );
  }, []);

  return (
    <StyledDialog onClose={form.cancel}>
      <DialogTitle title={"Mount"} image={MountSVG} disabled={form.loading} onClose={form.cancel} />
      <DialogForm>
        {form.errors && <FormErrorList errors={form.errors} />}
        <FormGroup>
          <Label>
            Name
            <Input
              name={"name"}
              placeholder={"Required"}
              required
              disabled={form.loading}
              value={name}
              onChange={setName}
            />
          </Label>
        </FormGroup>

        <FormGroup>
          <Label htmlFor={"resolver"}>Type</Label>
          <Select
            id={"resolver"}
            name={"resolver"}
            value={resolver}
            disabled={form.loading}
            onChange={changeResolver}
          >
            <Option label={"Redirection"} value={"redirection"}>
              Redirection
            </Option>
            <Option label={"Amazon S3"} value={"s3"}>
              Amazon S3
            </Option>
          </Select>
        </FormGroup>

        {resolver === "s3" && (
          <FormGroup>
            <Label>
              Public
              <Checkbox name={"public"} checked={isPublic} onChange={changePublicity} />
            </Label>
          </FormGroup>
        )}

        {resolver === "redirection" ? (
          <FormGroup>
            <Label>
              Redirect URL
              <Input name={"redirectURL"} value={redirectURL} disabled={form.loading} onChange={setRedirectURL} />
            </Label>
          </FormGroup>
        ) : (
          <FormGroup>
            <Label>Options</Label>
            <KeyValueInput name={"options"} rows={options} disabled={form.loading} onChange={setOptions} />
          </FormGroup>
        )}

        <ButtonGroup>
          <Button kind={"attention"} disabled={form.loading} onClick={form.submit}>
            Submit
          </Button>
          <Button disabled={form.loading} onClick={form.cancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </DialogForm>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  width: 750px;
`;

const S3PrivateOptions: KeyValue[] = [
  { key: "region", type: "string", value: "", canDelete: false },
  { key: "access_key_id", type: "string", value: "", canDelete: false },
  { key: "secret_access_key", type: "string", value: "", canDelete: false },
];

const S3PrivateOptionNames = S3PrivateOptions.map((option) => option.key);

const S3PublicOptions: KeyValue[] = [
  { key: "host", type: "string", value: "", canDelete: false },
  { key: "service", type: "string", value: "s3", canDelete: false },
  { key: "redirection", type: "string", value: "", canDelete: true },
  { key: "secure", type: "boolean", value: false, canDelete: false },
];

interface ResolverValidatorOptions {
  options: KeyValue[];
  isPublic: boolean;
}

const validateResolver = (resolver: string, options: ResolverValidatorOptions): void => {
  const validator = validators[resolver];
  if (validator) {
    return validator(options);
  }
  throw new Error(`Unknown resolver ${resolver}.`);
};

const validateFile = () => {
  return {};
};

const validateS3 = ({ options, isPublic }: ResolverValidatorOptions): void => {
  const requiredOptions = isPublic
    ? S3PublicOptions.filter((option) => !option.canDelete)
    : S3PublicOptions.concat(S3PrivateOptions).filter((option) => !option.canDelete);

  for (const option of requiredOptions) {
    if (!options.find((clientOption) => clientOption.key === option.key)) {
      throw new Error(`'${option.key}' is required.`);
    }
  }

  for (const option of options) {
    if (requiredOptions.find((required) => required.key === option.key) && option.value === "") {
      throw new Error(`'${option.key}' is required.`);
    }
  }
};

const validateRedirection = () => {
  return {};
};

const validators: { [key: string]: (options: ResolverValidatorOptions) => void } = {
  file: validateFile,
  s3: validateS3,
  redirection: validateRedirection,
};

export default observer(MountDialog);
