import styled from "styled-components";
import { DefaultTheme } from "./Theme";

const FormPageLayout = styled.div`
  background: ${({ theme }) => theme.colors.background};
  min-height: 100%;
  flex: 1;
  padding: 40px;
  box-sizing: border-box;
`;
FormPageLayout.defaultProps = {
  theme: DefaultTheme,
};

export default FormPageLayout;
