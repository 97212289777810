import { useCallback, useMemo, useRef } from "react";
import { Preference, PreferenceType } from "../state/Preferences";
import useApp from "./useApp";

export default function usePreference<T extends PreferenceType>(name: string, initial: T | (() => T)) {
  const initialRef = useRef(initial);
  initialRef.current = initial;

  const app = useApp();

  const preference = useMemo((): Preference<T> => {
    if (app.preferences.has(name)) {
      return app.preferences.get(name) as Preference<T>;
    } else {
      const initialValue = typeof initialRef.current === "function" ? initialRef.current() : initialRef.current;
      return app.preferences.create<T>(name, initialValue);
    }
  }, [app, name]);

  const value = preference.value;

  const setValue = useCallback(
    (value: T | ((current: T) => T)) => {
      let newValue = (typeof value === "function") ? value(preference.value) : value;
      preference.set(newValue);
    },
    [preference]
  );

  return [value, setValue] as const;
}
