import React from "react";
import styled from "styled-components";
import { Omit } from "./index";

export interface SelectionBoxProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "onSelect"> {
  forwardedRef?: React.Ref<HTMLDivElement>;
  disabled?: boolean;
  onSelect(box: SelectionRect): void;
}

export interface SelectionRect {
  left: number;
  top: number;
  width: number;
  height: number;
}

interface SelectionOrigin {
  x: number;
  y: number;
}

enum MouseButton {
  Left = 0,
  Right = 1,
}

const SelectionBoxWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const SelectionBoxContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledSelectionBox = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  user-select: none;
  pointer-events: none;
`;

class SelectionBox extends React.Component<SelectionBoxProps> {
  private readonly containerRef: React.RefObject<HTMLDivElement>;
  private readonly boxRef: React.RefObject<HTMLDivElement>;

  private origin?: SelectionOrigin;
  private box?: SelectionRect;
  private disabled?: boolean;
  private tracking?: number;

  constructor(props: SelectionBoxProps) {
    super(props);
    this.containerRef = React.createRef();
    this.boxRef = React.createRef();
  }

  public componentDidMount() {
    this.containerRef.current!.addEventListener("mousedown", this.startMouseTrack);
    this.containerRef.current!.addEventListener("mousemove", this.trackMouse);

    document.addEventListener("mouseup", this.selectTracked, { capture: true });
    document.addEventListener("dragstart", this.disable);
    document.addEventListener("dragend", this.enable);
  }

  public componentWillUnmount() {
    this.containerRef.current!.removeEventListener("mousedown", this.startMouseTrack);
    this.containerRef.current!.removeEventListener("mousemove", this.trackMouse);

    document.removeEventListener("mouseup", this.selectTracked, { capture: true });
    document.removeEventListener("dragstart", this.disable);
    document.removeEventListener("dragend", this.enable);
  }

  public render() {
    const { className, style, disabled, forwardedRef, children, onSelect, ...props } = this.props;
    return (
      <SelectionBoxWrapper {...props} className={className} ref={forwardedRef as any}>
        <SelectionBoxContainer ref={this.containerRef} style={style}>
          {children}
        </SelectionBoxContainer>

        <StyledSelectionBox ref={this.boxRef} />
      </SelectionBoxWrapper>
    );
  }

  protected startMouseTrack = (e: MouseEvent) => {
    clearInterval(this.tracking);

    if (this.props.disabled || this.disabled) {
      return;
    }

    if (e.target !== this.containerRef.current || e.button !== MouseButton.Left) {
      return;
    }

    const containerOffset = this.containerRef.current!.getBoundingClientRect();
    const x = e.pageX - containerOffset.left;
    const y = e.pageY - containerOffset.top;

    this.origin = { x, y };
    this.box = { left: x, top: y, width: 0, height: 0 };

    this.tracking = window.setInterval(() => {
      if (!this.props.disabled) {
        this.props.onSelect(this.box!);
      }
    }, 250);
  };

  protected stopMouseTrack = () => {
    clearInterval(this.tracking);
    this.origin = undefined;
    this.box = undefined;
    this.tracking = undefined;
  };

  protected selectTracked = (e: MouseEvent) => {
    if (!this.tracking) {
      return;
    }

    if (e.button === MouseButton.Left && !this.props.disabled) {
      this.props.onSelect(this.box!);
    }

    if (this.boxRef.current) {
      const style = `
        left: 0;
        top: 0;
        width: 0;
        height: 0;
      `;
      this.boxRef.current.setAttribute("style", style);
    }

    this.stopMouseTrack();
  };

  protected trackMouse = (e: MouseEvent) => {
    if (!this.tracking || !this.origin) {
      return;
    }

    if (this.props.disabled || this.disabled || e.buttons !== MouseButton.Right) {
      this.selectTracked(e);
      return;
    }

    const containerOffset = this.containerRef.current!.getBoundingClientRect();
    const x = e.pageX - containerOffset.left;
    const y = e.pageY - containerOffset.top;

    this.box = this.calculateBox(this.origin, x, y);
    this.applyBox();
  };

  private calculateBox = (origin: SelectionOrigin, cursorX: number, cursorY: number) => {
    const left = Math.min(origin.x, cursorX);
    const top = Math.min(origin.y, cursorY);
    const width = Math.max(origin.x, cursorX) - left;
    const height = Math.max(origin.y, cursorY) - top;
    return {
      left,
      top,
      width,
      height,
    };
  };

  private applyBox = () => {
    const style = `
      left: ${this.box!.left}px;
      top: ${this.box!.top}px;
      width: ${this.box!.width}px;
      height: ${this.box!.height}px;
    `;
    this.boxRef.current!.setAttribute("style", style);
  };

  private enable = () => {
    this.disabled = false;
  };

  private disable = () => {
    this.disabled = true;
    this.stopMouseTrack();
  };
}

export default SelectionBox;
