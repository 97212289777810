import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import useDialogForm from "../hooks/useDialogForm";
import Path from "../state/Path";
import ConfirmationDialog from "./ConfirmationDialog";
import ConfirmationWarning from "./ConfirmationWarning";
import FormErrorList from "./FormErrorList";
import PathTransfer from "./PathTransfer";


export interface PathCopyDialogProps {
  destination: Path;
}


export const PathCopyDialog: React.FC<PathCopyDialogProps> = ({ destination }) => {
  const form = useDialogForm({
    fields: {},
    onSubmit: confirm,
  })

  const clipboard = destination.storage.clipboard;
  const source = clipboard.items;

  function confirm() {
    return clipboard.paste(destination);
  }

  return (
    <StyledPathCopyDialog title={"Copying"} loading={form.loading} onConfirm={form.submit} onCancel={form.cancel}>
      <FormErrorList errors={form.errors} />
      Do you want to copy these resources?
      <ConfirmationWarning>It will replace resources with the same name in {destination.link}.</ConfirmationWarning>
      <PathTransfer source={source} destination={destination} />
    </StyledPathCopyDialog>
  );
}

const StyledPathCopyDialog = styled(ConfirmationDialog)`
  text-align: center;
`;

export default observer(PathCopyDialog);