import React from "react";
import FormPageLayout from "../components/FormPageLayout";
import NucleusLoginForm from "../components/NucleusLoginForm";


const AdminPage: React.FC = () => {
  return (
    <FormPageLayout>
      <NucleusLoginForm forceCredentials />
    </FormPageLayout>
  );
}

export default AdminPage;