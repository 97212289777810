import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import usePermission from "../hooks/usePermission";
import usePreference from "../hooks/usePreference";
import useQuery from "../hooks/useQuery";
import useStorageList from "../hooks/useStorageList";
import { Commands } from "../state/commands/Provider";
import { IGetGroupsCommand } from "../state/commands/types/GetGroupsCommand";
import { Preferences } from "../state/Preferences";
import Button from "./Button";
import ErrorMessage from "./ErrorMessage";
import Loader from "./Loader";
import Panel from "./Panel";
import UserManagementTree from "./UserManagementTree";

export interface UserManagementPanelProps {
  initialWidth?: number;
  initialHeight?: number;
  onClose(): void;
}

const UserManagementPanel: React.FC<UserManagementPanelProps> = ({ initialWidth, initialHeight = 300, onClose }) => {
  const [height, setHeight] = usePreference<number>(Preferences.UserManagementHeight, initialHeight);

  function cacheSize({ height }: { width: number; height: number }) {
    setHeight(height);
  }

  const storageList = useStorageList();
  const storage = storageList.selected;

  const { error } = useQuery(fetchGroups, {
    keys: [storage],
  });

  async function fetchGroups() {
    if (storage) {
      const command = storage.commands.get<IGetGroupsCommand>(Commands.GetGroups);
      if (await command?.allowed()) {
        return command?.execute({ groups: storage.groups });
      }
    }
  }

  const supportsUserManagement = storage?.commands.has<IGetGroupsCommand>(Commands.GetGroups);
  const allowsUserManagement = usePermission(async () => {
    if (!storage) {
      return false;
    }
    return storage.commands.allowed<IGetGroupsCommand>(Commands.GetGroups);
  }, [storage]);
  return (
    <StyledUserManagementPanel defaultHeight={height} onResizeStop={cacheSize}>
      <UserManagementHeader>User management</UserManagementHeader>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {storage && <UserManagementServer>{storage.publicName}</UserManagementServer>}
      {storage ? (
        supportsUserManagement ? (
          allowsUserManagement === "loading" ? (
            <Loader />
          ) : allowsUserManagement === "allowed" ? (
            <UserManagementTree width={initialWidth} height={height} storage={storage} />
          ) : (
            <UserManagementPlaceholder>
              You need to have admin rights to manage users on this server.
              <Button onClick={onClose}>Close</Button>
            </UserManagementPlaceholder>
          )
        ) : (
          <UserManagementPlaceholder>
            This storage does not support user management.
            <Button onClick={onClose}>Close</Button>
          </UserManagementPlaceholder>
        )
      ) : (
        <UserManagementPlaceholder>Select a server.</UserManagementPlaceholder>
      )}
    </StyledUserManagementPanel>
  );
};

const StyledUserManagementPanel = styled(Panel).attrs(() => ({
  resizable: true,
  directions: { top: true },
  minHeight: 300,
}))`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  will-change: transform;
`;

const UserManagementHeader = styled.div`
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  background: ${({ theme }) => theme.colors.toolbarBackground};
  color: ${({ theme }) => theme.colors.toolbarForeground};
`;

const UserManagementServer = styled.div`
  line-height: 25px;
  font-size: 10pt;
  text-align: center;
  background: ${({ theme }) => theme.colors.toolbarBackground};
  color: ${({ theme }) => theme.colors.toolbarForeground};
`;

const UserManagementPlaceholder = styled.div`
  padding: 1rem;
  color: ${({ theme }) => theme.colors.foreground};
  flex: 1;
  display: grid;
  place-content: center;
  text-align: center;

  ${Button} {
    margin-top: 10px;
  }
`;

export default observer(UserManagementPanel);
