import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { DefaultTheme } from "./Theme";

const TextArea = styled.textarea`
  display: block;
  font-family: unset;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.inputText};
  padding: 5px 8px;
  box-sizing: border-box;
  width: 100%;
  min-height: 85px;
  max-width: 100%;
  resize: none;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background: ${({ disabled, theme }) => (disabled ? theme.colors.disabledBackground : theme.colors.inputBackground)};
  
  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholders};
  }
`;
TextArea.defaultProps = {
  theme: DefaultTheme
};


export function useTextArea(initialValue: string): [string, (e: React.ChangeEvent<HTMLTextAreaElement>) => void] {
  const [value, setValue] = useState(initialValue);
  const setInputValue = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value), [setValue]);
  return [value, setInputValue];
}


export default TextArea;
