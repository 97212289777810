import React, {useMemo} from "react";
import {Size} from "react-virtualized";


export interface StaticSizerProps {
  defaultWidth?: number;
  defaultHeight?: number;
  children: (props: Size) => React.ReactNode;
}

const StaticSizer: React.FC<StaticSizerProps> = ({ defaultWidth, defaultHeight, children }) => {
  const size = useMemo((): Size => ({ width: defaultWidth!, height: defaultHeight! }), [defaultWidth, defaultHeight]);
  return <>{children(size)}</>;
}

export default StaticSizer;