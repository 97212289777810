export const LineHeight = 1.5;
export const FontSize = 13;

export const createMeasurer = (font: string = "600 13px Open Sans") => {
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (context) {
    context.font = font;
  }
  return context!;
};

export const measureCellTextHeight = (
  measurer: CanvasRenderingContext2D,
  maxTextHeight: number,
  text: string,
  maxWidth: number,
  lineHeight: number = LineHeight,
  fontSize: number = FontSize
): number => {
  const { width: textWidth } = measurer.measureText(text);
  const { width: space } = measurer.measureText(" ");
  const lineCount = Math.trunc(Math.round(textWidth) / maxWidth) + 1;
  const spaces = lineCount * space;
  const wrappedLines = Math.trunc(Math.round(textWidth + spaces) / maxWidth) + 1;
  const textHeight = Math.trunc(wrappedLines * lineHeight * fontSize) + 1;
  return Math.max(maxTextHeight, textHeight);
};

export default createMeasurer;
