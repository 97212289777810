import Layout from "./Layout";
import { observer } from "mobx-react";
import React from "react";
import styled, { css, keyframes } from "styled-components";
import usePreference from "../hooks/usePreference";
import { Preferences } from "../state/Preferences";

export interface RightPanelProps {
}

const RightPanel: React.FC<RightPanelProps> = ({ children }) => {
  const [opened] = usePreference<boolean>(Preferences.RightPanelOpened, true);
  const [width, setWidth] = usePreference<number>(Preferences.RightPanelWidth, 400);

  function cacheSize({ width }: { width: number; height: number }) {
    setWidth(width);
  }

  if (!opened) {
    return null;
  }

  return (
    <StyledRightPanel defaultWidth={width} open={opened} onResizeStop={cacheSize}>
      {children}
    </StyledRightPanel>
  );
};

export const RightPanelAnimationTime = 300;
export const RightPanelMinWidth = "400px";

interface StyledRightPanelProps {
  open: boolean;
}

export const StyledRightPanel = styled(Layout.RightPanel).attrs<StyledRightPanelProps>({
  minWidth: 400,
  resizable: true,
})`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: hidden;
  flex: 1;
  animation: ${({ open = true }: StyledRightPanelProps) =>
    open ? RightPanelAppearAnimation : RightPanelClosingAnimation};
  background: ${({ theme }) => theme.colors.background};
`;

const RightPanelAppearKeyframes = keyframes`
  0% {
    transform: translate(${RightPanelMinWidth}, 0);
    opacity: 0;
  }
  
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
`;

const RightPanelAppearAnimation = css`
  ${RightPanelAppearKeyframes} ${RightPanelAnimationTime}ms ease-in;
`;

const RightPanelClosingAnimationKeyframes = keyframes`
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  
  100% {
    transform: translate(${RightPanelMinWidth}, 0);
    opacity: 0;
  }
`;

const RightPanelClosingAnimation = css`
  ${RightPanelClosingAnimationKeyframes} ${RightPanelAnimationTime}ms ease-out forwards;
`;

export default observer(RightPanel);
