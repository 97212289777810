import React from "react";
import {formatFileSize} from "../util/File";


export interface FileSizeProps {
  size: number;
  className?: string;
}

const FileSize: React.FC<FileSizeProps> = React.memo(({ size, className }) => {
  return <span className={className}>{formatFileSize(size)}</span>;
});

export default FileSize;