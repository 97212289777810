import { StatusType } from "@omniverse/api/data";
import { GetACLError } from "../../../util/ACLErrors";

import { Commands } from "../Provider";
import { IGetACLResolvedCommandArguments } from "../types/GetACLResolvedCommand";
import { NucleusCommand } from "./index";
import { ResolvedACLs } from "../../ResolvedACL";

export default class NucleusGetACLResolvedCommand extends NucleusCommand<
  IGetACLResolvedCommandArguments,
  void,
  ResolvedACLs
> {
  public name = Commands.GetACL;

  public async allowed(): Promise<boolean> {
    return typeof this.provider.capabilities?.get_acl_resolved === "number";
  }

  public async execute({ path }: IGetACLResolvedCommandArguments): Promise<ResolvedACLs> {
    console.log(`[${this.provider.name}] Get resolved ACL for ${path.path}`);

    path.resolvedACL.loading = true;
    const connection = await this.provider.getConnection();
    try {
      const result = await connection.getAclResolved({ paths: [{ path: path.path }] });
      if (result.status !== StatusType.OK || result.responses[0].status !== StatusType.OK) {
        throw new GetACLError(result);
      }
      const resolvedACL = result.responses[0].acl!;

      path.resolvedACL.set(Object.entries(resolvedACL));
      return path.resolvedACL.map;
    } finally {
      path.resolvedACL.loading = false;
    }
  }
}
