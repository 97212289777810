import styled from "styled-components";
import { DefaultTheme } from "./Theme";

const RightPanelTitle = styled.div`
  flex: 1;
  font-size: 11pt;
  line-height: 24pt;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
RightPanelTitle.defaultProps = {
  theme: DefaultTheme,
};

export default RightPanelTitle;
