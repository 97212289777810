import { StatusType } from "@omniverse/api/data";
import TaskStatus from "../../TaskStatus";
import { Commands } from "../Provider";
import { IUploadCommandAllowedArguments, IUploadCommandArguments } from "../types/UploadCommand";
import { NucleusCommand } from "./index";

import { join } from "../../../util/Path";

export default class NucleusUploadCommand extends NucleusCommand<
  IUploadCommandArguments,
  IUploadCommandAllowedArguments
> {
  name = Commands.Upload;

  public async allowed({ destination }: IUploadCommandAllowedArguments): Promise<boolean> {
    return destination.canWrite();
  }

  public async execute({ upload }: IUploadCommandArguments) {
    console.log(`[${this.provider.name}] Upload ${upload.files.length} files to ${upload.destination.path}`);

    upload.setStatus(TaskStatus.Loading);
    let isAborted = false;
    upload.onAbort = () => (isAborted = true);

    const connection = await this.provider.getConnection();

    return new Promise<void>(async (resolve, reject) => {
      const description = upload.description || "Uploaded from web";

      if (this.provider.supportsVersioning) {
        for (const file of upload.files) {
          const finalPath = join(upload.destination.path, file.path ?? file.name);
          console.log(`[${this.provider.name}] Create checkpoint before upload for ${finalPath}"`);

          const res = await connection.stat2({ path: { path: finalPath } });
          if (res.status === StatusType.InvalidPath) continue;

          if (!res.checkpointed) {
            await connection.checkpointVersion({
              path: { path: finalPath },
              message: `Backup ${description}`,
            });
          }
        }
      }

      const link = await this.provider.linkGenerator.createUploadLink({
        path: upload.destination.path,
        storage: this.provider.name,
      });

      const data = new FormData();
      for (const file of upload.files) {
        data.append("size", file.size.toString());
        data.append("path", file.path ?? file.name);
        data.append("data", file);
      }

      if (isAborted) {
        upload.setStatus(TaskStatus.Aborted);
        resolve();
        return;
      }
      const request = new XMLHttpRequest();
      request.open("POST", link);
      request.upload?.addEventListener("progress", (e: ProgressEvent) => {
        upload.setLoaded(e.loaded);
      });
      request.addEventListener("load", async (e) => {
        if (request.status === 200) {
          if (this.provider.supportsVersioning) {
            for (const file of upload.files) {
              const finalPath = join(upload.destination.path, file.path ?? file.name);

              await connection.checkpointVersion({
                path: { path: finalPath },
                message: description,
              });
            }
          }
          upload.setStatus(TaskStatus.Done);
          resolve();
        } else {
          const error = request.response ? request.response.toString() : "Unknown error.";
          upload.setError(error);
          reject(error);
        }
      });
      request.addEventListener("error", (e) => {
        upload.setError(request.response?.toString() ?? "Unknown error.");
        reject(e);
      });
      request.addEventListener("abort", (e) => {
        if (upload.status !== TaskStatus.Error && upload.status !== TaskStatus.Done) {
          upload.setStatus(TaskStatus.Aborted);
        }
        resolve();
      });
      request.send(data);

      upload.onAbort = () => request.abort();
    });
  }
}
