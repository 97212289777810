import { Connection, ServerFeatures } from "@omniverse/api";
import { Path as NucleusPath, PathType as NucleusPathType, StatusType } from "@omniverse/api/data";
import { Profiles, Tokens } from "@omniverse/auth";
import { AuthProvider, Profile as NucleusProfile } from "@omniverse/auth/data";
import { ClientType } from "@omniverse/discovery";
import { Capabilities } from "@omniverse/discovery/data";
import { InterfaceCapabilities } from "@omniverse/idl/schema";
import { NGSearchService } from "@omniverse/ngsearch/client";
import { Search } from "@omniverse/search/client";
import { DefaultMessage } from "../../../util/PathErrors";
import { OfflineModeError } from "../../../util/SessionErrors";
import Path, { PathPermission, PathType } from "../../Path";
import Storage from "../../Storage";
import { Profile } from "../../User";
import { CommandRegistry, Commands, ILinkGenerator, ILinkParams, IProvider, TagCommands } from "../Provider";
import NucleusActivatePasswordCommand from "./NucleusActivatePasswordCommand";
import NucleusAddUserToGroupCommand from "./NucleusAddUserToGroupCommand";
import NucleusCopyCommand from "./NucleusCopyCommand";
import NucleusCreateAPITokenCommand from "./NucleusCreateAPITokenCommand";
import NucleusCreateCheckpointCommand from "./NucleusCreateCheckpointCommand";
import NucleusCreateFolderCommand from "./NucleusCreateFolderCommand";
import NucleusCreateGroupCommand from "./NucleusCreateGroupCommand";
import NucleusCreateUserCommand from "./NucleusCreateUserCommand";
import NucleusDeleteAPITokenCommand from "./NucleusDeleteAPITokenCommand";
import NucleusDeleteCheckpointCommand from "./NucleusDeleteCheckpointCommand";
import NucleusDeleteCommand from "./NucleusDeleteCommand";
import NucleusDeleteGroupCommand from "./NucleusDeleteGroupCommand";
import { closeDiscoveryConnection, createNucleusServiceClient } from "./NucleusDiscovery";
import NucleusDownloadCheckpointCommand from "./NucleusDownloadCheckpointCommand";
import NucleusGenerateInvitationLinkCommand from "./NucleusGenerateInvitationLinkCommand";
import NucleusGenerateResetPasswordLinkCommand from "./NucleusGenerateResetPasswordLinkCommand";
import NucleusGetACLCommand from "./NucleusGetACLCommand";
import NucleusGetACLResolvedCommand from "./NucleusGetACLResolvedCommand";
import NucleusGetAPITokensCommand from "./NucleusGetAPITokensCommand";
import NucleusGetCheckpointsCommand from "./NucleusGetCheckpointsCommand";
import NucleusGetGroupsCommand from "./NucleusGetGroupsCommand";
import NucleusGetGroupUsersCommand from "./NucleusGetGroupUsersCommand";
import NucleusGetSearchPrefixesCommand from "./NucleusGetSearchPrefixesCommand";
import NucleusGetUserGroupsCommand from "./NucleusGetUserGroupsCommand";
import NucleusGetUserProfileCommand from "./NucleusGetUserProfileCommand";
import NucleusGetUsersCommand from "./NucleusGetUsersCommand";

import NucleusLinkGenerator from "./NucleusLinkGenerator";
import NucleusListCommand from "./NucleusListCommand";
import NucleusMountCommand from "./NucleusMountCommand";
import NucleusMoveCommand from "./NucleusMoveCommand";
import NucleusRemoveUserFromGroupCommand from "./NucleusRemoveUserFromGroupCommand";
import NucleusRenameGroupCommand from "./NucleusRenameGroupCommand";
import NucleusResetPasswordCommand from "./NucleusResetPasswordCommand";
import NucleusRestoreCheckpointCommand from "./NucleusRestoreCheckpointCommand";
import NucleusSearchCommand from "./NucleusSearchCommand";
import NucleusFindSimilarFilesCommand from "./NucleusFindSimilarFilesCommand";
import NucleusSession from "./NucleusSession";
import NucleusSetACLCommand from "./NucleusSetACLCommand";
import NucleusSetUserAdminAccessCommand from "./NucleusSetUserAdminAccessCommand";
import NucleusSetUserEnabledCommand from "./NucleusSetUserEnabledCommand";
import NucleusSetUserProfileCommand from "./NucleusSetUserProfileCommand";
import NucleusSetUserReadOnlyAccessCommand from "./NucleusSetUserReadOnlyAccessCommand";
import NucleusSubscribeCommand from "./NucleusSubscribeCommand";
import NucleusUnmountCommand from "./NucleusUnmountCommand";
import NucleusUnsubscribeCommand from "./NucleusUnsubscribeCommand";
import NucleusUploadCommand from "./NucleusUploadCommand";
import NucleusAddTagCommand from "./tags/NucleusAddTagCommand";
import NucleusDeleteTagCommand from "./tags/NucleusDeleteTagCommand";
import NucleusEditTagCommand from "./tags/NucleusEditTagCommand";
import NucleusGetTagsCommand from "./tags/NucleusGetTagsCommand";
import NucleusGetTagSuggestionsCommand from "./tags/NucleusGetTagSuggestionsCommand";
import NucleusSetTagsCommand from "./tags/NucleusSetTagsCommand";

export class NucleusConnection extends Connection {
  public lftAddress: string = "";
}

export default class Nucleus implements IProvider {
  public commands: CommandRegistry<Nucleus>;
  public readonly type: string = "nucleus";
  public readonly server: string;
  public readonly linkGenerator: NucleusLinkGenerator;
  public profileCapabilities: Partial<Record<keyof typeof OPTIONAL_PROFILES_CAPABILITIES, number>> | undefined;
  public tokensCapabilities: Partial<Record<keyof typeof OPTIONAL_TOKENS_CAPABILITIES, number>> | undefined;
  public searchCapabilities?: Partial<Capabilities>;

  public session: NucleusSession;

  protected connectionPool: NucleusConnectionPool;

  constructor(server: string, session: NucleusSession) {
    this.commands = new CommandRegistry<Nucleus>(this);
    this.server = server;
    this.session = session;
    this.linkGenerator = new NucleusLinkGenerator(this);
    this.connectionPool = new NucleusConnectionPool(this.session, this.server);

    this.commands.set(Commands.List, NucleusListCommand);
    this.commands.set(Commands.Subscribe, NucleusSubscribeCommand);
    this.commands.set(Commands.Unsubscribe, NucleusUnsubscribeCommand);
    this.commands.set(Commands.CreateFolder, NucleusCreateFolderCommand);
    this.commands.set(Commands.Upload, NucleusUploadCommand);
    this.commands.set(Commands.Delete, NucleusDeleteCommand);
    this.commands.set(Commands.Copy, NucleusCopyCommand);
    this.commands.set(Commands.Move, NucleusMoveCommand);
    this.commands.set(Commands.Search, NucleusSearchCommand);
    this.commands.set(Commands.FindSimilar, NucleusFindSimilarFilesCommand);
    this.commands.set(Commands.GetSearchPrefixes, NucleusGetSearchPrefixesCommand);
    this.commands.set(Commands.GetResolvedACL, NucleusGetACLResolvedCommand);
    this.commands.set(Commands.GetACL, NucleusGetACLCommand);
    this.commands.set(Commands.SetACL, NucleusSetACLCommand);
    this.commands.set(Commands.Mount, NucleusMountCommand);
    this.commands.set(Commands.Unmount, NucleusUnmountCommand);

    this.commands.set(Commands.CreateGroup, NucleusCreateGroupCommand);
    this.commands.set(Commands.CreateUser, NucleusCreateUserCommand);
    this.commands.set(Commands.AddUserToGroup, NucleusAddUserToGroupCommand);
    this.commands.set(Commands.RemoveUserFromGroup, NucleusRemoveUserFromGroupCommand);
    this.commands.set(Commands.GetGroups, NucleusGetGroupsCommand);
    this.commands.set(Commands.GetUsers, NucleusGetUsersCommand);
    this.commands.set(Commands.GetAPITokens, NucleusGetAPITokensCommand);
    this.commands.set(Commands.CreateAPIToken, NucleusCreateAPITokenCommand);
    this.commands.set(Commands.DeleteAPIToken, NucleusDeleteAPITokenCommand);

    this.commands.set(Commands.GetCheckpoints, NucleusGetCheckpointsCommand);
    this.commands.set(Commands.DeleteCheckpoint, NucleusDeleteCheckpointCommand);
    this.commands.set(Commands.CreateCheckpoint, NucleusCreateCheckpointCommand);
    this.commands.set(Commands.DownloadCheckpoint, NucleusDownloadCheckpointCommand);
    this.commands.set(Commands.RestoreCheckpoint, NucleusRestoreCheckpointCommand);

    this.commands.set(Commands.GetGroupUsers, NucleusGetGroupUsersCommand);
    this.commands.set(Commands.GetUserGroups, NucleusGetUserGroupsCommand);
    this.commands.set(Commands.RenameGroup, NucleusRenameGroupCommand);
    this.commands.set(Commands.DeleteGroup, NucleusDeleteGroupCommand);
    this.commands.set(Commands.GenerateResetPasswordLink, NucleusGenerateResetPasswordLinkCommand);
    this.commands.set(Commands.GenerateInvitationLink, NucleusGenerateInvitationLinkCommand);
    this.commands.set(Commands.ActivateUser, NucleusActivatePasswordCommand);
    this.commands.set(Commands.ResetPassword, NucleusResetPasswordCommand);
    this.commands.set(Commands.GetUserProfile, NucleusGetUserProfileCommand);
    this.commands.set(Commands.SetUserProfile, NucleusSetUserProfileCommand);
    this.commands.set(Commands.SetUserAdminAccess, NucleusSetUserAdminAccessCommand);
    this.commands.set(Commands.SetUserReadOnlyAccess, NucleusSetUserReadOnlyAccessCommand);
    this.commands.set(Commands.SetUserEnabled, NucleusSetUserEnabledCommand);

    this.commands.set(TagCommands.Get, NucleusGetTagsCommand);
    this.commands.set(TagCommands.GetSuggestions, NucleusGetTagSuggestionsCommand);
    this.commands.set(TagCommands.Set, NucleusSetTagsCommand);
    this.commands.set(TagCommands.Add, NucleusAddTagCommand);
    this.commands.set(TagCommands.Edit, NucleusEditTagCommand);
    this.commands.set(TagCommands.Delete, NucleusDeleteTagCommand);
  }

  public get name(): string {
    return this.session.server;
  }

  public get publicName(): string {
    let serverName = this.session.server;
    if (serverName === "127.0.0.1") {
      serverName = "localhost";
    }
    return serverName;
  }

  public get capabilities(): Record<string, number> | undefined {
    return this.connectionPool.capabilities;
  }

  public get supportsVersioning(): boolean {
    return this.connectionPool.supportsVersioning;
  }

  public get supportsAdvancedSearch(): boolean {
    return typeof this.searchCapabilities?.["get_prefixes"] !== "undefined";
  }

  public async init(): Promise<void> {
    // get services capabilities
    Promise.all([this.createTokensClient(), this.createProfilesClient(), this.createSearchClient()])
      .then((clients) => clients.map((client) => client.transport.close()))
      .catch(console.warn);

    try {
      await this.linkGenerator.init();
    } catch (err) {
      console.warn(err);
    }
  }

  public async getRoot(storage: Storage): Promise<Path> {
    const root = new Path("/", PathType.Folder, storage);
    root.permissions = this.session.isSuperUser
      ? [PathPermission.Read, PathPermission.Write, PathPermission.Admin]
      : [PathPermission.Read];
    return root;
  }

  public close(): void {
    this.connectionPool.close().catch((err) => console.error(`Failed to close Nucleus connections: ${err}.`));
    closeDiscoveryConnection(this.server);
  }

  public toJSON(): any {
    return {
      type: this.type,
      server: this.server,
      session: this.session.toJSON(),
    };
  }

  public async getConnection(): Promise<NucleusConnection> {
    return await this.connectionPool.get();
  }

  public async createTokensClient(): Promise<Tokens> {
    const tokens = await this.createServiceClient(Tokens, "TOKENS", {
      capabilities: REQUIRED_TOKENS_CAPABILITIES,
    });

    this.tokensCapabilities = tokens[InterfaceCapabilities];
    return tokens;
  }

  public async createProfilesClient(): Promise<Profiles> {
    const profiles = await this.createServiceClient(Profiles, "AUTH", {
      capabilities: REQUIRED_PROFILES_CAPABILITIES,
    });
    this.profileCapabilities = profiles[InterfaceCapabilities];
    return profiles;
  }

  public async createSearchClient(): Promise<Search | NGSearchService> {
    try {
      const ngSearch = await this.createServiceClient(NGSearchService, "NGSEARCH");
      this.searchCapabilities = ngSearch[InterfaceCapabilities];
      return ngSearch;
    } catch (error) {
      console.warn("Couldn't discover ngsearch service:", error);

      const search = await this.createServiceClient(Search, "SEARCH", {
        capabilities: {
          find2: 0,
        },
      });
      this.searchCapabilities = search[InterfaceCapabilities];
      return search;
    }
  }

  public async createServiceClient<T>(
    type: ClientType<T>,
    serviceName: string,
    options: DiscoveryOptions = {}
  ): Promise<
    T & {
      [InterfaceCapabilities]?: Record<string, number> | undefined;
    }
  > {
    return createNucleusServiceClient(type, this.server, serviceName, options);
  }
}

export interface DiscoveryOptions {
  meta?: { [key: string]: string };
  capabilities?: { [func: string]: number };
}

export async function parseNucleusPath(data: NucleusPath, parent: Path, linkGenerator?: ILinkGenerator): Promise<Path> {
  const storage = parent.storage.name;
  const linkParams: ILinkParams = {
    storage,
    path: data.uri!,
  };

  const link = linkGenerator?.createLink(linkParams);
  const downloadLink = (await linkGenerator?.createDownloadLink(linkParams)) ?? "";
  const thumbnail = (await linkGenerator?.createThumbnailLink(linkParams)) ?? "";
  const generatedThumbnail = (await linkGenerator?.createGeneratedThumbnailLink(linkParams)) ?? "";
  const type = parseNucleusPathType(data.type!);

  const path = new Path(
    data.uri!,
    type!,
    parent.storage,
    data.created ? new Date(data.created) : undefined,
    data.modified ? new Date(data.modified) : undefined,
    data.created_by,
    data.modified_by,
    data.size,
    data.mounted,
    link,
    downloadLink,
    thumbnail,
    generatedThumbnail,
    data.acl
  );

  path.parent = parent;
  return path;
}

export function parseNucleusPathType(data: NucleusPathType): PathType {
  switch (data) {
    case NucleusPathType.Asset:
      return PathType.File;
    case NucleusPathType.Folder:
      return PathType.Folder;
    case NucleusPathType.Mount:
      return PathType.Mount;
    default:
      return PathType.Unknown;
  }
}

export function parseNucleusProfile(profile?: NucleusProfile): Profile | undefined {
  if (!profile) {
    return;
  }

  return {
    firstName: profile.first_name ?? "",
    lastName: profile.last_name ?? "",
    admin: profile.admin ?? false,
    provider: profile.provider ?? "",
    email: profile.email ?? "",
    readonly: profile.readonly ?? false,
    nucleusRo: profile.nucleus_ro ?? false,
    enabled: profile.enabled ?? false,
    activated: profile.activated ?? false,
    canChangePassword: Boolean(
      !profile.readonly && (profile.provider === AuthProvider.Internal || profile.provider === AuthProvider.System)
    ),
  };
}

export function convertToNucleusProfile(profile: Profile): NucleusProfile {
  return {
    first_name: profile.firstName,
    last_name: profile.lastName,
    admin: profile.admin,
    provider: profile.provider,
    email: profile.email,
    readonly: profile.readonly,
    enabled: profile.enabled,
    activated: profile.activated,
  };
}

const REQUIRED_NUCLEUS_CAPABILITIES = {
  ping: 0,
  auth: 2,
  authorize_token: 1,
  subscribe_server_notifications: 0,
  stat2: 1,
  list: 3,
  list2: 1,
  subscribe_list: 0,
  create: 2,
  create_asset: 0,
  update_asset: 0,
  create_object: 0,
  update_object: 0,
  update: 1,
  create_asset_with_hash: 0,
  update_asset_with_hash: 0,
  read: 0,
  read_asset_version: 0,
  read_asset_resolved: 0,
  read_object_version: 0,
  read_object_resolved: 0,
  subscribe_read_asset: 0,
  subscribe_read_object: 0,
  rename: 0,
  delete: 0,
  delete2: 0,
  copy2: 0,
  create_directory: 0,
  lock: 2,
  unlock: 1,
  copy: 1,
  get_transaction_id: 0,
  set_path_options: 1,
  set_path_options2: 0,
  get_acl: 0,
  change_acl: 0,
  get_acl_v2: 0,
  set_acl_v2: 0,
  get_groups: 0,
  get_group_users: 0,
  get_users: 0,
  get_user_groups: 0,
  create_group: 0,
  rename_group: 0,
  remove_group: 0,
  add_user_to_group: 0,
  remove_user_from_group: 0,
  mount: 0,
  unmount: 0,
  get_mount_info: 0,
  checkpoint_version: 0,
  replace_version: 0,
  get_checkpoints: 0,
  get_branches: 0,
};

const REQUIRED_PROFILES_CAPABILITIES = {
  get_settings: 0,
  get_all: 0,
  get: 0,
  set_info: 0,
  set_enabled: 0,
  set_admin: 0,
  add: 0,
};

const OPTIONAL_PROFILES_CAPABILITIES = {
  set_nucleus_ro: 0,
};

const REQUIRED_TOKENS_CAPABILITIES = {
  refresh: 0,
};

const OPTIONAL_TOKENS_CAPABILITIES = {
  create_api_token: 0,
  delete_api_token: 0,
  get_api_tokens: 0,
  auth_with_api_token: 0,
  generate: 0,
};

class NucleusConnectionPool {
  public capabilities?: Record<string, number>;
  public supportsVersioning: boolean = false;

  private pool: Array<Promise<NucleusConnection> | null> = [];
  private currentConnectionIndex: number = -1;

  public constructor(
    public readonly session: NucleusSession,
    public readonly server: string,
    public maxConnectionCount: number = 3
  ) {}

  public async get(): Promise<NucleusConnection> {
    const connectionIndex = (this.currentConnectionIndex = ++this.currentConnectionIndex % this.maxConnectionCount);

    let connection = this.pool[connectionIndex];
    if (connection) {
      console.log(
        `[${this.server}] Reusing the Nucleus connection (#${connectionIndex + 1} of ${this.maxConnectionCount}).`
      );
      return await connection;
    }

    console.log(
      `[${this.server}] Establish a new Nucleus connection (#${connectionIndex + 1} of ${this.maxConnectionCount})...`
    );
    this.pool[connectionIndex] = connection = this.establish();

    connection.catch(() => (this.pool[connectionIndex] = null));
    return connection;
  }

  public async close(): Promise<void> {
    for (let index = 0; index < this.maxConnectionCount; index++) {
      const connection = this.pool[index];
      if (connection) {
        (await connection).transport.close();
      }
    }

    this.pool = [];
  }

  protected async remove(connection: NucleusConnection): Promise<void> {
    for (let index = 0; index < this.maxConnectionCount; index++) {
      const connecting = this.pool[index];
      if (connecting) {
        const conn = await connecting;
        if (conn === connection) {
          this.pool[index] = null;
          connection.transport.close();
          break;
        }
      }
    }
  }

  protected async establish(): Promise<NucleusConnection> {
    if (!this.session.established) {
      throw new OfflineModeError();
    }

    const conn = await createNucleusServiceClient(Connection, this.server, "NUCLEUS", {
      capabilities: REQUIRED_NUCLEUS_CAPABILITIES,
    });
    this.capabilities = conn[InterfaceCapabilities];

    let serverFeatures;
    try {
      serverFeatures = await createNucleusServiceClient(ServerFeatures, this.server, "ServerFeatures");
      const versioning = await serverFeatures.versioning();
      if (versioning.status === StatusType.OK) {
        this.supportsVersioning = versioning.enabled ?? false;
      }
    } finally {
      if (serverFeatures) {
        serverFeatures.transport.close();
      }
    }

    try {
      const connection = (conn as unknown) as NucleusConnection;
      connection.transport.once("error", () => {
        this.remove(connection);
      });
      connection.transport.once("close", () => {
        this.remove(connection);
      });
      const result = await connection.authorizeToken({ token: this.session.accessToken! });
      if (result.status === StatusType.OK) {
        connection.lftAddress = result.lft_address!;
        return connection;
      }
      if (result.status === StatusType.TokenExpired) {
        await this.session.refresh();
        return this.establish();
      }

      throw new Error(`Cannot connect to the server (${result.status}).`);
    } catch (error: any) {
      conn.transport.close();

      if (!error.message) {
        if (error instanceof Event) {
          // The server transport raises connection errors as simple events.
          // If error is instance of Event class, then this is a connection error.
          throw new Error("Cannot connect to the server.");
        } else {
          throw new Error(DefaultMessage("UNKNOWN"));
        }
      }
      throw error;
    }
  }
}
