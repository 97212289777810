import React from "react";
import Path, { PathType } from "../state/Path";
import { Icons } from "./Icon";
import TreeIcon from "./TreeIcon";


export interface PathTreeIconProps {
  path: Path;
}

const PathTreeIcon: React.FC<PathTreeIconProps> = ({ path }) => {
  let icon: string;
  if (path.type === PathType.Mount) {
    icon = Icons.Mount;
  } else if (path === path.storage?.root) {
    icon = Icons.Cloud;
  } else {
    icon = Icons.Folder;
  }

  return <TreeIcon icon={icon} />;
};

export default PathTreeIcon;