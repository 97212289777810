import { encodeResetPasswordPayload } from "@omniverse/auth/react/hooks/ResetPassword";
import { isElectron } from "../../../util/Electron";
import { joinURL } from "../../../util/Path";
import { OfflineModeError } from "../../../util/SessionErrors";
import { Commands } from "../Provider";
import { IGenerateResetPasswordLinkCommandArguments } from "../types/GenerateResetPasswordLinkCommand";
import { NucleusCommand } from "./index";

export default class NucleusGenerateResetPasswordLinkCommand extends NucleusCommand<
  IGenerateResetPasswordLinkCommandArguments,
  void,
  string
> {
  name = Commands.GenerateResetPasswordLink;

  public async allowed(): Promise<boolean> {
    return Boolean(this.provider.session.isSuperUser);
  }

  public async execute({ user }: IGenerateResetPasswordLinkCommandArguments): Promise<string> {
    console.log(`[${this.provider.name}] Generate reset password payload for user "${user.name}"`);

    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }

    const payload = await encodeResetPasswordPayload(this.provider.name, user.name, this.provider.session.accessToken!);

    if (isElectron()) {
      const loginURL = await this.provider.session.getExternalLoginURL();
      return joinURL(loginURL, `/reset-password?payload=${payload}`);
    } else {
      return joinURL(`/reset-password?payload=${payload}`);
    }
  }
}
