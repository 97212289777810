import Icon, { Icons } from "./Icon";
import Spinner from "./Spinner";
import React from "react";
import styled from "styled-components";
import TaskStatus from "../state/TaskStatus";

const TaskStatusIcon: React.FC<{
  status: TaskStatus;
  className?: string;
  retryable?: boolean;
  onRetry?(): void;
}> = ({ status, retryable, className, onRetry }) => {
  if (status === TaskStatus.Loading) {
    return <Spinner className={className} />;
  }
  if (status === TaskStatus.Done) {
    return <Icon className={className} icon={Icons.Ok} title={"Completed"} />;
  } else if (retryable) {
    return <RetryIcon className={className} title={"Retry"} onClick={onRetry} />;
  } else if (status === TaskStatus.Aborted) {
    return <Icon className={className} icon={Icons.Abort} title={"Canceled"} />;
  } else if (status === TaskStatus.Error) {
    return <ErrorIcon className={className} title={"Failed"} />;
  } else if (status === TaskStatus.Idle) {
    return <Icon className={className} icon={Icons.Unknown} />;
  } else {
    return null;
  }
};

export const RetryIcon = styled(Icon).attrs({
  icon: Icons.Redo,
  clickable: true,
})``;

export const ErrorIcon = styled(Icon).attrs({
  icon: Icons.Exclamation,
})`
  color: ${({ theme }) => theme.colors.dangerText};
`;

export default TaskStatusIcon;
