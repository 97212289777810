import { StatusType } from "@omniverse/api/data";
import { GetUserGroupsError } from "../../../util/UserErrors";
import Group from "../../Group";
import { Commands } from "../Provider";
import { IGetUserGroupsCommandArguments } from "../types/GetUserGroupsCommand";
import { NucleusCommand } from "./index";

export default class NucleusGetUserGroupsCommand extends NucleusCommand<IGetUserGroupsCommandArguments, void, Group[]> {
  name = Commands.GetUserGroups;

  public async allowed(): Promise<boolean> {
    return Boolean(this.provider.session.isSuperUser);
  }

  public async execute({ user }: IGetUserGroupsCommandArguments): Promise<Group[]> {
    console.log(`[${this.provider.name}] Get groups for user "${user.name}"`);
    user.setLoading(true);
    try {
      const connection = await this.provider.getConnection();
      const result = await connection.getUserGroups({ username: this.name });
      if (result.status === StatusType.UserNotFound) {
        const groups: Group[] = [];
        user.setGroups(groups);
        return groups;
      }

      if (result.status !== StatusType.OK) {
        throw new GetUserGroupsError(result);
      }

      const groups = result.groups!.map((name) => new Group(name, user.storage));
      user.setGroups(groups);
      return groups;
    } finally {
      user.setLoading(false);
    }
  }
}
