import { AuthStatus } from "@omniverse/auth/data";
import { OfflineModeError } from "../../../util/SessionErrors";

import { Commands } from "../Provider";
import { ICreateAPITokenCommandArguments } from "../types/CreateAPITokenCommand";
import { NucleusCommand } from "./index";
import { APIToken } from "../../APITokens";

export default class NucleusCreateAPITokenCommand extends NucleusCommand<
  ICreateAPITokenCommandArguments,
  void,
  APIToken
> {
  name = Commands.CreateAPIToken;

  public async allowed(): Promise<boolean> {
    return typeof this.provider.tokensCapabilities?.create_api_token === "number";
  }

  public async execute({ storage, name }: ICreateAPITokenCommandArguments): Promise<APIToken> {
    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }

    storage.apiTokens.setLoading(true);
    let tokens;
    try {
      tokens = await this.provider.createTokensClient();

      const response = await tokens.createApiToken({ access_token: this.provider.session.accessToken!, name });

      if (response.status === AuthStatus.Expired) {
        await this.provider.session.refresh();
        return this.execute({ storage, name });
      }

      if (response.status === AuthStatus.Exists) {
        throw new Error(`Error creating API token: ${name} already exists`);
      }

      if (response.status !== AuthStatus.OK) {
        throw new Error(`Error creating API token: ${name}`);
      }

      const token: APIToken = { name, apiToken: response.token };
      storage.apiTokens.add(token);
      return token;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      storage.apiTokens.setLoading(false);
      await tokens?.transport.close();
    }
  }
}
