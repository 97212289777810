import React from "react";
import styled from "styled-components";
import { DefaultTheme } from "./Theme";

const ContentPlaceholder: React.FC = ({ children }) => {
  return <StyledContentPlaceholder>{children}</StyledContentPlaceholder>;
};

const StyledContentPlaceholder = styled.div`
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.foreground};
  display: grid;
  place-content: center;
  height: 100%;
`;
StyledContentPlaceholder.defaultProps = {
  theme: DefaultTheme,
};

export default ContentPlaceholder;
