import React, { AriaAttributes } from "react";
import DialogTitleBar, {
  DialogCloseIcon,
  DialogTitleFiller,
  DialogTitleImage,
  DialogTitleText,
} from "./DialogTitleBar";
import { PictureSource } from "./Picture";

export interface DialogTitleProps extends AriaAttributes {
  title: string;
  disabled?: boolean;
  id?: string;
  image?: PictureSource;
  imageStyle?: React.CSSProperties;
  onClose(): void;
}

const DialogTitle: React.FC<DialogTitleProps> = ({ title, id, disabled, image, imageStyle, onClose, ...props }) => {
  return (
    <DialogTitleBar {...props}>
      {image ? <DialogTitleImage src={image} style={imageStyle} /> : <DialogTitleFiller />}
      <DialogTitleText id={id}>{title}</DialogTitleText>
      <DialogCloseIcon disabled={disabled} onClick={onClose} />
    </DialogTitleBar>
  );
};

DialogTitle.defaultProps = {
  image: "",
  disabled: false,
};

export default DialogTitle;
