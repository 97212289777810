import { observer } from "mobx-react";
import React, { useCallback, useContext, useMemo } from "react";
import styled from "styled-components";
import ApplicationContext from "../context/ApplicationContext";
import Path from "../state/Path";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import Dialog from "./Dialog";
import DialogForm from "./DialogForm";
import { DialogContext } from "./Dialogs";
import DialogTitle from "./DialogTitle";
import FormGroup from "./FormGroup";
import Input from "./Input";
import Label from "./Label";
import PathCopyURLButton from "./PathCopyURLButton";

interface PathShareDialogProps {
  path: Path;
}

const PathShareDialog: React.FC<PathShareDialogProps> = ({ path }) => {
  const dialog = useContext(DialogContext);
  const { app } = useContext(ApplicationContext);

  const link = useMemo(() => {
    return app.href(path.link, { absolute: true });
  }, [path.link, app]);

  const preventChange = useCallback((e: React.ChangeEvent) => {
    e.preventDefault();
  }, []);

  return (
    <Dialog onClose={dialog.hide}>
      <DialogTitle title={"Share Path"} onClose={dialog.hide} />
      <DialogForm>
        <FormGroup>
          <ShareDialogExplanation>For sharing this path, copy the link below.</ShareDialogExplanation>

          <LinkLabel>
            <LinkLabelTitle>Link</LinkLabelTitle>
            <LinkInput value={link} onChange={preventChange} />
            <PathCopyURLButton path={path} />
          </LinkLabel>
        </FormGroup>

        <ButtonGroup>
          <Button onClick={dialog.hide}>Close</Button>
        </ButtonGroup>
      </DialogForm>
    </Dialog>
  );
};

const ShareDialogExplanation = styled.div`
  margin: 5px 0;
  font-size: 9pt;
  color: ${({ theme }) => theme.colors.hint};
`;

const LinkLabel = styled(Label)`
  display: flex;
  align-items: center;
`;

const LinkLabelTitle = styled.div`
  flex: 0 0 35px;
`;

export const LinkInput = styled(Input)`
  flex: 1;
  height: 30px;
`;

export default observer(PathShareDialog);
