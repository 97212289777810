import React, { useCallback } from "react";
import useApp from "./useApp";
import {
  NavigateFunction,
  NavigateOptions,
  To,
  UNSAFE_NavigationContext,
  UNSAFE_RouteContext,
  // eslint-disable-next-line no-restricted-imports
  useLocation,
  resolvePath,
  Path,
} from "react-router-dom";
import { parsePath } from "history";
import { joinURL } from "../util/Path";

export default function useAppNavigate() {
  const app = useApp();
  const navigate = useNavigate();

  return useCallback(
    (link: string, options?: NavigateOptions): void => {
      return navigate(app.href(link), options);
    },
    [app, navigate]
  );
}

// react-router useNavigate is inconsistent with(out) basename
// it causes loop from MicrosoftOutlookURLFixPage <Navigate > trying to strip '//' to '/' a
// This issue isn't going to be fixed ):
// https://github.com/remix-run/react-router/issues/8645

function resolveTo(toArg: To, routePathnames: string[], locationPathname: string): Path {
  let to = typeof toArg === "string" ? parsePath(toArg) : toArg;
  let toPathname = toArg === "" || to.pathname === "" ? "/" : to.pathname;

  // If a pathname is explicitly provided in `to`, it should be relative to the
  // route context. This is explained in `Note on `<Link to>` values` in our
  // migration guide from v5 as a means of disambiguation between `to` values
  // that begin with `/` and those that do not. However, this is problematic for
  // `to` values that do not provide a pathname. `to` can simply be a search or
  // hash string, in which case we should assume that the navigation is relative
  // to the current location's pathname and *not* the route pathname.
  let from: string;
  if (toPathname == null) {
    from = locationPathname;
  } else {
    let routePathnameIndex = routePathnames.length - 1;

    if (toPathname.startsWith("..")) {
      let toSegments = toPathname.split("/");

      // Each leading .. segment means "go up one route" instead of "go up one
      // URL segment".  This is a key difference from how <a href> works and a
      // major reason we call this a "to" value instead of a "href".
      while (toSegments[0] === "..") {
        toSegments.shift();
        routePathnameIndex -= 1;
      }

      to.pathname = toSegments.join("/");
    }

    // If there are more ".." segments than parent routes, resolve relative to
    // the root / URL.
    from = routePathnameIndex >= 0 ? routePathnames[routePathnameIndex] : "/";
  }

  let path = resolvePath(to, from);

  // Ensure the pathname has a trailing slash if the original to value had one.
  if (toPathname && toPathname !== "/" && toPathname.endsWith("/") && !path.pathname.endsWith("/")) {
    path.pathname += "/";
  }

  return path;
}

export function useNavigate(): NavigateFunction {
  let { basename, navigator } = React.useContext(UNSAFE_NavigationContext);
  let { matches } = React.useContext(UNSAFE_RouteContext);
  let { pathname: locationPathname } = useLocation();
  let routePathnamesJson = JSON.stringify(matches.map((match) => match.pathnameBase));
  let activeRef = React.useRef(false);
  React.useEffect(() => {
    activeRef.current = true;
  });
  let navigate: NavigateFunction = React.useCallback(
    (to: To | number, options: NavigateOptions = {}) => {
      if (!activeRef.current) return;
      if (typeof to === "number") {
        navigator.go(to);
        return;
      }
      let path = resolveTo(to, JSON.parse(routePathnamesJson), locationPathname);
      if (basename !== "/") {
        path.pathname = joinURL(basename, path.pathname);
      }

      (!!options.replace ? navigator.replace : navigator.push)(path, options.state);
    },
    [basename, navigator, routePathnamesJson, locationPathname]
  );
  return navigate;
}
