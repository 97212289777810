import React, { useCallback, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

export interface INotificationContext {
  show<P>(type: React.ComponentType<P>, props: P): Notification["id"];
  hide(notificationId: number): void;
}

export const NotificationContext = React.createContext<INotificationContext>({} as INotificationContext);

interface Notification<P = any> {
  id: number;
  type: React.ComponentType<P>;
  props: P;
}

const Notifications: React.FC = ({ children }) => {
  const notificationId = useRef(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  function show<P>(type: React.ComponentType<P>, props: P) {
    const id = notificationId.current++;
    const notification: Notification = {
      id,
      type,
      props,
    };

    setNotifications((notifications) => [...notifications, notification]);
    return id;
  }

  function hide(id: number) {
    setNotifications((notifications) => notifications.filter((notification) => notification.id !== id));
  }

  const context = useMemo<INotificationContext>(
    () => ({
      show,
      hide,
    }),
    []
  );

  return (
    <NotificationContext.Provider value={context}>
      <ActiveNotifications notifications={notifications} onClose={hide} />
      {children}
    </NotificationContext.Provider>
  );
};

const ActiveNotifications: React.FC<{
  notifications: Notification[];
  onClose(id: number): void;
}> = ({ notifications, onClose }) => {
  return ReactDOM.createPortal(
    <StyledNotifications>
      {notifications.map((notification) => (
        <ActiveNotification key={notification.id} notification={notification} onClose={onClose} />
      ))}
    </StyledNotifications>,
    document.body
  );
};

const StyledNotifications = styled.div`
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
`;

const ActiveNotification: React.FC<{
  notification: Notification;
  onClose(id: number): void;
}> = ({ notification, onClose }) => {
  const close = useCallback(() => {
    onClose(notification.id);
  }, [notification, onClose]);

  const View = notification.type;
  return <View key={notification.id} onClose={close} {...notification.props} />;
};

export default Notifications;
