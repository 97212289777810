import React from "react";
import styled from "styled-components";

export interface AttributeProps {
  name: React.ReactNode;
  value: React.ReactNode;
}

const Attribute: React.FunctionComponent<AttributeProps> = ({ name, value }) => (
  <StyledAttribute>
    <AttributeName>{name}</AttributeName>
    <AttributeValue>{value}</AttributeValue>
  </StyledAttribute>
);

const StyledAttribute = styled.div`
  width: 100%;
`;

const AttributeName = styled.span``;
const AttributeValue = styled.span`
  float: right;
`;

export default Attribute;
