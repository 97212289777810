import { observer } from "mobx-react";
import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import useDialog from "../hooks/useDialog";
import useDoubleClick from "../hooks/useDoubleClick";
import { DropStyle } from "../hooks/useDrop";
import useContextMenu from "../hooks/useContextMenu";
import { useMultipleSelection } from "../hooks/useMultipleSelection";
import usePathDrag from "../hooks/usePathDrag";
import usePathDrop from "../hooks/usePathDrop";
import usePathNavigate from "../hooks/usePathNavigate";
import { PathType } from "../state/Path";
import NewFolderIcon from "../static/new-folder.png";
import { getDefaultPathThumbnail } from "../util/PathThumbnail";
import { GridCellMeasurement, GridCellProps } from "./Grid";
import GridCell, { GridCellMeasureOptions, measureGridCellHeight } from "./GridCell";
import Icon, { Icons } from "./Icon";
import NewFolderDialog from "./NewFolderDialog";
import { NewFolderCellType, PathCellType, PathGridCellType } from "./PathGrid";
import PathMenu from "./PathMenu";

const PathGridCell: React.FC<GridCellProps<PathGridCellType>> = ({ data, ...props }) => {
  if (data.type === "path") {
    return <PathCell data={data} {...props} />;
  }
  if (data.type === "new-folder") {
    return <NewFolderCell data={data} {...props} />;
  }
  return null;
};

let PathCell: React.FC<GridCellProps<PathCellType>> = ({ data: { path }, height, style }) => {
  const navigateTo = usePathNavigate();
  const cell = useRef<HTMLDivElement>();
  const [menu, onContextMenu] = useContextMenu(PathMenu, { props: { path } });
  const selection = useMultipleSelection(path.storage.selectedPaths);

  async function select() {
    cell.current?.scrollIntoView?.({ block: "nearest" });
    selection.select(path);
  }

  async function open() {
    if (path.type === PathType.Folder || path.type === PathType.Mount) {
      navigateTo(path);
    }
  }

  const drag = usePathDrag(path);
  const drop = usePathDrop(path);
  const onClick = useDoubleClick(select, open);
  const defaultThumbnail = useMemo(() => getDefaultPathThumbnail(path), [path]);

  return (
    <StyledPathGridCell style={style} {...drag} {...drop} onContextMenu={onContextMenu} onClick={onClick}>
      <GridCell
        ref={cell}
        name={path.name}
        thumbnail={path.thumbnail}
        fallbackImages={[path.generatedThumbnail, defaultThumbnail]}
        bordered={false}
        scaling={false}
        selected={path.selected}
        highlighted={Boolean(menu)}
        cellHeight={150}
        actualHeight={height}
      >
        {!path.canWrite() && <PathGridLockIcon />}
      </GridCell>

      {menu}
    </StyledPathGridCell>
  );
};
PathCell = observer(PathCell);

const NewFolderCell: React.FC<GridCellProps<NewFolderCellType>> = ({ data: { cwd }, height, style }) => {
  const newFolderDialog = useDialog(NewFolderDialog, { path: cwd });

  function createNewFolder() {
    newFolderDialog.show();
  }

  return (
    <StyledPathGridCell style={style} onClick={createNewFolder}>
      <GridCell
        name={"New Folder"}
        thumbnail={NewFolderIcon}
        bordered={false}
        selected={false}
        highlighted={false}
        cellHeight={150}
        actualHeight={height}
      />
    </StyledPathGridCell>
  );
};

const StyledPathGridCell = styled.div`
  border-radius: 5px;
  user-select: none;
  ${DropStyle};
`;

const PathGridLockIcon = styled(Icon).attrs({ icon: Icons.Lock })`
  position: absolute;
  right: 15px;
  bottom: 10px;
  font-size: 14pt;
  width: 10px;
  color: ${({ theme }) => theme.colors.dangerText};
  vertical-align: middle;
`;

export const PathGridCellMeasurer = (
  measurement: GridCellMeasurement<PathGridCellType>
): GridCellMeasurement<PathGridCellType> => {
  const options: GridCellMeasureOptions = {
    ...measurement,
    paddingTop: 0,
    paddingBottom: 0,
  };

  if (measurement.data.type === "path") {
    return { ...measurement, height: measureGridCellHeight(measurement.data.path.name, options) };
  }
  return measurement;
};

export default observer(PathGridCell);
