import { observer } from "mobx-react";
import React from "react";
import usePreference from "../hooks/usePreference";
import useStorageList from "../hooks/useStorageList";
import { Preferences } from "../state/Preferences";
import PathListPanel from "./PathListPanel";
import PathPanel from "./PathPanel";

export interface ContentBrowserRightPanelProps {
  storageName: string;
}

const ContentBrowserRightPanel: React.FC<ContentBrowserRightPanelProps> = ({ storageName }) => {
  const [showCheckpoints, setShowCheckpoints] = usePreference<boolean>(Preferences.ShowCheckpoints, true);
  const [showTags, setShowTags] = usePreference<boolean>(Preferences.ShowTags, true);

  const storageList = useStorageList();
  const storage = storageList.find(storageName);
  if (storage) {
    if (storage.selectedPaths.items.length > 1) {
      return <PathListPanel paths={storage.selectedPaths.items} />;
    } else {
      const currentPath = storage.selectedPaths.items.length === 1 ? storage.selectedPaths.items[0] : storage.cwd;
      if (currentPath) {
        return (
          <PathPanel
            path={currentPath}
            initial={{ showCheckpoints, showTags }}
            onToggleCheckpoints={setShowCheckpoints}
            onToggleTags={setShowTags}
          />
        );
      }
    }
  }
  return null;
};

export default observer(ContentBrowserRightPanel);
