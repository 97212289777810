import styled from "styled-components";
import Loader, { LoaderSpinner } from "./Loader";


const ContentBrowserLoader = styled(Loader)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  
  ${LoaderSpinner} {
    font-size: 12pt;
  }
`;

export default ContentBrowserLoader;