import { Icons } from "./Icon";
import React from "react";
import TreeIcon from "./TreeIcon";

export interface TreeToggleProps {
  title?: string;
  opened?: boolean;
  onClick?(e: React.MouseEvent): void;
}

const TreeToggle: React.FC<TreeToggleProps> = ({ title = "Toggle", opened, onClick }) => {
  function handleClick(e: React.MouseEvent) {
    if (onClick) {
      e.stopPropagation();
      onClick(e);
    }
  }

  return <TreeIcon tabIndex={0} icon={opened ? Icons.Down : Icons.Right} title={title} onClick={handleClick} />;
};

export default TreeToggle;
