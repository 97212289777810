import Path, { PathType } from "../state/Path";
import Default from "../static/default.png";
import FBXThumbnail from "../static/fbx.png";
import Folder from "../static/folder.png";
import MDLThumbnail from "../static/mdl.png";
import MountThumbnail from "../static/mount.png";
import OBJThumbnail from "../static/obj.png";
import ScriptThumbnail from "../static/script.png";
import TextureThumbnail from "../static/texture.png";
import USDSettingsThumbnail from "../static/usd-settings.png";
import USDThumbnail from "../static/usd.png";


const thumbnailMatch: { [mask: string]: string } = {
  ".mdl": MDLThumbnail,
  ".stage.usd": USDThumbnail,
  ".sublayer.usd": USDThumbnail,
  ".settings.usd": USDSettingsThumbnail,
  ".usd": USDThumbnail,
  ".usda": USDThumbnail,
  ".usdc": USDThumbnail,
  ".fbx": FBXThumbnail,
  ".obj": OBJThumbnail,
  ".jpg": TextureThumbnail,
  ".png": TextureThumbnail,
  ".tga": TextureThumbnail,
  ".hdri": TextureThumbnail,
  ".py": ScriptThumbnail,
};


export function getDefaultPathThumbnail(path: Path) {
  if (!path) {
    return Default;
  }

  if (path.type === PathType.Folder) {
    return Folder;
  }

  if (path.type === PathType.Mount) {
    return MountThumbnail;
  }

  for (const [mask, defaultThumbnail] of Object.entries(thumbnailMatch)) {
    if (path.path.endsWith(mask)) {
      return defaultThumbnail;
    }
  }
  return Default;
}


