import { toShortISODate } from "../util/DateTime";
import useInput from "./useInput";

export default function useDateInput(initial?: string | (() => string)) {
  return useInput(() => {
    const dateString = typeof initial === "function" ? initial() : initial;
    if (dateString) {
      const date = new Date(dateString);
      return toShortISODate(date);
    } else {
      return "";
    }
  });
}
