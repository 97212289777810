import React, { useCallback } from "react";
import { TreeItemEventData } from "../components/Tree";

export default function useTreeItemEvent<T extends React.SyntheticEvent, D>(callback: ((e: T) => void) | undefined, data: D) {
  return useCallback((e: React.SyntheticEvent) => {
    const evt = e as T & TreeItemEventData<D>;
    evt.item = data;
    if (callback) {
      callback(evt);
    }
  }, [callback, data]);
}