import { decodeInvitationPayload, InvitationPayload } from "@omniverse/auth/react/hooks/InviteUser";
import InvitationForm from "@omniverse/auth/react/InvitationForm";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import AppNavigate from "../components/AppNavigate";
import FormPageLayout from "../components/FormPageLayout";
import useAppLocation from "../hooks/useAppLocation";
import useAppNavigate from "../hooks/useAppNavigate";
import useStorageList from "../hooks/useStorageList";
import Nucleus from "../state/commands/nucleus/Nucleus";
import NucleusSession from "../state/commands/nucleus/NucleusSession";
import { Commands, parsePublicName } from "../state/commands/Provider";
import { IActivatePasswordCommand } from "../state/commands/types/ActivatePasswordCommand";
import Storage from "../state/Storage";
import User from "../state/User";

const InvitationPage: React.FC = () => {
  const storageList = useStorageList();
  const navigate = useAppNavigate();
  const location = useAppLocation();
  const search = new URLSearchParams(location.search);
  const payloadString = search.get("payload") ?? "";
  const payload = useMemo((): InvitationPayload | null => {
    try {
      return decodeInvitationPayload(payloadString);
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [payloadString]);

  async function activate(password: string) {
    if (!payload) {
      throw new Error("Invalid invitation link.");
    }

    payload.server = parsePublicName(payload.server);

    let storage = storageList.find(payload.server);
    if (storage) {
      storageList.remove(storage);
    }

    // TODO: Use ProviderFactory to get storage by its type and payload
    const provider = new Nucleus(payload.server, new NucleusSession(payload.server));
    storage = new Storage(provider);
    await storage.init();

    const user = new User(payload.username, storage);
    const command = storage.commands.get<IActivatePasswordCommand>(Commands.ActivateUser);
    if (command) {
      await command.execute({ user, password, payload });
    } else {
      throw new Error(
        `Cannot activate password for this storage -- ${Commands.ActivateUser} command is not supported.`
      );
    }

    storageList.add(storage);
    navigate("/");
  }

  if (!payload) {
    return <AppNavigate to={"/"} />;
  }

  return (
    <FormPageLayout>
      <InvitationForm username={payload.username} server={payload.server} onSubmit={activate} />
    </FormPageLayout>
  );
};

export default observer(InvitationPage);
