import React, { useCallback, useMemo, useRef } from "react";
import styled from "styled-components";
import Checkbox from "./Checkbox";
import Icon, { Icons } from "./Icon";
import Input from "./Input";
import Select, { Option } from "./Select";
import { StyledTable, TableCell, TableCellComponentProps, TableColumn } from "./Table";
import VirtualizedTable, { VirtualizedTableProps, VirtualizedTableRowComponentProps } from "./VirtualizedTable";

export interface KeyValue {
  key: string;
  value: KeyValueType;
  type: KeyValueTypeName;
  /**
   * Defines whether the option can be deleted.
   * 'true' by default.
   */
  canDelete?: boolean;
}

export type KeyValueType = string | number | boolean;
export type KeyValueTypeName = "string" | "number" | "boolean";

const KeyValueInput: React.FC<{
  name: string;
  rows: KeyValue[];
  className?: string;
  disabled?: boolean;
  tabIndex?: number;
  onChange(rows: KeyValue[]): void;
}> = ({ rows, className, disabled = false, tabIndex, onChange }) => {
  const table = useRef<VirtualizedTable<KeyValue>>(null);
  const rowsRef = useRef(rows);
  rowsRef.current = rows;

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const addRow = useCallback(() => {
    onChangeRef.current(rowsRef.current.concat([{ key: "", value: "", type: "string", canDelete: true }]));
    setTimeout(() => table.current?.scrollToBottom(), 0);
  }, []);

  const updateRow = useCallback((index: number, field: keyof KeyValue, value: KeyValueType) => {
    const row: KeyValue = { ...rowsRef.current[index], [field]: value };
    if (field === "type") {
      row.value = "";
    }

    onChangeRef.current([...rowsRef.current.slice(0, index), row, ...rowsRef.current.slice(index + 1)]);
  }, []);

  const deleteRow = useCallback((index: number) => {
    onChangeRef.current([...rowsRef.current.slice(0, index), ...rowsRef.current.slice(index + 1)]);
  }, []);

  const cellProps = useMemo(() => ({ disabled, onChange: updateRow, onDelete: deleteRow }), [
    disabled,
    updateRow,
    deleteRow,
  ]);

  return (
    <>
      <KeyValueTable
        ref={(table as unknown) as any}
        rows={rows}
        columns={KeyValueColumns}
        rowHeight={KeyValueRowHeight + KeyValueRowBorder * 2}
        rowComponent={KeyValueRow}
        headerComponent={KeyValueHeader}
        headTableComponent={KeyValueHeadTable}
        cellComponent={KeyValueCell}
        cellProps={cellProps}
        className={className}
      />

      <KeyValueToolbar>
        <KeyValueControlIcon title={"Add row"} icon={Icons.Plus} tabIndex={tabIndex} onClick={addRow} />
      </KeyValueToolbar>
    </>
  );
};

KeyValueInput.defaultProps = {
  disabled: false,
  tabIndex: 0,
};

const KeyValueTable = styled(VirtualizedTable).attrs({
  resetScroll: false,
  emptyRows: 0,
})<VirtualizedTableProps<KeyValue, KeyValueExtraCellProps>>`
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 300px;
  will-change: auto;
`;

const KeyValueHeadTable = styled(StyledTable)`
  width: 690px;
`;

const KeyValueColumns: TableColumn[] = [
  { title: "Key", name: "key", width: 280 },
  { title: "Type", name: "type", width: 100 },
  { title: "Value", name: "value", width: 280 },
  { title: "", name: "controls", width: 30 },
];

const KeyValueRowHeight = 35;
const KeyValueRowBorder = 1;
const KeyValueRow = styled.tr<VirtualizedTableRowComponentProps<KeyValue>>`
  height: ${KeyValueRowHeight}px;
  box-sizing: border-box;
  border-left: 1px solid ${({ theme }) => theme.colors.border};
`;

const KeyValueHeader = styled.th`
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  text-align: left;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.foreground};
  box-sizing: border-box;
`;

type KeyValueCellProps = KeyValueExtraCellProps & TableCellComponentProps<KeyValue>;

interface KeyValueExtraCellProps {
  disabled: boolean;
  onChange(rowIndex: number, field: keyof KeyValue, value: KeyValueType): void;
  onDelete(rowIndex: number): void;
}

const KeyValueCell: React.FC<KeyValueCellProps> = (props) => {
  if (props.column.name === "key") {
    return <KeyCellInput {...props} />;
  }
  if (props.column.name === "type") {
    return <KeyValueTypeCell {...props} />;
  }
  if (props.column.name === "value") {
    return <KeyValueCellInput {...props} />;
  }
  if (props.column.name === "controls") {
    return <KeyValueControlsCell {...props} />;
  }
  return null;
};

const KeyValueControlsCell: React.FC<KeyValueCellProps> = ({
  column,
  row,
  rowIndex,
  value,
  style,
  disabled,
  onDelete,
}) => {
  const { canDelete = true } = row;
  const deleteRow = useCallback(() => onDelete(rowIndex), [onDelete, rowIndex]);

  const title = row.key ? `Delete ${row.key}` : "Delete";
  return (
    <BorderedCell style={style} column={column} row={row} rowIndex={rowIndex} value={value} disabled={disabled}>
      <KeyValueControls>
        {canDelete && (
          <KeyValueControlIcon icon={Icons.Minus} title={title} tabIndex={0} disabled={disabled} onClick={deleteRow} />
        )}
      </KeyValueControls>
    </BorderedCell>
  );
};

const KeyValueControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${KeyValueRowHeight}px;
  box-sizing: border-box;
  padding: 5px;
`;

const KeyCellInput: React.FC<KeyValueCellProps> = ({ column, row, rowIndex, value, style, disabled, onChange }) => {
  const change = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(rowIndex, "key", e.target.value);
    },
    [onChange, rowIndex]
  );

  const name = row.key ? `${row.key}_key` : "";
  return (
    <BorderedCell style={style} row={row} column={column} rowIndex={rowIndex} value={value} disabled={disabled}>
      <CellInput aria-label={name} name={name} autoFocus disabled={disabled} value={value} onChange={change} />
    </BorderedCell>
  );
};

const KeyValueTypeCell: React.FC<KeyValueCellProps> = ({ column, row, rowIndex, value, style, disabled, onChange }) => {
  const change = useCallback(
    (value: string) => {
      onChange(rowIndex, "type", value);
    },
    [onChange, rowIndex]
  );

  const name = row.key ? `${row.key}_type` : "";
  return (
    <BorderedCell style={style} row={row} column={column} rowIndex={rowIndex} value={value} disabled={disabled}>
      <CellSelect aria-label={name} name={name} disabled={disabled} value={value} onChange={change}>
        <Option value={"string"}>string</Option>
        <Option value={"number"}>number</Option>
        <Option value={"boolean"}>boolean</Option>
      </CellSelect>
    </BorderedCell>
  );
};

const KeyValueCellInput: React.FC<KeyValueCellProps> = ({
  column,
  row,
  rowIndex,
  value,
  style,
  disabled,
  onChange,
}) => {
  const change = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(rowIndex, "value", e.target.value);
    },
    [rowIndex, onChange]
  );

  const toggle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(rowIndex, "value", e.target.checked);
    },
    [rowIndex, onChange]
  );

  const handleClick = useCallback(
    () => {
      if (row.type === "boolean") {
        onChange(rowIndex, "value", !row.value);
      }
    },
    [rowIndex, row, onChange]
  );

  const name = row.key ? `${row.key}_value` : "";

  let input = null;
  if (row.type === "string") {
    input = (
      <CellInput
        data-test-id={"key-value-input__value-column"}
        aria-label={name}
        name={name}
        value={value}
        disabled={disabled}
        onChange={change}
      />
    );
  } else if (row.type === "number") {
    input = (
      <CellInput
        data-test-id={"key-value-input__value-column"}
        aria-label={name}
        name={name}
        type={"number"}
        disabled={disabled}
        value={value}
        onChange={change}
      />
    );
  } else if (row.type === "boolean") {
    input = (
      <CellCheckbox
        data-test-id={"key-value-input__value-column"}
        aria-label={name}
        name={name}
        checked={value}
        disabled={disabled}
        onChange={toggle}
      />
    );
  }

  return (
    <BorderedCell
      style={style}
      row={row}
      column={column}
      rowIndex={rowIndex}
      value={value}
      disabled={disabled}
      onClick={handleClick}
    >
      {input}
    </BorderedCell>
  );
};

const KeyValueToolbar = styled.div`
  margin: 0;
`;

const KeyValueControlIcon = styled(Icon).attrs({ clickable: true })`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.foreground};
`;

const BorderedCell = styled(TableCell)<{ disabled: boolean }>`
  background: ${({ disabled, theme }) => (disabled ? theme.colors.disabledBackground : theme.colors.inputBackground)};
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-sizing: border-box;
`;

const CellInput = styled(Input)`
  border: none;
  border-right: 1px solid transparent;
  height: ${KeyValueRowHeight}px;
  z-index: 1;
`;

const CellSelect = styled(Select)`
  border: none !important;
  border-right: 1px solid transparent;
  height: ${KeyValueRowHeight}px;
`;

const CellCheckbox = styled(Checkbox)`
  margin-left: 1em;
`;

export default React.memo(KeyValueInput);
