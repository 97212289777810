import { observer } from "mobx-react";
import React, { useState } from "react";
import Content from "../components/Content";
import { ContentBrowserView } from "../components/ContentBrowserViewToggle";
import Header from "../components/ContentHeader";
import ErrorMessage from "../components/ErrorMessage";
import MenuPlaceholder from "../components/MenuPlaceholder";
import Search from "../components/Search";
import ServerNotFound from "../components/ServerNotFound";
import UserListContent from "../components/UserListContent";
import UserListMenu from "../components/UserListMenu";
import UserPanel from "../components/UserPanel";
import useContextMenu from "../hooks/useContextMenu";
import useError from "../hooks/useError";
import usePreference from "../hooks/usePreference";
import useQuery from "../hooks/useQuery";
import useServerParam from "../hooks/useServerParam";
import useStorageList from "../hooks/useStorageList";
import { Commands } from "../state/commands/Provider";
import { IGetUsersCommand } from "../state/commands/types/GetUsersCommand";
import { Preferences } from "../state/Preferences";
import User from "../state/User";

export interface UserListPageProps {}

const UserListPage: React.FC<UserListPageProps> = () => {
  const serverName = useServerParam();
  const storageList = useStorageList();
  const storage = storageList.find(serverName);

  const [menu, onContextMenu] = useContextMenu(storage ? UserListMenu : MenuPlaceholder, {
    props: {
      users: storage?.users,
    },
  });
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [error, setError, clearError] = useError();

  const [rightPanelOpened] = usePreference<boolean>(Preferences.RightPanelOpened, true);
  const [view] = usePreference<ContentBrowserView>(Preferences.ContentView, ContentBrowserView.Grid);

  async function fetchUsers() {
    const command = storage?.commands.get<IGetUsersCommand>(Commands.GetUsers);
    if (command) {
      return command.execute({ users: storage!.users });
    }
  }

  useQuery(fetchUsers, {
    keys: [storage],
    onError: setError,
    onSubmit: clearError,
  });

  return (
    <>
      <Header>
        <Search disabled />
      </Header>

      <Content column={selectedUser && rightPanelOpened ? "" : "2 / 4"} onContextMenu={onContextMenu}>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {storage ? (
          <UserListContent view={view} users={storage.users} selectedUser={selectedUser} onSelect={setSelectedUser} />
        ) : (
          <ServerNotFound serverName={serverName} />
        )}
      </Content>

      {selectedUser && <UserPanel user={selectedUser} />}
      {menu}
    </>
  );
};

export default observer(UserListPage);
