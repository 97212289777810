import Path from "../../Path";
import { Commands } from "../Provider";
import { IUnsubscribeCommandArguments } from "../types/UnsubscribeCommand";
import { NucleusCommand } from "./index";
import { pathSubscriptions, thumbnailSubscriptions } from "./NucleusSubscribeCommand";

export default class NucleusUnsubscribeCommand extends NucleusCommand<IUnsubscribeCommandArguments> {
  public name: string = Commands.Unsubscribe;

  public async allowed(): Promise<boolean> {
    return true;
  }

  public async execute({ path }: IUnsubscribeCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Unsubscribe from ${path.path}`);

    this.unsubscribeFromPathEvents(path);
    this.unsubscribeFromThumbnailEvents(path);
  }

  protected generateSubscriptionKey(path: Path) {
    return `${path.storage.name}@${path.path}`;
  }

  protected unsubscribeFromPathEvents(path: Path): void {
    const key = this.generateSubscriptionKey(path);
    const subscription = pathSubscriptions.get(key);
    if (subscription) {
      subscription.close();
      pathSubscriptions.delete(key);
    }
  }

  protected unsubscribeFromThumbnailEvents(path: Path): void {
    const key = this.generateSubscriptionKey(path);
    const subscription = thumbnailSubscriptions.get(key);
    if (subscription) {
      subscription.close();
      thumbnailSubscriptions.delete(key);
    }
  }
}
