import React from "react";
import Path, { PathType } from "../state/Path";
import FileMenu from "./FileMenu";
import MountMenu from "./MountMenu";
import PathSelectionMenu from "./PathSelectionMenu";
import ServerMenu from "./ServerMenu";
import FolderMenu from "./FolderMenu";

export interface PathMenuProps {
  path: Path;
}


const PathMenu: React.FC<PathMenuProps> = ({ path }) => {
  if (path === path.storage.root) {
    return <ServerMenu storage={path.storage} />;
  }

  if (path.storage.selectedPaths.items.length > 1 && path.storage.selectedPaths.has(path)) {
    return <PathSelectionMenu storage={path.storage} />;
  }

  if (path.type === PathType.File) {
    return <FileMenu path={path} />;
  }

  if (path.type === PathType.Folder) {
    return <FolderMenu path={path} />;
  }

  if (path.type === PathType.Mount) {
    return <MountMenu path={path} />;
  }

  return null;
};

export default PathMenu;
