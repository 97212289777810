import React, { useCallback, useRef } from "react";


export default function useSelectionReset(setSelection?: (selection: null) => void) {
  const callback = useRef(setSelection);
  callback.current = setSelection;

  return useCallback((e: React.MouseEvent) => {
    if (e.button === 0 && e.target === e.currentTarget && callback.current) {
      e.stopPropagation();
      callback.current(null);
    }
  }, []);
}