import { AuthStatus } from "@omniverse/auth/data";
import { OfflineModeError } from "../../../util/SessionErrors";
import { GetUsersError } from "../../../util/UserErrors";
import User from "../../User";
import { Commands } from "../Provider";
import { IGetUsersCommandArguments } from "../types/GetUsersCommand";
import { NucleusCommand } from "./index";
import { parseNucleusProfile } from "./Nucleus";

export default class NucleusGetUsersCommand extends NucleusCommand<IGetUsersCommandArguments, void, User[]> {
  name = Commands.GetUsers;

  public async allowed(): Promise<boolean> {
    return true;
  }

  public async execute({ users }: IGetUsersCommandArguments): Promise<User[]> {
    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }

    console.log(`[${this.provider.name}] Get users`);
    users.setLoading(true);

    let profiles;
    try {
      profiles = await this.provider.createProfilesClient();

      const stream = await profiles.getAll({ token: this.provider.session.accessToken! });
      const results = await stream.readAll();
      const response = results[0];
      if (response.status === AuthStatus.OK) {
        const values = results
          .map((result) => {
            const profile = parseNucleusProfile(result.profile);
            return new User(result.username!, users.storage, profile);
          })
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name));

        users.set(values);
        return values;
      } else if (response.status === AuthStatus.Expired) {
        await this.provider.session.refresh();
        return this.execute({ users });
      } else {
        throw new GetUsersError(response);
      }
    } finally {
      users.setLoading(false);
      if (profiles) {
        await profiles.transport.close();
      }
    }
  }
}
