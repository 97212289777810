import { SSOSettings } from "@omniverse/auth/data";
import React from "react";
import styled from "styled-components";
import SSOButton from "./SSOButton";

export interface SSOButtonsProps {
  ssoSettings: SSOSettings[];
  onClick(sso: SSOSettings): void;
}

const SSOButtonGroup: React.FC<SSOButtonsProps> = ({ ssoSettings, onClick }) => {
  return ssoSettings && ssoSettings.length > 0 ? (
    <SSOSettingList>
      {ssoSettings.map((setting) => (
        <SSOButton key={setting.type} setting={setting} onClick={onClick} />
      ))}
    </SSOSettingList>
  ) : null;
};

const SSOSettingList = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin: 5px 15px;
`;

export default SSOButtonGroup;
