import styled from "styled-components";
import Spinner from "./Spinner";


const TreeSpinner = styled(Spinner)`
  width: 25px;
  margin: auto 5px auto 0;
  text-align: center;
`;

export default TreeSpinner;