import InfoPopup from "./InfoPopup";
import PopupCloseIcon from "./PopupCloseIcon";
import React from "react";
import styled from "styled-components";
import { ReactComponent as UserSVG } from "../static/user.svg";

export interface UserNotificationProps {
  duration?: number;
  onClose(): void;
}

const UserNotification: React.FC<UserNotificationProps> = ({ duration = 10000, children, onClose }) => {
  return (
    <StyledUserNotification duration={duration} onClose={onClose}>
      <UserIcon/>
      {children}
      <PopupCloseIcon onClick={onClose}/>
    </StyledUserNotification>
  );
};

const StyledUserNotification = styled(InfoPopup)`
  vertical-align: middle;
  padding-right: 40px;
`;

const UserIcon = styled(UserSVG)`
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  margin-right: 5px;
  fill: ${({ theme }) => theme.colors.foreground};
`;

export default UserNotification;
