import React from "react";
import styled from "styled-components";
import { ReactComponent as GroupSVG } from "../static/group.svg";
import { Icons } from "./Icon";
import List, { ListAutoComplete, ListInputProps, ListItem, ListProps } from "./List";
import { DefaultTheme } from "./Theme";


const GroupList: React.FC<ListProps> = (props) => {
  return (
    <List
      itemComponent={Group}
      inputComponent={GroupInput}
      {...props}
    />
  );
}

const GroupImage = styled(GroupSVG)`
  height: 20px;
  margin-right: 10px;
  fill: ${({ theme }) => theme.colors.inputText};
`;
GroupImage.defaultProps = {
  theme: DefaultTheme
};

const GroupsInputImage = styled(GroupImage)`
  position: absolute;
  left: 10px;
  top: 5px;
  filter: opacity(0.5);
  z-index: 1;
  pointer-events: none;
`;

const Group = styled(ListItem).attrs({
  image: GroupImage,
})``;

const GroupInput: React.FC<ListInputProps> = ({ allowAny, ...props }) => {
  return (
    <StyledListAutoComplete
      icon={Icons.Add}
      image={GroupsInputImage}
      placeholder={"Add Group"}
      allowAny={allowAny}
      {...props}
    />
  );
};

const StyledListAutoComplete = styled(ListAutoComplete)`
  padding: 5px 5px 5px 40px;
`;

export default GroupList;
