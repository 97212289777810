import React from "react";
import useDialogForm from "../hooks/useDialogForm";
import { Checkpoint } from "../state/Checkpoints";
import { Commands } from "../state/commands/Provider";
import { IDeleteCheckpointCommand } from "../state/commands/types/DeleteCheckpointCommand";
import Path from "../state/Path";
import ConfirmationDialog from "./ConfirmationDialog";
import ImportantText from "./ImportantText";

export interface CheckpointDeleteDialogProps {
  path: Path;
  checkpoint: Checkpoint;
}

const CheckpointDeleteDialog: React.FC<CheckpointDeleteDialogProps> = ({ path, checkpoint }) => {
  const form = useDialogForm({
    fields: {},
    onSubmit: confirm,
  });

  async function confirm() {
    const command = path.storage.commands.get<IDeleteCheckpointCommand>(Commands.DeleteCheckpoint);
    if (command) {
      return command.execute({ path, checkpoint });
    }
  }

  return (
    <ConfirmationDialog
      title={"Delete Checkpoint"}
      loading={form.loading}
      onConfirm={form.submit}
      onCancel={form.cancel}
    >
      Do you really want to delete checkpoint #{checkpoint.id} --{" "}
      <ImportantText>{checkpoint.message || checkpoint.id}</ImportantText> <br />
      for <ImportantText>{path.path}</ImportantText>?
    </ConfirmationDialog>
  );
};

export default CheckpointDeleteDialog;
