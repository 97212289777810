import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import usePreference from "../hooks/usePreference";
import { Preferences } from "../state/Preferences";
import ContentBrowserTree from "./ContentBrowserTree";
import Layout from "./Layout";
import UserManagementPanel from "./UserManagementPanel";


export interface ContentBrowserLeftPanelProps {
  userManagement?: boolean;
  onTabClose(): void;
}

const ContentBrowserLeftPanel: React.FC<ContentBrowserLeftPanelProps> = ({ userManagement, onTabClose }) => {
  const [width, setWidth] = usePreference<number>(Preferences.LeftPanelWidth, 250);

  function cacheSize({ width }: { width: number, height: number }) {
    setWidth(width);
  }

  return (
    <StyledContentBrowserLeftPanel defaultWidth={width} onResizeStop={cacheSize}>
      <ContentBrowserTree/>
      {userManagement && <UserManagementPanel onClose={onTabClose}/>}
    </StyledContentBrowserLeftPanel>
  )
}

const StyledContentBrowserLeftPanel = styled(Layout.LeftPanel).attrs({
  minWidth:  250,
  resizable: true
})`
  display: flex;
  flex-direction: column;
`;

export default observer(ContentBrowserLeftPanel);