import React from "react";
import styled from "styled-components";
import { ReactComponent as UserSVG } from "../static/user.svg";
import { Icons } from "./Icon";
import List, { ListAutoComplete, ListInputProps, ListItem, ListProps } from "./List";
import { DefaultTheme } from "./Theme";

const GroupUsers: React.FC<ListProps> = (props) => {
  return <List itemComponent={GroupUser} inputComponent={GroupUsersInput} {...props} />;
};

const GroupUserImage = styled(UserSVG)`
  height: 20px;
  margin-right: 10px;
  fill: ${({ theme }) => theme.colors.inputText};
`;
GroupUserImage.defaultProps = {
  theme: DefaultTheme,
};

const GroupUsersInputImage = styled(GroupUserImage)`
  position: absolute;
  left: 10px;
  top: 5px;
  filter: opacity(0.5);
  z-index: 1;
  pointer-events: none;
`;

const GroupUser = styled(ListItem).attrs({
  image: GroupUserImage,
})``;

const GroupUsersInput: React.FC<ListInputProps> = (props) => {
  return (
    <StyledListAutoComplete
      icon={Icons.Add}
      image={GroupUsersInputImage}
      placeholder={"Add User"}
      allowAny={false}
      {...props}
    />
  );
};

const StyledListAutoComplete = styled(ListAutoComplete)`
  padding: 5px 5px 5px 40px;
`;

export default GroupUsers;
