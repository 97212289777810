import { action, makeObservable, observable } from "mobx";

class Selection<T> {
  public items: T[];
  public default: T | undefined;

  public constructor() {
    this.default = undefined;
    this.items = [];

    makeObservable(this, {
      items: observable,
      default: observable,
      select: action.bound,
      add: action.bound,
      set: action.bound,
      toggle: action.bound,
      remove: action.bound,
      reset: action.bound,
      setDefault: action.bound,
    });
  }

  public select(item: T): void {
    this.items = [item];
  }

  public set(items: T[]): void {
    this.items = items;
  }

  public add(item: T): void {
    if (!this.has(item)) {
      this.items.push(item);
    }
  }

  public remove(item: T): void {
    this.items = this.items.filter((i) => i !== item);
  }

  public toggle(item: T): void {
    if (this.has(item)) {
      this.remove(item);
    } else {
      this.add(item);
    }
  }

  public reset(): void {
    if (this.default) {
      this.items = [this.default];
    } else {
      this.items = [];
    }
  }

  public has(item: T): boolean {
    return this.items.includes(item);
  }

  public setDefault(item: T | undefined): void {
    this.default = item;
  }
}

export default Selection;
