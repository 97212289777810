import { Commands } from "../Provider";
import { IActivatePasswordCommandArguments } from "../types/ActivatePasswordCommand";
import { IResetPasswordCommand } from "../types/ResetPasswordCommand";
import { NucleusCommand } from "./index";

export default class NucleusActivatePasswordCommand extends NucleusCommand<IActivatePasswordCommandArguments> {
  name = Commands.ActivateUser;

  public async allowed(): Promise<boolean> {
    return true;
  }

  public async execute({ user, password, payload }: IActivatePasswordCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Activate password for user "${user.name}"`);

    const command = this.provider.commands.get<IResetPasswordCommand>(Commands.ResetPassword);
    if (command) {
      await command.execute({ user, newPassword: password, payload });
    } else {
      throw new Error("ResetPasswordCommand is required to activate the user.");
    }
  }
}
