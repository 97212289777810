import { observer } from "mobx-react";
import React from "react";
import useContextMenu from "../hooks/useContextMenu";
import useCwd from "../hooks/useCwd";
import useDialog from "../hooks/useDialog";
import useHotkeys from "../hooks/useHotkeys";
import usePermission from "../hooks/usePermission";
import useStorageList from "../hooks/useStorageList";
import { Commands } from "../state/commands/Provider";
import { ICreateFolderAllowedArguments, ICreateFolderCommand } from "../state/commands/types/CreateFolderCommand";
import Path from "../state/Path";
import search from "../state/PathSearch";
import Content, { ContentProps } from "./Content";
import ContentBrowserMenu from "./ContentBrowserMenu";
import { ContentBrowserView } from "./ContentBrowserViewToggle";
import CWDMenu from "./CWDMenu";
import PathContentGroup from "./PathContentGroup";
import PathDeleteDialog from "./PathDeleteDialog";
import PathDeleteListDialog from "./PathDeleteListDialog";
import PathGrid from "./PathGrid";
import PathTable, { PathTableColumn } from "./PathTable";
import ServerGrid from "./ServerGrid";

export interface PathContentProps extends ContentProps {
  view: ContentBrowserView;
}

const PathContent: React.FC<PathContentProps> = ({ view, column, children }) => {
  const cwd = useCwd();
  if (cwd) {
    return (
      <CwdContent cwd={cwd} view={view} column={column}>
        {children}
      </CwdContent>
    );
  } else {
    return <PathContentPlaceholder column={column}>{children}</PathContentPlaceholder>;
  }
};

let CwdContent: React.FC<{ view: ContentBrowserView; cwd: Path } & ContentProps> = ({
  view,
  cwd,
  column,
  children,
}) => {
  const pathDeleteDialog = useDialog(PathDeleteDialog);
  const pathDeleteListDialog = useDialog(PathDeleteListDialog);

  function deleteSelectedPaths() {
    const selectedPaths = cwd.storage.selectedPaths.items;
    if (selectedPaths.length === 1) {
      pathDeleteDialog.show({ path: selectedPaths[0] });
    } else if (selectedPaths.length > 1) {
      pathDeleteListDialog.show({ paths: selectedPaths });
    }
  }

  const handleHotkey = useHotkeys({
    Delete: deleteSelectedPaths,
  });

  const [menu, onContextMenu] = useContextMenu(CWDMenu, { props: { path: cwd } });
  const paths = search.active ? search.results : cwd.children;
  const canCreateNewFolders = usePermission(
    () =>
      cwd.storage.commands.allowed<ICreateFolderCommand, ICreateFolderAllowedArguments>(Commands.CreateFolder, {
        path: cwd,
      }),
    [cwd.path]
  );

  const content =
    view === ContentBrowserView.Grid ? (
      <PathGrid
        paths={paths}
        cwd={cwd}
        canCreateFolder={canCreateNewFolders === "allowed" && !search.active}
        gapBorder={!search.active}
      />
    ) : (
      <PathTable
        key={Number(search.active)}
        cwd={cwd}
        defaultSorting={search.active ? PathTableColumn.None : PathTableColumn.Name}
        paths={paths}
        showEmptyColumn={search.active}
      />
    );

  const container = search.active ? (
    <PathContentGroup label={`Search results (${search.results.length})`} initial={true}>
      {content}
    </PathContentGroup>
  ) : (
    <>{content}</>
  );

  return (
    <Content tabIndex={-1} column={column} onKeyDown={handleHotkey} onContextMenu={onContextMenu}>
      {children}
      {container}
      {menu}
    </Content>
  );
};
CwdContent = observer(CwdContent);

const PathContentPlaceholder: React.FC<ContentProps> = ({ column, children }) => {
  const servers = useStorageList();
  const [menu, onContextMenu] = useContextMenu(ContentBrowserMenu);

  return (
    <Content column={column} onContextMenu={onContextMenu}>
      {children}
      <ServerGrid servers={servers.items} selectedServer={servers.selected} onSelect={servers.select} />
      {menu}
    </Content>
  );
};

export default observer(PathContent);
