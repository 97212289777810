import { action, computed, makeObservable, observable } from "mobx";
import { isElectron } from "../util/Electron";
import Download from "./Download";

class DownloadList {
  public items: Map<string, Download>;

  public constructor() {
    this.items = new Map();

    makeObservable(this, {
      items: observable,
      add: action.bound,
      abort: action.bound,
      remove: action.bound,
      setFailed: action.bound,
      setCompleted: action.bound,
      entries: computed,
    });

    if (isElectron()) {
      window.download?.subscribe?.({
        onProgress: (id, progress) => {
          const download = this.items.get(id);
          if (download) {
            download.setProgress(progress);
          }
        },
        onComplete: (id: string) => {
          const download = this.items.get(id);
          if (download) {
            download.setCompleted();
          }
        },
        onFail: (id: string) => {
          this.setFailed(id);
        },
        onCreate: (id: string, filename: string) => {
          this.add(
            id,
            new Download(filename, () => {
              window.download.abort(id);
            })
          );
        },
      });
    }
  }

  get entries() {
    return Array.from(this.items.entries());
  }

  public add(id: string, download: Download): Download {
    if (this.items.has(id)) {
      throw new Error("Download with this id already exists.");
    }
    this.items.set(id, download);
    return download;
  }

  public abort(id: string) {
    this.items.get(id)?.abort();
  }
  public setCompleted(id: string) {
    this.items.get(id)?.setCompleted();
  }

  public setFailed(id: string) {
    this.items.get(id)?.setFailed();
  }

  public remove(id: string) {
    this.items.get(id)?.abort();
    this.items.delete(id);
  }
}

export default DownloadList;
