import React from "react";
import useDialog from "../hooks/useDialog";
import usePermission from "../hooks/usePermission";
import { Commands } from "../state/commands/Provider";
import Path from "../state/Path";
import { Icons } from "./Icon";
import Loader from "./Loader";
import Menu from "./Menu";
import { MenuIconItem } from "./MenuItem";
import MountDeleteConfirmation from "./MountDeleteConfirmation";

export interface MountMenuProps {
  path: Path;
}

const MountMenu: React.FC<MountMenuProps> = ({ path }) => {
  const unmountDialog = useDialog(MountDeleteConfirmation, { path });

  function unmount() {
    unmountDialog.show();
  }

  const allowedToUnmount = usePermission(() => path.storage.commands.allowed(Commands.Unmount), [path]);
  const loading = allowedToUnmount === "loading";
  if (loading) {
    return (
      <Menu>
        <Loader />
      </Menu>
    )
  }

  const visible = allowedToUnmount === "allowed";
  if (!visible) {
    return null;
  }

  return (
    <Menu>
      <MenuIconItem icon={Icons.Remove} visible={allowedToUnmount === "allowed"} onClick={unmount}>
        Unmount
      </MenuIconItem>
    </Menu>
  );
};

export default MountMenu;
