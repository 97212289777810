import { AuthenticationResult } from "@omniverse/auth/react/AuthForm";
import SSOAuth from "@omniverse/auth/react/SSO";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import AppNavigate from "../components/AppNavigate";
import ErrorPage from "../components/ErrorPage";
import Loader from "../components/Loader";
import useApp from "../hooks/useApp";
import useAppLocation from "../hooks/useAppLocation";
import Nucleus from "../state/commands/nucleus/Nucleus";
import NucleusSession from "../state/commands/nucleus/NucleusSession";
import { parsePublicName } from "../state/commands/Provider";
import Storage from "../state/Storage";
import { joinURL } from "../util/Path";

const SSOPage = () => {
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState<string>("");
  const [error, setError] = useState<string | Error | null>(null);
  const { params } = useParams();
  const location = useAppLocation();

  const app = useApp();

  const signIn = useCallback(
    async ({ username, server, accessToken, refreshToken, profile, extras }: AuthenticationResult) => {
      setLoading(false);
      server = parsePublicName(server!);

      const authentication = new NucleusSession(
        server!,
        username!,
        accessToken!,
        refreshToken!,
        profile?.admin,
        profile?.nucleus_ro
      );
      const provider = new Nucleus(server!, authentication);
      const storage = new Storage(provider);
      await storage.init();

      app.storages.add(storage);

      const path = extras?.path ?? "/";
      setRedirect(joinURL("/", storage.root.link, path));
    },
    [app.storages]
  );

  const showError = useCallback((error: string | Error) => {
    setLoading(false);
    setError(error);
  }, []);

  if (redirect) {
    return <AppNavigate to={redirect} />;
  }

  return (
    <SSOAuth params={params ?? ""} search={location.search} onAuth={signIn} onError={showError}>
      {loading && <Loader />}
      {error && <ErrorPage>{error instanceof Error ? "An error has occurred. Try again later." : error}</ErrorPage>}
    </SSOAuth>
  );
};

export default SSOPage;
