import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import ContentBrowserLeftPanel from "../components/ContentBrowserLeftPanel";
import ContentBrowserLeftToolbar, { ContentBrowserLeftToolbarTab } from "../components/ContentBrowserLeftToolbar";
import ContentBrowserNotifications from "../components/ContentBrowserNotifications";
import ContentBrowserRightToolbar from "../components/ContentBrowserRightToolbar";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import Notifications from "../components/Notifications";
import useAppLocation from "../hooks/useAppLocation";
import useDND from "../hooks/useDND";
import usePermission from "../hooks/usePermission";
import usePreference from "../hooks/usePreference";
import useStorageList from "../hooks/useStorageList";
import { Commands } from "../state/commands/Provider";
import { ICreateGroupCommand } from "../state/commands/types/CreateGroupCommand";
import { Preferences } from "../state/Preferences";
import ContentBrowser from "./ContentBrowser";
import GroupListPage from "./GroupListPage";
import GroupPage from "./GroupPage";
import HomePage from "./HomePage";
import MicrosoftOutlookURLFixPage from "./MicrosoftOutlookURLFixPage";
import UserListPage from "./UserListPage";

const ContentLayout: React.FC = () => {
  const location = useAppLocation();
  const storageList = useStorageList();

  const [toolbarTab, setToolbarTab] = usePreference<ContentBrowserLeftToolbarTab | null>(
    Preferences.ContentLayoutTab,
    () => {
      if (location.pathname.startsWith("/groups/") || location.pathname.startsWith("/users/")) {
        return ContentBrowserLeftToolbarTab.UserManagement;
      }
      return null;
    }
  );

  const selectToolbarTab = useCallback(
    (tab: ContentBrowserLeftToolbarTab | null) => {
      setToolbarTab((currentTab) => {
        return tab === currentTab ? null : tab;
      });
    },
    [setToolbarTab]
  );

  const closeLeftPanelTab = useCallback(() => {
    setToolbarTab(null);
  }, [setToolbarTab]);

  const canManageUsers = usePermission(
    async () => {
      if (!storageList.selected) {
        return false;
      }
      return storageList.selected.commands.allowed<ICreateGroupCommand>(Commands.CreateGroup);
    },
    [storageList.selected]
  );

  const dnd = useDND();
  return (
    <StyledContentLayout onMouseUp={dnd.onMouseUp}>
      <Notifications>
        <Routes>
          <Route path={"/omniverse:/:server/*"} element={<MicrosoftOutlookURLFixPage />} />
          <Route path={"/omniverse://:server/*"} element={<ContentBrowser />} />
          <Route path={"/omniverse://"} element={<ContentBrowser />} />
          <Route path={"/groups/:server/:group"} element={<GroupPage />} />
          <Route path={"/groups/:server"} element={<GroupListPage />} />
          <Route path={"/users/:server"} element={<UserListPage />} />
          <Route path={"/"} element={<HomePage />} />
        </Routes>
      </Notifications>

      <ContentBrowserLeftPanel
        userManagement={toolbarTab === ContentBrowserLeftToolbarTab.UserManagement}
        onTabClose={closeLeftPanelTab}
      />
      <ContentBrowserLeftToolbar
        userManagement={canManageUsers === "allowed"}
        tab={toolbarTab}
        onSelect={selectToolbarTab}
      />

      <Footer />
      <ContentBrowserNotifications />
      <ContentBrowserRightToolbar />

      {dnd.ghost}
    </StyledContentLayout>
  );
};

const StyledContentLayout = styled(Layout).attrs({
  fullScreen: true,
})`
  background: ${({ theme }) => theme.colors.background};
`;

export default observer(ContentLayout);
