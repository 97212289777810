import { observer } from "mobx-react";
import React, { useMemo } from "react";
import Path from "../state/Path";
import Storage from "../state/Storage";
import PathTreeItem from "./PathTreeItem";
import Tree, { CustomTreeProps } from "./Tree";

export interface PathTreeProps extends Omit<CustomTreeProps<PathTreeItemType>, "selectedItem"> {
  storages: Storage[];
  selectedPath?: Path | null;
  toggled?: boolean;
  canAddStorage?: boolean;
}

export type PathTreeRootType = { type: "root", toggled: boolean };
export type PathTreePath = { type: "path"; path: Path };
export type PathTreeNewServer = { type: "new-server" };
export type PathTreeItemType = PathTreeRootType | PathTreePath | PathTreeNewServer;

const PathTree: React.FC<PathTreeProps> = ({
  className,
  storages,
  canAddStorage,
  selectedPath,
  toggled = true,
  highlightedItem,
  width,
  height,
  itemComponent: Item = PathTreeItem,
  onSelect,
  onToggle,
  onContextMenu,
  onItemContextMenu,
}) => {
  const root = useMemo((): PathTreeRootType => {
    return { type: "root", toggled };
  }, [toggled]);

  const folders = storages.map((server) => server.treeFolders).flat();
  const items = useMemo((): PathTreeItemType[] => {
    const items: PathTreeItemType[] = [root, ...folders.map((folder): PathTreePath => ({ type: "path", path: folder }))];
    if (canAddStorage) {
      items.push({ type: "new-server" });
    }
    return items;
  }, [root, folders, canAddStorage]);

  const selectedItem = useMemo(
    () => (selectedPath ? items.find((item) => item.type === "path" && item.path === selectedPath) : root),
    [root, items, selectedPath]
  );

  return (
    <Tree
      className={className}
      items={items}
      itemComponent={Item}
      selectedItem={selectedItem}
      highlightedItem={highlightedItem}
      width={width}
      height={height}
      onSelect={onSelect}
      onToggle={onToggle}
      onContextMenu={onContextMenu}
      onItemContextMenu={onItemContextMenu}
    />
  );
};

export default observer(PathTree);
