import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import PathUploadNotification from "./PathUploadNotification";
import ScrollBar from "./ScrollBar";
import DownloadNotification from "./DownloadNotification";
import { DefaultTheme } from "./Theme";

const ContentBrowserNotifications = () => {
  return (
    <StyledContentBrowserNotifications>
      <PathUploadNotification />
      <DownloadNotification />
    </StyledContentBrowserNotifications>
  );
};

const StyledContentBrowserNotifications = styled.div`
  position: fixed;
  bottom: 35px;
  right: 5px;
  display: flex;
  flex-direction: column-reverse;
  padding: 10px;
  max-height: 500px;
  overflow-x: visible;
  overflow-y: auto;

  z-index: 2;

  & > * {
    margin-top: 10px;
  }

  ${ScrollBar};
`;
StyledContentBrowserNotifications.defaultProps = {
  theme: DefaultTheme
};

export default observer(ContentBrowserNotifications);
