import { useCallback, useState } from "react";
import useMounted from "./useMounted";

export interface UseSubmitOptions<T> {
  onSuccess?(data: T): void;
  onError?(error: string): void;
  onSubmit?(): void;
}

export default function useSubmit<
  F extends (...args: any[]) => Promise<T>,
  A extends Parameters<F>,
  T = F extends (...args: any[]) => Promise<infer U> ? U : unknown
>(submitter: F, { onSubmit, onSuccess, onError = console.error }: UseSubmitOptions<T> = {}) {
  const mounted = useMounted();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const submit = useCallback(async (...args: A) => {
    setLoading(true);
    setError("");

    if (onSubmit) {
      onSubmit();
    }

    try {
      const result = await submitter(...args);
      if (onSuccess) {
        onSuccess(result);
      }
      return result;
    } catch (error : any) {
      if (mounted.current) {
        setError(error.message);
      }
      if (onError) {
        onError(error.message);
      }
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  }, [submitter, mounted, onSubmit, onSuccess, onError]);

  return {
    loading,
    error,
    setError,
    submit
  };
}