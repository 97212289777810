import React, { useCallback, useEffect, useRef } from "react";


export type ClickHandler = (e: React.MouseEvent) => void;
export interface DoubleClickOptions {
  delay?: number;
}

export default function useDoubleClick(
  onClick: ClickHandler,
  onDoubleClick: ClickHandler,
  { delay }: DoubleClickOptions = { delay: 250 }
): ClickHandler {
  const clicked = useRef(false);
  const timeout = useRef<number | null>(null);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (clicked.current) {
        clicked.current = false;
        timeout.current = null;

        onDoubleClick(e);
      } else {
        if (e) {
          e.preventDefault();
          e.stopPropagation();
        }
        onClick(e);

        clicked.current = true;
        timeout.current = window.setTimeout(() => {
          clicked.current = false;
        }, delay);
      }
    },
    [delay, clicked, timeout, onClick, onDoubleClick]
  );

  useEffect(() => {
    const lastTimeout = timeout.current;
    return () => {
      if (lastTimeout) {
        window.clearTimeout(lastTimeout);
      }
    };
  }, []);

  return handleClick;
}