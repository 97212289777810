import React from "react";
import User from "../state/User";
import ImportantText from "./ImportantText";
import UserNotification from "./UserNotification";

export interface ProfileSubmitNotificationProps {
  user: User;
  onClose(): void;
}

const ProfileSubmitNotification: React.FC<ProfileSubmitNotificationProps> = ({ user, onClose }) => {
  return (
    <UserNotification duration={5000} onClose={onClose}>
      Profile for <ImportantText>{user.name}</ImportantText> has been saved.
    </UserNotification>
  )
}

export default ProfileSubmitNotification;