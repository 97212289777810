import React from "react";
import useDialog from "../hooks/useDialog";
import useStorageList from "../hooks/useStorageList";
import { Icons } from "./Icon";
import Menu from "./Menu";
import { MenuIconItem } from "./MenuItem";
import NucleusAuthDialog from "./NucleusAuthDialog";

const ContentBrowserMenu: React.FC = () => {
  const storages = useStorageList();
  const authDialog = useDialog(NucleusAuthDialog);
  return (
    <Menu>
      <MenuIconItem icon={Icons.Cloud} visible={!storages.readOnly} onClick={() => authDialog.show()}>
        Add Server
      </MenuIconItem>
    </Menu>
  );
};

export default ContentBrowserMenu;
