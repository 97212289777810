import styled from "styled-components";

const SSOSplitter = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: 10pt;
  flex: 0 0 100%;
  margin: 1rem 0 0.75rem 0;
  position: relative;

  &:before {
    position: absolute;
    width: 50px;
    height: 50%;
    left: 15px;
    top: 0;
    bottom: 0;
    border-bottom: 1px solid #969696;
    content: " ";
  }

  &:after {
    position: absolute;
    width: 50px;
    height: 50%;
    right: 15px;
    top: 0;
    bottom: 0;
    border-bottom: 1px solid #969696;
    content: " ";
  }
`;

export default SSOSplitter;
