import React from "react";
import styled from "styled-components";
import Icon, { Icons } from "./Icon";
import { DefaultTheme } from "./Theme";
import Toggleable, { Toggle, ToggleableHeader, ToggleableLabel } from "./Toggleable";

export interface RightPanelToggleableProps {
  label: string;
  icon?: string;
  initial?: boolean;
  opened?: boolean;
  visible?: boolean;
  onToggle?(opened: boolean): void;
}

const RightPanelToggleable: React.FC<RightPanelToggleableProps> = ({
  label,
  icon,
  initial = true,
  opened,
  visible,
  children,
  onToggle,
}) => {
  return (
    <StyledRightPanelToggleable
      label={<PanelLabel icon={icon}>{label}</PanelLabel>}
      initial={initial}
      opened={opened}
      visible={visible}
      onToggle={onToggle}
    >
      {children}
    </StyledRightPanelToggleable>
  );
};

const StyledRightPanelToggleable = styled(Toggleable).attrs(() => ({
  toggle: PanelToggle,
  labelComponent: PanelLabelContainer,
  header: PanelToggleHeader,
}))`
  color: ${({ theme }) => theme.colors.foreground};
  font-size: 11pt;
  position: relative;
  min-height: 30px;
  flex: 0 0 auto;
`;
StyledRightPanelToggleable.defaultProps = {
  theme: DefaultTheme,
};

export const PanelToggle = styled(Toggle)`
  margin-left: auto;
  flex: 0 0 70px;
  font-size: 12pt;
`;

export const PanelLabelContainer = styled(ToggleableLabel)`
  padding: 5px 15px 5px 15px;
  box-sizing: border-box;
  height: 30px;
`;

export const PanelToggleHeader = styled(ToggleableHeader)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;
PanelToggleHeader.defaultProps = {
  theme: DefaultTheme,
};

export const PanelLabel: React.FC<{
  icon?: Icons | string;
}> = ({ icon, children }) => {
  return (
    <>
      {icon && <PanelLabelIcon icon={icon} />}
      {children}
    </>
  );
};

const PanelLabelIcon = styled(Icon)`
  margin-right: 10px;
`;

export default RightPanelToggleable;
