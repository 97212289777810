import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import usePreference from "../hooks/usePreference";
import { Preferences } from "../state/Preferences";
import Icon, { Icons } from "./Icon";
import Layout from "./Layout";
import ToolbarTab from "./ToolbarTab";

export interface ContentBrowserRightToolbarProps {}

const ContentBrowserRightToolbar: React.FC<ContentBrowserRightToolbarProps> = () => {
  const [uploadsVisible, setUploadsVisibility] = usePreference<boolean>(Preferences.UploadsVisible, false);

  function toggleUploadsVisibility() {
    setUploadsVisibility((value) => !value);
  }

  return (
    <StyledContentBrowserRightToolbar>
      <ToolbarTab name={"Uploads"} selected={uploadsVisible} onClick={toggleUploadsVisibility}>
        <Icon icon={Icons.Upload} />
      </ToolbarTab>
    </StyledContentBrowserRightToolbar>
  );
};

const StyledContentBrowserRightToolbar = styled(Layout.RightPanelFooter)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${({ theme }) => theme.colors.toolbarBackground};
`;

export default observer(ContentBrowserRightToolbar);
