import { observer } from "mobx-react";
import React, { useMemo } from "react";
import styled from "styled-components";
import useContextMenu from "../hooks/useContextMenu";
import useSelectionReset from "../hooks/useSelectionReset";
import Group from "../state/Group";
import User from "../state/User";
import GroupMenu from "./GroupMenu";
import GroupUserMenu from "./GroupUserMenu";
import { TableColumn } from "./Table";
import TableColumnResizing from "./TableColumnResizing";
import { TableDiv, TableDivBody, TableDivHead, TableDivHeader, TableDivRow } from "./TableDiv";
import UserTableCell from "./UserTableCell";
import VirtualizedTable, { VirtualizedTableRowComponentProps } from "./VirtualizedTable";

export interface GroupUserTableProps {
  group: Group;
  selectedUser?: User | null;
  onSelect?(group: User | null): void;
}

interface GroupUserTableExtraCellProps {}

interface GroupUserTableRowExtraProps {
  group: Group;
}

const GroupUserTable: React.FC<GroupUserTableProps> = ({ group, selectedUser, onSelect }) => {
  const rows = group.users;
  const selectedRows = useMemo(() => (selectedUser ? [selectedUser] : []), [selectedUser]);
  const [menu, onContextMenu] = useContextMenu(GroupMenu, { props: { group } });

  const rowProps: GroupUserTableRowExtraProps = useMemo(
    () => ({
      group,
    }),
    [group]
  );

  const resetSelection = useSelectionReset(onSelect);
  return (
    <TableColumnResizing columns={GroupUserTableColumns} headerComponent={GroupUserHeader}>
      {({ columns, headerComponent }) => (
        <>
          <VirtualizedTable<User, GroupUserTableExtraCellProps, GroupUserTableRowExtraProps>
            columns={columns}
            rows={rows}
            selectedRows={selectedRows}
            rowHeight={35}
            tableComponent={TableDiv}
            bodyComponent={TableDivBody}
            headComponent={TableDivHead}
            headTableComponent={TableDiv}
            rowComponent={GroupUserRow}
            rowProps={rowProps}
            headerComponent={headerComponent}
            cellComponent={UserTableCell}
            emptyRows={0}
            onSelect={onSelect}
            onClick={resetSelection}
            onContextMenu={onContextMenu}
          />
          {menu}
        </>
      )}
    </TableColumnResizing>
  );
};

const GroupUserTableColumns: TableColumn[] = [
  { name: "name", title: "User", width: 250 },
  { name: "firstName", title: "First Name", width: 200 },
  { name: "lastName", title: "Last Name", width: 200 },
  { name: "email", title: "Email", width: 100 },
  { name: "provider", title: "Account Type", width: 100 },
  { name: "admin", title: "Administrator", width: 100 },
  { name: "nucleusRo", title: "Read Only", width: 100 },
  { name: "disabled", title: "Disabled", width: 100 },
];

const GroupUserHeader = styled(TableDivHeader)`
  padding-left: ${({ column }) => (column.name === "name" ? "35px" : "10px")};
  font-weight: 600;
`;

let GroupUserRow: React.FC<VirtualizedTableRowComponentProps<User> & GroupUserTableRowExtraProps> = ({
  row: user,
  group,
  children,
  ...props
}) => {
  const [menu, onContextMenu] = useContextMenu(GroupUserMenu, { props: { group, user } });
  return (
    <StyledGroupUserRow {...props} row={user} onContextMenu={onContextMenu} highlighted={Boolean(menu)}>
      {children}
      {menu}
    </StyledGroupUserRow>
  );
};
GroupUserRow = observer(GroupUserRow);

const StyledGroupUserRow = styled(TableDivRow)`
  svg {
    fill: ${({ theme, selected }) => (selected ? theme.colors.selectedItemForeground : theme.colors.foreground)};
  }
`;

export default observer(GroupUserTable);
