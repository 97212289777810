import React, { useCallback, useContext, useState } from "react";
import { NotificationContext } from "../components/Notifications";


function useNotification<P>(notification: React.ComponentType<P>, staticProps?: Partial<P>) {
  const [id, setId] = useState<number | null>(null)
  const notifications = useContext(NotificationContext);

  const show = useCallback((props?: Partial<P>) => {
    const allProps = { ...staticProps, ...props } as P;
    const id = notifications.show(notification, allProps);
    setId(id);
  }, [notifications, notification, staticProps]);

  const hide = useCallback(() => {
    if (id) {
      notifications.hide(id);
    }
  }, [notifications, id]);

  return {
    show,
    hide
  };
}

export default useNotification;