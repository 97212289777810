import { useContext } from "react";
import { DialogContext } from "../components/Dialogs";
import useForm, { FormSubmitCallback } from "./useForm";

export interface DialogFormSettings<F, T> {
  fields: F;
  onSubmit: FormSubmitCallback<F, T>;
}

function useDialogForm<F, T>({ fields, onSubmit }: DialogFormSettings<F, T>) {
  const dialog = useContext(DialogContext);
  return useForm({
    fields,
    onSubmit,
    onSuccess: dialog.hide,
    onCancel: dialog.hide,
  });
}

export default useDialogForm;
