import { StatusType } from "@omniverse/api/data";
import { Commands } from "../Provider";
import { IGetCheckpointsCommand } from "../types/GetCheckpointsCommand";
import {
  IRestoreCheckpointCommandAllowedArguments,
  IRestoreCheckpointCommandArguments,
} from "../types/RestoreCheckpointCommand";

import { NucleusCommand } from "./index";

export default class NucleusRestoreCheckpointCommand extends NucleusCommand<
  IRestoreCheckpointCommandArguments,
  IRestoreCheckpointCommandAllowedArguments
> {
  name = Commands.RestoreCheckpoint;

  public async allowed({ path }: IRestoreCheckpointCommandAllowedArguments): Promise<boolean> {
    return path.canWrite();
  }

  public async execute({ path, checkpoint }: IRestoreCheckpointCommandArguments) {
    console.log(`[${this.provider.name}] Restore checkpoint #${checkpoint.id} for ${path.path}`);

    const connection = await this.provider.getConnection();
    const response = await connection.replaceVersion({
      src_path: { path: path.path, checkpoint: checkpoint.id },
      dst_path: { path: path.path },
      message: `Restored Checkpoint ${checkpoint.id}`,
    });

    if (response.status !== StatusType.OK) {
      throw new Error(`Cannot restore checkpoint ${checkpoint} for ${path} -- ${response.status}.`);
    }

    const loadCheckpoints = this.provider.commands.get<IGetCheckpointsCommand>(Commands.GetCheckpoints);
    if (loadCheckpoints) {
      await loadCheckpoints.execute({ path, checkpoints: path.checkpoints });
    }
  }
}
