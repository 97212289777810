import { observer } from "mobx-react";
import React, { useMemo } from "react";
import useAppNavigate from "../hooks/useAppNavigate";
import useDoubleClick from "../hooks/useDoubleClick";
import useContextMenu from "../hooks/useContextMenu";
import useSelectionReset from "../hooks/useSelectionReset";
import Storage from "../state/Storage";
import ServerImage from "../static/server.png";
import Grid, { GridCellProps } from "./Grid";
import GridCell, { GridCellAligner } from "./GridCell";
import ServerMenu from "./ServerMenu";

export interface ServerGridProps {
  servers: Storage[];
  selectedServer?: Storage | null;
  onSelect?(server: Storage | null): void;
}

interface ServerGridExtraCellProps {
  selectedServer?: Storage | null;
  onSelect?(server: Storage | null): void;
}

const ServerGrid: React.FC<ServerGridProps> = ({ servers, selectedServer, onSelect }) => {
  const cellProps = useMemo(
    (): ServerGridExtraCellProps => ({
      selectedServer,
      onSelect,
    }),
    [selectedServer, onSelect]
  );

  const resetSelection = useSelectionReset(onSelect);
  return (
    <Grid
      cells={servers}
      cellProps={cellProps}
      cellWidth={150}
      cellHeight={150}
      cellRenderer={ServerGridCellRenderer}
      gap={20}
      onClick={resetSelection}
    />
  );
};

const ServerGridCellRenderer: React.FC<GridCellProps<Storage, ServerGridExtraCellProps>> = ({
  data: server,
  style,
  width,
  height,
  selectedServer,
  onSelect,
}) => {
  const navigate = useAppNavigate();
  const [menu, onContextMenu] = useContextMenu(ServerMenu, { props: { storage: server } });
  const handleClick = useDoubleClick(selectServer, openServer);

  function selectServer() {
    if (onSelect) {
      onSelect(server);
    }
  }

  function openServer() {
    navigate(`/omniverse://${server.name}/`);
  }

  return (
    <GridCellAligner style={style} cellWidth={width} onClick={handleClick} onContextMenu={onContextMenu}>
      <GridCell
        name={server.publicName}
        selected={selectedServer === server}
        highlighted={Boolean(menu)}
        bordered={false}
        scaling
        thumbnail={ServerImage}
        actualHeight={height}
        cellHeight={150}
      />
      {menu}
    </GridCellAligner>
  );
};

export default observer(ServerGrid);
