import React from "react";
import useDialogForm from "../hooks/useDialogForm";
import Path from "../state/Path";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import Dialog from "./Dialog";
import DialogForm from "./DialogForm";
import DialogTitle from "./DialogTitle";
import FormErrorList from "./FormErrorList";
import FormGroup from "./FormGroup";
import Label from "./Label";
import TextArea, { useTextArea } from "./TextArea";
import { PathUploadType } from "../state/PathUpload";

export interface CheckpointUploadNotesDialogProps {
  path: Path;
  files: File[];
  type?: PathUploadType;
}

interface CheckpointDialogFields {
  description: string;
}

const CheckpointUploadNotesDialog: React.FC<CheckpointUploadNotesDialogProps> = ({ path, files, type }) => {
  const [description, setDescription] = useTextArea("");

  const form = useDialogForm({
    fields: {
      description,
    },
    onSubmit: upload,
  });

  async function upload({ description }: CheckpointDialogFields) {
    path.upload(files, type, description);
  }

  return (
    <Dialog onClose={form.cancel}>
      <DialogTitle title={"Upload"} onClose={form.cancel} />
      <DialogForm>
        <FormGroup>
          <FormErrorList errors={form.errors} />
        </FormGroup>

        <FormGroup>
          <Label>
            Description:
            <TextArea
              name={"description"}
              placeholder={"Not required"}
              disabled={form.loading}
              value={description}
              onChange={setDescription}
            />
          </Label>
        </FormGroup>

        <ButtonGroup>
          <Button kind={"attention"} disabled={form.loading} onClick={form.submit}>
            Submit
          </Button>
          <Button disabled={form.loading} onClick={form.cancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </DialogForm>
    </Dialog>
  );
};

export default CheckpointUploadNotesDialog;
