import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AutoComplete, {
  AutoCompleteContainer,
  AutoCompleteMatch,
  AutoCompleteProps,
  AutoCompleteSuggestion,
  AutoCompleteSuggestionProps,
} from "./AutoComplete";
import { useChangeListener, useStateOrProp } from "../hooks/useStateOrProp";
import { StyledRenderer } from "./index";
import Tag from "./Tag";
import TextArea from "./TextArea";
import { DefaultTheme } from "./Theme";

export interface TagsProps {
  tags: string[];
  value?: string;
  mode?: TagMode;
  suggestions?: string[];
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  tabIndex?: number;
  containerComponent?: StyledRenderer;
  matchComponent?: StyledRenderer;
  suggestionComponent?: StyledRenderer<AutoCompleteSuggestionProps>;
  inputComponent?: StyledRenderer;
  onFocus?(e: React.FocusEvent): void;
  onBlur?(e: React.FocusEvent): void;
  onChange?(value: string): void;
  onSubmit?(tags: string[]): void;
  onModeChange?(mode: TagMode): void;
}

export enum TagMode {
  View,
  Edit,
}

export const Tags: React.FC<TagsProps> = ({
  tags,
  value: valueProp,
  mode: modeProp,
  suggestions,
  disabled = true,
  loading = false,
  placeholder = "",
  tabIndex = -1,
  className,
  containerComponent = TagAutoCompleteContainer,
  inputComponent = TagAutoCompleteInput,
  matchComponent = TagAutoCompleteMatch,
  suggestionComponent = TagAutoCompleteSuggestion,
  onBlur,
  onChange,
  onSubmit,
  onModeChange,
  ...props
}) => {
  const input = useRef<HTMLInputElement>(null);
  const selectedTag = useRef<string>();
  const selectedSuggestion = useRef<string>();

  const { changed: tagsChanged, reset: resetTagChanged } = useChangeListener(tags);
  const [value, setValue, valueControlled] = useStateOrProp(valueProp, onChange, "");
  const [mode, setMode, modeControlled] = useStateOrProp(modeProp, onModeChange, TagMode.View);
  const [selectionStart, setSelectionStart] = useState(0);

  useEffect(() => {
    resetTagChanged();
  }, [mode, resetTagChanged]);

  const switchToEdit = useCallback(() => {
    if (!disabled && mode !== TagMode.Edit) {
      setMode(TagMode.Edit);
      if (!modeControlled && onModeChange) {
        onModeChange(TagMode.Edit);
      }

      const newValue = fromTags(tags);
      setValue(newValue);
      if (!modeControlled && onChange) {
        onChange(newValue);
      }
    }
  }, [tags, disabled, mode, modeControlled, setMode, onModeChange, setValue, onChange]);

  const switchToTag = useCallback(
    (tag: string) => {
      selectedTag.current = tag;
      switchToEdit();
    },
    [selectedTag, switchToEdit]
  );

  const change = useCallback(
    (newValue: string, suggestion: string) => {
      setValue(newValue);

      if (!valueControlled && onChange) {
        onChange(newValue);
      }

      if (suggestion) {
        selectedSuggestion.current = suggestion;
      }
    },
    [selectedSuggestion, valueControlled, setValue, onChange]
  );

  const submit = useCallback(() => {
    setMode(TagMode.View);
    if (!modeControlled && onModeChange) {
      onModeChange(TagMode.View);
    }

    if (onSubmit) {
      onSubmit(toTags(value));
    }
  }, [value, modeControlled, setMode, onModeChange, onSubmit]);

  const blur = useCallback(
    (e: React.FocusEvent) => {
      if (onBlur) {
        onBlur(e);
      }

      if (!e.isDefaultPrevented()) {
        submit();
      }
    },
    [submit, onBlur]
  );

  const cancel = useCallback(() => {
    setMode(TagMode.View);
    if (!modeControlled && onModeChange) {
      onModeChange(TagMode.View);
    }
  }, [modeControlled, setMode, onModeChange]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        e.stopPropagation();
        if (mode === TagMode.Edit) {
          submit();
        } else if (mode === TagMode.View) {
          switchToEdit();
        }
      }
    },
    [mode, switchToEdit, submit]
  );

  useTagInputSelectionSpy(input, setSelectionStart, selectedTag, mode);
  useTagInputCaretScroll(input, selectedSuggestion);
  const matcher = useTagMatcher(value, selectionStart);
  const replacer = useTagReplacer(value, selectionStart);

  if (!tags) {
    tags = [];
  }

  return (
    <StyledTags
      {...props}
      className={className}
      tabIndex={mode === TagMode.Edit || disabled ? undefined : tabIndex}
      readOnly={disabled}
      onClick={switchToEdit}
      onKeyDown={handleKeyDown}
    >
      {mode === TagMode.Edit ? (
        <>
          {tagsChanged && (
            <TagsWarning>
              The tags have been updated by someone else. <Cancel onMouseDown={cancel}>Refresh</Cancel>
            </TagsWarning>
          )}
          <AutoComplete
            ref={input}
            tabIndex={tabIndex}
            containerComponent={containerComponent}
            inputComponent={inputComponent}
            suggestionComponent={suggestionComponent}
            matchComponent={matchComponent}
            suggestions={suggestions}
            loading={loading}
            matcher={matcher}
            replacer={replacer}
            value={value}
            onChange={change}
            onBlur={blur}
          />
        </>
      ) : tags.length > 0 ? (
        tags.map((tag) => <Tag key={tag} tag={tag} disabled={disabled} hideDelete={true} onClick={switchToTag} />)
      ) : (
        <TagPlaceholder>{placeholder}</TagPlaceholder>
      )}
    </StyledTags>
  );
};

export const StyledTags = styled.div<{
  readOnly: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  font-size: 10pt;
  position: relative;
  cursor: ${({ readOnly }) => (readOnly ? "default" : "text")};
  color: ${({ theme }) => theme.colors.foreground};
  gap: 5px;

  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.selectedItemBorder};
  }
`;
StyledTags.defaultProps = {
  theme: DefaultTheme,
};

export const TagPlaceholder = styled.div`
  padding: 2px 8px; 
  color: ${({ theme }) => theme.colors.placeholders};
`;
TagPlaceholder.defaultProps = {
  theme: DefaultTheme,
};

export const TagAutoCompleteContainer = styled(AutoCompleteContainer)`
  flex: 0 0 100%;
`;

export const TagAutoCompleteMatch = AutoCompleteMatch;
export const TagAutoCompleteSuggestion = AutoCompleteSuggestion;

export const TagAutoCompleteInput = styled(TextArea)`
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 10pt;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.inputText};
`;
TagAutoCompleteInput.defaultProps = {
  theme: DefaultTheme,
};

export const TagsWarning = styled.div`
  padding: 1em;
  font-size: 9pt;
  background: ${({ theme }) => theme.colors.dangerBackground};
  color: ${({ theme }) => theme.colors.dangerText};
`;

const Cancel = styled.div`
  display: inline-block;
  cursor: pointer;
  font-size: 9pt;
  color: ${({ theme }) => theme.colors.dangerText};
  filter: brightness(90%);
  text-decoration: underline;
`;

const useTagMatcher = (value: string, selectionStart: number): AutoCompleteProps["matcher"] => {
  const [tagStart, tagEnd] = getTagRange(value, selectionStart);
  const currentTag = value.substring(tagStart, tagEnd).trim();

  return ({ suggestion, lastSelectedSuggestion }) => {
    return (
      currentTag !== "" &&
      suggestion !== lastSelectedSuggestion &&
      suggestion.toLowerCase().startsWith(currentTag.toLowerCase())
    );
  };
};

function getTagRange(value: string, selectionStart: number | null) {
  if (!selectionStart) {
    selectionStart = 0;
  }

  const previousText = value.substring(0, selectionStart);
  const nextText = value.substring(selectionStart);
  const tagStart = previousText.lastIndexOf(",") + 1;
  const nextComma = nextText.indexOf(",");
  const tagEnd = nextComma === -1 ? value.length : previousText.length + nextComma;
  return [tagStart, tagEnd];
}

const useTagReplacer = (value: string, selectionStart: number) => {
  const [tagStart, tagEnd] = getTagRange(value, selectionStart);
  return (currentValue: string, suggestion: string) => {
    const prefix = tagStart === 0 ? "" : currentValue.substring(0, tagStart) + " ";
    return prefix + suggestion + currentValue.substring(tagEnd);
  };
};

const useTagInputSelectionSpy = (
  inputRef: React.RefObject<HTMLInputElement>,
  setSelectionStart: (start: number) => void,
  selectedTagRef: React.MutableRefObject<string | undefined>,
  mode: TagMode
) => {
  useEffect(() => {
    const input = inputRef.current;
    const selectedTag = selectedTagRef.current;
    const spySelectionStart = (e: Event) => {
      setSelectionStart((e.target as HTMLTextAreaElement).selectionStart);
    };

    if (mode === TagMode.Edit && input) {
      input.focus();
      input.selectionStart = selectedTag
        ? input.textContent!.indexOf(selectedTag) + selectedTag.length
        : input.textContent!.length;
      input.addEventListener("click", spySelectionStart);
      input.addEventListener("keyup", spySelectionStart);
      setSelectionStart(input.selectionStart);
    }

    return () => {
      if (input) {
        input.removeEventListener("click", spySelectionStart);
        input.removeEventListener("keyup", spySelectionStart);
      }
    };
  }, [mode, inputRef, selectedTagRef, setSelectionStart]);
};

const useTagInputCaretScroll = (
  inputRef: React.RefObject<HTMLInputElement>,
  selectedSuggestionRef: React.MutableRefObject<string | undefined>
) => {
  const input = inputRef.current;
  const selectedSuggestion = selectedSuggestionRef.current;

  useEffect(() => {
    if (input && selectedSuggestion) {
      const range = getTagRange(input.value, input.selectionStart);
      const end = range[1];
      input.selectionStart = input.selectionEnd = end;
    }
  }, [input, selectedSuggestion]);
};

export function fromTags(tags: string[]): string {
  return tags.join(", ");
}

export function toTags(value: string): string[] {
  return value
    .split(",")
    .map((tag) => tag.trim())
    .filter((tag) => tag.length > 0);
}

export default Tags;
