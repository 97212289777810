import { useEffect } from "react";
import useAppNavigate from "../hooks/useAppNavigate";

export default function AppNavigate({ to }: { to: string }) {
  const navigate = useAppNavigate();

  useEffect(() => {
    navigate(to);
  });

  return null;
}
