import { observer } from "mobx-react";
import React from "react";
import useContextMenu from "../hooks/useContextMenu";
import Group from "../state/Group";
import { ReactComponent as GroupSVG } from "../static/group.svg";
import { GridCellProps } from "./Grid";
import GridCell, { GridCellAligner } from "./GridCell";
import GroupMenu from "./GroupMenu";

export type GroupGridCellProps = GridCellProps<Group, GroupGridCellExtraProps>;

export interface GroupGridCellExtraProps {
  selectedGroup?: Group | null;
  onSelect?(user: Group): void;
}

const GroupGridCell: React.FC<GroupGridCellProps> = ({
  style,
  width,
  height,
  data: group,
  selectedGroup,
  onSelect,
}) => {
  const [menu, onContextMenu] = useContextMenu(GroupMenu, { props: { group }});

  function selectGroup() {
    if (onSelect) {
      onSelect(group);
    }
  }

  return (
    <GridCellAligner style={style} cellWidth={width} onClick={selectGroup} onContextMenu={onContextMenu}>
      <GridCell
        name={group.name}
        selected={selectedGroup === group}
        highlighted={Boolean(menu)}
        bordered={false}
        scaling
        thumbnail={GroupSVG}
        actualHeight={height}
        cellHeight={150}
      />
      {menu}
    </GridCellAligner>
  );
};

export default observer(GroupGridCell);
