export default class Queue {
  constructor() {
    this.callbacks = [];
    this.buffer = [];
  }

  put = (data) => {
    const callback = this.callbacks.pop();
    if (callback) {
      callback(data);
    } else {
      this.buffer = [data].concat(this.buffer);
    }
  };

  get = () => {
    return new Promise(resolve => {
      if (this.buffer.length) {
        const item = this.buffer.pop();
        resolve(item);
      } else {
        this.callbacks = [resolve].concat(this.callbacks);
      }
    });
  }
}