import { StatusType } from "@omniverse/api/data";

export class GetGroupsError extends Error {
  public readonly messages: { [status: string]: string } = {
    [StatusType.Denied]: "You don't have access to get server groups.",
  };
  public readonly result: { status: StatusType };

  constructor(result: { status: StatusType }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}

export class ManageGroupsError extends Error {
  public readonly messages: { [status: string]: string } = {
    [StatusType.Denied]: "You don't have access to manage groups on this server.",
  };
  public readonly result: { status: StatusType };

  constructor(result: { status: StatusType }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}

export class GetGroupUsersError extends Error {
  public readonly messages: { [status: string]: string } = {
    [StatusType.Denied]: "You don't have access to read users of this group.",
  };
  public readonly result: { status: StatusType };

  constructor(result: { status: StatusType }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}

export const DefaultMessage = (status: string) => `Unknown error, please contact administrator (${status}).`;