import React from "react";
import styled from "styled-components";
import { stopPropagation } from "../util/DOM";
import Icon from "./Icon";

export interface DetailedGridCellProps {
  title: string;
  image: string;
  video?: string;
  preview?: string;
  details: string;
  onClick?(e: React.MouseEvent): void;
}

const DetailedGridCell: React.FC<DetailedGridCellProps> = ({ title, image, video, preview, details, onClick }) => {
  return (
    <DetailWrapper onClick={onClick}>
      <DetailInfo>
        <DetailImage src={image} alt={title} />
        <DetailTitle>{title}</DetailTitle>
      </DetailInfo>
      <Detail>
        {details}
        {video && preview && (
          <DetailVideoLink href={video} target={"_blank"} rel={"noopener noreferrer"} onClick={stopPropagation}>
            <DetailVideoPreview src={preview} />
            <DetailVideoPlayButton />
          </DetailVideoLink>
        )}
      </Detail>
    </DetailWrapper>
  );
};

const DetailTitle = styled.span`
  flex: 0 0 20px;
`;

const DetailImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 150px;
  margin: auto;
`;

const DetailVideoLink = styled.a`
  position: relative;
  text-decoration: none;
  display: block;
  opacity: 0;
  transition: 0.3s all;
  margin: 0 auto;
`;

const DetailVideoPreview = styled.img`
  max-width: 250px;
  max-height: 0;
  transition: 0.3s all;
`;

const DetailVideoPlayButton = styled(Icon).attrs({ icon: "fa fa-play" })`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20pt;
  color: white;
  background: black;
  border-radius: 50%;
  transition: 0.3s all;

  &:hover {
    background: #333;
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;
  background: ${({ theme }) => theme.colors.hoverItemBackground};
  color: ${({ theme }) => theme.colors.foreground};
  padding: 10px;
  font-style: italic;
  transition: 0.3s all;
  flex: 1;

  &:hover {
    ${DetailVideoLink} {
      opacity: 1;
      margin: 20px auto;
    }

    ${DetailVideoPreview} {
      max-height: 140px;
    }
  }
`;

const DetailInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 150px;
  padding: 10px 5px;
  box-sizing: border-box;
`;

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  max-width: 300px;
  text-align: center;
  color: ${({ theme }) => theme.colors.foreground};
  border: 1px solid transparent;
  z-index: 1;

  &:hover {
    background: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 3px;
    box-shadow: ${({ theme }) => theme.shadows.default};

    ${Detail} {
      opacity: 1;
    }
  }
`;

export default DetailedGridCell;
