import { observer } from "mobx-react";
import React from "react";
import usePermission from "../hooks/usePermission";
import { Commands } from "../state/commands/Provider";
import { IAddUserToGroupCommand } from "../state/commands/types/AddUserToGroupCommand";
import Group from "../state/Group";
import User from "../state/User";
import { ContentBrowserView } from "./ContentBrowserViewToggle";
import GroupUserGrid from "./GroupUserGrid";
import GroupUserTable from "./GroupUserTable";

export interface GroupContentProps {
  view: ContentBrowserView;
  group: Group;
  selectedUser: User | null;
  onSelect(user: User | null): void;
}

const GroupContent: React.FC<GroupContentProps> = ({ view, group, selectedUser, onSelect }) => {
  const supportsUserManagement = usePermission(
    () => group.storage.commands.allowed<IAddUserToGroupCommand>(Commands.AddUserToGroup),
    [group]
  );

  if (view === ContentBrowserView.Grid) {
    return (
      <GroupUserGrid
        group={group}
        selectedUser={selectedUser}
        canAddUsers={supportsUserManagement === "allowed" && !group.readonly}
        onSelect={onSelect}
      />
    );
  }

  if (view === ContentBrowserView.Table) {
    return <GroupUserTable group={group} selectedUser={selectedUser} onSelect={onSelect} />;
  }

  return null;
};

export default observer(GroupContent);
