import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";

interface PageProps {
  title?: string;
  className?: string;
}

const Page: React.FC<PageProps> = ({ className, title, children }) => {
  useEffect(() => {
    if (title) {
      document.head.title = document.title = title;
    }
  }, [title]);

  return (
    <PageWrapper className={className}>
      {children}
    </PageWrapper>
  );
};

Page.defaultProps = {
  title: "Omniverse"
};

const PageWrapper = styled.div``;

export default Page;
