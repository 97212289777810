import styled from "styled-components";
import { DefaultTheme } from "./Theme";

const DialogTextWrapper = styled.div`
  padding: 0 1em 1em 1em;
  color: ${({ theme }) => theme.colors.foreground};
`;
DialogTextWrapper.defaultProps = {
  theme: DefaultTheme
};

export const DialogCenteredTextWrapper = styled(DialogTextWrapper)`
  text-align: center;
`;

export default DialogTextWrapper;
