import Schema from "@omniverse/idl/schema";


export const Capabilities = Schema.Map(Schema.Number);

export const ServerFeaturesServerRemoteCapabilities = Capabilities;

export const ServerFeaturesServerLocalCapabilities = Schema.Literal({'exchange_capabilities': 0, 'omni_objects': 0, 'omni_objects2': 0, 'lft': 0, 'hashes': 0, 'versioning': 0});

export const ServerFeaturesClientRemoteCapabilities = Capabilities;

export const ServerFeaturesClientLocalCapabilities = Schema.Literal({'exchange_capabilities': 0, 'omni_objects': 0, 'omni_objects2': 0, 'lft': 0, 'hashes': 0, 'versioning': 0});

export const ConnectionServerRemoteCapabilities = Capabilities;

export const ConnectionServerLocalCapabilities = Schema.Literal({'ping': 0, 'auth': 2, 'authorize_token': 1, 'subscribe_server_notifications': 0, 'stat2': 1, 'list': 4, 'list2': 4, 'subscribe_list': 1, 'create': 2, 'update': 1, 'create_asset': 0, 'update_asset': 0, 'create_asset_with_hash': 0, 'update_asset_with_hash': 0, 'create_object': 0, 'update_object': 0, 'deep_copy_object_struct': 0, 'read': 0, 'read_asset_version': 0, 'read_asset_resolved': 0, 'subscribe_read_asset': 0, 'read_object_version': 0, 'read_object_resolved': 0, 'subscribe_read_object': 1, 'rename': 0, 'delete': 0, 'delete2': 0, 'copy2': 0, 'create_directory': 0, 'lock': 2, 'unlock': 1, 'copy': 1, 'get_transaction_id': 0, 'set_path_options': 1, 'set_path_options2': 0, 'get_acl': 0, 'change_acl': 0, 'get_acl_v2': 0, 'get_acl_resolved': 0, 'set_acl_v2': 0, 'get_groups': 0, 'get_group_users': 0, 'get_users': 0, 'get_user_groups': 0, 'create_group': 0, 'rename_group': 0, 'remove_group': 0, 'add_user_to_group': 0, 'remove_user_from_group': 0, 'mount': 0, 'unmount': 0, 'get_mount_info': 0, 'checkpoint_version': 1, 'replace_version': 0, 'get_checkpoints': 0, 'get_branches': 0});

export const ConnectionClientRemoteCapabilities = Capabilities;

export const ConnectionClientLocalCapabilities = Schema.Literal({'ping': 0, 'auth': 2, 'authorize_token': 1, 'subscribe_server_notifications': 0, 'stat2': 1, 'list': 4, 'list2': 4, 'subscribe_list': 1, 'create': 2, 'update': 1, 'create_asset': 0, 'update_asset': 0, 'create_asset_with_hash': 0, 'update_asset_with_hash': 0, 'create_object': 0, 'update_object': 0, 'deep_copy_object_struct': 0, 'read': 0, 'read_asset_version': 0, 'read_asset_resolved': 0, 'subscribe_read_asset': 0, 'read_object_version': 0, 'read_object_resolved': 0, 'subscribe_read_object': 1, 'rename': 0, 'delete': 0, 'delete2': 0, 'copy2': 0, 'create_directory': 0, 'lock': 2, 'unlock': 1, 'copy': 1, 'get_transaction_id': 0, 'set_path_options': 1, 'set_path_options2': 0, 'get_acl': 0, 'change_acl': 0, 'get_acl_v2': 0, 'get_acl_resolved': 0, 'set_acl_v2': 0, 'get_groups': 0, 'get_group_users': 0, 'get_users': 0, 'get_user_groups': 0, 'create_group': 0, 'rename_group': 0, 'remove_group': 0, 'add_user_to_group': 0, 'remove_user_from_group': 0, 'mount': 0, 'unmount': 0, 'get_mount_info': 0, 'checkpoint_version': 1, 'replace_version': 0, 'get_checkpoints': 0, 'get_branches': 0});

export const TimestampMicroseconds = Schema.Number;

export const Timestamps = Schema.Map(TimestampMicroseconds);

export const StatusType = Schema.Enum({
  OK: "OK",
  Done: "DONE",
  Idle: "IDLE",
  Denied: "DENIED",
  Latest: "LATEST",
  InvalidCommand: "INVALID_COMMAND",
  InvalidPath: "INVALID_URI",
  Unauthenticated: "UNAUTHENTICATED",
  ContentLengthMismatch: "CONTENT_LENGTH_MISMATCH",
  AlreadyExists: "ALREADY_EXISTS",
  NotImplemented: "NOT_IMPLEMENTED",
  ResourceBusy: "RESOURCE_BUSY",
  NotAsset: "NOT_ASSET",
  InternalError: "INTERNAL_ERROR",
  IncompatibleVersion: "INVALID_VERSION",
  InvalidETag: "INVALID_ETAG",
  InvalidTransactionId: "INVALID_TRANSACTION_ID",
  AccessLost: "ACCESS_LOST",
  ConnectionLost: "CONNECTION_LOST",
  UnknownStatus: "UNKNOWN_STATUS",
  Timeout: "TIMEOUT",
  OperationFailed: "OPERATION_FAILED",
  BufferTooSmall: "BUFFER_TOO_SMALL",
  ContentBufferOverflow: "CONTENT_BUFFER_OVERFLOW",
  InvalidParameters: "INVALID_PARAMETERS",
  NotInitialized: "NOT_INITIALIZED",
  AlreadyAuthenticated: "ALREADY_AUTHENTICATED",
  InvalidContentId: "INVALID_CONTENT_ID",
  UserNotFound: "USER_NOT_FOUND",
  GroupNotFound: "GROUP_NOT_FOUND",
  GroupAlreadyExists: "GROUP_ALREADY_EXISTS",
  UserNotInGroup: "USER_NOT_IN_GROUP",
  UserAlreadyInGroup: "USER_ALREADY_IN_GROUP",
  MountExistsUnderPath: "MOUNT_EXISTS_UNDER_PATH",
  TokenExpired: "TOKEN_EXPIRED",
  NotExist: "NOT_EXIST",
  FolderNotEmpty: "FOLDER_NOT_EMPTY",
  NotObject: "NOT_OBJECT",
  PartiallyCompleted: "PARTIALLY_COMPLETED",
});

export const Checkpoint = Schema.Object({
  status: StatusType,
  checkpoint_id: Schema.Optional(Schema.Number),
  message: Schema.Optional(Schema.String),
});

export const HashType = Schema.Object({
  type: Schema.String,
  block_size: Schema.Number,
});

export const ServerFeaturesServerCapabilities = ServerFeaturesServerRemoteCapabilities;

export const VersioningResponse = Schema.Object({
  status: StatusType,
  enabled: Schema.Optional(Schema.Boolean),
});

export const HashesResponse = Schema.Object({
  status: StatusType,
  types: Schema.Optional(Schema.Array(HashType)),
});

export const LftResponse = Schema.Object({
  status: StatusType,
  lft_server_path: Schema.Optional(Schema.String),
  upload_threshold: Schema.Optional(Schema.Number),
});

export const OmniObjects2Response = Schema.Object({
  status: StatusType,
  ext_value_min_size: Schema.Optional(Schema.Number),
});

export const OmniObjectsResponse = Schema.Object({
  status: StatusType,
  enabled: Schema.Optional(Schema.Boolean),
  ext_value_min_size: Schema.Optional(Schema.Number),
});

export const CapabilitiesResponse = Schema.Object({
  status: StatusType,
  server_capabilities: ServerFeaturesServerCapabilities,
});

export const ServerFeaturesClientCapabilities = ServerFeaturesClientLocalCapabilities;

export const NotificationLevel = Schema.Enum({
  Info: "INFO",
  Warning: "WARNING",
  Critical: "CRITICAL",
});

export const JSONString = Schema.String;

export const MountInfo = Schema.Object({
  uri: Schema.String,
  redirect_url: Schema.Optional(Schema.String),
  resolver: Schema.Optional(Schema.String),
  options: Schema.Optional(JSONString),
});

export const PathPermission = Schema.Enum({
  Read: "read",
  Write: "write",
  Admin: "admin",
});

export const ACL = Schema.Map(Schema.Array(PathPermission));

export const PathAtVersion = Schema.Object({
  path: Schema.String,
  branch: Schema.Optional(Schema.String),
  checkpoint: Schema.Optional(Schema.Number),
});

export const ACLAtLevelValue = Schema.Object({
  acl: Schema.Array(PathPermission),
  path: Schema.String,
  group: Schema.Optional(Schema.String),
});

export const ACLAtLevel = Schema.Map(ACLAtLevelValue);

export const GetACLResolvedResponse = Schema.Object({
  status: StatusType,
  acl: Schema.Optional(ACLAtLevel),
});

export const GetACLResponse = Schema.Object({
  status: StatusType,
  acl: Schema.Optional(ACL),
});

export const FullUpdateEtag = Schema.Literal("");

export const PathType = Schema.Enum({
  Any: "none",
  Asset: "asset",
  Folder: "folder",
  Channel: "channel",
  Mount: "mount",
  Object: "omniobject",
});

export const List2ResponsePathEntry = Schema.Object({
  path: Schema.Optional(Schema.String),
  branch: Schema.Optional(Schema.String),
  etag: Schema.Optional(Schema.String),
  acl: Schema.Optional(Schema.Array(PathPermission)),
  created_timestamp: Schema.Optional(Schema.Number),
  modified_timestamp: Schema.Optional(Schema.Number),
  path_type: Schema.Optional(PathType),
  size: Schema.Optional(Schema.Number),
  empty: Schema.Optional(Schema.Boolean),
  mounted: Schema.Optional(Schema.Boolean),
  created_by: Schema.Optional(Schema.String),
  modified_by: Schema.Optional(Schema.String),
  hash_type: Schema.Optional(Schema.String),
  hash_value: Schema.Optional(Schema.String),
  hash_bsize: Schema.Optional(Schema.Number),
  checkpointed: Schema.Optional(Schema.Boolean),
  destination: Schema.Optional(Schema.String),
  transaction_id: Schema.Optional(Schema.Number),
  locked_by: Schema.Optional(Schema.String),
  lock_time: Schema.Optional(Schema.Number),
  lock_owner: Schema.Optional(Schema.String),
  lock_duration: Schema.Optional(Schema.Number),
  lock_etag: Schema.Optional(Schema.String),
  checkpoint_count: Schema.Optional(Schema.Number),
});

export const PathEvent = Schema.Enum({
  Full: "full",
  Create: "create",
  Delete: "delete",
  Delta: "delta",
  ChangeAcl: "change_acl",
  Options: "set_path_options",
  Locked: "lock",
  Unlocked: "unlock",
  Rename: "rename",
  Copy: "copy",
  VersionReplaced: "replace_version",
  CheckpointsChanged: "checkpoints_changed",
});

export const ConnectionServerCapabilities = ConnectionServerRemoteCapabilities;

export const PathAtBranch = Schema.Object({
  path: Schema.String,
  branch: Schema.Optional(Schema.String),
});

export const StringPair = Schema.Object({
  key: Schema.String,
  value: Schema.String,
});

export const GetBranchesResponse = Schema.Object({
  status: StatusType,
  branches: Schema.Array(Schema.String),
});

export const GetCheckpointsResponse = Schema.Object({
  status: StatusType,
  checkpoints: Schema.Array(Checkpoint),
});

export const Response = Schema.Object({
  status: StatusType,
  ts: Timestamps,
});

export const CheckpointVersionResponse = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  path: Schema.String,
  branch: Schema.String,
  checkpoint: Schema.Number,
});

export const MountsInfo = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  mounts: Schema.Array(MountInfo),
});

export const ResponseWithUri = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  uri: Schema.Optional(Schema.String),
});

export const RemoveUserFromGroupResponse = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  username: Schema.Optional(Schema.String),
  group: Schema.Optional(Schema.String),
});

export const AddUserToGroupResponse = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  username: Schema.Optional(Schema.String),
  group: Schema.Optional(Schema.String),
});

export const RemoveGroupResponse = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  group: Schema.Optional(Schema.String),
  change_count: Schema.Optional(Schema.Number),
});

export const RenameGroupResponse = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  group: Schema.Optional(Schema.String),
  new_group: Schema.Optional(Schema.String),
});

export const CreateGroupResponse = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  group: Schema.Optional(Schema.String),
});

export const GetUserGroupsResponse = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  username: Schema.Optional(Schema.String),
  groups: Schema.Array(Schema.String),
});

export const UserList = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  users: Schema.Optional(Schema.Array(Schema.String)),
});

export const GetGroupUsersResponse = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  group: Schema.Optional(Schema.String),
  users: Schema.Array(Schema.String),
});

export const GroupList = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  groups: Schema.Optional(Schema.Array(Schema.String)),
});

export const SetACLResponse = Schema.Object({
  ts: Timestamps,
  status: StatusType,
  pathStatuses: Schema.Array(StatusType),
});

export const PathAtVersionACLPair = Schema.Object({
  path_at_version: PathAtVersion,
  acl: ACL,
});

export const GetACLResolvedResponses = Schema.Object({
  ts: Timestamps,
  status: StatusType,
  responses: Schema.Array(GetACLResolvedResponse),
});

export const GetACLResponses = Schema.Object({
  ts: Timestamps,
  status: StatusType,
  responses: Schema.Array(GetACLResponse),
});

export const ACLResult = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  acl: Schema.Optional(ACL),
});

export const SetPathOptionsResult = Schema.Object({
  status: StatusType,
  ts: Timestamps,
});

export const TransactionIDResult = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  transaction_id: Schema.Optional(Schema.Number),
});

export const CopyResult = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  uri: Schema.Optional(Schema.String),
  transaction_id: Schema.Optional(Schema.String),
  num_copied: Schema.Optional(Schema.String),
});

export const UnlockResult = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  etag: Schema.Optional(Schema.String),
  uri: Schema.Optional(Schema.String),
});

export const LockResult = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  etag: Schema.Optional(Schema.String),
  uri: Schema.Optional(Schema.String),
});

export const CreateDirectoryResult = Schema.Object({
  status: StatusType,
  ts: Timestamps,
});

export const Copy2Response = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  responses: Schema.Array(StatusType),
});

export const PathsToCopy = Schema.Object({
  src: PathAtVersion,
  dst: PathAtBranch,
});

export const Delete2Response = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  responses: Schema.Array(StatusType),
});

export const DeletedPath = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  uri: Schema.Optional(Schema.String),
  acl: Schema.Optional(Schema.Array(PathPermission)),
  type: Schema.Optional(PathType),
  transaction_id: Schema.Optional(Schema.String),
});

export const MoveResponse = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  responses: Schema.Array(StatusType),
});

export const SourceDestinationPair = StringPair;

export const SubscribeReadObjectResult = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  sequence: Schema.Optional(Schema.Number),
  content: Schema.Optional(Schema.Stream),
  uri_redirection: Schema.Optional(Schema.String),
  size: Schema.Optional(Schema.Number),
  object_id: Schema.Optional(Schema.Number),
});

export const ReadObjectResolvedResult = Schema.Object({
  status: StatusType,
  sequence: Schema.Optional(Schema.Number),
  branch: Schema.Optional(Schema.String),
  ts: Schema.Optional(Timestamps),
  content: Schema.Optional(Schema.Stream),
  size: Schema.Optional(Schema.Number),
  uri_redirection: Schema.Optional(Schema.String),
});

export const ReadObjectVersionResult = Schema.Object({
  status: StatusType,
  sequence: Schema.Optional(Schema.Number),
  ts: Schema.Optional(Timestamps),
  content: Schema.Optional(Schema.Stream),
  size: Schema.Optional(Schema.Number),
  uri_redirection: Schema.Optional(Schema.String),
});

export const SubscribeReadAssetResult = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  etag: Schema.Optional(Schema.String),
  delta: Schema.Optional(Schema.String),
  content: Schema.Optional(Schema.Stream),
  uri_redirection: Schema.Optional(Schema.String),
  hash_value: Schema.Optional(Schema.String),
  hash_type: Schema.Optional(Schema.String),
  hash_bsize: Schema.Optional(Schema.Number),
  size: Schema.Optional(Schema.Number),
  transaction_id: Schema.Optional(Schema.Number),
});

export const ReadAssetResolvedResult = Schema.Object({
  status: StatusType,
  branch: Schema.Optional(Schema.String),
  ts: Schema.Optional(Timestamps),
  etag: Schema.Optional(Schema.String),
  delta: Schema.Optional(Schema.String),
  content: Schema.Optional(Schema.Stream),
  uri_redirection: Schema.Optional(Schema.String),
  hash_value: Schema.Optional(Schema.String),
  hash_type: Schema.Optional(Schema.String),
  hash_bsize: Schema.Optional(Schema.Number),
  size: Schema.Optional(Schema.Number),
  transaction_id: Schema.Optional(Schema.Number),
});

export const ReadAssetVersionResult = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  etag: Schema.Optional(Schema.String),
  delta: Schema.Optional(Schema.String),
  content: Schema.Optional(Schema.Stream),
  uri_redirection: Schema.Optional(Schema.String),
  hash_value: Schema.Optional(Schema.String),
  hash_type: Schema.Optional(Schema.String),
  hash_bsize: Schema.Optional(Schema.Number),
  size: Schema.Optional(Schema.Number),
  transaction_id: Schema.Optional(Schema.Number),
});

export const ReadResult = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  etag: Schema.Optional(Schema.String),
  delta: Schema.Optional(Schema.String),
  content: Schema.Optional(Schema.Stream),
  uri_redirection: Schema.Optional(Schema.String),
  hash_value: Schema.Optional(Schema.String),
  hash_type: Schema.Optional(Schema.String),
  hash_bsize: Schema.Optional(Schema.Number),
  size: Schema.Optional(Schema.Number),
  transaction_id: Schema.Optional(Schema.String),
  uri: Schema.Optional(Schema.String),
});

export const DeepCopyObjectStructResult = Schema.Object({
  status: StatusType,
  sequence: Schema.Optional(Schema.Number),
  ts: Schema.Optional(Timestamps),
  transaction_id: Schema.Optional(Schema.String),
});

export const UpdateObjectResult = Schema.Object({
  status: StatusType,
  sequence: Schema.Optional(Schema.Number),
  ts: Schema.Optional(Timestamps),
  transaction_id: Schema.Optional(Schema.Number),
});

export const CreateObjectResult = Schema.Object({
  status: StatusType,
  sequence: Schema.Optional(Schema.Number),
  object_id: Schema.Optional(Schema.Number),
  ts: Schema.Optional(Timestamps),
  transaction_id: Schema.Optional(Schema.Number),
});

export const UpdateAssetWithHashResult = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  etag: Schema.Optional(Schema.String),
  transaction_id: Schema.Optional(Schema.Number),
  hash_type: Schema.Optional(Schema.String),
  hash_value: Schema.Optional(Schema.String),
  hash_bsize: Schema.Optional(Schema.Number),
});

export const CreateAssetWithHashResult = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  etag: Schema.Optional(Schema.String),
  transaction_id: Schema.Optional(Schema.Number),
  hash_type: Schema.Optional(Schema.String),
  hash_value: Schema.Optional(Schema.String),
  hash_bsize: Schema.Optional(Schema.Number),
});

export const UpdateAssetResult = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  etag: Schema.Optional(Schema.String),
  transaction_id: Schema.Optional(Schema.Number),
  hash_type: Schema.Optional(Schema.String),
  hash_value: Schema.Optional(Schema.String),
  hash_bsize: Schema.Optional(Schema.Number),
});

export const CreateAssetResult = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  etag: Schema.Optional(Schema.String),
  transaction_id: Schema.Optional(Schema.Number),
  hash_type: Schema.Optional(Schema.String),
  hash_value: Schema.Optional(Schema.String),
  hash_bsize: Schema.Optional(Schema.Number),
});

export const UploadResult = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  uri: Schema.Optional(Schema.String),
  etag: Schema.Optional(Schema.String),
  type: Schema.Optional(PathType),
  transaction_id: Schema.Optional(Schema.String),
  hash_type: Schema.Optional(Schema.String),
  hash_value: Schema.Optional(Schema.String),
  hash_bsize: Schema.Optional(Schema.Number),
});

export const PathTypeCode = Schema.Enum({
  Any: 0,
  Asset: 1,
  Folder: 2,
  Channel: 3,
  Object: 4,
  Mount: 5,
});

export const SubscribeListResponse = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  event: Schema.Optional(PathEvent),
  entry: Schema.Optional(List2ResponsePathEntry),
});

export const List2Response = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  entries: Schema.Optional(Schema.Array(List2ResponsePathEntry)),
});

export const Path = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  type: Schema.Optional(PathType),
  uri: Schema.Optional(Schema.String),
  destination: Schema.Optional(Schema.String),
  acl: Schema.Optional(Schema.Array(PathPermission)),
  created: Schema.Optional(Schema.String),
  created_date_seconds: Schema.Optional(Schema.Number),
  created_by: Schema.Optional(Schema.String),
  modified: Schema.Optional(Schema.String),
  modified_date_seconds: Schema.Optional(Schema.Number),
  modified_by: Schema.Optional(Schema.String),
  size: Schema.Optional(Schema.Number),
  etag: Schema.Optional(Schema.String),
  event: Schema.Optional(PathEvent),
  empty: Schema.Optional(Schema.Boolean),
  mounted: Schema.Optional(Schema.Boolean),
  transaction_id: Schema.Optional(Schema.String),
  hash_type: Schema.Optional(Schema.String),
  hash_value: Schema.Optional(Schema.String),
  hash_bsize: Schema.Optional(Schema.Number),
  locked_by: Schema.Optional(Schema.String),
  lock_time: Schema.Optional(Schema.Number),
  lock_owner: Schema.Optional(Schema.String),
  lock_duration: Schema.Optional(Schema.Number),
  lock_etag: Schema.Optional(Schema.String),
});

export const Stat2Result = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  type: Schema.Optional(PathType),
  uri: Schema.Optional(Schema.String),
  acl: Schema.Optional(Schema.Array(PathPermission)),
  created: Schema.Optional(Schema.String),
  created_date_seconds: Schema.Optional(Schema.Number),
  created_by: Schema.Optional(Schema.String),
  modified: Schema.Optional(Schema.String),
  modified_date_seconds: Schema.Optional(Schema.Number),
  modified_by: Schema.Optional(Schema.String),
  mounted: Schema.Optional(Schema.Boolean),
  empty: Schema.Optional(Schema.Boolean),
  size: Schema.Optional(Schema.Number),
  etag: Schema.Optional(Schema.String),
  transaction_id: Schema.Optional(Schema.String),
  hash_type: Schema.Optional(Schema.String),
  hash_value: Schema.Optional(Schema.String),
  hash_bsize: Schema.Optional(Schema.Number),
  checkpointed: Schema.Optional(Schema.Boolean),
  locked_by: Schema.Optional(Schema.String),
  lock_time: Schema.Optional(Schema.Number),
  lock_owner: Schema.Optional(Schema.String),
  lock_duration: Schema.Optional(Schema.Number),
  lock_etag: Schema.Optional(Schema.String),
});

export const ServerNotificationResponse = Schema.Object({
  status: StatusType,
  ts: Timestamps,
  level: Schema.Optional(NotificationLevel),
  notification: Schema.Optional(Schema.String),
});

export const Auth = Schema.Object({
  status: StatusType,
  server_capabilities: Schema.Optional(ConnectionServerCapabilities),
  ts: Schema.Optional(Timestamps),
  version: Schema.Optional(Schema.String),
  username: Schema.String,
  token: Schema.String,
  connection_id: Schema.String,
  connection_id_signature: Schema.Optional(Schema.String),
  max_chunk_size: Schema.Number,
  lft_address: Schema.Optional(Schema.String),
  lft_threshold: Schema.Optional(Schema.Number),
  super_user: Schema.Optional(Schema.Boolean),
});

export const ConnectionClientCapabilities = ConnectionClientLocalCapabilities;

export const Version = Schema.Literal("1.19");

export const PingResponse = Schema.Object({
  status: StatusType,
  ts: Schema.Optional(Timestamps),
  auth: Schema.Optional(Schema.String),
  username: Schema.Optional(Schema.String),
  token: Schema.Optional(Schema.String),
  connection_id: Schema.Optional(Schema.String),
  max_chunk_size: Schema.Optional(Schema.Number),
  version: Schema.Optional(Schema.String),
});



export const ServerFeaturesExchangeCapabilitiesRequest = Schema.Object({
  client_capabilities: ServerFeaturesClientCapabilities,
});

export const ServerFeaturesOmniObjectsRequest = Schema.Object({
});

export const ServerFeaturesOmniObjects2Request = Schema.Object({
});

export const ServerFeaturesLftRequest = Schema.Object({
});

export const ServerFeaturesHashesRequest = Schema.Object({
});

export const ServerFeaturesVersioningRequest = Schema.Object({
});

export const ConnectionPingRequest = Schema.Object({
  ts: Schema.Optional(Timestamps),
});

export const ConnectionAuthRequest = Schema.Object({
  version: Version,
  client_capabilities: Schema.Optional(ConnectionClientCapabilities),
  username: Schema.Optional(Schema.String),
  password: Schema.Optional(Schema.String),
  token: Schema.Optional(Schema.String),
  ssoCookie: Schema.Optional(Schema.String),
});

export const ConnectionAuthorizeTokenRequest = Schema.Object({
  token: Schema.String,
  version: Version,
  client_capabilities: ConnectionClientCapabilities,
});

export const ConnectionSubscribeServerNotificationsRequest = Schema.Object({
});

export const ConnectionStat2Request = Schema.Object({
  path: PathAtVersion,
});

export const ConnectionListRequest = Schema.Object({
  uri: Schema.String,
  recursive: Schema.Boolean,
  show_hidden: Schema.Boolean,
  type: Schema.Optional(PathType),
});

export const ConnectionList2Request = Schema.Object({
  path: Schema.String,
  branches: Schema.Optional(Schema.Array(Schema.String)),
  path_types: Schema.Optional(Schema.Array(PathType)),
  show_hidden: Schema.Optional(Schema.Boolean),
});

export const ConnectionSubscribeListRequest = Schema.Object({
  path: PathAtBranch,
});

export const ConnectionCreateRequest = Schema.Object({
  uri: Schema.String,
  content: Schema.Optional(Schema.Stream),
  type: Schema.Optional(PathTypeCode),
  content_id: Schema.Optional(Schema.String),
  overwrite: Schema.Optional(Schema.Boolean),
});

export const ConnectionUpdateRequest = Schema.Object({
  uri: Schema.String,
  etag: Schema.Optional(Schema.String),
  delta: Schema.Optional(Schema.String),
  content: Schema.Optional(Schema.Stream),
  content_id: Schema.Optional(Schema.String),
  ts: Schema.Optional(Timestamps),
});

export const ConnectionCreateAssetRequest = Schema.Object({
  path: PathAtBranch,
  content: Schema.Optional(Schema.Stream),
  content_id: Schema.Optional(Schema.Number),
  overwrite: Schema.Optional(Schema.Boolean),
});

export const ConnectionUpdateAssetRequest = Schema.Object({
  path: PathAtBranch,
  etag: Schema.Optional(Schema.String),
  delta: Schema.Optional(Schema.String),
  content: Schema.Optional(Schema.Stream),
  content_id: Schema.Optional(Schema.Number),
  ts: Schema.Optional(Timestamps),
});

export const ConnectionCreateAssetWithHashRequest = Schema.Object({
  path: PathAtBranch,
  hash_value: Schema.String,
  hash_type: Schema.String,
  hash_bsize: Schema.Number,
  overwrite: Schema.Optional(Schema.Boolean),
});

export const ConnectionUpdateAssetWithHashRequest = Schema.Object({
  path: PathAtBranch,
  hash_value: Schema.String,
  hash_type: Schema.String,
  hash_bsize: Schema.Number,
  etag: Schema.Optional(Schema.String),
  delta: Schema.Optional(Schema.String),
  ts: Schema.Optional(Timestamps),
});

export const ConnectionCreateObjectRequest = Schema.Object({
  path: PathAtBranch,
  content: Schema.Optional(Schema.Stream),
  content_id: Schema.Optional(Schema.Number),
  overwrite: Schema.Optional(Schema.Boolean),
});

export const ConnectionUpdateObjectRequest = Schema.Object({
  path: PathAtBranch,
  object_id: Schema.Number,
  content: Schema.Optional(Schema.Stream),
  content_id: Schema.Optional(Schema.Number),
  ts: Schema.Optional(Timestamps),
});

export const ConnectionDeepCopyObjectStructRequest = Schema.Object({
  src_path: PathAtVersion,
  dst_path: PathAtBranch,
});

export const ConnectionReadRequest = Schema.Object({
  uri: Schema.String,
  etag: Schema.Optional(Schema.String),
});

export const ConnectionReadAssetVersionRequest = Schema.Object({
  path: PathAtVersion,
  etag: Schema.Optional(Schema.String),
});

export const ConnectionReadAssetResolvedRequest = Schema.Object({
  path: Schema.String,
  branches: Schema.Array(Schema.String),
});

export const ConnectionSubscribeReadAssetRequest = Schema.Object({
  path: PathAtBranch,
  etag: Schema.Optional(Schema.String),
});

export const ConnectionReadObjectVersionRequest = Schema.Object({
  path: PathAtVersion,
  sequence: Schema.Optional(Schema.Number),
});

export const ConnectionReadObjectResolvedRequest = Schema.Object({
  path: Schema.String,
  branches: Schema.Array(Schema.String),
});

export const ConnectionSubscribeReadObjectRequest = Schema.Object({
  path: PathAtBranch,
  object_id: Schema.Optional(Schema.Number),
  sequence: Schema.Optional(Schema.Number),
});

export const ConnectionRenameRequest = Schema.Object({
  source_and_destination: Schema.Array(SourceDestinationPair),
  branch: Schema.Optional(Schema.String),
});

export const ConnectionDeleteRequest = Schema.Object({
  uri: Schema.String,
});

export const ConnectionDelete2Request = Schema.Object({
  paths_to_delete: Schema.Array(PathAtVersion),
});

export const ConnectionCopy2Request = Schema.Object({
  paths_to_copy: Schema.Array(PathsToCopy),
});

export const ConnectionCreateDirectoryRequest = Schema.Object({
  path: PathAtBranch,
});

export const ConnectionLockRequest = Schema.Object({
  uri: Schema.String,
  etag: Schema.String,
  duration: Schema.Optional(Schema.Number),
  branch: Schema.Optional(Schema.String),
});

export const ConnectionUnlockRequest = Schema.Object({
  uri: Schema.String,
  force: Schema.Optional(Schema.Boolean),
  branch: Schema.Optional(Schema.String),
});

export const ConnectionCopyRequest = Schema.Object({
  uri: Schema.String,
  to: Schema.String,
  transaction_id: Schema.String,
});

export const ConnectionGetTransactionIdRequest = Schema.Object({
});

export const ConnectionSetPathOptionsRequest = Schema.Object({
  uri: Schema.String,
  created_by: Schema.Optional(Schema.String),
  modified_by: Schema.Optional(Schema.String),
  created: Schema.Optional(Schema.String),
  modified: Schema.Optional(Schema.String),
  branch: Schema.Optional(Schema.String),
  checkpoint: Schema.Optional(Schema.Number),
});

export const ConnectionSetPathOptions2Request = Schema.Object({
  path: Schema.String,
  created_by: Schema.Optional(Schema.String),
  modified_by: Schema.Optional(Schema.String),
  created: Schema.Optional(Schema.Number),
  modified: Schema.Optional(Schema.Number),
  branch: Schema.Optional(Schema.String),
  checkpoint: Schema.Optional(Schema.Number),
});

export const ConnectionGetAclRequest = Schema.Object({
  uri: Schema.String,
});

export const ConnectionChangeAclRequest = Schema.Object({
  uri: Schema.String,
  acl: ACL,
});

export const ConnectionGetAclV2Request = Schema.Object({
  paths: Schema.Array(PathAtVersion),
});

export const ConnectionGetAclResolvedRequest = Schema.Object({
  paths: Schema.Array(PathAtVersion),
});

export const ConnectionSetAclV2Request = Schema.Object({
  path_and_acls: Schema.Array(PathAtVersionACLPair),
});

export const ConnectionGetGroupsRequest = Schema.Object({
});

export const ConnectionGetGroupUsersRequest = Schema.Object({
  group_name: Schema.String,
});

export const ConnectionGetUsersRequest = Schema.Object({
});

export const ConnectionGetUserGroupsRequest = Schema.Object({
  username: Schema.String,
});

export const ConnectionCreateGroupRequest = Schema.Object({
  group_name: Schema.String,
});

export const ConnectionRenameGroupRequest = Schema.Object({
  group_name: Schema.String,
  new_group_name: Schema.String,
});

export const ConnectionRemoveGroupRequest = Schema.Object({
  group_name: Schema.String,
});

export const ConnectionAddUserToGroupRequest = Schema.Object({
  username: Schema.String,
  group_name: Schema.String,
});

export const ConnectionRemoveUserFromGroupRequest = Schema.Object({
  username: Schema.String,
  group_name: Schema.String,
});

export const ConnectionMountRequest = Schema.Object({
  uri: Schema.String,
  resolver: Schema.Optional(Schema.String),
  redirect_url: Schema.Optional(Schema.String),
  options: Schema.Optional(JSONString),
});

export const ConnectionUnmountRequest = Schema.Object({
  uri: Schema.String,
});

export const ConnectionGetMountInfoRequest = Schema.Object({
});

export const ConnectionCheckpointVersionRequest = Schema.Object({
  path: PathAtBranch,
  message: Schema.Optional(Schema.String),
  force: Schema.Optional(Schema.Boolean),
});

export const ConnectionReplaceVersionRequest = Schema.Object({
  src_path: PathAtVersion,
  dst_path: PathAtBranch,
  message: Schema.Optional(Schema.String),
});

export const ConnectionGetCheckpointsRequest = Schema.Object({
  path: PathAtBranch,
});

export const ConnectionGetBranchesRequest = Schema.Object({
  path: Schema.String,
});
