import styled from "styled-components";
import ButtonGroup from "./ButtonGroup";

const FormSubmitButtonGroup = styled(ButtonGroup)`
  justify-content: center;
  
  & > * {
    margin: 0 10px;
  }
`;

FormSubmitButtonGroup.displayName = "FormSubmitButtonGroup";

export default FormSubmitButtonGroup;
