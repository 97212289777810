import { StatusType } from "@omniverse/api/data";
import { ManageGroupsError } from "../../../util/GroupErrors";
import { Commands } from "../Provider";
import { IRenameGroupCommandAllowedArguments, IRenameGroupCommandArguments } from "../types/RenameGroupCommand";
import { NucleusCommand } from "./index";

export default class NucleusRenameGroupCommand extends NucleusCommand<
  IRenameGroupCommandArguments,
  IRenameGroupCommandAllowedArguments
> {
  public name = Commands.RenameGroup;

  public async allowed(): Promise<boolean> {
    return Boolean(this.provider.session.isSuperUser);
  }

  public async execute({ group, newName }: IRenameGroupCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Rename group ${group.name} to ${newName}`);
    const connection = await this.provider.getConnection();
    const result = await connection.renameGroup({ group_name: group.name, new_group_name: newName });
    if (result.status !== StatusType.OK) {
      throw new ManageGroupsError(result);
    }

    group.rename(newName);
  }
}
