import React, { useCallback, useContext } from "react";
import SortingIcon from "./SortingIcon";
import { TableHeaderComponentProps } from "./Table";
import { TableDivHeader } from "./TableDiv";
import { TableSorterContext } from "./TableSorter";

const TableSorterHeader: React.FC<TableHeaderComponentProps> = ({ column, onClick, children, ...props }) => {
  const context = useContext(TableSorterContext);

  const handleClick = useCallback(() => {
    onClick(column);
  }, [column, onClick]);

  return (
    <TableDivHeader {...props} column={column} onClick={handleClick}>
      {children}
      {column.name === context.sorting && <SortingIcon direction={context.direction} />}
    </TableDivHeader>
  );
};

export default TableSorterHeader;
