import { autorun, makeObservable, observable } from "mobx";
import { isElectron } from "../util/Electron";
import { joinURL } from "../util/Path";
import DownloadList from "./DownloadList";
import PreferenceStore from "./Preferences";
import StorageList from "./StorageList";

export interface ApplicationOptions {
  /**
   * Specifies the default base path used for all links.
   */
  basename?: string;

  /**
   * Specifies if it should add basename to react-router links
   * Should be true if used as embedded application
   * and router's basename doesn't match with Navigator basename
   */
  useBasenamePrefix?: boolean;
}

export interface ApplicationLoadOptions extends ApplicationOptions {
  /**
   * Defines a key used to store application state in the local storage.
   * "state" by default.
   */
  key?: string;
}

export interface ApplicationUseLocalStorageOptions {
  /**
   * Defines a key used to store application state in the local storage.
   * "state" by default.
   */
  key?: string;
}

class Application {
  public basename: string;
  public storages: StorageList;
  public preferences: PreferenceStore;
  public readonly downloads: DownloadList;
  public useBasenamePrefix: boolean;

  constructor({ basename = "", useBasenamePrefix = true }: ApplicationOptions = {}) {
    this.basename = basename;
    this.useBasenamePrefix = useBasenamePrefix;
    this.storages = new StorageList();
    this.preferences = new PreferenceStore();
    this.downloads = new DownloadList();

    makeObservable(this, {
      storages: observable,
      downloads: observable,
    });
  }

  public static async load({
    basename,
    useBasenamePrefix,
    key = "state",
  }: ApplicationLoadOptions = {}): Promise<Application> {
    const json = localStorage.getItem(key);
    const app = json
      ? Application.fromJSON(JSON.parse(json), { basename, useBasenamePrefix })
      : new Application({ basename, useBasenamePrefix });
    app.useLocalStorage();

    await app.init();
    return app;
  }

  public static fromJSON(data: any, options: ApplicationOptions = {}): Application {
    const app = new Application(options);
    app.storages = data.storages ? StorageList.fromJSON(data.storages) : new StorageList();
    app.preferences = data.preferences ? PreferenceStore.fromJSON(data.preferences) : new PreferenceStore();
    return app;
  }

  public async init(): Promise<void> {
    await Promise.all(Array.from(this.storages.map.values()).map((server) => server.init()));
  }

  public useLocalStorage({ key = "state" }: ApplicationUseLocalStorageOptions = {}): void {
    autorun(() => {
      const state = this.toJSON();
      const json = JSON.stringify(state);
      localStorage.setItem(key, json);
    });
  }

  /**
   * Creates a navigation link using the basename specified for the application.
   */
  public href(path: string, { absolute }: ApplicationHrefOptions = {}): string {
    if (isElectron()) {
      return path;
    }

    if (absolute) {
      return joinURL(window.location.origin, this.basename, path);
    }

    return this.useBasenamePrefix ? joinURL(this.basename, path) : path;
  }

  public toJSON() {
    return {
      basename: this.basename,
      storages: this.storages.toJSON(),
      preferences: this.preferences.toJSON(),
    };
  }
}

export interface ApplicationHrefOptions {
  absolute?: boolean;
}

export default Application;
