import styled from "styled-components";

const RightPanelPath = styled.div`
  padding: 5px 15px;
  font-size: 9pt;
  font-weight: 400;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.hint};
  word-break: break-all;
`;

export default RightPanelPath;
