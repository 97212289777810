import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import User from "../state/User";
import { ReactComponent as UserSVG } from "../static/group.svg";
import Checkbox from "./Checkbox";
import { TableCellComponentProps } from "./Table";
import { TableDivCell } from "./TableDiv";
import TableIcon from "./TableIcon";

const UserTableCell: React.FC<TableCellComponentProps<User>> = ({ column, children, row: user, ...props }) => {
  if (column.name === "name") {
    children = (
      <>
        <TableIcon src={UserSVG} />
        {user.name}
      </>
    );
  } else if (column.name === "admin") {
    children = (
      <Checkbox
        readOnly
        title={user.profile?.admin ? `${user.name} is an admin` : `${user.name} is not an admin`}
        checked={user.profile ? Boolean(user.profile.admin) : false}
      />
    );
  } else if (column.name === "nucleusRo") {
    children = (
      <Checkbox
        readOnly
        title={user.profile?.nucleusRo ? `${user.name} is read-only` : `${user.name} is not read-only`}
        checked={user.profile ? Boolean(user.profile.nucleusRo) : false}
      />
    );
  } else if (column.name === "disabled") {
    children = (
      <Checkbox
        readOnly
        title={user.profile?.enabled ? `${user.name} is enabled` : `${user.name} is disabled`}
        checked={user.profile ? Boolean(!user.profile.enabled) : false}
      />
    );
  } else {
    const profileFields: { [key: string]: string } = {
      firstName: user.profile?.firstName || "-",
      lastName: user.profile?.lastName || "-",
      email: user.profile?.email || "-",
      provider: user.profile?.provider || "Unknown",
    };

    children = <>{profileFields[column.name]}</>;
  }

  return (
    <StyledUserCell column={column} row={user} {...props}>
      {children}
    </StyledUserCell>
  );
};

const StyledUserCell = styled(TableDivCell)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: ${({ column }) => (column.name === "name" ? "0" : "15px")};
  overflow: hidden;
`;

export default observer(UserTableCell);
