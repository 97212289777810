import { action, computed, makeObservable, observable } from "mobx";
import Group from "./Group";
import Storage from "./Storage";

export interface Profile {
  firstName?: string;
  lastName?: string;
  email?: string;
  admin?: boolean;
  provider?: string;
  readonly?: boolean;
  nucleusRo?: boolean;
  enabled?: boolean;
  activated?: boolean;

  canChangePassword?: boolean;
}

class User {
  public profile: Profile | null;
  public groups: Group[];
  public loading: boolean;

  public constructor(public readonly name: string, public readonly storage: Storage, profile: Profile | null = null) {
    this.profile = profile;
    this.groups = [];
    this.loading = false;

    makeObservable(this, {
      profile: observable,
      groups: observable,
      loading: observable,
      groupNames: computed,
      canManagePassword: computed,
      setGroups: action.bound,
      addGroup: action.bound,
      removeGroup: action.bound,
      setProfile: action.bound,
      setLoading: action.bound,
      clear: action.bound,
    });
  }

  public get groupNames(): string[] {
    return this.groups.map((group) => group.name);
  }

  public get canManagePassword(): boolean {
    return Boolean(this.profile?.canChangePassword);
  }

  public setGroups(groups: Group[]): void {
    this.groups = groups;
  }

  public addGroup(group: Group): void {
    this.groups.push(group);
  }

  public removeGroup(group: Group): void {
    this.groups = this.groups.filter((g) => g.name !== group.name);
  }

  public setProfile(profile: Profile): void {
    this.profile = { ...this.profile, ...profile };
  }

  public setLoading(value: boolean): void {
    this.loading = value;
  }

  public clear(): void {
    this.groups = [];
  }
}

export default User;
