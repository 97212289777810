import React from "react";
import SelectionBox, { SelectionRect } from "./SelectionBox";
import { VirtualizedTableBodyComponentProps } from "./VirtualizedTable";

export interface VirtualizedTableSelectionBoxProps<TRow>
  extends Omit<VirtualizedTableBodyComponentProps<TRow>, "offsetTop"> {
  className?: string;
  style?: React.CSSProperties;
  onSelect(rows: TRow[]): void;
}

interface MemoizedVirtualizedSelection {
  [index: number]: boolean;
}

class VirtualizedTableSelectionBox<TRow> extends React.Component<VirtualizedTableSelectionBoxProps<TRow>> {
  private lastSelection: MemoizedVirtualizedSelection = {};

  public render() {
    const { className, style, children } = this.props;
    return (
      <SelectionBox className={className} style={style} onSelect={this.handleSelectionChange}>
        {children}
      </SelectionBox>
    );
  }

  protected handleSelectionChange = (box: SelectionRect) => {
    const { rowHeight, rows, onSelect } = this.props;

    if (!box) {
      onSelect([]);
      this.lastSelection = {};
      return;
    }

    const boxTop = box.top;
    const boxBottom = boxTop + box.height;

    const firstSelectedRow = Math.trunc(boxTop / rowHeight);
    const lastSelectedRow = Math.trunc(boxBottom / rowHeight);
    const selectedRowsIndexes = [];
    for (let row = firstSelectedRow; row <= lastSelectedRow; row++) {
      if (row < rows.length) {
        selectedRowsIndexes.push(row);
      }
    }

    const selection: MemoizedVirtualizedSelection = {};

    let selectionChanged = selectedRowsIndexes.length !== Object.keys(this.lastSelection).length;
    for (const row of selectedRowsIndexes) {
      if (!this.lastSelection[row]) {
        selectionChanged = true;
      }

      selection[row] = true;
    }

    this.lastSelection = selection;
    if (selectionChanged) {
      onSelect(selectedRowsIndexes.map((index) => rows[index]));
    }
  };
}

export default VirtualizedTableSelectionBox;
