import styled from "styled-components";
import Button from "./Button";


export interface ButtonGroupProps {
  placement?: "left" | "center" | "right" | "rows" | "columns";
}

const ButtonGroup = styled.div<ButtonGroupProps>`
  display: flex;
  flex-direction: ${({ placement = "center" }) => placement === "rows" ? "column" : "row"};
  justify-content: ${({ placement = "center" }) => justify[placement]};
  
  & > & {
    padding: 0;
  }
  
  & > ${Button} {
    margin: ${({ placement }) => placement === "rows" ? "0 0 0.5rem 0" : "0 0.5rem 0 0"};
    flex: ${({ placement }) => placement === "columns" ? "1" : "0 1 auto"};
  }
  
  & > ${Button}:last-child {
    margin: 0;
  }
`;

const justify = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
  rows: "flex-start",
  columns: "flex-start"
}

ButtonGroup.defaultProps = {
  placement: "center"
};

export default ButtonGroup;