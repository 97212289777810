import { ICommand } from "../Provider";
import Nucleus from "./Nucleus";

export abstract class NucleusCommand<Arguments = void, AllowedArguments = void, Result = void>
  implements ICommand<Arguments, AllowedArguments, Result> {

  public abstract name: string;

  public constructor(public readonly provider: Nucleus) {
  }

  public abstract allowed(args: AllowedArguments): Promise<boolean>;
  public abstract execute(args: Arguments): Promise<Result>;
}