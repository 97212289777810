import React from "react";
import useDialog from "../hooks/useDialog";
import usePermission from "../hooks/usePermission";
import { Commands } from "../state/commands/Provider";
import { ICreateUserCommand } from "../state/commands/types/CreateUserCommand";
import Users from "../state/Users";
import { Icons } from "./Icon";
import Loader from "./Loader";
import Menu from "./Menu";
import { MenuIconItem } from "./MenuItem";
import UserFormDialog from "./UserFormDialog";

export interface UserListMenuProps {
  users: Users;
}

const UserListMenu: React.FC<UserListMenuProps> = ({ users }) => {
  const userDialog = useDialog(UserFormDialog, { users });

  function addUser() {
    return userDialog.show();
  }

  const canAddUsers = usePermission(() => users.storage.commands.allowed<ICreateUserCommand>(Commands.CreateUser), [
    users,
  ]);

  const loading = canAddUsers === "loading";
  if (loading) {
    return (
      <Menu>
        <Loader />
      </Menu>
    )
  }

  const visible = canAddUsers === "allowed";
  if (!visible) {
    return null;
  }

  return (
    <Menu>
      <MenuIconItem icon={Icons.User} visible={canAddUsers === "allowed"} onClick={addUser}>
        Add User
      </MenuIconItem>
    </Menu>
  );
};

export default UserListMenu;
