import { action, computed, makeObservable, observable } from "mobx";
import Group from "./Group";
import Storage from "./Storage";

class Groups {
  public items: Group[];
  public loading: boolean;

  public constructor(public storage: Storage) {
    this.items = [];
    this.loading = false;

    makeObservable(this, {
      items: observable,
      loading: observable,
      names: computed,
      add: action.bound,
      set: action.bound,
      delete: action.bound,
    });
  }

  public get names(): string[] {
    return this.items.map((group) => group.name).sort();
  }

  public add(group: Group): void {
    this.items.push(group);
    this.items = this.items.sort((a, b) => a.name.localeCompare(b.name));
  }

  public set(groups: Group[]) {
    this.items = groups;
  }

  public delete(group: Group): void {
    this.items = this.items.filter((g) => g.name !== group.name);
  }
}

export default Groups;
