import { PathPermission } from "@omniverse/api/data";
import { action, computed, makeObservable, observable } from "mobx";
import Path from "./Path";
import Storage from "./Storage";

export type ResolvedACLEntry = [string, { acl: PathPermission[]; group?: string; path: string }];

export type ACLAtLevel = {
  acl: Set<PathPermission>;
  inheritedFromPath: string;
  inheritedFromGroup?: string;
};
export type ResolvedACLs = Map<string, ACLAtLevel>;

class ResolvedACL {
  public map: ResolvedACLs;
  public loading: boolean;

  constructor(public readonly path: Path, public readonly storage: Storage) {
    this.map = new Map();

    this.loading = false;

    makeObservable(this, {
      map: observable,
      loading: observable,
      keys: computed,
      entries: computed,

      set: action.bound,
    });
  }

  public get keys(): string[] {
    return Array.from(this.map.keys());
  }

  public get entries(): [string, ACLAtLevel][] {
    return Array.from(this.map.entries());
  }

  public set(acl: ResolvedACLEntry[]) {
    this.map.clear();
    for (const [userOrGroup, permissions] of acl) {
      this.map.set(userOrGroup, {
        acl: new Set(permissions.acl),
        inheritedFromGroup: permissions.group,
        inheritedFromPath: permissions.path,
      });
    }
  }
}

export default ResolvedACL;
