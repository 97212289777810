import React, { useCallback, useState } from "react";
import { Profile } from "../state/User";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import Form from "./Form";
import FormError from "./FormError";
import FormGroup from "./FormGroup";
import Input from "./Input";
import Label from "./Label";

export interface ProfileFormProps {
  initial?: Profile | null;
  loading?: boolean;
  readonly?: boolean;
  error?: string;
  onSubmit(profile: Profile): void;
  onCancel?(): void;
}

const ProfileForm: React.FC<ProfileFormProps> = ({ initial, readonly, loading, error, onSubmit, onCancel }) => {
  const [firstName, setFirstName] = useState((initial && initial.firstName) || "");
  const [lastName, setLastName] = useState((initial && initial.lastName) || "");
  const [email, setEmail] = useState((initial && initial.email) || "");

  const submit = useCallback(() => {
    onSubmit({
      firstName,
      lastName,
      email,
    });
  }, [firstName, lastName, email, onSubmit]);

  const cancel = useCallback(() => {
    setFirstName((initial && initial.firstName) || "");
    setLastName((initial && initial.lastName) || "");
    setEmail((initial && initial.email) || "");

    if (onCancel) {
      onCancel();
    }
  }, [initial, onCancel]);

  return (
    <Form>
      {error && <FormError>{error}</FormError>}
      <FormGroup>
        <Label>
          First name:{" "}
          <Input
            name={"firstName"}
            value={firstName}
            readOnly={readonly}
            disabled={loading}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Label>
      </FormGroup>

      <FormGroup>
        <Label>
          Last name:{" "}
          <Input
            name={"lastName"}
            value={lastName}
            readOnly={readonly}
            disabled={loading}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Label>
      </FormGroup>

      <FormGroup>
        <Label>
          Email:{" "}
          <Input
            name={"email"}
            value={email}
            readOnly={readonly}
            disabled={loading}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Label>
      </FormGroup>

      {!readonly && (
        <ButtonGroup>
          <Button name={"submit"} disabled={loading} onClick={submit}>
            Save
          </Button>
          <Button name={"cancel"} disabled={loading} onClick={cancel}>
            Cancel
          </Button>
        </ButtonGroup>
      )}
    </Form>
  );
};

export default ProfileForm;
