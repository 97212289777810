import { useContext } from "react";
import ApplicationContext from "../context/ApplicationContext";
import Application from "../state/Application";


const useApp = (): Application => {
  const context = useContext(ApplicationContext);
  return context.app;
};

export default useApp;