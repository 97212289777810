import styled from "styled-components";
import NvidiaLogoImage from "./files/nvidia-omniverse-logo.svg";

const NvidiaLogo = styled.img.attrs({
  src: NvidiaLogoImage
})`
  position: relative;
  z-index: 1;
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: 220px;
  margin: 25px 20px 25px 7px;
  pointer-events: none;
`;

export default NvidiaLogo;
