import React from "react";
import useDialogForm from "../hooks/useDialogForm";
import { Commands } from "../state/commands/Provider";
import { ISetUserAdminAccessCommand } from "../state/commands/types/SetUserAdminAccessCommand";
import User from "../state/User";
import { ReactComponent as UserSVG } from "../static/user.svg";
import ConfirmationDialog from "./ConfirmationDialog";
import FormErrorList from "./FormErrorList";
import ImportantText from "./ImportantText";

export interface UserGrantAdminAccessConfirmationProps {
  user: User;
}

const UserGrantAdminAccessConfirmation: React.FC<UserGrantAdminAccessConfirmationProps> = ({ user }) => {
  const form = useDialogForm({
    fields: {},
    onSubmit: grantAccess,
  });

  function grantAccess() {
    const command = user.storage.commands.get<ISetUserAdminAccessCommand>(Commands.SetUserAdminAccess);
    if (command) {
      return command.execute({ user, admin: true });
    } else {
      throw new Error("User management is not supported.");
    }
  }

  return (
    <ConfirmationDialog
      title={"Grant Admin Access"}
      loading={form.loading}
      image={UserSVG}
      onConfirm={form.submit}
      onCancel={form.cancel}
    >
      <FormErrorList errors={form.errors} />
      Are you sure you want to grant admin access for <br /> <ImportantText>{user.name}</ImportantText> account?
    </ConfirmationDialog>
  );
};

export default UserGrantAdminAccessConfirmation;
