import { OfflineModeError } from "../../../util/SessionErrors";
import { Commands } from "../Provider";
import { IGetAPITokensCommandArguments } from "../types/GetAPITokensCommand";
import { NucleusCommand } from "./index";
import { APIToken } from "../../APITokens";
import { AuthStatus } from "@omniverse/auth/data";

export default class NucleusGetAPITokensCommand extends NucleusCommand<
  IGetAPITokensCommandArguments,
  void,
  APIToken[]
> {
  name = Commands.GetAPITokens;

  public async allowed(): Promise<boolean> {
    return typeof this.provider.tokensCapabilities?.get_api_tokens === "number";
  }

  public async execute({ storage }: IGetAPITokensCommandArguments): Promise<APIToken[]> {
    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }
    storage.apiTokens.setLoading(true);
    let tokens;
    try {
      tokens = await this.provider.createTokensClient();
      const response = await tokens.getApiTokens({ access_token: this.provider.session.accessToken! });

      if (response.status === AuthStatus.Expired) {
        await this.provider.session.refresh();
        return this.execute({ storage });
      }

      if (response.status !== AuthStatus.OK) {
        throw new Error("Error in fetching API tokens");
      }

      storage.apiTokens.set(response.tokens!);
      return response.tokens!;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      storage.apiTokens.setLoading(false);
      await tokens?.transport.close();
    }
  }
}
