import React from "react";
import { StyledRenderer } from "./index";
import ResizableTableHeader from "./ResizableTableHeader";
import { TableColumn, TableHeaderComponentProps } from "./Table";

export interface TableColumnResizingProps {
  columns: TableColumn[];
  headerComponent?: StyledRenderer<TableHeaderComponentProps>;
  children(data: TableColumnResizingData): JSX.Element;
  onResize?(column: TableColumn, newWidth: number): void;
}

export interface TableColumnResizingState {
  columns: TableColumn[];
  sizedColumns: TableColumn[];
  columnsWidth: { [name: string]: number };
}

export interface TableColumnResizingData {
  columns: TableColumn[];
  headerComponent: StyledRenderer<TableHeaderComponentProps>;
}

export default class TableColumnResizing extends React.Component<TableColumnResizingProps, TableColumnResizingState> {
  public static getDerivedStateFromProps(props: TableColumnResizingProps, state: TableColumnResizingState) {
    const derivedState: Partial<TableColumnResizingState> = {};

    if (props.columns !== state.columns) {
      derivedState.columns = props.columns;
      derivedState.columnsWidth = props.columns.reduce(
        (mapping, column) => ({
          ...mapping,
          [column.name]: state.columnsWidth[column.name] || column.width,
        }),
        {}
      );
      derivedState.sizedColumns = props.columns.map((column) => {
        return { ...column, width: derivedState.columnsWidth![column.name] };
      });
    }
    return derivedState;
  }

  constructor(props: TableColumnResizingProps) {
    super(props);
    this.state = {
      columns: props.columns,
      sizedColumns: props.columns,
      columnsWidth: props.columns.reduce((mapping, column) => ({ ...mapping, [column.name]: column.width }), {}),
    };
  }

  public render() {
    return this.props.children({
      columns: this.state.sizedColumns,
      headerComponent: this.renderHeader,
    });
  }

  protected renderHeader = ({ column, index, onClick, children, style }: TableHeaderComponentProps) => {
    const Header = this.props.headerComponent;
    return (
      <ResizableTableHeader
        column={column}
        style={style}
        resizable={index !== this.props.columns.length - 1}
        onResize={this.resize}
      >
        {Header ? (
          <Header column={column} index={index} onClick={onClick} style={style}>
            {children}
          </Header>
        ) : (
          children
        )}
      </ResizableTableHeader>
    );
  };

  protected resize = (column: TableColumn, newWidth: number) => {
    this.setState(({ columnsWidth, sizedColumns }) => ({
      columnsWidth: { ...columnsWidth, [column.name]: newWidth },
      sizedColumns: sizedColumns.map((sizedColumn) =>
        sizedColumn.name === column.name
          ? {
              ...sizedColumn,
              width: newWidth,
            }
          : sizedColumn
      ),
    }));

    if (this.props.onResize) {
      this.props.onResize(column, newWidth);
    }
  };
}
