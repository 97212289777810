import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AutoComplete, { AutoCompleteProps } from "./AutoComplete";
import { StyledRenderer } from "./index";
import { DefaultTheme } from "./Theme";

export interface BreadcrumbsProps {
  className?: string;
  readonly?: boolean;
  input?: StyledRenderer<AutoCompleteProps>;
  autoCompleteProps?: Partial<AutoCompleteProps>;
  onFocus?(): string;
  onChange?(newValue: string): void;
}

enum BreadcrumbsMode {
  view,
  editor,
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  className,
  readonly,
  autoCompleteProps = {},
  input: StyledInput = BreadcrumbAutoComplete,
  children,
  onChange,
  onFocus,
  ...props
}) => {
  const [mode, setMode] = useState(BreadcrumbsMode.view);
  const [value, setValue] = useState("");

  const input = useRef<HTMLInputElement>(null);
  const handleFocus = useCallback(() => {
    if (!readonly) {
      if (onFocus) {
        setValue(onFocus());
      }
      setMode(BreadcrumbsMode.editor);
    }
  }, [readonly, onFocus]);

  const submit = useCallback(() => {
    setMode(BreadcrumbsMode.view);
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      submit();
      if (autoCompleteProps.onBlur) {
        autoCompleteProps.onBlur(e);
      }
    },
    [autoCompleteProps, submit]
  );

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        submit();
      } else if (["Escape", "Esc"].includes(e.key)) {
        setMode(BreadcrumbsMode.view);
      }

      if (autoCompleteProps.onKeyUp) {
        autoCompleteProps.onKeyUp(e);
      }
    },
    [submit, autoCompleteProps]
  );

  const handleChange = useCallback(
    (value: string) => {
      if (autoCompleteProps.onChange) {
        autoCompleteProps.onChange(value);
      }
      setValue(value);
    },
    [autoCompleteProps, setValue]
  );

  useEffect(() => {
    if (input.current) {
      input.current.focus();
      input.current.select();
    }
  }, [mode, input]);

  useEffect(() => {
    if (readonly && mode === BreadcrumbsMode.editor) {
      setMode(BreadcrumbsMode.view);
    }
  }, [readonly, mode]);

  if (mode === BreadcrumbsMode.view) {
    const length = Array.isArray(children) ? children.length : 1;
    return (
      <Container {...props} className={className} onClick={handleFocus}>
        {children &&
          React.Children.map(children, (breadcrumb, index) => {
            return (
              <React.Fragment key={index}>
                {breadcrumb}
                {index !== length - 1 && <BreadcrumbSeparator />}
              </React.Fragment>
            );
          })}
      </Container>
    );
  }

  if (mode === BreadcrumbsMode.editor) {
    return (
      <StyledInput
        ref={input}
        {...autoCompleteProps}
        value={value}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
      />
    );
  }

  return null;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: text;
`;

export const BreadcrumbSeparator = styled.span.attrs({
  children: "/",
})`
  display: inline-block;
  margin: 0 1px;
  color: ${({ theme }) => theme.colors.foreground};
  font-size: 16pt;
`;
BreadcrumbSeparator.defaultProps = {
  theme: DefaultTheme,
};

export const BreadcrumbAutoComplete = styled(AutoComplete)`
  font-weight: 600;
`;

export default Breadcrumbs;
