import { observer } from "mobx-react";
import React from "react";
import useAppNavigate from "../hooks/useAppNavigate";
import useCwd from "../hooks/useCwd";
import useDialog from "../hooks/useDialog";
import usePermission from "../hooks/usePermission";
import useStorageList from "../hooks/useStorageList";
import { Commands } from "../state/commands/Provider";
import { ICreateFolderAllowedArguments, ICreateFolderCommand } from "../state/commands/types/CreateFolderCommand";
import { IGetAPITokensCommand } from "../state/commands/types/GetAPITokensCommand";
import Storage from "../state/Storage";
import APITokensDialog from "./APITokensDialog";
import { Icons } from "./Icon";
import Loader from "./Loader";
import Menu from "./Menu";
import { MenuIconItem, MenuItemDivider } from "./MenuItem";
import NewFolderDialog from "./NewFolderDialog";
import NucleusAuthDialog from "./NucleusAuthDialog";

export interface ServerMenuProps {
  storage: Storage;
}

const ServerMenu: React.FC<ServerMenuProps> = ({ storage }) => {
  const navigate = useAppNavigate();
  const cwd = useCwd();
  const storages = useStorageList();
  const authentication = useDialog(NucleusAuthDialog, { serverName: storage.publicName });
  const newFolderDialog = useDialog(NewFolderDialog, { path: storage.root });
  const apiTokensDialog = useDialog(APITokensDialog, { storage: storage });

  function createNewFolder() {
    newFolderDialog.show();
  }

  function showAPITokens() {
    apiTokensDialog.show();
  }

  function connect() {
    authentication.show();
  }

  function disconnect() {
    storage.session.disconnect();
    storage.close();
  }

  function clearCache() {
    storage.clear();
  }

  function removeServer() {
    if (storages.readOnly) {
      return;
    }

    if (cwd?.storage === storage) {
      navigate("/");
    }
    storages.remove(storage);
    storage.close();
  }

  const canCreateNewFolder = usePermission(
    () =>
      storage.commands.allowed<ICreateFolderCommand, ICreateFolderAllowedArguments>(Commands.CreateFolder, {
        path: storage.root,
      }),
    [storage.root]
  );

  const canGetAPITokens = usePermission(
    () => storage.commands.allowed<IGetAPITokensCommand>(Commands.GetAPITokens),
    [storage]
  );

  const loading = canCreateNewFolder === "loading" || canGetAPITokens === "loading";
  if (loading) {
    return (
      <Menu>
        <Loader />
      </Menu>
    );
  }

  return (
    <Menu>
      <MenuIconItem
        icon={Icons.FolderPlus}
        visible={storage.session.established && canCreateNewFolder === "allowed"}
        onClick={createNewFolder}
      >
        New Folder
      </MenuIconItem>

      <MenuItemDivider visible={storage.session.established && canCreateNewFolder === "allowed"} />

      <MenuIconItem
        icon={Icons.Key}
        visible={storage.session.established && canGetAPITokens === "allowed"}
        onClick={showAPITokens}
      >
        API Tokens
      </MenuIconItem>

      <MenuItemDivider visible={storage.session.established && canGetAPITokens === "allowed"} />

      <MenuIconItem icon={Icons.User} visible={!storage.session.established} onClick={connect}>
        Log In
      </MenuIconItem>

      <MenuIconItem icon={Icons.User} visible={storage.session.established} onClick={disconnect}>
        Log Out
      </MenuIconItem>

      <MenuIconItem icon={Icons.History} onClick={clearCache}>
        Clear Cached Folders
      </MenuIconItem>

      <MenuItemDivider />

      <MenuIconItem icon={Icons.Remove} visible={!storages.readOnly} onClick={removeServer}>
        Remove Server
      </MenuIconItem>
    </Menu>
  );
};

export default observer(ServerMenu);
