import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import Page from "./Page";
import { DefaultTheme } from "./Theme";

export interface ErrorPageProps {
  title?: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ title, children }) => {
  return (
    <Page title={title}>
      <ErrorMessage>
        <ErrorIcon icon={"fa fa-exclamation-triangle"} />
        {children}
      </ErrorMessage>
    </Page>
  );
};

const ErrorIcon = styled(Icon)`
  display: block;
  font-size: 40pt;
  color: ${({ theme }) => theme.colors.dangerText};
`;
ErrorIcon.defaultProps = {
  theme: DefaultTheme,
};

const ErrorMessage = styled.div`
  display: block;
  max-width: 400px;
  padding: 1rem;
  box-sizing: border-box;
  margin: 1rem auto;
  text-align: center;
  color: ${({ theme }) => theme.colors.foreground};
`;
ErrorMessage.defaultProps = {
  theme: DefaultTheme,
};

export default ErrorPage;
