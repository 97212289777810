import { AuthenticationResult } from "@omniverse/auth/react/AuthForm";
import RegistrationForm from "@omniverse/auth/react/RegistrationForm";
import React from "react";
import useNucleusSignIn from "../hooks/useNucleusSignIn";

export interface NucleusRegistrationFormProps {
  className?: string;
  extras?: Record<string, string>;
  server: string;
  onSuccess(result: AuthenticationResult): void;
  onCancel(): void;
}

const NucleusRegistrationForm: React.FC<NucleusRegistrationFormProps> = ({
  className,
  extras,
  server,
  onSuccess,
  onCancel,
}) => {
  const register = useNucleusSignIn({ onSuccess });
  return (
    <RegistrationForm className={className} server={server} extras={extras} onSuccess={register} onCancel={onCancel} />
  );
};

export default NucleusRegistrationForm;
