import styled from "styled-components";
import OmniverseLogoImage from "./files/omniverse-logo.png";

const OmniverseLogo = styled.img.attrs({
  src: OmniverseLogoImage
})`
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: 222px;
  pointer-events: none;
  z-index: 0;
`;

export default OmniverseLogo;
