import { StatusType } from "@omniverse/api/data";
import { ManageGroupsError } from "../../../util/GroupErrors";
import Group from "../../Group";
import { Commands } from "../Provider";
import { ICreateGroupCommandArguments } from "../types/CreateGroupCommand";
import { NucleusCommand } from "./index";
import { NucleusConnection } from "./Nucleus";

export default class NucleusCreateGroupCommand extends NucleusCommand<ICreateGroupCommandArguments> {
  public name = Commands.CreateGroup;

  public async allowed(): Promise<boolean> {
    return Boolean(this.provider.session.isSuperUser);
  }

  public async execute({ groups, name }: ICreateGroupCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Create group "${name}"`);
    const connection: NucleusConnection = await this.provider.getConnection();
    const result = await connection.createGroup({ group_name: name });
    if (result.status !== StatusType.OK) {
      throw new ManageGroupsError(result);
    }

    groups.add(new Group(name, groups.storage));
  }
}
