import { observer } from "mobx-react";
import React from "react";
import usePermission from "../hooks/usePermission";
import { Commands } from "../state/commands/Provider";
import { ICreateGroupCommand } from "../state/commands/types/CreateGroupCommand";
import Group from "../state/Group";
import Groups from "../state/Groups";
import { ContentBrowserView } from "./ContentBrowserViewToggle";
import GroupGrid from "./GroupGrid";
import GroupTable from "./GroupTable";

export interface GroupListContentProps {
  view: ContentBrowserView;
  groups: Groups;
  selectedGroup?: Group | null;
  onSelect(group: Group | null): void;
}

const GroupListContent: React.FC<GroupListContentProps> = ({ view, groups, selectedGroup, onSelect }) => {
  const canAddGroup = usePermission(() => groups.storage.commands.allowed<ICreateGroupCommand>(Commands.CreateGroup), [
    groups,
  ]);

  if (view === ContentBrowserView.Grid) {
    return (
      <GroupGrid
        groups={groups}
        selectedGroup={selectedGroup}
        canAddGroup={canAddGroup === "allowed"}
        onSelect={onSelect}
      />
    );
  }

  if (view === ContentBrowserView.Table) {
    return <GroupTable groups={groups} selectedGroup={selectedGroup} onSelect={onSelect} />;
  }

  return null;
};

export default observer(GroupListContent);
