import { observer } from "mobx-react";
import styled from "styled-components";
import Layout from "./Layout";

export interface ContentProps {
  column?: string;
}

const Content = styled(Layout.Content)<ContentProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  outline: none;
  grid-column: ${({ column }) => column};
`;

export default observer(Content);