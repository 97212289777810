import { observer } from "mobx-react";
import React, { useState } from "react";
import styled from "styled-components";
import useError from "../hooks/useError";
import useQuery from "../hooks/useQuery";
import { TagCommands } from "../state/commands/Provider";
import { IGetTagsCommand } from "../state/commands/types/tags/GetTagsCommand";
import { IGetTagSuggestionsCommand } from "../state/commands/types/tags/GetTagSuggestionsCommand";
import Tags from "../state/Tags";
import AdvancedTagEditor from "./AdvancedTagEditor";
import Checkbox from "./Checkbox";
import ErrorMessage from "./ErrorMessage";
import Label from "./Label";
import TagEditor from "./TagEditor";

export interface TagListProps {
  tags: Tags;
  readOnly?: boolean;
}

const TagList: React.FC<TagListProps> = ({ tags, readOnly = false }) => {
  const [advanced, setAdvanced] = useState(false);

  function toggleAdvancedMode(e: React.ChangeEvent<HTMLInputElement>) {
    setAdvanced(e.target.checked);
  }

  const [error, setError, clearError] = useError();

  useQuery(
    async () => {
      const getTags = tags.storage.commands.get<IGetTagsCommand>(TagCommands.Get);
      if (getTags) {
        return getTags.execute({ tags });
      } else {
        throw new Error("Tags are not supported.");
      }
    },
    {
      keys: [tags],
      onSubmit: clearError,
      onError: setError,
    }
  );

  useQuery(
    async () => {
      const getSuggestions = tags.storage.commands.get<IGetTagSuggestionsCommand>(TagCommands.GetSuggestions);
      if (getSuggestions) {
        return getSuggestions.execute({ tags });
      } else {
        throw new Error("Tags are not supported.");
      }
    },
    {
      keys: [tags],
      onSubmit: clearError,
      onError: setError,
    }
  );

  return (
    <>
      <TagModeControl>
        <TagModeToggle name={"advanced-view"} checked={advanced} onChange={toggleAdvancedMode} />
        Advanced view
      </TagModeControl>

      {advanced ? <AdvancedTagEditor tags={tags} readOnly={readOnly} /> : <TagEditor tags={tags} readOnly={readOnly} />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

const TagModeControl = styled(Label)`
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.foreground};
`;

export const TagModeToggle = styled(Checkbox)`
  width: 12px;
`;

export default observer(TagList);
