import React from "react";
import styled from "styled-components";
import LogoImage from "../static/logo.png";
import { isElectron } from "../util/Electron";
import Layout from "./Layout";
import { DefaultTheme } from "./Theme";

export interface HeaderProps {
  logo?: React.ReactNode;
  titleBar?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ logo, children, ...props }) => {
  if (typeof logo === "undefined" && !isElectron()) {
    logo = <HeaderLogo />;
  }

  return (
    <StyledHeader {...props}>
      {logo}
      {children}
    </StyledHeader>
  );
};

const StyledHeader = styled(Layout.Header)`
  display: flex;
  padding: 10px 15px;
  height: 50px;
  background: ${({ theme }) => theme.colors.headerBackground};
  color: ${({ theme }) => theme.colors.headerForeground};
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  box-sizing: border-box;
`;
StyledHeader.defaultProps = {
  theme: DefaultTheme,
};

export const HeaderLogo = styled.img.attrs({
  src: LogoImage,
})`
  margin-right: 10px;
  height: 30px;
  user-select: none;
  pointer-events: none;
`;

export default Header;
