import React from "react";
import useDialog from "../hooks/useDialog";
import usePermission from "../hooks/usePermission";
import { Commands } from "../state/commands/Provider";
import { ICreateFolderAllowedArguments, ICreateFolderCommand } from "../state/commands/types/CreateFolderCommand";
import { IMountCommand } from "../state/commands/types/MountCommand";
import { IUploadCommand, IUploadCommandAllowedArguments } from "../state/commands/types/UploadCommand";
import Path from "../state/Path";
import { Icons } from "./Icon";
import Loader from "./Loader";
import Menu from "./Menu";
import { MenuIconItem, MenuItemDivider } from "./MenuItem";
import MountDialog from "./MountDialog";
import NewFolderDialog from "./NewFolderDialog";
import PathCopyDialog from "./PathCopyDialog";
import PathUploadDialog from "./PathUploadDialog";

interface CWDMenuProps {
  path: Path;
}

const CWDMenu: React.FC<CWDMenuProps> = ({ path }) => {
  const newFolderDialog = useDialog(NewFolderDialog, { path });
  const newMountDialog = useDialog(MountDialog, { path });
  const pathCopyDialog = useDialog(PathCopyDialog, { destination: path });
  const pathUploadDialog = useDialog(PathUploadDialog, { path });

  function newFolder() {
    newFolderDialog.show();
  }

  function newMount() {
    newMountDialog.show();
  }

  function paste() {
    pathCopyDialog.show();
  }

  function uploadFile() {
    pathUploadDialog.show({ type: "file" });
  }

  function uploadFolder() {
    pathUploadDialog.show({ type: "directory" });
  }

  const canCreateFolders = usePermission(
    () =>
      path.storage.commands.allowed<ICreateFolderCommand, ICreateFolderAllowedArguments>(Commands.CreateFolder, {
        path,
      }),
    [path]
  );

  const canCreateMounts = usePermission(() => path.storage.commands.allowed<IMountCommand>(Commands.Mount), [path]);
  const canPaste = usePermission(() => path.storage.clipboard.canPaste(path), [path]);
  const canUpload = usePermission(
    () =>
      path.storage.commands.allowed<IUploadCommand, IUploadCommandAllowedArguments>(Commands.Upload, {
        destination: path,
      }),
    [path]
  );

  const loading =
    canCreateFolders === "loading" ||
    canCreateMounts === "loading" ||
    canUpload === "loading" ||
    canPaste === "loading";

  if (loading) {
    return (
      <Menu>
        <Loader />
      </Menu>
    );
  }

  const visible = (
    canCreateFolders === "allowed" ||
    canCreateMounts === "allowed" ||
    canUpload === "allowed" ||
    canPaste === "allowed"
  );
  if (!visible) {
    return null;
  }

  return (
    <Menu>
      <MenuIconItem icon={Icons.FolderPlus} visible={canCreateFolders === "allowed"} onClick={newFolder}>
        New Folder
      </MenuIconItem>

      <MenuIconItem icon={Icons.Mount} visible={canCreateMounts === "allowed"} onClick={newMount}>
        New Mount
      </MenuIconItem>

      <MenuItemDivider />

      <MenuIconItem icon={Icons.Paste} visible={canPaste === "allowed"} onClick={paste}>
        Paste
      </MenuIconItem>

      <MenuIconItem icon={Icons.Upload} visible={canUpload === "allowed"} onClick={uploadFile}>
        Upload Files
      </MenuIconItem>

      <MenuIconItem icon={Icons.Upload} visible={canUpload === "allowed"} onClick={uploadFolder}>
        Upload Folder
      </MenuIconItem>
    </Menu>
  );
};

export default CWDMenu;
