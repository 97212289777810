import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import usePathDropSource from "../hooks/usePathDropSource";
import Path, { PathType } from "../state/Path";
import { acceptedImageFormats } from "../util/Image";
import DropArea, {
  DropAreaArrow,
  DropAreaPreview,
  DropAreaPreviewDeleteIcon,
  DropAreaPreviews,
  DropAreaThumbnail,
} from "./DropArea";

export interface SearchDropAreaProps {
  image: File | Path | null;
  onDrop(image: File | Path | null): void;
  onError(error: string): void;
}

const SearchDropArea: React.FC<SearchDropAreaProps> = ({ image, onDrop, onError }) => {
  const { onMouseEnter, onMouseLeave, onMouseUp } = usePathDropSource(
    ([path]) => onDrop(path),
    ([path, ...rest]) => path?.type === PathType.File && rest.length === 0
  );

  const drop = useCallback(
    (files: File[]) => {
      onDrop(files[0]);
    },
    [onDrop]
  );

  const deleteImage = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onDrop(null);
    },
    [onDrop]
  );

  const files = useMemo(() => (image instanceof File ? [image] : []), [image]);

  const placeholder =
    image instanceof Path ? (
      <DropAreaPreviews>
        <DropAreaPreview>
          <DropAreaPreviewDeleteIcon onClick={deleteImage} />
          <DropAreaThumbnail src={image.generatedThumbnail} alt={image.name} />
        </DropAreaPreview>
      </DropAreaPreviews>
    ) : (
      <>
        <DropAreaArrow />
        Drag and drop an image here, <br /> or click to select an image
      </>
    );

  return (
    <StyledSearchDropArea onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onMouseUp={onMouseUp}>
      <DropArea
        accept={acceptedImageFormats}
        files={files}
        placeholder={placeholder}
        maxFiles={1}
        replace
        onDrop={drop}
        onError={onError}
      />
    </StyledSearchDropArea>
  );
};

const StyledSearchDropArea = styled.div`
  grid-column: 1 / 3;
`;

export default SearchDropArea;
