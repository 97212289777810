import { ModifyTagsResultPath, StatusCode, Tag as NucleusTag } from "@omniverse/tagging/data";
import { Tag } from "../../../Tags";

export function parseNucleusTag(data: NucleusTag): Tag {
  return new Tag(data.name!, data.tag_namespace, data.value);
}

export function convertTagToNucleus(tag: Tag): NucleusTag {
  return {
    name: tag.name,
    tag_namespace: tag.namespace ?? "",
    value: tag.value,
  };
}

export function ensureNucleusTagResults(result: ModifyTagsResultPath, tags: Tag[]) {
  for (let index = 0; index < result.status.length; index++) {
    const status = result.status[index];
    if (status !== StatusCode.Ok) {
      const tag = tags[index];
      if (tag) {
        throw new Error(`Cannot set tag ${tag.fullName} -- ${status}.`);
      }
    }
  }
}
