import { useMemo } from "react";
// eslint-disable-next-line no-restricted-imports
import { useLocation } from "react-router-dom";
import useApp from "./useApp";

export default function useAppLocation() {
  const app = useApp();
  const location = useLocation();
  const pathStripRegexp = useMemo(() => {
    let basename = app.basename;
    if (basename.endsWith('/')) {
      basename = basename.slice(0, basename.length - 1)
    }
    return new RegExp(`^${basename}`)
  }, [app.basename]);

  // useLocation should be a stable object, so since we want to change it's contents
  // we need to ensure that we produce a stable object as well to maintain API interoperability
  return useMemo(() => {
    // We strip off the basename prefix (which we ensure will not end in `/`) but then ensure that if
    // the current pathname was equal to the basename, we fallback to `/`
    const pathname = location.pathname.replace(pathStripRegexp, '') || '/';

    return {
      ...location,
      pathname,
    };
  }, [location, pathStripRegexp])
}
