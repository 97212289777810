import React, { useCallback, useEffect, useState } from "react";

export interface PictureProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  src?: PictureSource;
  alt?: string;
  fallbacks?: Array<PictureSource | undefined>;
}

export type PictureSource = string | React.ComponentType<PictureComponentProps>;

export interface PictureComponentProps {
  className?: string;
}

const Picture: React.FC<PictureProps> = ({ src, alt = "", fallbacks = [], ...props }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const fallbackCount = fallbacks?.length ?? 0;

  useEffect(() => {
    setCurrentIndex(0);
  }, [src, fallbackCount]);

  const handleError = useCallback(() => {
    const images = [src, ...fallbacks].filter(image => Boolean(image));
    for (let index = currentIndex + 1; index < images.length; index++) {
      const image = images[index];
      if (image) {
        setCurrentIndex(index);
        return;
      }
    }
  }, [currentIndex, src, fallbacks]);

  const images = [src, ...fallbacks].filter(image => Boolean(image));
  const currentImage = images[currentIndex];

  if (!currentImage || typeof currentImage === "string") {
    return (
      <img
        src={currentImage as string}
        alt={alt}
        {...props}
        onError={handleError}
      />
    );
  }

  const Image = currentImage;
  return <Image {...props} />;
};

export default Picture;
