import { StatusType } from "@omniverse/api/data";
import { Checkpoint } from "../../Checkpoints";
import { PathType } from "../../Path";
import { Commands } from "../Provider";
import {
  IGetCheckpointsCommandAllowedArguments,
  IGetCheckpointsCommandArguments,
} from "../types/GetCheckpointsCommand";
import { NucleusCommand } from "./index";

export default class NucleusGetCheckpointsCommand extends NucleusCommand<
  IGetCheckpointsCommandArguments,
  IGetCheckpointsCommandAllowedArguments,
  Checkpoint[]
> {
  name = Commands.GetCheckpoints;

  public async allowed({ path, checkpoints }: IGetCheckpointsCommandAllowedArguments): Promise<boolean> {
    return this.provider.supportsVersioning && path.type === PathType.File && !path.mounted;
  }

  public async execute({ path, checkpoints }: IGetCheckpointsCommandArguments): Promise<Checkpoint[]> {
    console.log(`[${this.provider.name}] Get checkpoints for ${path.path}`);

    checkpoints.setLoading(true);
    try {
      const connection = await this.provider.getConnection();
      const response = await connection.getCheckpoints({ path: { path: path.path } });
      if (response.status !== StatusType.OK) {
        throw new Error(`Failed to download checkpoints from the server: ${response.status}.`);
      }

      const result = await Promise.all(
        response.checkpoints.map(async (checkpointData) => {
          const stat = await connection.stat2({ path: { path: path.path, checkpoint: checkpointData.checkpoint_id } });
          const checkpoint: Checkpoint = {
            id: checkpointData.checkpoint_id!,
            message: checkpointData.message,
            date: new Date(stat.created_date_seconds! * 1000),
            size: stat.size,
            author: stat.created_by,
          };

          return checkpoint;
        })
      );
      checkpoints.setCheckpoints(result);
      return result;
    } finally {
      checkpoints.setLoading(false);
    }
  }
}
