import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import usePreference from "../hooks/usePreference";
import useStorageList from "../hooks/useStorageList";
import PathUpload from "../state/PathUpload";
import { Preferences } from "../state/Preferences";
import { Icons } from "./Icon";
import Notification from "./Notification";
import PathUploadProgress from "./PathUploadProgress";

export interface PathUploadNotificationProps {}

const PathUploadNotification: React.FC<PathUploadNotificationProps> = () => {
  const servers = useStorageList();
  const [visible, setVisible] = usePreference<boolean>(Preferences.UploadsVisible, false);
  const [minimized, setMinimized] = useState(false);

  const show = useCallback(() => {
    setVisible(true);
    setMinimized(false);
  }, [setVisible]);

  const hide = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const toggle = useCallback(() => {
    setMinimized((value) => !value);
  }, []);

  const closeUpload = useCallback((upload: PathUpload) => {
    upload.storage.uploads.remove(upload);
  }, []);

  const abortUpload = useCallback((upload: PathUpload) => {
    upload.abort();
  }, []);

  const uploads: PathUpload[] = servers.items.map((server) => Array.from(server.uploads.items.values())).flat();

  useEffect(() => {
    if (uploads.length > 0) {
      show();
    }
  }, [uploads.length, show]);

  if (!visible) {
    return null;
  }

  return (
    <Notification title={"Upload"} minimized={minimized} icon={Icons.Upload} onClose={hide} onToggle={toggle}>
      {uploads.length > 0 ? (
        uploads.map((upload) => (
          <PathUploadProgress
            key={upload.destination.path}
            upload={upload}
            onClose={closeUpload}
            onAbort={abortUpload}
          />
        ))
      ) : (
        <PathUploadPlaceholder />
      )}
    </Notification>
  );
};

const PathUploadPlaceholder = () => {
  return <StyledPathUploadPlaceholder>No uploads.</StyledPathUploadPlaceholder>;
};

const StyledPathUploadPlaceholder = styled.div`
  color: ${({ theme }) => theme.colors.foreground};
  font-size: 10pt;
  padding: 5px 5px 0 5px;
`;

export default observer(PathUploadNotification);
