import { toBase64 } from "../../../util/File";
import { getName, getParent, join } from "../../../util/Path";
import { OfflineModeError } from "../../../util/SessionErrors";
import { ILinkGenerator, ILinkParams } from "../Provider";
import Nucleus from "./Nucleus";

class NucleusLinkGenerator implements ILinkGenerator {
  public static readonly prefix: string = "omniverse://";
  public readonly userThumbnailPattern = /(.*)\/\.thumbs\/256x256\/(.*)\.png/;
  public readonly generatedThumbnailPattern = /(.*)\/\.thumbs\/256x256\/(.*)\.auto\.png/;

  public readonly provider: Nucleus;
  public initialized: boolean;
  public lftAddress: string;

  // Indicate for command that it can use path2 method on LFT server
  public isCheckpointPath2Supported: boolean = false;

  public constructor(provider: Nucleus) {
    this.provider = provider;
    this.initialized = false;
    this.lftAddress = "";
  }

  public async init(): Promise<void> {
    if (this.initialized) {
      return;
    }

    const connection = await this.provider.getConnection();
    this.lftAddress = connection.lftAddress;
    if (!this.lftAddress) {
      throw new Error("Server is not configured for file transferring.");
    }
    this.isCheckpointPath2Supported = await this.getIsPath2Supported();

    this.initialized = true;
  }

  public async getIsPath2Supported(): Promise<boolean> {
    // If path2 method doesn't exist then error would 404,
    // we pass not parameters and method exist it would return 400
    const res = await fetch(`${this.lftAddress}/path2/`);
    return res.status !== 404;
  }

  public async createCheckpointDownloadLink(path: string, checkpoint: number): Promise<string> {
    if (!this.lftAddress) {
      return "";
    }

    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }

    const query = new URLSearchParams();
    query.set("path", await toBase64(new Blob([path])));
    query.set("token", this.provider.session.accessToken!);
    query.set("iat", new Date().getTime().toString());
    query.set("checkpoint", String(checkpoint));

    const endpoint = `${this.lftAddress}/path2/`;
    return `${endpoint}?${query}`;
  }

  public async createDownloadLink({ path, storage }: ILinkParams): Promise<string> {
    if (!this.lftAddress) {
      return "";
    }

    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }

    const query = new URLSearchParams();
    query.set("path", await toBase64(new Blob([path])));
    query.set("token", this.provider.session.accessToken!);
    query.set("iat", new Date().getTime().toString());

    const endpoint = path.endsWith("/") ? `${this.lftAddress}/path/zip/` : `${this.lftAddress}/path/`;
    return `${endpoint}?${query}`;
  }

  public async createUploadLink({ path }: ILinkParams): Promise<string> {
    if (!this.lftAddress) {
      return "";
    }

    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }

    const query = new URLSearchParams();
    query.set("path", await toBase64(new Blob([path])));
    query.set("token", this.provider.session.accessToken!);

    return `${this.lftAddress}/path/bulk/?${query}`;
  }

  public async createThumbnailLink({ path, storage }: ILinkParams): Promise<string> {
    const parent = getParent(path);
    const name = getName(path);
    const thumbnailName = `${name}.png`;
    return this.createDownloadLink({ path: join(this.getThumbnailFolder(parent), thumbnailName), storage });
  }

  public async createGeneratedThumbnailLink({ path, storage }: ILinkParams): Promise<string> {
    const parent = getParent(path);
    const name = getName(path);
    const thumbnailName = `${name}.auto.png`;
    return this.createDownloadLink({ path: join(this.getThumbnailFolder(parent), thumbnailName), storage });
  }

  public getThumbnailFolder(path: string): string {
    return join(path, ".thumbs", "256x256");
  }

  public parseLink(link: string): ILinkParams | null {
    const regex = /omniverse:\/\/(.*?)(\/.*)/;
    const match = regex.exec(link);
    if (match) {
      const storage = match[1];
      const path = match[2];
      return {
        storage,
        path,
      };
    } else {
      return null;
    }
  }

  public createLink({ path, storage }: ILinkParams): string {
    return `${NucleusLinkGenerator.prefix}${storage}${path}`;
  }

  public static getServer(link: string): string | null {
    const prefixIndex = link.indexOf(NucleusLinkGenerator.prefix);
    if (prefixIndex === -1) {
      return null;
    }

    const path = link.substr(prefixIndex + NucleusLinkGenerator.prefix.length);
    return path.substr(0, path.indexOf("/"));
  }

  public static getServerPath(link: string): string {
    const prefixIndex = link.indexOf(NucleusLinkGenerator.prefix);
    if (prefixIndex === -1) {
      return "/";
    }

    const fullPath = link.substr(prefixIndex + NucleusLinkGenerator.prefix.length);
    return fullPath.substr(fullPath.indexOf("/"));
  }

  public static isOmniverse(link: string): boolean {
    return link.startsWith(NucleusLinkGenerator.prefix);
  }
}

export default NucleusLinkGenerator;
