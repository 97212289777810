import { css } from "styled-components";

const ScrollBar = css`
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.scrollBar};
    border-radius: 5px;
  }
    
  ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.scrollBarBackground};
    }
    
    ::-webkit-scrollbar-corner {
      background: ${({ theme }) => theme.colors.scrollBarBackground};
    }
`;

export default ScrollBar;
