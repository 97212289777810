import { observer } from "mobx-react";
import React, { useMemo } from "react";
import styled from "styled-components";
import useContextMenu from "../hooks/useContextMenu";
import useSelectionReset from "../hooks/useSelectionReset";
import Group from "../state/Group";
import Groups from "../state/Groups";
import { ReactComponent as GroupSVG } from "../static/group.svg";
import GroupMenu from "./GroupMenu";
import { TableCellComponentProps, TableColumn, TableRowComponentProps } from "./Table";
import { TableDiv, TableDivBody, TableDivCell, TableDivHead, TableDivHeader, TableDivRow } from "./TableDiv";
import TableIcon from "./TableIcon";
import VirtualizedTable from "./VirtualizedTable";

export interface GroupTableProps {
  groups: Groups;
  selectedGroup?: Group | null;
  onSelect?(group: Group | null): void;
}

const GroupTable: React.FC<GroupTableProps> = ({ groups, selectedGroup, onSelect }) => {
  const rows = groups.items;
  const selectedRows = useMemo(() => (selectedGroup ? [selectedGroup] : []), [selectedGroup]);
  const resetSelection = useSelectionReset(onSelect);
  return (
    <VirtualizedTable
      columns={GroupTableColumns}
      rows={rows}
      selectedRows={selectedRows}
      tableComponent={TableDiv}
      bodyComponent={TableDivBody}
      headComponent={TableDivHead}
      headerComponent={GroupHeader}
      headTableComponent={TableDiv}
      rowComponent={GroupRow}
      rowHeight={35}
      cellComponent={GroupCell}
      emptyRows={0}
      onClick={resetSelection}
      onSelect={onSelect}
    />
  );
};

const GroupTableColumns: TableColumn[] = [{ name: "name", title: "Group name", width: 300 }];

const GroupHeader = styled(TableDivHeader)`
  padding-left: ${({ column }) => (column.name === "name" ? "35px" : "0")};
  font-weight: 600;
`;

const GroupRow: React.FC<TableRowComponentProps<Group>> = ({ row: group, highlighted, children, ...props }) => {
  const [menu, onContextMenu] = useContextMenu(GroupMenu, { props: { group } });
  return (
    <StyledGroupRow {...props} row={group} highlighted={highlighted || Boolean(menu)} onContextMenu={onContextMenu}>
      {children}
      {menu}
    </StyledGroupRow>
  );
};

const StyledGroupRow = styled(TableDivRow)`
  svg {
    fill: ${({ theme, selected }) => (selected ? theme.colors.selectedItemForeground : theme.colors.foreground)};
  }
`;

let GroupCell: React.FC<TableCellComponentProps<Group>> = ({ column, children, row: group, ...props }) => {
  if (column.name === "name") {
    children = (
      <>
        <TableIcon src={GroupSVG} />
        {group.name}
      </>
    );
  }

  return (
    <StyledGroupCell column={column} row={group} {...props}>
      {children}
    </StyledGroupCell>
  );
};
GroupCell = observer(GroupCell);

const StyledGroupCell = styled(TableDivCell)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: ${({ column }) => (column.name === "name" ? "0" : "15px")};
  overflow: hidden;
`;

export default observer(GroupTable);
