import React, { AriaAttributes, useCallback, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import styled, { keyframes } from "styled-components";
import ScrollBar from "./ScrollBar";
import { DefaultTheme } from "./Theme";

const DialogAppearing = keyframes`
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
`;

const DialogOverlay = styled.div<{ overlay: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ overlay }) => (overlay ? "rgba(0, 0, 0, 0.1)" : "transparent")};
  animation: ${DialogAppearing} 300ms ease-in;
  user-select: none;
  z-index: 3;
`;

const DialogBody = styled.dialog.attrs({ open: true })<{ disabled: boolean }>`
  position: relative;
  min-width: 400px;
  max-width: 700px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: ${({ theme }) => theme.shadows.default};
  margin: 20px auto;
  border: none;
  padding: 0;
  background: ${({ theme }) => theme.colors.background};
  overflow: auto;
  user-select: ${({ disabled }) => (disabled ? "none" : "auto")};
  filter: ${({ disabled }) => (disabled ? "brightness(80%)" : "none")};
  ${ScrollBar};
`;
DialogBody.defaultProps = {
  theme: DefaultTheme,
};

export interface DialogProps extends AriaAttributes {
  rootId?: string;
  disabled?: boolean;
  className?: string;
  overlay?: boolean;
  onConfirm?(): void;
  onClose?(): void;
}

const Dialog: React.FC<DialogProps> = ({
  rootId = "root",
  disabled = false,
  className,
  overlay = true,
  children,
  onConfirm,
  onClose,
  ...props
}) => {
  const root = useMemo(() => {
    return document.getElementById(rootId);
  }, [rootId]);

  const handleHotKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.stopPropagation();
        e.preventDefault();

        if (!disabled && onConfirm) {
          onConfirm();
        }
      } else if (["Escape", "Esc"].includes(e.key)) {
        e.stopPropagation();
        e.preventDefault();

        if (onClose) {
          onClose();
        }
      }
    },
    [disabled, onConfirm, onClose]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleHotKey);
    return () => {
      document.removeEventListener("keyup", handleHotKey);
    };
  }, [handleHotKey]);

  if (root != null) {
    return ReactDOM.createPortal(
      <DialogOverlay overlay={overlay}>
        <DialogBody {...props} role={"dialog"} className={className} disabled={disabled}>
          {children}
        </DialogBody>
      </DialogOverlay>,
      root
    );
  }
  return null;
};

export default Dialog;
