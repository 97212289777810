import React, { useCallback, useEffect, useRef } from "react";
import { AutoSizer, CellMeasurer, CellMeasurerCache, Grid, GridCellProps } from "react-virtualized";
import styled from "styled-components";
import ScrollBar from "./ScrollBar";
import StaticSizer from "./StaticSizer";
import { DefaultTheme } from "./Theme";

export interface TreeProps<T> {
  className?: string;
  items: T[];
  itemComponent: React.ComponentType<TreeItemComponentProps<T>>;
  selectedItem?: T | null;
  highlightedItem?: T | null;
  width?: number;
  height?: number;
  onSelect?(e: React.MouseEvent & TreeItemEventData<T>): void;
  onToggle?(e: React.MouseEvent & TreeItemEventData<T>): void;
  onContextMenu?(e: React.MouseEvent): void;
  onItemContextMenu?(e: React.MouseEvent & TreeItemEventData<T>): void;
}

export type CustomTreeProps<T> = Omit<TreeProps<T>, "items" | "itemComponent"> & {
  itemComponent?: React.ComponentType<TreeItemComponentProps<T>>;
};

export interface TreeItemComponentProps<T> {
  item: T;
  style?: React.CSSProperties;
  selected?: boolean;
  highlighted?: boolean;
  onSelect?(e: React.MouseEvent & TreeItemEventData<T>): void;
  onToggle?(e: React.MouseEvent & TreeItemEventData<T>): void;
  onContextMenu?(e: React.MouseEvent & TreeItemEventData<T>): void;
}

export interface TreeItemEventData<T> {
  item: T;
}

function Tree<T>({
  className,
  items,
  itemComponent,
  selectedItem,
  highlightedItem,
  width,
  height,
  onSelect,
  onToggle,
  onContextMenu,
  onItemContextMenu,
}: TreeProps<T>) {
  const tree = useRef(null);

  const renderer = useCallback(
    ({ row: rowIndex, style }: BaseTreeItemProps) => {
      const item = items[rowIndex];
      const Item = itemComponent;
      return (
        <Item
          key={rowIndex}
          item={item}
          style={style}
          selected={item === selectedItem}
          highlighted={item === highlightedItem}
          onSelect={onSelect}
          onToggle={onToggle}
          onContextMenu={onItemContextMenu}
        />
      );
    },
    [items, itemComponent, selectedItem, highlightedItem, onSelect, onToggle, onItemContextMenu]
  );

  const Sizer = typeof width === "undefined" || typeof height === "undefined" ? AutoSizer : StaticSizer;

  useTreeScrollToSelection(tree, items, selectedItem);

  return (
    <StyledTree className={className} onContextMenu={onContextMenu}>
      <Sizer
        disableWidth={typeof width !== "undefined"}
        disableHeight={typeof height !== "undefined"}
        defaultWidth={width}
        defaultHeight={height}
      >
        {({ height: sizerHeight, width: sizerWidth }) => {
          const resultWidth = sizerWidth ?? width;
          const resultHeight = sizerHeight ?? height;

          return (
            <BaseTree
              ref={tree}
              width={resultWidth}
              height={resultHeight}
              rows={items}
              rowCount={items.length}
              rowHeight={30}
              itemRenderer={renderer}
            />
          );
        }}
      </Sizer>
    </StyledTree>
  );
}

const StyledTree = styled.div`
  display: block;
  height: 100%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.background};
`;
StyledTree.defaultProps = {
  theme: DefaultTheme,
};

function useTreeScrollToSelection<T>(tree: React.RefObject<BaseTree>, items: T[], selectedItem: T | null) {
  const lastSelected = useRef<T | null>();
  useEffect(() => {
    if (!selectedItem) {
      lastSelected.current = null;
      return;
    }

    const selectedItemIndex = items.findIndex((item) => item === selectedItem);
    if (selectedItemIndex !== -1) {
      tree.current?.scrollToRow(selectedItemIndex);
      lastSelected.current = selectedItem;
    }
  }, [tree, items, selectedItem]);
}

interface BaseTreeProps {
  className?: string;
  width: number;
  height: number;
  rowHeight: number;
  rowCount: number;
  rows?: any[];
  itemRenderer: (props: BaseTreeItemProps) => React.ReactNode;
}

export interface BaseTreeItemProps {
  row: number;
  style?: React.CSSProperties;
}

class BaseTree extends React.Component<BaseTreeProps> {
  private readonly grid: React.RefObject<Grid>;
  private readonly cache: CellMeasurerCache;
  private style?: React.CSSProperties;
  private lastWidth?: number;

  private getStyle = (width: number) => {
    if (width !== this.lastWidth) {
      this.lastWidth = width;
      this.style = { minWidth: width };
    }
    return this.style;
  };

  private getItemStyle = (width: number, style: React.CSSProperties) => {
    style = { ...style, width: "100%", minWidth: style.width };
    return style;
  };

  constructor(props: BaseTreeProps) {
    super(props);
    this.cache = new CellMeasurerCache({ defaultWidth: 100, minWidth: props.width, fixedHeight: true });
    this.grid = React.createRef();
  }

  public scrollToRow(rowIndex: number) {
    if (this.grid.current) {
      this.grid.current.scrollToCell({ rowIndex, columnIndex: 0 });
    }
  }

  public componentDidUpdate({ rows: previousRows }: BaseTreeProps): void {
    const { rows } = this.props;
    if (rows !== previousRows && previousRows) {
      for (let index = 0; index < previousRows.length; index++) {
        const previousRow = previousRows[index];
        const row = rows ? rows[index] : undefined;
        if (previousRow !== row) {
          this.cache.clear(index, 0);
        }
      }
    }
  }

  public render() {
    const { className, height, children, rowHeight, rowCount, width, ...props } = this.props;
    const style = this.getStyle(width);
    return (
      <StyledGrid
        ref={this.grid}
        {...props}
        className={className}
        columnCount={1}
        columnWidth={this.cache.columnWidth}
        containerStyle={style}
        width={width}
        height={height}
        cellRenderer={this.renderCell}
        rowCount={rowCount}
        rowHeight={rowHeight}
      />
    );
  }

  private renderCell = ({ key, rowIndex, columnIndex, parent, style }: GridCellProps) => {
    const Item = this.props.itemRenderer;

    style = this.getItemStyle(this.props.width, style);
    return (
      <CellMeasurer key={key} cache={this.cache} rowIndex={rowIndex} parent={parent} columnIndex={columnIndex}>
        {Item({ row: rowIndex, style })}
      </CellMeasurer>
    );
  };
}

const StyledGrid = styled(Grid)`
  outline: none;
  ${ScrollBar};
`;
StyledGrid.defaultProps = {
  theme: DefaultTheme,
};

export default Tree;
