
export default class EventEmitter {
  constructor(eventNames) {
    this.events = {};
    for (const event of eventNames) {
      this.events[event] = [];
    }
  }

  on(event, callback) {
    if (!this.events[event]) {
      throw new Error(`Unknown event '${event}'.`);
    }
    this.events[event].push(callback);
  }

  once(event, callback) {
    const cb = (...args) => {
      this.off(event, cb);
      callback(...args);
    };
    this.on(event, cb);
  }

  off(event, callback) {
    if (!this.events[event]) {
      throw new Error(`Unknown event '${event}'.`);
    }
    this.events[event] = this.events[event].filter((cb) => cb !== callback);
  }

  emit(eventName, event) {
    if (!this.events[eventName]) {
      throw new Error(`Unknown event '${eventName}'.`);
    }

    for (const callback of this.events[eventName]) {
      callback(event);
    }
  }
}