import cache from "memory-cache";
import { minutes } from "../../../util/DateTime";
import { Commands } from "../Provider";
import { SearchPrefixes } from "../types/GetSearchPrefixesCommand";
import { NucleusCommand } from "./index";

export default class NucleusGetSearchPrefixesCommand extends NucleusCommand<void, void, SearchPrefixes> {
  name = Commands.GetSearchPrefixes;

  public async allowed(args: void): Promise<boolean> {
    return this.provider.supportsAdvancedSearch;
  }

  public async execute(): Promise<SearchPrefixes> {
    const cacheKey = `${this.provider.name}.getPrefixes`;
    const cached = cache.get(cacheKey);
    if (cached) {
      return cached;
    }

    const service = await this.provider.createSearchClient();
    try {
      const response = await service.getPrefixes();
      const prefixes = new Set(response.prefixes);
      const result = {
        name: prefixes.has("name"),
        description: prefixes.has("description"),
        type: prefixes.has("ext"),
        tags: prefixes.has("tag"),
        image: prefixes.has("image"),
        dateStart: prefixes.has("modified_after"),
        dateEnd: prefixes.has("modified_before"),
        fileSize: prefixes.has("larger_than") || prefixes.has("smaller_than"),
        createdBy: prefixes.has("created_by"),
        modifiedBy: prefixes.has("modified_by"),
      };

      cache.put(cacheKey, result, cacheTime);
      return result;
    } finally {
      await service.transport.close();
    }
  }
}

const cacheTime = minutes(10);
