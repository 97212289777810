import React from "react";
import styled from "styled-components";
import useDialog from "../hooks/useDialog";
import Icon from "./Icon";
import NucleusAuthDialog from "./NucleusAuthDialog";
import { PathTreeNewServer } from "./PathTree";
import { PathTreeItemProps } from "./PathTreeItem";
import TreeItem from "./TreeItem";
import TreeItemName from "./TreeItemName";
import TreeTogglePlaceholder from "./TreeTogglePlaceholder";

const NewServerTreeItem: React.FC<PathTreeItemProps<PathTreeNewServer>> = ({ style }) => {
  const authentication = useDialog(NucleusAuthDialog);

  function showAuthentication() {
    authentication.show();
  }

  return (
    <StyledNewServerTreeItem level={0} style={style} onClick={showAuthentication}>
      <TreeTogglePlaceholder />
      <NewServerIcon />
      <TreeItemName>Add Server</TreeItemName>
    </StyledNewServerTreeItem>
  );
};

const StyledNewServerTreeItem = styled(TreeItem)`
  color: ${({ theme }) => theme.colors.hint};
`;

const NewServerIcon = () => {
  return (
    <StyledNewServerIcon>
      <Icon icon={"fas fa-handshake"} />
    </StyledNewServerIcon>
  );
};

const StyledNewServerIcon = styled.div`
  width: 25px;
  margin: auto 5px auto 0;
  text-align: center;
  position: relative;
  font-size: 14pt;
`;

export default NewServerTreeItem;
