import Schema from "@omniverse/idl/schema";
import * as _data from "./data";

export class TagSuggestions {
  constructor(transport) {
    this.transport = transport;
  }
  
  /*
    Asynchronous function to check capabilities with the server

    @param capabilities Client's capabilities
    @returns TagSuggestionsCheckCapabilitiesResult
   */
  async checkCapabilities() {
    const _request = {version: _data.TagSuggestionsCheckCapabilitiesClientVersion.value, capabilities: _data.TagSuggestionsClientCapabilities.value, };
    if (!_data.TagSuggestionsCheckCapabilitiesRequest.validate(_request)) {
      throw new Error("Invalid TagSuggestionsCheckCapabilitiesRequest.");
    }
    return await this.transport.call({ interfaceName: "TagSuggestions", methodName: "check_capabilities", request: _request, schemas: { request: _data.TagSuggestionsCheckCapabilitiesRequest, response: _data.TagSuggestionsCheckCapabilitiesResult }});
  }
  
  /*
    Asynchronous function to modify tag suggestions of a given folder.
    This function is provided to help GUIs suggest tags to their users
    depending on the project (path) they are working in.
    Categories can be seen as Namespaces but don't have to be used that way.
    Possible Operations:
    Add: Adds Tags to the given category.
    Reset: Clears all tags from the listed Categories and 'resets' them to the
    supplied tags. Can be used to clear categories.
    Remove: Removes tags from categories.

    @param auth_token AuthToken for a connection to the nucleus server. The
    token is used to verify existence of tagged paths and access rights.
    @param path Path of a folder that shall have its tag suggestions modified
    @param categories List of categories to modify
    @param modify_type Modification operation
    @returns ModifySuggestionsResult List per modified Category and Tag.
   */
  async modifySuggestions({auth_token, path, categories, modify_type, }) {
    const _request = {version: _data.TagSuggestionsModifySuggestionsClientVersion.value, auth_token, path, categories, modify_type, };
    if (!_data.TagSuggestionsModifySuggestionsRequest.validate(_request)) {
      throw new Error("Invalid TagSuggestionsModifySuggestionsRequest.");
    }
    return await this.transport.call({ interfaceName: "TagSuggestions", methodName: "modify_suggestions", request: _request, schemas: { request: _data.TagSuggestionsModifySuggestionsRequest, response: _data.ModifySuggestionsResult }});
  }
  
  /*
    Asynchronous function to receive all the tag suggestions of a given folder
    and optionally of all its parent folders.

    @param auth_token AuthToken for a connection to the nucleus server. The
    token is used to verify existence of tagged paths and access rights.
    @param path Path of a folder to request the tag suggestions from.
    @param recursive Set to true to scan all folders towards the root for
    suggestions and union the results.
   */
  async getSuggestions({auth_token, path, recursive, }) {
    const _request = {version: _data.TagSuggestionsGetSuggestionsClientVersion.value, auth_token, path, recursive, };
    if (!_data.TagSuggestionsGetSuggestionsRequest.validate(_request)) {
      throw new Error("Invalid TagSuggestionsGetSuggestionsRequest.");
    }
    return await this.transport.call({ interfaceName: "TagSuggestions", methodName: "get_suggestions", request: _request, schemas: { request: _data.TagSuggestionsGetSuggestionsRequest, response: _data.GetSuggestionsResult }});
  }
  
}
TagSuggestions[Schema.InterfaceName] = "TagSuggestions";
TagSuggestions[Schema.InterfaceOrigin] = "OmniTagging.idl.ts";
TagSuggestions[Schema.InterfaceCapabilities] = _data.TagSuggestionsClientLocalCapabilities.value;

export class TagSubscription {
  constructor(transport) {
    this.transport = transport;
  }
  
  /*
    Asynchronous function to check capabilities with the server.

    @param capabilities Client's capabilities
    @returns TagSubscriptionCheckCapabilitiesResult
   */
  async checkCapabilities() {
    const _request = {version: _data.TagSubscriptionCheckCapabilitiesClientVersion.value, capabilities: _data.TagSubscriptionClientCapabilities.value, };
    if (!_data.TagSubscriptionCheckCapabilitiesRequest.validate(_request)) {
      throw new Error("Invalid TagSubscriptionCheckCapabilitiesRequest.");
    }
    return await this.transport.call({ interfaceName: "TagSubscription", methodName: "check_capabilities", request: _request, schemas: { request: _data.TagSubscriptionCheckCapabilitiesRequest, response: _data.TagSubscriptionCheckCapabilitiesResult }});
  }
  
  /*
    Asynchronous function to get notifications when tags get modified by the
    service which match the filters of the subscription.
    The queries follow the same pattern as in TaggingService::tag_query().
    The queries are ANDed to generate the final result.
    Currently all filter queries are exact string matches except for
    pathQueries.
    Reset events trigger one result per cleared namespace and then one result
    per entry in these namespaces.
    There's one notification per result
    The first notification contains only the subscription ID and confirms the
    successful registration if the ID is != "".

    @param client_id client ID to subscribe with. Used to filter the client's
    own tag modifications.
    @param query Query tested against the modified tags. Same principle as the
    TaggingService::tag_query().
    @param auth_token AuthToken to verify the user's access rights.
    @returns SubscriptionResult[] asynchronous generator to retrieve the
    notifications event containing the tag and its modification type.
   */
  async subscribe({auth_token, client_id, query, }) {
    const _request = {version: _data.TagSubscriptionSubscribeClientVersion.value, auth_token, client_id, query, };
    if (!_data.TagSubscriptionSubscribeRequest.validate(_request)) {
      throw new Error("Invalid TagSubscriptionSubscribeRequest.");
    }
    return await this.transport.callMany({ interfaceName: "TagSubscription", methodName: "subscribe", request: _request, schemas: { request: _data.TagSubscriptionSubscribeRequest, response: _data.SubscriptionEvent }});
  }
  
}
TagSubscription[Schema.InterfaceName] = "TagSubscription";
TagSubscription[Schema.InterfaceOrigin] = "OmniTagging.idl.ts";
TagSubscription[Schema.InterfaceCapabilities] = _data.TagSubscriptionClientLocalCapabilities.value;

export class TaggingService {
  constructor(transport) {
    this.transport = transport;
  }
  
  /*
    Asynchronous function to check capabilities with the server.

    @param capabilities Client's capabilities
    @returns TaggingServiceCheckCapabilitiesResult
   */
  async checkCapabilities() {
    const _request = {version: _data.TaggingServiceCheckCapabilitiesClientVersion.value, capabilities: _data.TaggingServiceClientCapabilities.value, };
    if (!_data.TaggingServiceCheckCapabilitiesRequest.validate(_request)) {
      throw new Error("Invalid TaggingServiceCheckCapabilitiesRequest.");
    }
    return await this.transport.call({ interfaceName: "TaggingService", methodName: "check_capabilities", request: _request, schemas: { request: _data.TaggingServiceCheckCapabilitiesRequest, response: _data.TaggingServiceCheckCapabilitiesResult }});
  }
  
  /*
    Asynchronous function to generate a client ID.

    @returns GenerateClientIdResult
   */
  async generateClientId() {
    const _request = {version: _data.TaggingServiceGenerateClientIdClientVersion.value, };
    if (!_data.TaggingServiceGenerateClientIdRequest.validate(_request)) {
      throw new Error("Invalid TaggingServiceGenerateClientIdRequest.");
    }
    return await this.transport.call({ interfaceName: "TaggingService", methodName: "generate_client_id", request: _request, schemas: { request: _data.TaggingServiceGenerateClientIdRequest, response: _data.GenerateClientIdResult }});
  }
  
  /*
    Asynchronous function to modify tags for a set of paths.
    Add: Adds Tags (namespace, name, value) to paths if the Tags (excluding the
    value) did not exist before.
    Set: Adds Tags to paths no matter if they existed before or not.
    Reset: Clears all Tags from the listed namespaces and 'resets' them to the
    supplied Tags in all listed paths. Can be used to clear namespaces.
    Remove: Removes Tags (namespace, name) from paths.

    @param auth_token AuthToken for a connection to the nucleus server. The
    token is used to verify existence of tagged paths and access rights.
    @param client_id this ID is received from generate_client_id() and is used
    to prevent that a client is not receiving its own tag modifications as a
    subscription event.
    @param paths list of paths (file or folder) that get their tags modified.
    @param tags List of tags to modify. Can be empty in case of a Reset
    operation. Tags are leading and trailing whitespace stripped.
    @param modify_type Modify Operations. See description section.
    @returns ModifyTagsResult struct containing result status per modified path.
   */
  async modifyTags({auth_token, paths, tags, modify_type, client_id, }) {
    const _request = {version: _data.TaggingServiceModifyTagsClientVersion.value, auth_token, paths, tags, modify_type, client_id, };
    if (!_data.TaggingServiceModifyTagsRequest.validate(_request)) {
      throw new Error("Invalid TaggingServiceModifyTagsRequest.");
    }
    return await this.transport.call({ interfaceName: "TaggingService", methodName: "modify_tags", request: _request, schemas: { request: _data.TaggingServiceModifyTagsRequest, response: _data.ModifyTagsResult }});
  }
  
  /*
    Asynchronous function to receive all the tags of a given set of paths.

    @param auth_token AuthToken for a connection to the nucleus server. The
    token is used to verify existence of tagged paths and access rights.
    @param paths list of paths (file or folder) to get the tags from.
    @returns GetTagsResult struct containing a list of tags per queried path.
   */
  async getTags({auth_token, paths, }) {
    const _request = {version: _data.TaggingServiceGetTagsClientVersion.value, auth_token, paths, };
    if (!_data.TaggingServiceGetTagsRequest.validate(_request)) {
      throw new Error("Invalid TaggingServiceGetTagsRequest.");
    }
    return await this.transport.call({ interfaceName: "TaggingService", methodName: "get_tags", request: _request, schemas: { request: _data.TaggingServiceGetTagsRequest, response: _data.GetTagsResult }});
  }
  
  /*
    Asynchronous function to query the tagging service's data base. There's one
    query string per property (path, tag_namespace, tag_name, tag_value).
    The queries are ANDed to generate the final result. The users can select in
    which properties they are interested in to reduce the amount of data to be
    sent.
    Currently all queries are exact string matches except for pathQueries. Path
    queries use path.startsWith(pathQuery) to allow filtering for a common root.
    Leaving a query empty is interpreted as ANY (equal to * wildcard)

    @param query Query struct containing the 4 query strings per property
    (path, tag_namespace, tag_name, tag_value). Not setting this parameter is
    interpreted as ANY for all Query fields.
    @param ret_filter ReturnFilter struct containing a boolean per property to
    include/exclude it in the result. If not set then all flags default to true.
    @param max_results Integer to limit the maximum number of returned results
    (wildcard queries). The service has an configured limit which has
    precedence to this one.
    @param auth_token AuthToken to verify the user's access rights.
    @returns QueryResult struct containing tags matching the query & filter.
   */
  async tagQuery({auth_token, query, ret_filter, max_results, }) {
    const _request = {version: _data.TaggingServiceTagQueryClientVersion.value, auth_token, query, ret_filter, max_results, };
    if (!_data.TaggingServiceTagQueryRequest.validate(_request)) {
      throw new Error("Invalid TaggingServiceTagQueryRequest.");
    }
    return await this.transport.call({ interfaceName: "TaggingService", methodName: "tag_query", request: _request, schemas: { request: _data.TaggingServiceTagQueryRequest, response: _data.QueryResult }});
  }
  
}
TaggingService[Schema.InterfaceName] = "TaggingService";
TaggingService[Schema.InterfaceOrigin] = "OmniTagging.idl.ts";
TaggingService[Schema.InterfaceCapabilities] = _data.TaggingServiceClientLocalCapabilities.value;
