import { observer } from "mobx-react";
import React from "react";
import Path from "../state/Path";
import { getDefaultPathThumbnail } from "../util/PathThumbnail";
import Ghost from "./Ghost";


export interface PathGhostProps {
  path: Path;
  allowed?: boolean;
}

const PathGhost: React.FC<PathGhostProps> = ({ path, allowed }) => {
  const defaultThumbnail = getDefaultPathThumbnail(path);
  return (
    <Ghost thumbnail={path.thumbnail} fallbacks={[path.generatedThumbnail, defaultThumbnail]} allowed={allowed}>
      {path.name}
    </Ghost>
  );
};

export default observer(PathGhost);