import { AuthStatus } from "@omniverse/auth/data";
import { OfflineModeError } from "../../../util/SessionErrors";
import { SetProfileError } from "../../../util/UserErrors";
import { Commands } from "../Provider";
import { ISetUserProfileEnabledCommandArguments } from "../types/SetUserEnabledCommand";
import { NucleusCommand } from "./index";
import { parseNucleusProfile } from "./Nucleus";

export default class NucleusSetUserEnabledCommand extends NucleusCommand<ISetUserProfileEnabledCommandArguments> {
  name = Commands.SetUserEnabled;

  public async allowed(): Promise<boolean> {
    return Boolean(this.provider.session.isSuperUser);
  }

  public async execute({ user, enabled }: ISetUserProfileEnabledCommandArguments): Promise<void> {
    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }

    console.log(`[${this.provider.name}] ${enabled ? "Enable" : "Disable"} account for user "${user.name}"`);
    const profiles = await this.provider.createProfilesClient();
    try {
      const result = await profiles.setEnabled({
        username: user.name,
        token: this.provider.session.accessToken!,
        enabled,
      });

      if (result.status === AuthStatus.Expired) {
        await this.provider.session.refresh();
        return this.execute({ user, enabled });
      }

      if (result.status !== AuthStatus.OK) {
        throw new SetProfileError(result);
      }

      const profile = parseNucleusProfile(result.profile!);
      user.setProfile(profile!);
    } finally {
      await profiles.transport.close();
    }
  }
}
