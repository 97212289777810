import React from "react";
import {ReactComponent as FileImage} from "../static/file.svg";
import Ghost from "./Ghost";


export interface PathListGhostProps {
  pathCount: number;
  allowed?: boolean;
}

const PathListGhost: React.FC<PathListGhostProps> = ({ pathCount, allowed }) => {
  return (
    <Ghost thumbnail={FileImage} allowed={allowed}>
      {pathCount} items
    </Ghost>
  )
};


export default PathListGhost;