import React, { useCallback } from "react";
import styled from "styled-components";
import { ReactComponent as UserSVG } from "../static/user.svg";
import Layout from "./Layout";
import ToolbarTab from "./ToolbarTab";

export interface ContentBrowserLeftToolbarProps {
  userManagement?: boolean;
  tab: ContentBrowserLeftToolbarTab | null;
  onSelect(tab: ContentBrowserLeftToolbarTab): void;
}

export enum ContentBrowserLeftToolbarTab {
  UserManagement,
}

const ContentBrowserLeftToolbar: React.FC<ContentBrowserLeftToolbarProps> = ({ userManagement, tab, onSelect }) => {
  const selectUserManagement = useCallback(() => {
    onSelect(ContentBrowserLeftToolbarTab.UserManagement);
  }, [onSelect]);

  return (
    <StyledLeftPanelFooter>
      {userManagement && (
        <ToolbarTab
          name={"User Management"}
          selected={tab === ContentBrowserLeftToolbarTab.UserManagement}
          onClick={selectUserManagement}
        >
          <UserSVG />
        </ToolbarTab>
      )}
    </StyledLeftPanelFooter>
  );
};

const StyledLeftPanelFooter = styled(Layout.LeftPanelFooter)`
  display: flex;
  gap: 10px;
  padding: 0 5px;
  align-items: center;
  background: ${({ theme }) => theme.colors.toolbarBackground};
`;

export default ContentBrowserLeftToolbar;
