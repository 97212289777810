import { observer } from "mobx-react";
import React, { useState } from "react";
import { AutoSizer } from "react-virtualized";
import useDialogForm from "../hooks/useDialogForm";
import useStorageList from "../hooks/useStorageList";
import Path from "../state/Path";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import Dialog from "./Dialog";
import DialogForm from "./DialogForm";
import DialogTitle from "./DialogTitle";
import FormGroup from "./FormGroup";
import PathTree from "./PathTree";
import { PathTreeItemEvent } from "./PathTreeItem";

export interface PathBrowserDialogProps {
  initial?: Path | null;
  title?: string;
  onSelect(path: Path | null): void;
}

const PathBrowserDialog: React.FC<PathBrowserDialogProps> = ({
  initial = null,
  title = "Select path",
  onSelect,
}) => {
  const [path, setPath] = useState<Path | null>(initial);
  const storages = useStorageList();

  const form = useDialogForm({
    fields: {
      path,
    },
    onSubmit: confirm,
  });

  async function confirm() {
    onSelect(path);
  }

  function selectPath(e: React.MouseEvent & PathTreeItemEvent) {
    if (e.item.type === "path") {
      setPath(e.item.path);
    }
  }

  function togglePath(e: React.MouseEvent & PathTreeItemEvent) {
    if (e.item.type === "path") {
      return e.item.path.toggle();
    }
  }

  return (
    <Dialog aria-label={title}>
      <DialogTitle title={title} onClose={form.cancel} />
      <DialogForm>
        <FormGroup>
          <PathTree
            storages={storages.items}
            selectedPath={path}
            width={350}
            height={200}
            onSelect={selectPath}
            onToggle={togglePath}
          />
        </FormGroup>

        <ButtonGroup>
          <Button kind={"attention"} onClick={form.submit}>
            Select
          </Button>

          <Button onClick={form.cancel}>Cancel</Button>
        </ButtonGroup>
      </DialogForm>
    </Dialog>
  );
};

export default observer(PathBrowserDialog);
