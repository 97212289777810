import React from "react";
import styled from "styled-components";

const FormError = styled.div`
  background-color: #d46a6a;
  color: white;
  margin: 15px 0;
  padding: 5px 8px;
  font-size: 10pt;
`;

export const ServerError = () => <FormError>Something went wrong. Try again later.</FormError>;

export default FormError;
