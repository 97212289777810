import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useAppLocation from "../hooks/useAppLocation";
import useQuery from "../hooks/useQuery";
import { Checkpoint } from "../state/Checkpoints";
import { Commands } from "../state/commands/Provider";
import { IGetCheckpointsCommand } from "../state/commands/types/GetCheckpointsCommand";
import Path from "../state/Path";
import { getDayName, getMonthName } from "../util/DateTime";
import CheckpointView from "./CheckpointView";
import ErrorMessage from "./ErrorMessage";
import FormToggleable from "./FormToggleable";
import Loader from "./Loader";

export interface CheckpointListProps {
  path: Path;
}

const CheckpointList: React.FC<CheckpointListProps> = ({ path }) => {
  const [selectedCheckpoint, setSelectedCheckpoint] = useState<number | null>(null);
  const location = useAppLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const checkpointParam = params.get("checkpoint");
    if (checkpointParam) {
      setSelectedCheckpoint(parseInt(checkpointParam) || null);
    }
  }, [location.search]);

  const { error } = useQuery(
    async () => {
      const command = path.storage.commands.get<IGetCheckpointsCommand>(Commands.GetCheckpoints);
      if (command) {
        return await command.execute({ path, checkpoints: path.checkpoints });
      } else {
        throw new Error("Checkpoints commands are not supported.");
      }
    },
    {
      keys: [path],
    }
  );

  return (
    <StyledCheckpointList>
      {error && <ErrorMessage>{error}</ErrorMessage>}

      {path.checkpoints.loading ? (
        <Loader>Loading...</Loader>
      ) : path.checkpoints.map.size === 0 ? (
        <CheckpointPlaceholder>No checkpoints yet.</CheckpointPlaceholder>
      ) : (
        <>
          {path.checkpoints.described.length > 0 && (
            <CheckpointsGroup
              checkpoints={path.checkpoints.described}
              path={path}
              onSelect={setSelectedCheckpoint}
              selectedCheckpointId={selectedCheckpoint}
              label={"Described checkpoints"}
            />
          )}

          {path.checkpoints.byDates.today.length > 0 && (
            <CheckpointsGroup
              checkpoints={path.checkpoints.byDates.today}
              path={path}
              onSelect={setSelectedCheckpoint}
              selectedCheckpointId={selectedCheckpoint}
              label={"Today"}
            />
          )}
          {Object.entries(path.checkpoints.byDates.thisWeek).length > 0 &&
            Object.entries(path.checkpoints.byDates.thisWeek)
              .map(([dayOfTheWeek]) => Number(dayOfTheWeek))
              .sort(byDesc)
              .map((dayOfTheWeek) => (
                <CheckpointsGroup
                  key={dayOfTheWeek}
                  checkpoints={path.checkpoints.byDates.thisWeek[dayOfTheWeek]}
                  path={path}
                  onSelect={setSelectedCheckpoint}
                  selectedCheckpointId={selectedCheckpoint}
                  label={getDayName(dayOfTheWeek)}
                />
              ))}

          {Object.entries(path.checkpoints.byDates.thisYear).length > 0 &&
            Object.entries(path.checkpoints.byDates.thisYear)
              .map(([month]) => Number(month))
              .sort(byDesc)
              .map((month) => (
                <CheckpointsGroup
                  key={month}
                  checkpoints={path.checkpoints.byDates.thisYear[month]}
                  path={path}
                  onSelect={setSelectedCheckpoint}
                  selectedCheckpointId={selectedCheckpoint}
                  label={getMonthName(month)}
                />
              ))}

          {Object.keys(path.checkpoints.byDates.others)
            .map((year) => Number(year))
            .sort(byDesc)
            .map((year) =>
              Object.keys(path.checkpoints.byDates.others[year])
                .map((month) => Number(month))
                .sort(byDesc)
                .map((month) => {
                  const label = `${getMonthName(month)}, ${year}`;
                  return (
                    <CheckpointsGroup
                      key={label}
                      checkpoints={path.checkpoints.byDates.others[year][month]}
                      path={path}
                      onSelect={setSelectedCheckpoint}
                      selectedCheckpointId={selectedCheckpoint}
                      label={label}
                    />
                  );
                })
            )
            .flat()}
        </>
      )}
    </StyledCheckpointList>
  );
};

const StyledCheckpointList = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

const CheckpointsGroup = ({
  checkpoints,
  path,
  onSelect,
  selectedCheckpointId,
  label,
}: {
  checkpoints: Checkpoint[];
  path: Path;
  onSelect: (id: number | null) => void;
  selectedCheckpointId: number | null;
  label: string;
}) => {
  return (
    <StyleFormToggleable label={label} initial container={StyledFormToggleableContainer}>
      {checkpoints.map((checkpoint) => (
        <CheckpointView
          key={checkpoint.id}
          checkpoint={checkpoint}
          path={path}
          onSelect={onSelect}
          selectedCheckpointId={selectedCheckpointId}
        />
      ))}
    </StyleFormToggleable>
  );
};

const StyleFormToggleable = styled(FormToggleable)`
  margin: 15px;
`;

const StyledFormToggleableContainer = styled.div`
  margin-top: 8px;
  padding: 5px 0;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

const CheckpointPlaceholder = styled.div`
  padding: 10px 15px;
  font-size: 10pt;
`;

const byDesc = (a: number, b: number) => b - a;

export default observer(CheckpointList);
