import React, { AriaAttributes, useCallback } from "react";
import styled, { css } from "styled-components";
import { Omit } from "./index";
import { ThemedComponentProps } from "./Theme";

export interface IconProps extends ThemedComponentProps, AriaAttributes, React.ComponentPropsWithoutRef<"span"> {
  icon: Icons | string;
  clickable?: boolean;
  draggable?: boolean;
  disabled?: boolean;
}

export type PredefinedIconProps = Omit<IconProps, "icon">;

export const Icon = ({ icon, title, clickable, draggable, disabled, tabIndex, onKeyDown, ...props }: IconProps) => {
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      onKeyDown?.(e);

      if (e.defaultPrevented) {
        return;
      }

      if ((e.key === " " || e.key === "Enter") && !disabled) {
        e.stopPropagation();
        e.preventDefault();
        e.currentTarget.click();
      }
    },
    [disabled, onKeyDown]
  );

  return (
    <IconContainer
      {...props}
      title={title}
      clickable={clickable}
      disabled={disabled}
      draggable={draggable && !disabled}
      role={"img"}
      aria-label={title}
      onKeyDown={handleKeyDown}
      onClick={disabled ? undefined : props.onClick}
      onClickCapture={disabled ? undefined : props.onClickCapture}
      onMouseDown={disabled ? undefined : props.onMouseDown}
      onMouseDownCapture={disabled ? undefined : props.onMouseDownCapture}
    >
      <i className={icon} />
    </IconContainer>
  );
};

Icon.displayName = "Icon";

Icon.defaultProps = {
  tabIndex: -1,
};

const IconContainer = styled.span<{ clickable?: boolean; disabled?: boolean }>`
  display: inline-block;
  line-height: 1;
  user-select: none;

  ${({ tabIndex }) =>
    tabIndex === -1 &&
    css`
      outline: none;
    `};

  cursor: ${({ clickable, disabled }) => (clickable && !disabled ? "pointer" : "default")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
`;

export default Icon;

export enum Icons {
  Folder = "fas fa-fw fa-folder",
  Cloud = "fas fa-fw fa-cloud",
  Bars = "fas fa-fw fa-bars",
  Unknown = "fas fa-fw fa-question",
  Plus = "fas fa-fw fa-plus-square",
  Minus = "fas fa-fw fa-minus-square",
  Right = "fas fa-fw fa-caret-right",
  Down = "fas fa-fw fa-caret-down",
  Checked = "fas fa-fw fa-check-square",
  Empty = "fas fa-fw fa-square",
  Search = "fas fa-fw fa-search",
  File = "fas fa-fw fa-file",
  Close = "fas fa-fw fa-times",
  Spinner = "fas fa-fw fa-spinner",
  Save = "fas fa-fw fa-save",
  Undo = "fas fa-fw fa-undo",
  Redo = "fas fa-fw fa-redo",
  Delete = "fas fa-fw fa-trash",
  AngleLeft = "fas fa-fw fa-angle-left",
  AngleRight = "fas fa-fw fa-angle-right",
  AngleUp = "fas fa-fw fa-angle-up",
  AngleDown = "fas fa-fw fa-angle-down",
  Exclamation = "fas fa-fw fa-exclamation-triangle",
  Key = "fas fa-fw fa-key",
  FolderPlus = "fas fa-fw fa-folder-open",
  Add = "fas fa-fw fa-plus",
  Edit = "fas fa-fw fa-pencil-alt",
  Open = "fas fa-fw fa-folder-open",
  Remove = "fas fa-fw fa-times-circle",
  Download = "fas fa-fs fa-cloud-download-alt",
  Upload = "fas fa-fw fa-cloud-upload-alt",
  List = "fas fa-fw fa-list",
  Grid = "fas fa-fw fa-th-large",
  Ok = "fas fa-fw fa-check",
  Minimize = "fas fa-fw fa-caret-down",
  Maximize = "fas fa-fw fa-caret-up",
  Abort = "fas fa-fw fa-times-circle",
  Alert = "fas fa-fw fa-exclamation-triangle",
  Info = "fas fa-fw fa-info-circle",
  Warning = "fas fa-fw fa-exclamation-circle",
  Share = "fas fa-fw fa-share",
  ShareSquare = "fas fa-fw fa-share-square",
  Clipboard = "fas fa-fw fa-clipboard",
  Copy = "fas fa-fw fa-copy",
  Paste = "fas fa-fw fa-paste",
  ArrowRight = "fas fa-fw fa-arrow-right",
  ToggleOn = "fas fa-fw fa-toggle-on",
  ToggleOff = "fas fa-fw fa-toggle-off",
  History = "fas fa-fw fa-history",
  Star = "fas fa-fw fa-star",
  Link = "fas fa-fw fa-link",
  Mount = "fas fa-fw fa-hdd",
  Settings = "fas fa-fw fa-cog",
  Lock = "fas fa-fw fa-lock",
  Convert = "fas fa-fw fa-cube",
  Camera = "fas fa-fw fa-camera",
  Tags = "fas fa-fw fa-tags",
  Globe = "fas fa-fw fa-globe",
  User = "fas fa-fw fa-user",
  Users = "fas fa-fw fa-users",
  Group = "fas fa-fw fa-users",
  Eye = "fas fa-fw fa-eye",
  Wrench = "fas fa-fw fa-wrench",
  Options = "fas fa-fw fa-sliders-h",
  Image = "fas fa-fw fa-file-image",
}
