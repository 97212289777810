import { AuthStatus, Profile } from "@omniverse/auth/data";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import ButtonGroup from "./ButtonGroup";
import Form from "./Form";
import FormErrorList from "./FormErrorList";
import FormGroup from "./FormGroup";
import FormSpinner from "./FormSpinner";
import useCredentialAuth from "./hooks/CredentialAuth";
import useCredentialSettings from "./hooks/CredentialSettings";
import useForm from "./hooks/Form";
import { useInput } from "./hooks/Input";
import useSSORedirect from "./hooks/SSORedirect";
import useSSOSettings from "./hooks/SSOSettings";
import { Icon } from "./Icon";
import Input from "./Input";
import Link from "./Link";
import LoginButton from "./LoginButton";
import NvidiaLogo from "./NvidiaLogo";
import OmniverseLogo from "./OmniverseLogo";
import ServerForm, { ServerFormFields } from "./ServerForm";
import ServerName from "./ServerName";
import Spinner from "./Spinner";
import { SSOExtras } from "./SSO";
import SSOButtonGroup from "./SSOButtonGroup";
import SSOSplitter from "./SSOSplitter";
import { getBaseURL, joinURL } from "./util/URL";

export interface AuthFormProps {
  className?: string;
  loading?: boolean;

  /**
   * Forces the form to show credential inputs even if the service specifies them as hidden.
   * Can be used to display the authentication form for administrators and system accounts.
   */
  forceCredentials?: boolean;

  /**
   * Additional errors that will be shown with internal errors
   * from the authentication process.
   */
  errors?: string[];

  initial?: {
    username?: string;
    password?: string;
    server?: string;
  };

  /**
   * Specifies read-only form fields.
   */
  readonly?: {
    username?: boolean;
    server?: boolean;
  };

  /**
   * Extra parameters that will be encoded and saved for SSO.
   * These parameters will be restored in AuthenticationResult after the SSO.
   */
  extras?: SSOExtras;

  /**
   * Specifies the URL that will be used by SSO to redirect user back to the application.
   * If not specified then will use the current base URL + /sso endpoint,
   * for example:
   *    "http://localhost:3000/sso"
   *    "https://pb-ribbon.ov.nvidia.com/omni/auth/login/sso
   */
  ssoRedirectBackTo?: string;

  /**
   * A random string generated by the authentication service.
   * Used by clients to subscribe to authentication results.
   */
  nonce?: string;

  /**
   * Callback that is called if the authentication is successful.
   * Passes AuthenticationResult with token as the argument.
   * @param result
   */
  onSuccess?(result: AuthenticationResult): void;

  /**
   * Callback that is called if the authentication is failed.
   * Passes error messages as the argument.
   * @param errors
   */
  onFail?(errors: string[]): void;

  /**
   * Callback to be called for running the authentication.
   * Allows to override what happens when the user clicks on "Sign in" button.
   * Should return a promise with AuthenticationResult.
   *
   * If omitted then the default authentication mechanism is used.
   * @param auth
   */
  onSignIn?(auth: Authentication): Promise<AuthenticationResult>;

  /**
   * Callback that is called before onSignIn callback.
   * Should determine whether the authentication process needs to be continued.
   *
   * This is no-op by default that returns true.
   * @param auth
   */
  onStart?(auth: Authentication): boolean;

  /**
   * Callback to be called when user clicks on "Register" button.
   * If this callback is not provided then the "Register" button won't appear.
   */
  onRegister?(server: string): void;
}

export interface Authentication {
  username: string;
  password: string;
  server: string;
  nonce?: string;
}

export interface AuthenticationResult {
  server?: string;
  username?: string;
  profile?: Profile;
  accessToken?: string;
  refreshToken?: string;
  errors?: string[];
  status?: AuthStatus;
  extras?: SSOExtras;
  nonce?: string;
}

const AuthForm: React.FC<AuthFormProps> = ({
  initial = {},
  extras = {},
  errors,
  className,
  forceCredentials = false,
  readonly = {},
  loading,
  ssoRedirectBackTo,
  nonce,
  onSignIn,
  onStart,
  onSuccess,
  onRegister,
  onFail,
  children,
}) => {
  if (!ssoRedirectBackTo) {
    const baseURL = getBaseURL();
    if (baseURL.startsWith("/")) {
      ssoRedirectBackTo = joinURL(window.location.origin, baseURL, "/sso");
    } else if (baseURL) {
      ssoRedirectBackTo = joinURL(baseURL, "/sso");
    } else {
      ssoRedirectBackTo = joinURL(window.location.origin, "/sso");
    }
  }

  const [username, setUsername] = useInput(initial.username || "");
  const [password, setPassword] = useInput(initial.password || "");
  const [server, setServer] = useState(typeof initial.server === "undefined" ? "" : validateServer(initial.server));

  const authenticate = useCredentialAuth();
  const form = useForm<Authentication, AuthenticationResult>({
    fields: {
      username,
      password,
      server,
      nonce,
    },
    onStart,
    onSubmit: onSignIn || authenticate,
    onSuccess,
    onFail,
  });

  const credentialSettings = useCredentialSettings(server);
  const ssoSettings = useSSOSettings(server);
  const redirect = useSSORedirect(server, ssoRedirectBackTo, extras, nonce);

  const register = useCallback(() => {
    if (loading || form.loading) {
      return;
    }

    if (!server) {
      form.setErrors(["You must specify the server."]);
      return;
    }

    if (onRegister) {
      onRegister(server);
    }
  }, [server, form, loading, onRegister]);

  const chooseServer = useCallback((fields: ServerFormFields) => {
    setServer(validateServer(fields.server));
  }, []);

  const resetServer = useCallback(() => {
    form.setErrors([]);
    setServer("");
  }, [form]);

  const supportsCredentials = forceCredentials || credentialSettings.settings?.is_ui_visible;
  const supportsRegistration = !forceCredentials && onRegister && credentialSettings.settings?.can_register;

  if (!server) {
    return <ServerForm className={className} onSuccess={chooseServer} />;
  }

  if (!credentialSettings.settings) {
    return (
      <Form className={className}>
        <NvidiaLogo />
        <OmniverseLogo />

        {credentialSettings.errors.length === 0 ? (
          <FormSpinner />
        ) : (
          <>
            <ServerName>
              {server}
              {readonly && !readonly.server && <EditIcon onClick={resetServer} />}
            </ServerName>
            <FormGroup>
              <FormErrorList errors={credentialSettings.errors} />
            </FormGroup>
            <ButtonGroup>
              <LoginButton onClick={credentialSettings.retry}>Retry</LoginButton>
            </ButtonGroup>
          </>
        )}
      </Form>
    );
  }

  return (
    <Form className={className}>
      <NvidiaLogo />
      <OmniverseLogo />

      <ServerName>
        {server}
        {readonly && !readonly.server && <EditIcon onClick={resetServer} />}
      </ServerName>

      <FormGroup>
        <FormErrorList errors={errors} />
        <FormErrorList errors={form.errors} />
      </FormGroup>

      {supportsCredentials && (
        <>
          <FormGroup>
            <Input
              autoFocus
              id={"username"}
              name={"username"}
              disabled={loading || form.loading || readonly.username}
              placeholder={"Username"}
              value={username}
              onChange={setUsername}
            />
          </FormGroup>

          <FormGroup>
            <Input
              id={"password"}
              type={"password"}
              name={"password"}
              disabled={loading || form.loading}
              placeholder={"Password"}
              value={password}
              onChange={setPassword}
            />
          </FormGroup>

          <ButtonGroup>
            <LoginButton disabled={loading || form.loading} onClick={form.submit}>
              {(loading || form.loading) && <Spinner />} Log in
            </LoginButton>

            {supportsRegistration && <Link onClick={register}>Create Account</Link>}
          </ButtonGroup>
        </>
      )}

      {ssoSettings && ssoSettings.length > 0 && credentialSettings.settings.is_ui_visible && (
        <SSOSplitter>or</SSOSplitter>
      )}
      {ssoSettings && <SSOButtonGroup ssoSettings={ssoSettings} onClick={redirect} />}

      {children}
    </Form>
  );
};

const EditIcon = styled(Icon).attrs({
  icon: "fa fa-pencil",
})`
  margin-left: 10px;
`;

const validateServer = (server: string): string => {
  const portIndex = server.indexOf(":3009");
  if (portIndex !== -1) {
    server = server.substring(0, portIndex);
  }
  return server;
};

export default AuthForm;
