import { observer } from "mobx-react";
import React, { useState } from "react";
import styled from "styled-components";
import useDialogForm from "../hooks/useDialogForm";
import { Commands } from "../state/commands/Provider";
import { IAddUserToGroupCommand } from "../state/commands/types/AddUserToGroupCommand";
import Group from "../state/Group";
import User from "../state/User";
import { ReactComponent as GroupSVG } from "../static/group.svg";
import AutoComplete, { AutoCompleteInput, AutoCompleteMatch } from "./AutoComplete";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import Dialog from "./Dialog";
import DialogForm from "./DialogForm";
import DialogTitle from "./DialogTitle";
import FormErrorList from "./FormErrorList";
import FormGroup from "./FormGroup";
import ImportantText from "./ImportantText";
import Label from "./Label";

export interface GroupUserDialogProps {
  group: Group;
  suggestions: string[];
}

interface GroupUserDialogFields {
  username: string;
}

const GroupAddUserDialog: React.FC<GroupUserDialogProps> = ({ group, suggestions }) => {
  const [username, setUsername] = useState("");

  const form = useDialogForm({
    fields: {
      username,
    },
    onSubmit: submit,
  });

  async function submit({ username }: GroupUserDialogFields) {
    const command = group.storage.commands.get<IAddUserToGroupCommand>(Commands.AddUserToGroup);
    if (await command?.allowed()) {
      const user = new User(username, group.storage);
      return await command!.execute({ group, user });
    }
  }

  return (
    <StyledDialog onClose={form.cancel}>
      <DialogTitle title={"Add User to Group"} image={GroupSVG} onClose={form.cancel} />
      <DialogForm>
        <FormErrorList errors={form.errors} />
        <FormGroup>
          <Label>
            Group: <ImportantText>{group.name}</ImportantText>
          </Label>
        </FormGroup>
        <FormGroup>
          <Label>User:</Label>
          <StyledAutoComplete
            inputComponent={StyledAutoCompleteInput}
            matchComponent={StyledAutoCompleteMatch}
            disabled={form.loading}
            placeholder={"Username"}
            suggestions={suggestions}
            value={username}
            onChange={setUsername}
          />
        </FormGroup>

        <ButtonGroup>
          <Button kind={"attention"} disabled={form.loading} onClick={form.submit}>
            Submit
          </Button>
          <Button disabled={form.loading} onClick={form.cancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </DialogForm>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  overflow: visible;
`;

const StyledAutoComplete = styled(AutoComplete)`
  position: inherit;
`;

const StyledAutoCompleteInput = styled(AutoCompleteInput).attrs({
  autoFocus: true,
})``;

const StyledAutoCompleteMatch = styled(AutoCompleteMatch)`
  background: ${({ theme }) => theme.colors.inputBackground};
`;

export default observer(GroupAddUserDialog);
