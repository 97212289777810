import React, { useContext, useEffect } from "react";
import Path from "../state/Path";
import { FileWithPath, getFilesFromEvent } from "../util/Browser";
import { DialogContext } from "./Dialogs";

export interface PathUploadDialogProps {
  path: Path;
  type?: "file" | "directory";
}

const PathUploadDialog: React.FC<PathUploadDialogProps> = ({ path, type }) => {
  const dialog = useContext(DialogContext);

  useEffect(() => {
    const previousDialog = document.getElementById("upload-dialog");
    if (previousDialog) {
      previousDialog.remove();
    }

    const input = document.createElement("input");
    input.id = "upload-dialog";
    input.type = "file";
    input.setAttribute("multiple", "true");
    input.setAttribute("style", "display: none");
    if (type === "directory") {
      input.setAttribute("directory", "true");
      input.setAttribute("webkitdirectory", "true");
    }

    input.onchange = async (event: Event) => {
      input.remove();
      const files = (await getFilesFromEvent(event)) as FileWithPath[];

      try {
        await path.upload(files);
      } catch (error) {}
    };
    input.onclose = () => {
      input.remove();
      dialog.hide();
    };

    document.body.appendChild(input);
    input.click();
  }, [path, type, dialog]);

  return null;
};

export default PathUploadDialog;
