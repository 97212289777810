import Schema from "@omniverse/idl/schema";
import * as _data from "./data";

export class Search {
  constructor(transport) {
    this.transport = transport;
  }
  
  /*
    @deprecated
   */
  async find({query, token, }) {
    const _request = {version: _data.SearchFindClientVersion.value, query, token, };
    if (!_data.SearchFindRequest.validate(_request)) {
      throw new Error("Invalid SearchFindRequest.");
    }
    return await this.transport.callMany({ interfaceName: "Search", methodName: "find", request: _request, schemas: { request: _data.SearchFindRequest, response: _data.Path }});
  }
  
  /*
    
   */
  async find2({query, token, }) {
    const _request = {version: _data.SearchFind2ClientVersion.value, query, token, };
    if (!_data.SearchFind2Request.validate(_request)) {
      throw new Error("Invalid SearchFind2Request.");
    }
    return await this.transport.call({ interfaceName: "Search", methodName: "find2", request: _request, schemas: { request: _data.SearchFind2Request, response: _data.SearchResult }});
  }
  
  /*
    
   */
  async findPagedCursor({query, token, size, cursor_id, }) {
    const _request = {version: _data.SearchFindPagedCursorClientVersion.value, query, token, size, cursor_id, };
    if (!_data.SearchFindPagedCursorRequest.validate(_request)) {
      throw new Error("Invalid SearchFindPagedCursorRequest.");
    }
    return await this.transport.call({ interfaceName: "Search", methodName: "find_paged_cursor", request: _request, schemas: { request: _data.SearchFindPagedCursorRequest, response: _data.SearchResultCursor }});
  }
  
  /*
    
   */
  async findPagedSkip({query, token, size, skip_first, }) {
    const _request = {version: _data.SearchFindPagedSkipClientVersion.value, query, token, size, skip_first, };
    if (!_data.SearchFindPagedSkipRequest.validate(_request)) {
      throw new Error("Invalid SearchFindPagedSkipRequest.");
    }
    return await this.transport.call({ interfaceName: "Search", methodName: "find_paged_skip", request: _request, schemas: { request: _data.SearchFindPagedSkipRequest, response: _data.SearchResult }});
  }
  
  /*
    
   */
  async getPrefixes() {
    const _request = {version: _data.SearchGetPrefixesClientVersion.value, };
    if (!_data.SearchGetPrefixesRequest.validate(_request)) {
      throw new Error("Invalid SearchGetPrefixesRequest.");
    }
    return await this.transport.call({ interfaceName: "Search", methodName: "get_prefixes", request: _request, schemas: { request: _data.SearchGetPrefixesRequest, response: _data.PrefixResult }});
  }
  
}
Search[Schema.InterfaceName] = "Search";
Search[Schema.InterfaceOrigin] = "Search.idl.ts";
Search[Schema.InterfaceCapabilities] = _data.SearchClientLocalCapabilities.value;
