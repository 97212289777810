export default class Serializer {
  async serialize(data) {
    throw new Error("Not implemented.");
  }

  async deserialize(data) {
    throw new Error("Not implemented.");
  }
}

export const SerializerName = Symbol("SerializerName");

export function fromStringToArrayBuffer(str) {
  return new TextEncoder().encode(str).buffer;
}

export function fromArrayBufferToString(buffer) {
  return new TextDecoder().decode(buffer);
}

export function concatBuffers(...buffers) {
  const result = new Uint8Array(
    new ArrayBuffer(
      buffers.reduce((size, buffer) => size + buffer.byteLength, 0)
    )
  );

  let offset = 0;
  for (const buffer of buffers) {
    result.set(new Uint8Array(buffer), offset);
    offset += buffer.byteLength;
  }
  return result.buffer;
}

export class SerializerFactory {
  static serializers = {};

  static register(serializerType) {
    SerializerFactory.serializers[serializerType[SerializerName]] = serializerType;
  }

  static create(serializerName) {
    const serializerType = SerializerFactory.serializers[serializerName];
    if (!serializerType) {
      throw new Error(`Serializer ${serializerName} is not registered.`);
    }
    return new serializerType();
  }
}
