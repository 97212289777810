import React from "react";
import dnd from "../state/DND";
import Path from "../state/Path";

type DropCallback = (paths: Path[]) => void;
type DropAllowedCallback = (paths: Path[]) => boolean;

export default function usePathDropSource(onDrop: DropCallback, allowed: DropAllowedCallback = defaultAllowed) {
  const onMouseEnter = async (e: React.MouseEvent) => {
    if (dnd.type === "path" || dnd.type === "paths") {
      dnd.setAllowed(allowed(getData()));
    }
  };

  const onMouseLeave = (e: React.MouseEvent) => {
    if (dnd.type === "path" || dnd.type === "paths") {
      dnd.setAllowed(false);
    }
  };

  const onMouseUp = (e: React.MouseEvent) => {
    if (dnd.allowed && (dnd.type === "path" || dnd.type === "paths")) {
      onDrop(getData());
    }
  };

  return {
    allowed: dnd.allowed,
    onMouseEnter,
    onMouseLeave,
    onMouseUp,
  };
}

const defaultAllowed = () => true;

const getData = (): Path[] => {
  return dnd.type === "path" ? [dnd.data] : dnd.type === "paths" ? dnd.data : [];
};
