import React, { useCallback } from "react";
import styled from "styled-components";

export interface FormProps extends React.HTMLAttributes<HTMLFormElement> {
}

export const Form = ({ onSubmit, ...props }: FormProps) => {
  const submit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (onSubmit) {
        onSubmit(e);
      }
    },
    [onSubmit]
  );

  return <StyledForm {...props} onSubmit={submit}/>;
};

const StyledForm = styled.form`
  position: relative;
  padding: 1rem;
  background: #2d2d2d;
  color: #e0e0e0;
  box-shadow: #111;
  width: 275px;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0 auto;
`;

export default Form;
