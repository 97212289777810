import { StatusType } from "@omniverse/api/data";
import { GetGroupUsersError } from "../../../util/GroupErrors";
import User from "../../User";
import { Commands } from "../Provider";
import { IGetGroupUsersCommandArguments } from "../types/GetGroupUsersCommand";
import { NucleusCommand } from "./index";

export default class NucleusGetGroupUsersCommand extends NucleusCommand<IGetGroupUsersCommandArguments, void, User[]> {
  name = Commands.GetGroupUsers;

  public async allowed(): Promise<boolean> {
    return Boolean(this.provider.session.isSuperUser);
  }

  public async execute({ group }: IGetGroupUsersCommandArguments): Promise<User[]> {
    console.log(`[${this.provider.name}] Get users for group "${group.name}"`);

    const connection = await this.provider.getConnection();
    const result = await connection.getGroupUsers({ group_name: group.name });
    if (result.status !== StatusType.OK) {
      throw new GetGroupUsersError(result);
    }

    const users = result.users!.map((username) => {
      let foundUser = group.storage.users.items.find((user) => user.name === username);
      return foundUser ?? new User(username, group.storage);
    });
    group.setUsers(users);
    return users;
  }
}
