import { StatusType } from "@omniverse/api/data";
import { GetACLError } from "../../../util/ACLErrors";
import { ACLPermissions } from "../../ACL";
import { Commands } from "../Provider";
import { IGetACLCommandArguments } from "../types/GetACLCommand";
import { NucleusCommand } from "./index";

export default class NucleusGetACLCommand extends NucleusCommand<IGetACLCommandArguments, void, ACLPermissions> {
  public name = Commands.GetACL;

  public async allowed(): Promise<boolean> {
    return !this.provider.session.isNucleusReadOnly;
  }

  public async execute({ path }: IGetACLCommandArguments): Promise<ACLPermissions> {
    console.log(`[${this.provider.name}] Get ACL for ${path.link}`);

    const connection = await this.provider.getConnection();
    const result = await connection.getAcl({ uri: path.path });
    if (result.status !== StatusType.OK) {
      throw new GetACLError(result);
    }

    path.acl.set(Object.entries(result.acl!));
    return path.acl.map;
  }
}
