import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as CloseSVG } from "../static/close.svg";
import { ReactComponent as MaximizeSVG } from "../static/maximize.svg";
import { ReactComponent as MinimizeSVG } from "../static/minimize.svg";
import { isElectron } from "../util/Electron";
import { HeaderLogo } from "./Header";

const TitleBar: React.FC = () => {
  return (
    <StyledTitleBar>
      <TitleBarLogo />
      <TitleBarText>Navigator</TitleBarText>

      <ResizableArea />
      <Controls>
        <Minimize />
        <Maximize />
        <Close />
      </Controls>
    </StyledTitleBar>
  );
};

const StyledTitleBar = styled.div`
  display: flex;
  flex: 0 0 15px;
  height: 30px;
  justify-content: flex-end;
  align-items: center;
  background: #212121;
  z-index: 1;
  -webkit-user-select: none;
  -webkit-app-region: drag;
`;

const ResizableArea = styled.div`
  flex: 1;
`;

const Controls = styled.div`
  height: 30px;
  flex: 0 0 auto;
  display: flex;
  background: #212121;
  z-index: 2;
  -webkit-app-region: no-drag;
  -webkit-user-drag: none;
`;

const TitleBarIcon = css`
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
  -webkit-app-region: no-drag;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const Minimize: React.FC = () => {
  async function minimize() {
    if (isElectron()) {
      await window.app.minimize();
    }
  }

  return <MinimizeIcon onClick={minimize} />;
};

const MinimizeIcon = styled(MinimizeSVG)`
  ${TitleBarIcon};
`;

const Maximize: React.FC = () => {
  async function maximize() {
    if (isElectron()) {
      await window.app.toggle();
    }
  }

  return <MaximizeIcon onClick={maximize} />;
};

const MaximizeIcon = styled(MaximizeSVG)`
  ${TitleBarIcon};
`;

const Close: React.FC = () => {
  async function close() {
    if (isElectron()) {
      await window.app.quit();
    }
  }

  return <CloseIcon onClick={close} />;
};

const CloseIcon = styled(CloseSVG)`
  ${TitleBarIcon};

  &:hover {
    background: #911812;
  }
`;

const TitleBarLogo = styled(HeaderLogo)`
  height: 20px;
  padding: 0 0.5em;
  margin-right: 0;
`;

const TitleBarText = styled.span`
  text-transform: uppercase;
  font-size: 10pt;
  font-weight: 600;
  padding-top: 1px;
  font-family: "Open Sans", sans-serif;
  color: #b7b7b7;
  -webkit-font-smoothing: subpixel-antialiased;
`;

export default TitleBar;
