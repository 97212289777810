import styled from "styled-components";
import Label from "./Label";

export const Checkbox = styled.input.attrs(() => ({
  type: "checkbox",
}))`
  margin: 0;
  vertical-align: middle;

  ${Label} > &, ${Label} + & {
    margin: 0 15px;
  }
`;

Checkbox.displayName = "Checkbox";

export default Checkbox;
