import { observer } from "mobx-react";
import React, { useMemo } from "react";
import PathUpload from "../state/PathUpload";
import { formatFileSize } from "../util/File";
import TaskView from "./TaskView";

export interface PathUploadProgressProps {
  upload: PathUpload;
  onClose(upload: PathUpload): void;
  onAbort(upload: PathUpload): void;
}

const PathUploadProgress: React.FC<PathUploadProgressProps> = ({ upload, onClose, onAbort }) => {
  const statusText = useMemo(() => {
    if (upload.error) {
      return upload.error;
    }

    let text = formatFileSize(upload.loaded);
    if (upload.total) {
      text += ` / ${formatFileSize(upload.total)}`;
    }
    return text;
  }, [upload.loaded, upload.total, upload.error]);

  return (
    <TaskView
      process={upload}
      percent={(upload.loaded / upload.total) * 100}
      text={upload.target}
      status={upload.status}
      statusText={statusText}
      onClose={onClose}
      onAbort={onAbort}
    />
  );
};

export default observer(PathUploadProgress);