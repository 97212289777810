import DiscoverySearch, { ClientType, DiscoveryError, } from "@omniverse/discovery";

export default async function connect<T>(
  server: string,
  clientType: ClientType<T>,
  capabilities: Record<string, number> = {}
): Promise<T> {
  const discovery = new DiscoverySearch(server);
  try {
    const supportedTransport = undefined;
    const client = await discovery.find(clientType, {deployment: "external"}, supportedTransport, capabilities);
    if (!client) {
      throw new DiscoveryError();
    }
    return client;
  } finally {
    discovery.close();
  }
}

export function handleConnectionErrors(server: string, error: Error) {
  console.error(error);
  if (error instanceof Event && error.type === "error") {
    return {
      server,
      errors: ["Cannot connect to the authentication service."],
    };
  }
  if (error instanceof DiscoveryError) {
    return {
      server,
      errors: ["Cannot connect to the authentication service."],
    };
  }
  return {
    server,
    errors: [`Unexpected error. Try again later.`],
  };
}
