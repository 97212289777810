import { StatusType } from "@omniverse/api/data";
import { GetGroupsError } from "../../../util/GroupErrors";
import Group from "../../Group";
import { Commands } from "../Provider";
import { IGetGroupsCommandArguments } from "../types/GetGroupsCommand";
import { NucleusCommand } from "./index";

export default class NucleusGetGroupsCommand extends NucleusCommand<IGetGroupsCommandArguments, void, Group[]> {
  name = Commands.GetGroups;

  public async allowed(): Promise<boolean> {
    return Boolean(this.provider.session.isSuperUser);
  }

  public async execute({ groups }: IGetGroupsCommandArguments): Promise<Group[]> {
    console.log(`[${this.provider.name}] Get groups`);

    const connection = await this.provider.getConnection();
    const result = await connection.getGroups();
    if (result.status !== StatusType.OK) {
      throw new GetGroupsError(result);
    }

    groups.set(result.groups!.sort().map((name) => new Group(name, groups.storage)));
    return groups.items;
  }
}
