import { observer, useLocalObservable } from "mobx-react";
import React, { useEffect, useMemo, useRef } from "react";
import useDialog from "../hooks/useDialog";
import useGridSelectionFocus from "../hooks/useGridSelectionFocus";
import useSelectionReset from "../hooks/useSelectionReset";
import User from "../state/User";
import Users from "../state/Users";
import { ReactComponent as NewUserIcon } from "../static/new-user.svg";
import Grid, { GridCellProps } from "./Grid";
import GridCell, { GridCellAligner } from "./GridCell";
import UserFormDialog from "./UserFormDialog";
import UserGridCell, { UserGridCellExtraProps } from "./UserGridCell";

export interface UserGridProps {
  users: Users;
  selectedUser?: User | null;
  highlightedUser?: User | null;
  canAddUsers?: boolean;
  width?: number;
  height?: number;
  onSelect?(user: User | null): void;
  onContextMenu?(e: React.MouseEvent & { data: User }): void;
}

type UserItem = { type: "user"; user: User };
type NewUserItem = { type: "new-user"; users: Users };
type UserGridItemType = UserItem | NewUserItem;

function UserGrid({
  users,
  selectedUser,
  highlightedUser,
  canAddUsers,
  width,
  height,
  onSelect,
  onContextMenu,
}: UserGridProps) {
  const grid = useRef<Grid<UserGridItemType>>(null);
  const state = useLocalObservable(() => {
    return {
      canAddUsers,
      users,
      get cells() {
        const cells: UserGridItemType[] = [];
        if (this.canAddUsers) {
          cells.push({ type: "new-user", users: this.users });
        }
        return cells.concat(this.users.items.map((user) => ({ type: "user", user })));
      },

      update(canAddUsers: boolean | undefined, users: Users) {
        this.canAddUsers = canAddUsers;
        this.users = users;
      },
    };
  });

  useEffect(() => {
    state.update(canAddUsers, users);
  }, [state, canAddUsers, users]);

  const selectedUserIndex = useMemo(() => {
    return state.cells.findIndex((cell) => cell.type === "user" && cell.user === selectedUser);
  }, [state.cells, selectedUser]);

  useGridSelectionFocus(grid.current, selectedUserIndex);

  const cellProps: UserGridCellExtraProps = useMemo(
    () => ({
      selectedUser,
      highlightedUser,
      onSelect,
      onContextMenu,
    }),
    [selectedUser, highlightedUser, onSelect, onContextMenu]
  );

  const resetSelection = useSelectionReset(onSelect);
  return (
    <Grid
      ref={grid}
      cells={state.cells}
      cellWidth={150}
      cellHeight={150}
      cellRenderer={UserGridCellRenderer}
      cellProps={cellProps}
      gap={20}
      width={width}
      height={height}
      onClick={resetSelection}
    />
  );
}

const UserGridCellRenderer: React.FC<GridCellProps<UserGridItemType>> = ({ data, ...props }) => {
  if (data.type === "user") {
    return <UserGridCell data={data.user} {...props} />;
  }
  if (data.type === "new-user") {
    return <NewUserCell data={data} {...props} />;
  }
  return null;
};

const NewUserCell: React.FC<GridCellProps<NewUserItem>> = ({ data: { users }, style, width, height }) => {
  const userDialog = useDialog(UserFormDialog, { users });

  function addUser() {
    userDialog.show();
  }

  return (
    <GridCellAligner style={style} cellWidth={width} onClick={addUser}>
      <GridCell
        name={"Add User"}
        thumbnail={NewUserIcon}
        bordered={false}
        selected={false}
        highlighted={false}
        cellHeight={150}
        actualHeight={height}
      />
    </GridCellAligner>
  );
};

export default observer(UserGrid);
