export function join(...args: string[]): string {
  return args.reduce((path, value, index) => {
    if (!value) {
      return path;
    }

    if (!value.startsWith("/")) {
      value = "/" + value;
    }

    if (index !== args.length - 1 && value.endsWith("/")) {
      value = value.substring(0, value.length - 1);
    }

    return path + value;
  }, "");
}

export function joinURL(...args: string[]): string {
  return args.reduce((path, value, index) => {
    if (!value) {
      return path;
    }

    if (index !== 0 && !value.startsWith("/")) {
      value = "/" + value;
    }

    if (index !== args.length - 1 && value.endsWith("/")) {
      value = value.substring(0, value.length - 1);
    }

    return path + value;
  }, "");
}

export function getName(path: string): string {
  if (path === "/") {
    return "/";
  }

  const pieces = path.split("/").filter((piece) => piece);
  if (pieces.length) {
    return pieces[pieces.length - 1];
  }
  return "";
}

export function getExtension(path: string): string {
  const fileName = getName(path);
  const dotIndex = fileName.lastIndexOf(".");
  if (dotIndex === -1 || dotIndex === 0) {
    return "";
  }
  return fileName.substring(dotIndex);
}

export function getNameWithoutExtension(path: string): string {
  const fileName = getName(path);
  const dotIndex = fileName.lastIndexOf(".");
  if (dotIndex === -1 || dotIndex === 0) {
    return fileName;
  }
  return fileName.substring(0, dotIndex);
}

export function getParent(path: string): string {
  if (path === "/") {
    return "/";
  }

  const pieces = path.split("/").filter((piece) => piece);
  return join(...pieces.slice(0, pieces.length - 1), "/");
}

export function isFolder(path?: string): boolean {
  return Boolean(path && path.endsWith("/"));
}

export function traverse<T>(path: string, callback: (path: string) => T | undefined): T | undefined {
  let pieces = path
    .split("/")
    .filter((piece) => piece.length > 0)
    .slice(0, -1);
  let current = "/";

  let result = callback(current);
  if (typeof result !== "undefined") {
    return result;
  }

  for (const piece of pieces) {
    current = join(current, piece, "/");
    result = callback(current);
    if (typeof result !== "undefined") {
      return result;
    }
  }

  return callback(path);
}

export function traverseMap<T>(path: string, callback: (path: string) => T): T[] {
  let results = [];
  let pieces = path
    .split("/")
    .filter((piece) => piece.length > 0)
    .slice(0, -1);
  let current = "/";

  results.push(callback(current));
  for (const piece of pieces) {
    current = join(current, piece, "/");
    results.push(callback(current));
  }

  results.push(callback(path));
  return results;
}

export function isDirectory(uri: string): boolean {
  return uri.endsWith("/");
}

export function splitPath(uri: string): string[] {
  let currentPath = "/";
  const path = uri.split("/").filter((directory) => !!directory);
  return [
    currentPath,
    ...path.map((directory, index) => {
      currentPath += directory;
      if (index !== path.length - 1 || isDirectory(uri)) {
        currentPath += "/";
      }
      return currentPath;
    }),
  ];
}

export function getParents(uri: string): string[] {
  return splitPath(uri).slice(0, -1);
}
