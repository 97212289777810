import { action, computed, makeObservable, observable } from "mobx";
import Storage from "./Storage";

export interface APIToken {
  name: string;
  apiToken?: string;
}

class APITokens {
  public items: APIToken[];
  public loading: boolean;

  public constructor(public readonly storage: Storage) {
    this.items = [];
    this.loading = false;

    makeObservable(this, {
      items: observable,
      loading: observable,
      names: computed,
      add: action.bound,
      set: action.bound,
      delete: action.bound,
      setLoading: action.bound,
    });
  }

  public get names(): string[] {
    return this.items.map((token) => token.name);
  }

  public add(token: APIToken): void {
    this.items.unshift(token);
  }

  public set(users: APIToken[]): void {
    this.items = users;
  }

  public delete(tokenName: string): void {
    this.items = this.items.filter((t) => t.name !== tokenName);
  }

  public setLoading(value: boolean): void {
    this.loading = value;
  }
}

export default APITokens;
