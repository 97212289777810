import React, { useEffect, useState } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import Loader from "./components/Loader";
import TitleBar from "./components/TitleBar";
import Navigator from "./Navigator";
import Application from "./state/Application";
import { isElectron } from "./util/Electron";
import { joinURL } from "./util/Path";
import { getBaseURL } from "./util/URL";

const electron = isElectron();
if (electron) {
  Object.assign(console, window.log.functions);
}

const basename = getBaseURL();

function App() {
  const [loading, setLoading] = useState(true);
  const [app, setApp] = useState<Application | undefined>(undefined);

  useEffect(() => {
    initializeApplication()
      .then((app) => setApp(app))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loader />;
  }

  const Router: React.ComponentType<{ basename?: string }> = electron ? HashRouter : BrowserRouter;
  return (
    <Router basename={basename}>
      {isElectron() && <TitleBar />}
      <Navigator app={app} />
    </Router>
  );
}

export async function initializeApplication(): Promise<Application> {
  let settings;
  try {
    const response = await fetch(joinURL(basename, "/settings.json"));
    settings = await response.json();
  } catch (error) {
    // Settings don't exist or can't be loaded -- initialize the app from local storage.
    return Application.load({ basename, useBasenamePrefix: false });
  }

  const etag = localStorage.getItem("etag");
  if (etag === settings.etag) {
    // These application settings didn't change since the last deployment.
    // Use localStorage state instead of initializing the app from the settings.
    return Application.load({ basename, useBasenamePrefix: false });
  }

  // The etag is changed.
  // We need to bust the local storage and reinitialize the application based on the server settings.
  localStorage.clear();
  localStorage.setItem("etag", settings.etag);

  const app = Application.fromJSON(settings, { basename, useBasenamePrefix: false });
  app.useLocalStorage();
  return app;
}

export default App;
