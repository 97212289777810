import React from "react";
import Icon, { Icons } from "./Icon";

interface SortingIconProps {
  className?: string;
  direction: SortingDirection;
}

type SortingDirection = "ASC" | "DESC";

const SortingIcon: React.FC<SortingIconProps> = ({ direction, className }) => (
  <Icon icon={direction === "ASC" ? Icons.AngleDown : Icons.AngleUp} className={className} />
);

export default SortingIcon;
