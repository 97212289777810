import styled from "styled-components";
import Picture from "./Picture";

const TableIcon = styled(Picture)`
  width: 25px;
  height: 25px;
  margin: 0 5px;
  vertical-align: middle;
`;

export default TableIcon;
