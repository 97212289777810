import { TaggingService } from "@omniverse/tagging";
import { Tag as NucleusTag, TagModifyType } from "@omniverse/tagging/data";
import { Tag } from "../../../Tags";
import { TagCommands } from "../../Provider";
import { ISetTagsCommandAllowedArguments, ISetTagsCommandArguments } from "../../types/tags/SetTagsCommand";
import { NucleusCommand } from "../index";
import { convertTagToNucleus, ensureNucleusTagResults } from "./index";

export default class NucleusSetTagsCommand extends NucleusCommand<
  ISetTagsCommandArguments,
  ISetTagsCommandAllowedArguments
> {
  name = TagCommands.Set;

  public async allowed({ path }: ISetTagsCommandAllowedArguments): Promise<boolean> {
    return path.canWrite();
  }

  public async execute({ tags, values }: ISetTagsCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Set tags for ${tags.path}`);
    console.dir(values.map((tag) => tag.fullName));

    const data: NucleusTag[] = values.map(convertTagToNucleus);

    if (!values.find((tag) => tag.isAppearance)) {
      data.push({ tag_namespace: Tag.AppearanceNamespace });
    }

    if (!values.find((tag) => tag.isGenerated)) {
      data.push({ tag_namespace: Tag.AppearanceGeneratedNamespace });
    }

    if (!values.find((tag) => tag.isExcluded)) {
      data.push({ tag_namespace: Tag.AppearanceExcludedNamespace });
    }

    tags.setLoading(true);

    const service = await this.provider.createServiceClient(TaggingService, "TAGS", {
      capabilities: { modify_tags: 1 },
    });

    try {
      const response = await service.modifyTags({
        paths: [tags.path],
        tags: data,
        auth_token: this.provider.session.accessToken!,
        modify_type: TagModifyType.Reset,
      });

      const [result] = response.path_result;

      ensureNucleusTagResults(result, values);
      tags.setTags(values);
    } finally {
      tags.setLoading(false);
      await service.transport.close();
    }
  }
}
