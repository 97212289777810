import React from "react";
import User from "../state/User";
import ImportantText from "./ImportantText";
import UserNotification from "./UserNotification";

export interface ResetPasswordLinkNotificationProps {
  user: User;
  onClose(): void;
}

const ResetPasswordLinkNotification: React.FC<ResetPasswordLinkNotificationProps> = ({ user, onClose }) => {
  return (
    <UserNotification onClose={onClose}>
      The password reset link has been copied to your clipboard and is valid for 24 hours. Please provide it to{" "}
      <ImportantText>{user.name}</ImportantText> via a private and secure channel.
    </UserNotification>
  );
};

export default ResetPasswordLinkNotification;
