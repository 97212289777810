import { SSO } from "@omniverse/auth/client";
import { SSOSettings } from "@omniverse/auth/data";
import { useEffect, useState } from "react";
import connect from "../Connection";

export default function useSSOSettings(server: string) {
  const [settings, setSettings] = useState<SSOSettings[] | null>(null);

  useEffect(() => {
    let subscribed = true;
    setSettings(null);

    let debounced: number;
    if (server) {
      debounced = window.setTimeout(() => {
        let sso: SSO | null = null;

        connect(server, SSO, { "get_settings": 0 })
          .then((conn) => (sso = conn))
          .then((sso) => sso.getSettings({}))
          .then((ssoSettings) => ssoSettings.readAll())
          .then((ssoSettings) => {
            if (subscribed) {
              setSettings(ssoSettings);
            }
          })
          .catch((err) => console.warn(err))
          .finally(() => {
            if (sso) {
              sso.transport.close();
            }
          });
      }, 300);
    }

    return () => {
      subscribed = false;
      if (debounced) {
        clearTimeout(debounced);
      }
    };
  }, [server]);

  return settings;
}
