import { useEffect, useRef } from "react";
import Selection from "../state/Selection";

export function useMultipleSelection<T = any>(selection: Selection<T>) {
  const multipleSelection = useRef(false);
  const callbacks = useRef({
    select: (item: T) => {
      if (multipleSelection.current) {
        if (selection.default) {
          selection.remove(selection.default);
        }
        selection.toggle(item);
      } else {
        selection.select(item);
      }
    },
    selectMultiple: (items: T[]) => {
      if (items.length === 0) {
        if (!multipleSelection.current) {
          selection.reset();
        }
        return;
      }

      if (multipleSelection.current) {
        if (selection.default) {
          selection.remove(selection.default);
        }

        for (const item of items) {
          selection.add(item);
        }
      } else {
        selection.set(items);
      }
    }
  });

  useEffect(() => {
    function toggleMultipleSelection(event: KeyboardEvent) {
      multipleSelection.current = event.ctrlKey;
    }

    window.addEventListener("keydown", toggleMultipleSelection);
    window.addEventListener("keyup", toggleMultipleSelection);
    return () => {
      window.removeEventListener("keydown", toggleMultipleSelection);
      window.removeEventListener("keyup", toggleMultipleSelection);
    };
  }, []);

  return callbacks.current;
}