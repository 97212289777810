import { TaggingService } from "@omniverse/tagging";
import { OfflineModeError } from "../../../../util/SessionErrors";
import { PathType } from "../../../Path";
import { Tag } from "../../../Tags";
import { TagCommands } from "../../Provider";
import { IGetTagsCommandAllowedArguments, IGetTagsCommandArguments } from "../../types/tags/GetTagsCommand";
import { NucleusCommand } from "../index";
import { parseNucleusTag } from "./index";

export default class NucleusGetTagsCommand extends NucleusCommand<
  IGetTagsCommandArguments,
  IGetTagsCommandAllowedArguments,
  Tag[]
> {
  name = TagCommands.Get;

  public async allowed({ path }: IGetTagsCommandAllowedArguments): Promise<boolean> {
    return path.type === PathType.File;
  }

  public async execute({ tags }: IGetTagsCommandArguments): Promise<Tag[]> {
    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }

    console.log(`[${this.provider.name}] Get tags for ${tags.path}`);
    tags.setLoading(true);

    const service = await this.provider.createServiceClient(TaggingService, "TAGS", {
      capabilities: { get_tags: 1 },
    });

    try {
      const response = await service.getTags({
        paths: [tags.path],
        auth_token: this.provider.session.accessToken!,
      });
      const [result] = response.path_result;

      tags.setTags(result.tags.map(parseNucleusTag));
      return tags.items;
    } finally {
      tags.setLoading(false);
      await service.transport.close();
    }
  }
}
