import React from "react";
import styled from "styled-components";
import ScrollBar from "./ScrollBar";
import Tabs, { Tab, TabContent, TabsProps } from "./Tabs";
import { DefaultTheme } from "./Theme";

const RightPanelTabs: React.FC<TabsProps> = (props) => {
  return <StyledTabs content={PanelTabContent} {...props} />;
};

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const PanelTabContent = styled(TabContent)`
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  ${ScrollBar};
`;
PanelTabContent.defaultProps = {
  theme: DefaultTheme,
};

export const RightPanelTab = styled(Tab)`
  margin-left: 5px;
  margin-right: -5px;
  background: ${({ theme, selected }) =>
    selected ? theme.colors.panelBackground : theme.colors.background};
  color: ${({ theme, selected }) => (selected ? theme.colors.panelText : theme.colors.inputText)};
  overflow: hidden;
`;
RightPanelTab.defaultProps = {
  theme: DefaultTheme,
};

export default RightPanelTabs;
