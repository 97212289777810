import Path from "../../Path";
import { Commands } from "../Provider";
import { ISearchCommand } from "../types/SearchCommand";
import {
  IFindSimilarFilesCommandAllowedArguments,
  IFindSimilarFilesCommandArguments,
} from "../types/FindSimilarFilesCommand";
import { NucleusCommand } from "./index";
import { IGetSearchPrefixesCommand } from "../types/GetSearchPrefixesCommand";

export default class NucleusFindSimilarFilesCommand extends NucleusCommand<
  IFindSimilarFilesCommandArguments,
  IFindSimilarFilesCommandAllowedArguments,
  Path[]
> {
  name = Commands.FindSimilar;

  public async allowed({ file }: IFindSimilarFilesCommandAllowedArguments): Promise<boolean> {
    if (!this.provider.supportsAdvancedSearch) {
      return false;
    }

    if (!file.thumbnail && !file.generatedThumbnail) {
      return false;
    }

    const getPrefixes = this.provider.commands.get<IGetSearchPrefixesCommand>(Commands.GetSearchPrefixes);
    if (!getPrefixes) {
      return false;
    }

    const prefixes = await getPrefixes.execute();
    if (!prefixes.image) {
      return false;
    }

    try {
      const thumbnail = await Promise.any([this.loadImage(file.thumbnail), this.loadImage(file.generatedThumbnail)]);
      return Boolean(thumbnail);
    } catch {
      return false;
    }
  }

  public async execute({ search, file }: IFindSimilarFilesCommandArguments): Promise<Path[]> {
    const searchCommand = await this.provider.commands.get<ISearchCommand>(Commands.Search);
    if (!searchCommand) {
      throw new Error("Search command does not exist.");
    }

    file.invalidateLink();

    search.clear();
    search.setQuery({
      sample: file.link,
    });
    return await search.run(file.storage);
  }

  public loadImage(url: string): Promise<HTMLImageElement> {
    if (!url) {
      return Promise.reject("URL is not specified.");
    }

    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = (err) => reject(err);
      image.src = url;
    });
  }
}
