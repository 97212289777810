import React, { useCallback, useRef } from "react";

type Hotkeys<T> = {
  [hotkey: string]: HotkeyCallback<T> | undefined
}

type HotkeyCallback<T> = (e: React.KeyboardEvent<T>) => void;

export default function useHotkeys<T>(hotkeyMap: Hotkeys<T>): HotkeyCallback<T> {
  const hotkeys = useRef<Hotkeys<T>>(hotkeyMap);
  hotkeys.current = hotkeyMap;

  return useCallback((e: React.KeyboardEvent<T>) => {
    const handler = hotkeys.current[e.key];
    if (handler) {
      e.preventDefault();
      e.stopPropagation();
      return handler(e);
    }
  }, []);
}