import { StatusType } from "@omniverse/api/data";
import { join } from "../../../util/Path";
import { MountError } from "../../../util/PathErrors";
import Path, { PathType } from "../../Path";
import { Commands } from "../Provider";
import { IMountCommandArguments } from "../types/MountCommand";
import { NucleusCommand } from "./index";

export default class NucleusMountCommand extends NucleusCommand<IMountCommandArguments> {
  name = Commands.Mount;

  public async allowed(): Promise<boolean> {
    return Boolean(this.provider.session.isSuperUser);
  }

  public async execute({ parent, name, resolver, isPublic, redirectURL, options }: IMountCommandArguments) {
    console.log(`[${this.provider.name}] Mount ${name} to ${parent.path}`);
    console.table({
      resolver,
      isPublic,
      redirectURL,
      options,
    });

    const optionJSON: { [key: string]: string | number | boolean } = {};
    for (const option of options) {
      optionJSON[option.key] = option.value;
      if (option.type === "number" && typeof option.value === "string") {
        optionJSON[option.key] = Number(option.value);
      }
    }

    const path = join(parent.path, name);
    const connection = await this.provider.getConnection();
    const response = await connection.mount({
      uri: path,
      resolver,
      redirect_url: redirectURL,
      options: JSON.stringify(optionJSON),
    });

    if (response.status !== StatusType.OK) {
      throw new MountError(response);
    }

    const dateCreated = new Date();
    const dateModified = new Date();
    const size = 0;
    const mount = new Path(
      path,
      PathType.Mount,
      parent.storage,
      dateCreated,
      dateModified,
      this.provider.session.username,
      this.provider.session.username,
      size,
      true
    );
    parent.add(mount);
  }
}
