import React, { useCallback } from "react";
import { Commands } from "../state/commands/Provider";
import { IMoveCommand, IMoveCommandAllowedArguments } from "../state/commands/types/MoveCommand";
import dnd from "../state/DND";
import Path from "../state/Path";
import { useDrag } from "./useDrag";
import usePermission from "./usePermission";

export default function usePathDrag(path: Path) {
  const allowedToMove = usePermission(
    () =>
      path.storage.commands.allowed<IMoveCommand, IMoveCommandAllowedArguments>(Commands.Move, {
        source: [path],
      }),
    [path]
  );

  const draggable = allowedToMove === "allowed";
  const onDragStart = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const multipleSelection = path.storage.selectedPaths.items.length > 1 && path.storage.selectedPaths.has(path);
      if (multipleSelection) {
        dnd.set("paths", path.storage.selectedPaths.items);
      } else {
        dnd.set("path", path);
      }
    },
    [path]
  );

  const onDragEnd = useCallback(() => {
    dnd.reset();
  }, []);

  return useDrag(path, { draggable, onDragStart, onDragEnd });
}
