import { TaggingService } from "@omniverse/tagging";
import { unique } from "../../../../util/Array";
import { getParent } from "../../../../util/Path";
import { OfflineModeError } from "../../../../util/SessionErrors";
import { TagCommands } from "../../Provider";
import { IGetTagSuggestionsCommandArguments } from "../../types/tags/GetTagSuggestionsCommand";
import { NucleusCommand } from "../index";

export default class NucleusGetTagSuggestionsCommand extends NucleusCommand<
  IGetTagSuggestionsCommandArguments,
  void,
  string[]
> {
  name = TagCommands.GetSuggestions;

  public async allowed(): Promise<boolean> {
    return true;
  }

  public async execute({ tags }: IGetTagSuggestionsCommandArguments): Promise<string[]> {
    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }

    console.log(`[${this.provider.name}] Get tag suggestions for ${tags.path}`);

    const service = await this.provider.createServiceClient(TaggingService, "TAGS", { capabilities: { tag_query: 1 } });
    try {
      const response = await service.tagQuery({
        auth_token: this.provider.session.accessToken!,
        query: { path: getParent(tags.path) },
        ret_filter: {
          return_paths: false,
          return_tags: true,
          return_namespaces: false,
          return_values: false,
          exclude_hidden: true,
        },
      });

      const suggestions = unique(response.tags.filter((tag) => Boolean(tag.name)).map((tag) => tag.name!));
      tags.setSuggestions(suggestions);

      return suggestions;
    } finally {
      await service.transport.close();
    }
  }
}
