import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { Commands } from "./commands/Provider";
import { ISearchCommand } from "./commands/types/SearchCommand";
import Path from "./Path";
import Storage from "./Storage";

export interface PathSearchQuery {
  name?: string[];
  description?: string;
  tags?: string[];
  createdBy?: string;
  modifiedBy?: string;
  type?: string;
  fileSize?: string;
  fileSizeComparator?: string;
  dateStart?: string;
  dateEnd?: string;
  image?: File;
  /**
   * Represents a URL for finding similar files.
   */
  sample?: string;
}

export class PathSearch {
  public query: PathSearchQuery;

  /**
   * Visual representation of the search query.
   */
  public text: string;

  public results: Path[];
  public loading: boolean;
  public active: boolean;

  public constructor() {
    this.query = {};
    this.text = "";
    this.results = [];
    this.loading = false;
    this.active = false;

    makeObservable(this, {
      query: observable,
      text: observable,
      results: observable,
      loading: observable,
      active: observable,
      setQuery: action.bound,
      setText: action.bound,
      clear: action.bound,
      run: action.bound,
      hasQuery: computed,
    });
  }

  public get hasQuery(): boolean {
    return hasAnySearchOptions(this.query) || Boolean(this.text);
  }

  public setQuery(query: PathSearchQuery): void {
    this.query = query;
  }

  public setText(text: string): void {
    this.text = text;
  }

  public clear(): void {
    this.query = {};
    this.text = "";
    this.active = false;
  }

  public async run(storage: Storage): Promise<Path[]> {
    this.loading = true;
    this.active = true;
    this.results = [];

    let command;
    try {
      command = storage.commands.get<ISearchCommand>(Commands.Search);
      if (command) {
        const results = await command.execute({
          search: this,
          query: this.query,
          parent: storage.cwd ?? storage.root,
          text: this.text,
        });

        runInAction(() => {
          this.results = results;
        });
      }
      return this.results;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export function hasAnySearchOptions(options?: PathSearchQuery): boolean {
  return Boolean(
    options &&
      Object.values(options).some((value) => {
        if (value instanceof Array) return value.length > 0;
        return Boolean(value);
      })
  );
}

export function sanitizeTags(tags: string[]): string[] {
  return tags ? tags.map((tag) => tag.trim()).filter(Boolean) : [];
}

const search = new PathSearch();
export default search;
