import { observer } from "mobx-react";
import React from "react";
import useDialog from "../hooks/useDialog";
import usePermission from "../hooks/usePermission";
import { Commands } from "../state/commands/Provider";
import { ICopyCommand } from "../state/commands/types/CopyCommand";
import { ICreateFolderAllowedArguments, ICreateFolderCommand } from "../state/commands/types/CreateFolderCommand";
import { IDeleteCommand, IDeleteCommandAllowedArguments } from "../state/commands/types/DeleteCommand";
import { IMountCommand } from "../state/commands/types/MountCommand";
import { IMoveCommand, IMoveCommandAllowedArguments } from "../state/commands/types/MoveCommand";
import { IUploadCommand, IUploadCommandAllowedArguments } from "../state/commands/types/UploadCommand";
import Path from "../state/Path";
import { Icons } from "./Icon";
import Loader from "./Loader";
import Menu from "./Menu";
import { MenuIconItem, MenuItemDivider } from "./MenuItem";
import MountDialog from "./MountDialog";
import NewFolderDialog from "./NewFolderDialog";
import PathCopyDialog from "./PathCopyDialog";
import PathDeleteDialog from "./PathDeleteDialog";
import PathRenameDialog from "./PathRenameDialog";
import PathShareDialog from "./PathShareDialog";
import PathUploadDialog from "./PathUploadDialog";

interface FolderMenuProps {
  path: Path;
}

const FolderMenu: React.FC<FolderMenuProps> = ({ path }) => {
  const newFolderDialog = useDialog(NewFolderDialog, { path });
  const newMountDialog = useDialog(MountDialog, { path });
  const pathCopyDialog = useDialog(PathCopyDialog, { destination: path });
  const pathRenameDialog = useDialog(PathRenameDialog, { path });
  const pathDeleteConfirmation = useDialog(PathDeleteDialog, { path });
  const pathShareDialog = useDialog(PathShareDialog, { path });
  const pathUploadDialog = useDialog(PathUploadDialog, { path });

  function newFolder() {
    newFolderDialog.show();
  }

  function newMount() {
    newMountDialog.show();
  }

  function copyFolder() {
    path.storage.clipboard.copy([path]);
  }

  function paste() {
    pathCopyDialog.show();
  }

  function renameFolder() {
    pathRenameDialog.show();
  }

  function deleteFolder() {
    pathDeleteConfirmation.show();
  }

  function shareFolder() {
    pathShareDialog.show();
  }

  function downloadFolder() {
    return path.download();
  }

  function uploadFile() {
    pathUploadDialog.show({ type: "file" });
  }

  function uploadFolder() {
    pathUploadDialog.show({ type: "directory" });
  }

  const canCreateNewFolders = usePermission(
    () =>
      path.storage.commands.allowed<ICreateFolderCommand, ICreateFolderAllowedArguments>(Commands.CreateFolder, {
        path,
      }),
    [path]
  );
  const canCreateNewMounts = usePermission(() => path.storage.commands.allowed<IMountCommand>(Commands.Mount), [path]);
  const canCopy = path.storage.commands.has<ICopyCommand>(Commands.Copy);
  const canPaste = usePermission(() => path.storage.clipboard.canPaste(path), [path]);
  const canRename = usePermission(
    () =>
      path.storage.commands.allowed<IMoveCommand, IMoveCommandAllowedArguments>(Commands.Move, {
        source: [path],
      }),
    [path]
  );
  const canDelete = usePermission(
    () =>
      path.storage.commands.allowed<IDeleteCommand, IDeleteCommandAllowedArguments>(Commands.Delete, {
        path,
      }),
    [path]
  );
  const canUpload = usePermission(
    () =>
      path.storage.commands.allowed<IUploadCommand, IUploadCommandAllowedArguments>(Commands.Upload, {
        destination: path,
      }),
    [path]
  );

  const loading =
    canCreateNewFolders === "loading" ||
    canCreateNewMounts === "loading" ||
    canPaste === "loading" ||
    canRename === "loading" ||
    canDelete === "loading" ||
    canUpload === "loading";

  if (loading) {
    return (
      <Menu>
        <Loader />
      </Menu>
    );
  }

  return (
    <Menu>
      <MenuIconItem icon={Icons.FolderPlus} visible={canCreateNewFolders === "allowed"} onClick={newFolder}>
        New Folder
      </MenuIconItem>

      <MenuIconItem icon={Icons.Mount} visible={canCreateNewMounts === "allowed"} onClick={newMount}>
        New Mount
      </MenuIconItem>

      <MenuItemDivider visible={path.canWrite()} />

      <MenuIconItem icon={Icons.Copy} visible={canCopy} onClick={copyFolder}>
        Copy
      </MenuIconItem>

      <MenuIconItem icon={Icons.Paste} visible={canPaste === "allowed"} onClick={paste}>
        Paste
      </MenuIconItem>

      <MenuIconItem icon={Icons.Edit} visible={canRename === "allowed"} onClick={renameFolder}>
        Rename
      </MenuIconItem>

      <MenuIconItem icon={Icons.Delete} visible={canDelete === "allowed"} onClick={deleteFolder}>
        Delete
      </MenuIconItem>

      <MenuItemDivider />

      <MenuIconItem icon={Icons.Share} onClick={shareFolder}>
        Share
      </MenuIconItem>

      <MenuIconItem icon={Icons.Download} visible={path.canDownload()} onClick={downloadFolder}>
        Download
      </MenuIconItem>

      <MenuIconItem icon={Icons.Upload} visible={canUpload === "allowed"} onClick={uploadFile}>
        Upload Files
      </MenuIconItem>

      <MenuIconItem icon={Icons.Upload} visible={canUpload === "allowed"} onClick={uploadFolder}>
        Upload Folder
      </MenuIconItem>
    </Menu>
  );
};

export default observer(FolderMenu);
