import styled from "styled-components";
import Spinner from "./Spinner";

const FormSpinner = styled(Spinner)`
  font-size: 18pt;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FormSpinner;
