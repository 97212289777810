import React, { useCallback, useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useStateOrProp } from "../hooks/useStateOrProp";
import { StyledRenderer } from "./index";
import { DefaultTheme } from "./Theme";

export interface TabsProps {
  selectedTab?: string;
  className?: string;
  style?: React.CSSProperties;
  header?: StyledRenderer;
  content?: StyledRenderer;
  onTabSelect?(tab: string): void;
}

export interface TabContextState {
  selectedTab: string | null;
  onTabSelect?(tab: string): void;
}

export const TabContext = React.createContext<TabContextState>({
  selectedTab: null,
});

export const Tabs: React.FC<TabsProps> = ({
  selectedTab: selectedTabProp,
  className,
  style,
  header: Header = TabHeaders,
  content: Content = TabContent,
  onTabSelect,
  children = [],
  ...props
}) => {
  const [selectedTab, selectTab] = useStateOrProp(selectedTabProp, onTabSelect);

  useEffect(() => {
    if (!selectedTab) {
      for (const child of React.Children.toArray(children)) {
        const tab = child as React.ReactElement<TabProps>;
        const tabName = tab && tab.props && tab.props.name;
        if (tabName) {
          selectTab(tabName);
          break;
        }
      }
    }
  }, [selectedTab, selectTab, children]);

  const context = useMemo<TabContextState>(
    () => ({
      selectedTab,
      onTabSelect: selectTab,
    }),
    [selectedTab, selectTab]
  );

  const selectedTabComponent = React.Children.toArray(children).find((child) => {
    const tab = child as React.ReactElement<TabProps>;
    return tab && tab.props && tab.props.name === selectedTab;
  }) as React.ReactElement<TabProps>;

  const selectedContent = selectedTabComponent ? selectedTabComponent.props.children : null;

  return (
    <TabContext.Provider value={context}>
      <StyledTabs {...props} className={className} style={style}>
        <Header selectedTab={selectedTab}>
          {React.Children.map(children, (tab) =>
            tab
              ? React.cloneElement(tab as React.ReactElement, {
                  selected: (tab as React.ReactElement)?.props?.name === selectedTab,
                })
              : null
          )}
        </Header>

        <Content selectedTab={selectedTab}>{selectedContent}</Content>
      </StyledTabs>
    </TabContext.Provider>
  );
};

const StyledTabs = styled.div``;

export const TabHeaders = styled.div<{ selectedTab: string }>`
  display: grid;
  grid-gap: 5px;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
`;

export const TabContent = styled.div<{ selectedTab: string }>`
  padding: 5px;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.foreground};
`;
TabContent.defaultProps = {
  theme: DefaultTheme,
};

export interface TabProps {
  label: React.ReactNode;
  name: string;
  className?: string;
  selected?: boolean;
  onSelect?(name: string): void;
  children?: React.ReactNode;
}

export const Tab: React.FC<TabProps> = ({ label, name, selected = false, className, onSelect, ...props }) => {
  const context = useContext(TabContext);
  const handleClick = useCallback(() => {
    if (onSelect) {
      onSelect(name);
    }
    if (context.onTabSelect) {
      context.onTabSelect(name);
    }
  }, [name, onSelect, context]);

  return (
    <StyledTab className={className} selected={selected} onClick={handleClick} {...props}>
      {label}
    </StyledTab>
  );
};

const StyledTab = styled.div<{ selected: boolean }>`
  cursor: pointer;
  line-height: 1;
  padding: 5px;
  background: ${({ theme, selected }) => (selected ? theme.colors.background : theme.colors.hoverItemBackground)};
  color: ${({ theme }) => theme.colors.foreground};
  font-size: 10pt;
`;
StyledTab.defaultProps = {
  theme: DefaultTheme,
};

export default Tabs;
