import { StatusType } from "@omniverse/api/data";
import { Commands } from "../Provider";
import {
  IDeleteCheckpointCommandAllowedArguments,
  IDeleteCheckpointCommandArguments,
} from "../types/DeleteCheckpointCommand";

import { NucleusCommand } from "./index";

export default class NucleusDeleteCheckpointCommand extends NucleusCommand<
  IDeleteCheckpointCommandArguments,
  IDeleteCheckpointCommandAllowedArguments
> {
  name = Commands.DeleteCheckpoint;

  public async allowed({ path }: IDeleteCheckpointCommandAllowedArguments): Promise<boolean> {
    return path.canWrite();
  }

  public async execute({ path, checkpoint }: IDeleteCheckpointCommandArguments) {
    console.log(`[${this.provider.name}] Delete checkpoint #${checkpoint.id} for ${path.path}`);
    const connection = await this.provider.getConnection();
    const response = await connection.delete2({
      paths_to_delete: [{ path: path.path, checkpoint: checkpoint.id }],
    });

    const invalidStatus = response.responses.find((status) => status !== StatusType.OK);
    if (invalidStatus) {
      throw new Error(`Cannot delete checkpoint ${checkpoint.id} for ${path} -- ${invalidStatus}.`);
    }

    path.checkpoints.map.delete(checkpoint.id!);
  }
}
