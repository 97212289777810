import React from "react";
import useDialog from "../hooks/useDialog";
import usePermission from "../hooks/usePermission";
import { Commands } from "../state/commands/Provider";
import { ICopyCommand } from "../state/commands/types/CopyCommand";
import { IDeleteCommand, IDeleteCommandAllowedArguments } from "../state/commands/types/DeleteCommand";
import IFindSimilarFilesCommand, {
  IFindSimilarFilesCommandAllowedArguments,
} from "../state/commands/types/FindSimilarFilesCommand";
import { IMoveCommand, IMoveCommandAllowedArguments } from "../state/commands/types/MoveCommand";
import Path from "../state/Path";
import search from "../state/PathSearch";
import { Icons } from "./Icon";
import Loader from "./Loader";
import Menu from "./Menu";
import { MenuIconItem, MenuItemDivider } from "./MenuItem";
import PathDeleteDialog from "./PathDeleteDialog";
import PathRenameDialog from "./PathRenameDialog";
import PathShareDialog from "./PathShareDialog";

export interface FileMenuProps {
  path: Path;
}

const FileMenu: React.FC<FileMenuProps> = ({ path }) => {
  const pathRenameDialog = useDialog(PathRenameDialog, { path });
  const pathDeleteConfirmation = useDialog(PathDeleteDialog, { path });
  const pathShareDialog = useDialog(PathShareDialog, { path });

  function copyFile() {
    path.storage.clipboard.copy([path]);
  }

  function renameFile() {
    pathRenameDialog.show();
  }

  function deleteFile() {
    pathDeleteConfirmation.show();
  }

  function shareFile() {
    pathShareDialog.show();
  }

  function downloadFile() {
    return path.download();
  }

  async function findSimilar() {
    const command = path.storage.commands.get<IFindSimilarFilesCommand>(Commands.FindSimilar);
    if (command) {
      await command.execute({ search, file: path });
    }
  }

  const supportsCopy = path.storage.commands.has<ICopyCommand>(Commands.Copy);
  const allowedToRename = usePermission(
    () => path.storage.commands.allowed<IMoveCommand, IMoveCommandAllowedArguments>(Commands.Move, { source: [path] }),
    [path]
  );
  const allowedToDelete = usePermission(
    () => path.storage.commands.allowed<IDeleteCommand, IDeleteCommandAllowedArguments>(Commands.Delete, { path }),
    [path]
  );
  const allowedToFindSimilar = usePermission(
    () =>
      path.storage.commands.allowed<IFindSimilarFilesCommand, IFindSimilarFilesCommandAllowedArguments>(
        Commands.FindSimilar,
        { file: path }
      ),
    [path]
  );

  const loading = allowedToRename === "loading" || allowedToDelete === "loading" || allowedToFindSimilar === "loading";
  if (loading) {
    return (
      <Menu>
        <Loader />
      </Menu>
    );
  }

  return (
    <Menu>
      <MenuIconItem icon={Icons.Copy} visible={supportsCopy} onClick={copyFile}>
        Copy
      </MenuIconItem>

      <MenuIconItem icon={Icons.Edit} visible={allowedToRename === "allowed"} onClick={renameFile}>
        Rename
      </MenuIconItem>

      <MenuIconItem icon={Icons.Delete} visible={allowedToDelete === "allowed"} onClick={deleteFile}>
        Delete
      </MenuIconItem>

      <MenuItemDivider />

      <MenuIconItem icon={Icons.Search} visible={allowedToFindSimilar === "allowed"} onClick={findSimilar}>
        Find similar
      </MenuIconItem>

      <MenuIconItem icon={Icons.Share} onClick={shareFile}>
        Share
      </MenuIconItem>

      <MenuIconItem icon={Icons.Download} visible={path.canDownload()} onClick={downloadFile}>
        Download
      </MenuIconItem>
    </Menu>
  );
};

export default FileMenu;
