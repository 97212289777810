import styled from "styled-components";
import Spinner from "./Spinner";

const Button = styled.button`
  background: #e0e0e0;
  color: #6e6e6e;
  box-sizing: border-box;
  height: 30px;
  padding: 0 10px;
  min-width: 100px;
  border: 1px solid transparent;
  border-radius: 2px;
  box-shadow: 0 3px 5px -2px #222;
  font-family: unset;
  font-size: 11pt;
  cursor: pointer;
  outline: none;
  line-height: 1;

  &:active {
    box-shadow: none;
    outline: none;
  }

  & > ${Spinner} {
    margin-right: 0.5em;
  }
`;

export default Button;