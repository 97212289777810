import styled from "styled-components";
import Icon from "./Icon";

const PopupIcon = styled(Icon)`
  margin-right: 10px;
  font-size: 14pt;
  vertical-align: text-bottom;
`;

export default PopupIcon;
