import { StatusType } from "@omniverse/api/data";
import { ListError } from "../../../util/PathErrors";
import Path from "../../Path";
import { IListCommandArguments } from "../types/ListCommand";
import { Commands } from "../Provider";
import { NucleusCommand } from "./index";
import { parseNucleusPath } from "./Nucleus";

export default class NucleusListCommand extends NucleusCommand<IListCommandArguments, undefined, Path[]> {
  public readonly name: string = Commands.List;

  public async allowed(): Promise<boolean> {
    return true;
  }

  public async execute({ path }: IListCommandArguments): Promise<Path[]> {
    console.log(`[${this.provider.name}] List ${path.path} content`);

    path.setLoading(true);

    let results;
    try {
      const connection = await this.provider.getConnection();
      results = await connection.list({ uri: path.path, recursive: false, show_hidden: false });
      const children: Path[] = [];

      let subscribed = true;
      do {
        const result = await results.read();
        if (typeof result === "symbol") {
          break;
        }

        if (result.status === StatusType.Latest) {
          break;
        }

        if (result.status === StatusType.OK) {
          const child = await parseNucleusPath(result, path, this.provider.linkGenerator);
          children.push(child);
        } else {
          throw new ListError(result);
        }
      } while (subscribed);

      path.mergeChildren(children);
      return children;
    } catch (error) {
      if (results) {
        await results.close();
      }

      if (typeof error === "symbol") {
        throw new ListError({ status: StatusType.ConnectionLost });
      } else {
        throw error;
      }
    } finally {
      path.setLoading(false);
    }
  }
}
