import { useEffect } from "react";
import Grid from "../components/Grid";

export default function useGridSelectionFocus(grid: Grid<any, any> | undefined | null, cellIndex: number) {
  useEffect(() => {
    if (cellIndex !== -1) {
      const scrolling = setTimeout(() => {
        // This timeout is required to wait for the side panel animation
        grid?.scrollToCell(cellIndex);
      }, 100);

      return () => clearTimeout(scrolling);
    }
  }, [grid, cellIndex]);
}
