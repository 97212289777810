import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import AutoComplete, { AutoCompleteMatch, AutoCompleteProps, AutoCompleteSuggestion } from "./AutoComplete";
import Icon, { IconProps, Icons } from "./Icon";
import { StyledRenderer } from "./index";
import Input from "./Input";
import { DefaultTheme } from "./Theme";

export interface ListProps {
  items: string[];
  suggestions?: string[];
  disabled?: boolean;
  canEdit?: boolean;
  allowAny?: boolean;
  className?: string;
  itemComponent?: StyledRenderer<ListItemProps>;
  inputComponent?: StyledRenderer<ListInputProps>;
  onAdd?(item: string): void;
  onEdit?(oldItem: string, newItem: string): void;
  onDelete?(item: string): void;
}

const List: React.FC<ListProps> = ({
  items,
  suggestions,
  className,
  disabled = true,
  canEdit = false,
  allowAny = true,
  itemComponent: Item = ListItem,
  inputComponent: InputComponent = ListAutoComplete,
  onAdd,
  onEdit,
  onDelete,
  ...props
}) => {
  const [modified, setModified] = useState<Set<string>>(new Set());

  const addModified = useCallback(
    (value: string) => {
      setModified((modified) => {
        const newModified = new Set(modified);
        newModified.add(value);
        return newModified;
      });
    },
    []
  );

  const submitModified = useCallback(
    (oldValue: string, newValue: string) => {
      setModified((modified) => {
        const newModified = new Set(modified);
        newModified.delete(oldValue);
        return newModified;
      });

      if (onEdit) {
        onEdit(oldValue, newValue);
      }
    },
    [onEdit]
  );

  const cancelModification = useCallback(
    (item: string) => {
      setModified((modified) => {
        const newModified = new Set(modified);
        newModified.delete(item);
        return newModified;
      });
    },
    []
  );

  return (
    <StyledList className={className} {...props}>
      {items && items.length
        ? items.map((item) =>
            modified.has(item) ? (
              <InputComponent
                key={item}
                initial={item}
                suggestions={suggestions}
                icon={Icons.Edit}
                focused
                allowCancel
                allowAny={allowAny}
                onSubmit={(newValue: string) => submitModified(item, newValue)}
                onCancel={() => cancelModification(item)}
              />
            ) : (
              <Item
                key={item}
                item={item}
                disabled={disabled}
                canEdit={canEdit}
                onEdit={addModified}
                onDelete={onDelete}
              />
            )
          )
        : null}
      {!disabled && onAdd && <InputComponent icon={Icons.Add} suggestions={suggestions} onSubmit={onAdd} />}
    </StyledList>
  );
};

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.inputBackground};
  color: ${({ theme }) => theme.colors.inputText};
`;
StyledList.defaultProps = {
  theme: DefaultTheme,
};

export interface ListItemProps {
  item: string;
  disabled: boolean;
  canEdit: boolean;
  className?: string;
  onEdit?(item: string): void;
  onDelete?(item: string): void;
}

export const ListItem: React.FC<ListItemProps & { image?: StyledRenderer }> = ({
  item,
  image: Image = React.Fragment,
  disabled,
  canEdit,
  className,
  onEdit,
  onDelete,
}) => {
  const editItem = useCallback(() => {
    if (canEdit && onEdit) {
      onEdit(item);
    }
  }, [item, canEdit, onEdit]);

  const deleteItem = useCallback(() => {
    if (!disabled && onDelete) {
      onDelete(item);
    }
  }, [item, disabled, onDelete]);

  return (
    <StyledListItem className={className} onClick={editItem}>
      <Image />
      <ListItemText title={item}>{item}</ListItemText>
      {!disabled && (
        <ListItemIcons>
          <DeleteIcon onClick={deleteItem} />
          {canEdit && <EditIcon onClick={editItem} />}
        </ListItemIcons>
      )}
    </StyledListItem>
  );
};

const StyledListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 10px;
  overflow: hidden;
`;

const ListItemText = styled.span`
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 10px;
`;

const ListItemIcons = styled.div`
  margin-left: auto;
  font-size: 12pt;
`;

const ListItemIcon = styled(Icon).attrs({ clickable: true })`
  margin-right: 20px;
`;

export const EditIcon = styled(ListItemIcon).attrs({
  icon: Icons.Edit,
})``;

export const DeleteIcon = styled(ListItemIcon).attrs({
  icon: Icons.Remove,
})`
  color: ${({ theme }) => theme.colors.dangerText};
`;
DeleteIcon.defaultProps = {
  theme: DefaultTheme,
};

export interface ListInputProps {
  icon?: string;
  initial?: string;
  image?: StyledRenderer;
  placeholder?: string;
  suggestions?: string[];
  allowAny?: boolean;
  allowCancel?: boolean;
  focused?: boolean;
  className?: string;
  inputComponent?: AutoCompleteProps["inputComponent"];
  matchComponent?: AutoCompleteProps["matchComponent"];
  suggestionComponent?: AutoCompleteProps["suggestionComponent"];
  iconComponent?: StyledRenderer<ListInputIconProps>;
  controlsComponent?: StyledRenderer;
  onSubmit(item: string): void;
  onCancel?(): void;
}

export const ListAutoComplete: React.FC<ListInputProps> = ({
  icon,
  initial = "",
  image: Image = React.Fragment,
  placeholder,
  suggestions = [],
  allowAny = true,
  allowCancel = false,
  focused,
  className,
  inputComponent = StyledListInput,
  matchComponent = StyledMatch,
  suggestionComponent = StyledSuggestion,
  iconComponent: IconComponent = ListInputIcon,
  controlsComponent: Controls = ListInputControls,
  onSubmit,
  onCancel,
}) => {
  const input = useRef<HTMLInputElement>(null);
  const [item, setItem] = useState(initial);

  const valueIsAllowed = useMemo(() => {
    return !!item && (allowAny || suggestions.includes(item));
  }, [item, suggestions, allowAny]);

  const onIconClick = useCallback(() => {
    if (valueIsAllowed) {
      setItem("");
      onSubmit(item);
    } else if (input.current) {
      input.current.focus();
    }
  }, [item, valueIsAllowed, onSubmit]);

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" && item) {
        e.stopPropagation();
        e.preventDefault();
        onIconClick();
      }
    },
    [item, onIconClick]
  );

  const handleItemChange = useCallback((value: string) => {
    setItem(value);
  }, []);

  useEffect(() => {
    if (focused && input.current) {
      input.current.focus();
    }
  }, [focused]);

  return (
    <Wrapper>
      <Image />
      <AutoComplete
        ref={input}
        className={className}
        inputComponent={inputComponent}
        matchComponent={matchComponent}
        suggestionComponent={suggestionComponent}
        suggestions={suggestions}
        placeholder={placeholder}
        value={item}
        onChange={handleItemChange}
        onKeyUp={handleKeyUp}
      />
      <Controls>
        {allowCancel && <IconComponent icon={Icons.Undo} title={"Cancel"} allowed onClick={onCancel} />}
        {icon && <IconComponent icon={icon} title={"Submit"} allowed={valueIsAllowed} onClick={onIconClick} />}
      </Controls>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

export const StyledListInput = styled(Input)`
  padding: 5px 50px 5px 40px;
  border: none;
  outline: none;
  border-top: 1px solid ${({ theme }) => theme.colors.background};
  font-size: 10pt;

  &::placeholder {
    font-size: 10pt;
  }
`;
StyledListInput.defaultProps = {
  theme: DefaultTheme,
};

const StyledMatch = styled(AutoCompleteMatch)`
  position: sticky;
  box-sizing: border-box;
  margin: 0 5px;
  width: calc(100% - 15px);
  background: ${({ theme }) => theme.colors.inputBackground};
`;
StyledMatch.defaultProps = {
  theme: DefaultTheme,
};

const StyledSuggestion = styled(AutoCompleteSuggestion)`
  font-size: 10pt;
  &:hover {
    background: ${({ theme }) => theme.colors.selectedItemHover};
    color: ${({ theme }) => theme.colors.selectedItemForeground};
  }
`;
StyledSuggestion.defaultProps = {
  theme: DefaultTheme,
};

export const ListInputControls = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
`;

export interface ListInputIconProps extends IconProps {
  allowed: boolean;
}

export const ListInputIcon = styled(({ allowed, ...props }: ListInputIconProps) => <Icon {...props} />)`
  font-size: 12pt;
  margin-top: 7px;
  margin-right: 20px;
  color: ${({ allowed, theme }) => (allowed ? theme.colors.successBackground : theme.colors.placeholders)};
`;
ListInputIcon.defaultProps = {
  theme: DefaultTheme,
};

export default List;
