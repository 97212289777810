import Schema from "@omniverse/idl/schema";
import * as _data from "./data";

export class ServerFeatures {
  constructor(transport) {
    this.transport = transport;
  }
  
  /*
    
   */
  async exchangeCapabilities() {
    const _request = {client_capabilities: _data.ServerFeaturesClientCapabilities.value, };
    if (!_data.ServerFeaturesExchangeCapabilitiesRequest.validate(_request)) {
      throw new Error("Invalid ServerFeaturesExchangeCapabilitiesRequest.");
    }
    return await this.transport.call({ interfaceName: "ServerFeatures", methodName: "exchange_capabilities", request: _request, schemas: { request: _data.ServerFeaturesExchangeCapabilitiesRequest, response: _data.CapabilitiesResponse }});
  }
  
  /*
    
   */
  async omniObjects() {
    const _request = {};
    if (!_data.ServerFeaturesOmniObjectsRequest.validate(_request)) {
      throw new Error("Invalid ServerFeaturesOmniObjectsRequest.");
    }
    return await this.transport.call({ interfaceName: "ServerFeatures", methodName: "omni_objects", request: _request, schemas: { request: _data.ServerFeaturesOmniObjectsRequest, response: _data.OmniObjectsResponse }});
  }
  
  /*
    
   */
  async omniObjects2() {
    const _request = {};
    if (!_data.ServerFeaturesOmniObjects2Request.validate(_request)) {
      throw new Error("Invalid ServerFeaturesOmniObjects2Request.");
    }
    return await this.transport.call({ interfaceName: "ServerFeatures", methodName: "omni_objects2", request: _request, schemas: { request: _data.ServerFeaturesOmniObjects2Request, response: _data.OmniObjects2Response }});
  }
  
  /*
    
   */
  async lft() {
    const _request = {};
    if (!_data.ServerFeaturesLftRequest.validate(_request)) {
      throw new Error("Invalid ServerFeaturesLftRequest.");
    }
    return await this.transport.call({ interfaceName: "ServerFeatures", methodName: "lft", request: _request, schemas: { request: _data.ServerFeaturesLftRequest, response: _data.LftResponse }});
  }
  
  /*
    
   */
  async hashes() {
    const _request = {};
    if (!_data.ServerFeaturesHashesRequest.validate(_request)) {
      throw new Error("Invalid ServerFeaturesHashesRequest.");
    }
    return await this.transport.call({ interfaceName: "ServerFeatures", methodName: "hashes", request: _request, schemas: { request: _data.ServerFeaturesHashesRequest, response: _data.HashesResponse }});
  }
  
  /*
    
   */
  async versioning() {
    const _request = {};
    if (!_data.ServerFeaturesVersioningRequest.validate(_request)) {
      throw new Error("Invalid ServerFeaturesVersioningRequest.");
    }
    return await this.transport.call({ interfaceName: "ServerFeatures", methodName: "versioning", request: _request, schemas: { request: _data.ServerFeaturesVersioningRequest, response: _data.VersioningResponse }});
  }
  
}
ServerFeatures[Schema.InterfaceName] = "ServerFeatures";
ServerFeatures[Schema.InterfaceOrigin] = "omni1.idl.ts";
ServerFeatures[Schema.InterfaceCapabilities] = _data.ServerFeaturesClientLocalCapabilities.value;

export class Connection {
  constructor(transport) {
    this.transport = transport;
  }
  
  /*
    
   */
  async ping({ts, } = {}) {
    const _request = {ts, };
    if (!_data.ConnectionPingRequest.validate(_request)) {
      throw new Error("Invalid ConnectionPingRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "ping", request: _request, schemas: { request: _data.ConnectionPingRequest, response: _data.PingResponse }});
  }
  
  /*
    Authorize
    At version 2
    In "Auth" response - "connection_id_signature" is added.
    At version 1:
    In parameters - "client_capabilities" are added.
    In "Auth" response - "version", "server_capabilities" are added. New status
    - "TokenExpired".
   */
  async auth({username, password, token, ssoCookie, } = {}) {
    const _request = {version: _data.Version.value, client_capabilities: _data.ConnectionClientCapabilities.value, username, password, token, ssoCookie, };
    if (!_data.ConnectionAuthRequest.validate(_request)) {
      throw new Error("Invalid ConnectionAuthRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "auth", request: _request, schemas: { request: _data.ConnectionAuthRequest, response: _data.Auth }});
  }
  
  /*
    Authorize with a token
    At version 1:
    In "Auth" response - "connection_id_signature" is added.
   */
  async authorizeToken({token, }) {
    const _request = {token, version: _data.Version.value, client_capabilities: _data.ConnectionClientCapabilities.value, };
    if (!_data.ConnectionAuthorizeTokenRequest.validate(_request)) {
      throw new Error("Invalid ConnectionAuthorizeTokenRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "authorize_token", request: _request, schemas: { request: _data.ConnectionAuthorizeTokenRequest, response: _data.Auth }});
  }
  
  /*
    
   */
  async subscribeServerNotifications() {
    const _request = {};
    if (!_data.ConnectionSubscribeServerNotificationsRequest.validate(_request)) {
      throw new Error("Invalid ConnectionSubscribeServerNotificationsRequest.");
    }
    return await this.transport.callMany({ interfaceName: "Connection", methodName: "subscribe_server_notifications", request: _request, schemas: { request: _data.ConnectionSubscribeServerNotificationsRequest, response: _data.ServerNotificationResponse }});
  }
  
  /*
    Stat a path
    At version 1:
    In response: "locked_by", "lock_time", "lock_owner", "lock_duration",
    "lock_etag" were added
   */
  async stat2({path, }) {
    const _request = {path, };
    if (!_data.ConnectionStat2Request.validate(_request)) {
      throw new Error("Invalid ConnectionStat2Request.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "stat2", request: _request, schemas: { request: _data.ConnectionStat2Request, response: _data.Stat2Result }});
  }
  
  /*
    List directory by mask and be notified about changes.
    At version 4:
    In response: "locked_by", "lock_time", "lock_owner", "lock_duration",
    "lock_etag" were added
    At version 3:
    In response: PARTIALLY_COMPLETED status returned in case early break
    At version 2:
    In response: "destination", "created_date_seconds",
    "modified_date_seconds", "empty" fields are added. PathEvent::Rename is
    added.
    At version 1:
    In response: "PathEvent::Options" is added. "transaction_id" is added.
   */
  async list({uri, recursive, show_hidden, type, }) {
    const _request = {uri, recursive, show_hidden, type, };
    if (!_data.ConnectionListRequest.validate(_request)) {
      throw new Error("Invalid ConnectionListRequest.");
    }
    return await this.transport.callMany({ interfaceName: "Connection", methodName: "list", request: _request, schemas: { request: _data.ConnectionListRequest, response: _data.Path }});
  }
  
  /*
    List paths in Omniverse, only directories can be listed, apply branch
    resolution rules
    At version 4:
    In response: added optional checkpoint_count field
    At version 3:
    In request: show_hidden optional argument is added
    At version 2:
    In response: PARTIALLY_COMPLETED status returned in case early break
    At version 1:
    In response: "locked_by", "lock_time", "lock_owner", "lock_duration",
    "lock_etag" were added
   */
  async list2({path, branches, path_types, show_hidden, }) {
    const _request = {path, branches, path_types, show_hidden, };
    if (!_data.ConnectionList2Request.validate(_request)) {
      throw new Error("Invalid ConnectionList2Request.");
    }
    return await this.transport.callMany({ interfaceName: "Connection", methodName: "list2", request: _request, schemas: { request: _data.ConnectionList2Request, response: _data.List2Response }});
  }
  
  /*
    Subscribe to changes in a directory. Only directories are supported.
    Non-recursive subscriptions only.
    At version 1:
    In response: added optional checkpoint_count field and
    PathType::CheckpointsChanged event
   */
  async subscribeList({path, }) {
    const _request = {path, };
    if (!_data.ConnectionSubscribeListRequest.validate(_request)) {
      throw new Error("Invalid ConnectionSubscribeListRequest.");
    }
    return await this.transport.callMany({ interfaceName: "Connection", methodName: "subscribe_list", request: _request, schemas: { request: _data.ConnectionSubscribeListRequest, response: _data.SubscribeListResponse }});
  }
  
  /*
    Create a path
    At version 2:
    In response - StatusType::NotObject is added.
    !!! IMPLICIT CHANGE !!! - "overwrite" parameter is fixed to work correctly
    with LFT
    At version 1:
    In request parameters - optional "overwrite" flag is added.
   */
  async create({uri, content, type, content_id, overwrite, }) {
    const _request = {uri, content, type, content_id, overwrite, };
    if (!_data.ConnectionCreateRequest.validate(_request)) {
      throw new Error("Invalid ConnectionCreateRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "create", request: _request, schemas: { request: _data.ConnectionCreateRequest, response: _data.UploadResult }});
  }
  
  /*
    Update a path
    At version 1:
    In response - StatusType::NotObject is added.
   */
  async update({uri, etag, delta, content, content_id, ts, }) {
    const _request = {uri, etag, delta, content, content_id, ts, };
    if (!_data.ConnectionUpdateRequest.validate(_request)) {
      throw new Error("Invalid ConnectionUpdateRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "update", request: _request, schemas: { request: _data.ConnectionUpdateRequest, response: _data.UploadResult }});
  }
  
  /*
    Create an asset
   */
  async createAsset({path, content, content_id, overwrite, }) {
    const _request = {path, content, content_id, overwrite, };
    if (!_data.ConnectionCreateAssetRequest.validate(_request)) {
      throw new Error("Invalid ConnectionCreateAssetRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "create_asset", request: _request, schemas: { request: _data.ConnectionCreateAssetRequest, response: _data.CreateAssetResult }});
  }
  
  /*
    Update an asset
   */
  async updateAsset({path, etag, delta, content, content_id, ts, }) {
    const _request = {path, etag, delta, content, content_id, ts, };
    if (!_data.ConnectionUpdateAssetRequest.validate(_request)) {
      throw new Error("Invalid ConnectionUpdateAssetRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "update_asset", request: _request, schemas: { request: _data.ConnectionUpdateAssetRequest, response: _data.UpdateAssetResult }});
  }
  
  /*
    
   */
  async createAssetWithHash({path, hash_value, hash_type, hash_bsize, overwrite, }) {
    const _request = {path, hash_value, hash_type, hash_bsize, overwrite, };
    if (!_data.ConnectionCreateAssetWithHashRequest.validate(_request)) {
      throw new Error("Invalid ConnectionCreateAssetWithHashRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "create_asset_with_hash", request: _request, schemas: { request: _data.ConnectionCreateAssetWithHashRequest, response: _data.CreateAssetWithHashResult }});
  }
  
  /*
    
   */
  async updateAssetWithHash({path, hash_value, hash_type, hash_bsize, etag, delta, ts, }) {
    const _request = {path, hash_value, hash_type, hash_bsize, etag, delta, ts, };
    if (!_data.ConnectionUpdateAssetWithHashRequest.validate(_request)) {
      throw new Error("Invalid ConnectionUpdateAssetWithHashRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "update_asset_with_hash", request: _request, schemas: { request: _data.ConnectionUpdateAssetWithHashRequest, response: _data.UpdateAssetWithHashResult }});
  }
  
  /*
    Create an object
   */
  async createObject({path, content, content_id, overwrite, }) {
    const _request = {path, content, content_id, overwrite, };
    if (!_data.ConnectionCreateObjectRequest.validate(_request)) {
      throw new Error("Invalid ConnectionCreateObjectRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "create_object", request: _request, schemas: { request: _data.ConnectionCreateObjectRequest, response: _data.CreateObjectResult }});
  }
  
  /*
    Update an object
   */
  async updateObject({path, object_id, content, content_id, ts, }) {
    const _request = {path, object_id, content, content_id, ts, };
    if (!_data.ConnectionUpdateObjectRequest.validate(_request)) {
      throw new Error("Invalid ConnectionUpdateObjectRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "update_object", request: _request, schemas: { request: _data.ConnectionUpdateObjectRequest, response: _data.UpdateObjectResult }});
  }
  
  /*
    Deep copy object structure without values
   */
  async deepCopyObjectStruct({src_path, dst_path, }) {
    const _request = {src_path, dst_path, };
    if (!_data.ConnectionDeepCopyObjectStructRequest.validate(_request)) {
      throw new Error("Invalid ConnectionDeepCopyObjectStructRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "deep_copy_object_struct", request: _request, schemas: { request: _data.ConnectionDeepCopyObjectStructRequest, response: _data.DeepCopyObjectStructResult }});
  }
  
  /*
    Read and subscribe for a path
   */
  async read({uri, etag, }) {
    const _request = {uri, etag, };
    if (!_data.ConnectionReadRequest.validate(_request)) {
      throw new Error("Invalid ConnectionReadRequest.");
    }
    return await this.transport.callMany({ interfaceName: "Connection", methodName: "read", request: _request, schemas: { request: _data.ConnectionReadRequest, response: _data.ReadResult }});
  }
  
  /*
    Read and subscribe for an asset with version
   */
  async readAssetVersion({path, etag, }) {
    const _request = {path, etag, };
    if (!_data.ConnectionReadAssetVersionRequest.validate(_request)) {
      throw new Error("Invalid ConnectionReadAssetVersionRequest.");
    }
    return await this.transport.callMany({ interfaceName: "Connection", methodName: "read_asset_version", request: _request, schemas: { request: _data.ConnectionReadAssetVersionRequest, response: _data.ReadAssetVersionResult }});
  }
  
  /*
    Read and subscribe for an asset in branches
   */
  async readAssetResolved({path, branches, }) {
    const _request = {path, branches, };
    if (!_data.ConnectionReadAssetResolvedRequest.validate(_request)) {
      throw new Error("Invalid ConnectionReadAssetResolvedRequest.");
    }
    return await this.transport.callMany({ interfaceName: "Connection", methodName: "read_asset_resolved", request: _request, schemas: { request: _data.ConnectionReadAssetResolvedRequest, response: _data.ReadAssetResolvedResult }});
  }
  
  /*
    Read an asset and subscribe to further updates
   */
  async subscribeReadAsset({path, etag, }) {
    const _request = {path, etag, };
    if (!_data.ConnectionSubscribeReadAssetRequest.validate(_request)) {
      throw new Error("Invalid ConnectionSubscribeReadAssetRequest.");
    }
    return await this.transport.callMany({ interfaceName: "Connection", methodName: "subscribe_read_asset", request: _request, schemas: { request: _data.ConnectionSubscribeReadAssetRequest, response: _data.SubscribeReadAssetResult }});
  }
  
  /*
    Read and subscribe for an object with version
   */
  async readObjectVersion({path, sequence, }) {
    const _request = {path, sequence, };
    if (!_data.ConnectionReadObjectVersionRequest.validate(_request)) {
      throw new Error("Invalid ConnectionReadObjectVersionRequest.");
    }
    return await this.transport.callMany({ interfaceName: "Connection", methodName: "read_object_version", request: _request, schemas: { request: _data.ConnectionReadObjectVersionRequest, response: _data.ReadObjectVersionResult }});
  }
  
  /*
    Read and subscribe for an object in branches
   */
  async readObjectResolved({path, branches, }) {
    const _request = {path, branches, };
    if (!_data.ConnectionReadObjectResolvedRequest.validate(_request)) {
      throw new Error("Invalid ConnectionReadObjectResolvedRequest.");
    }
    return await this.transport.callMany({ interfaceName: "Connection", methodName: "read_object_resolved", request: _request, schemas: { request: _data.ConnectionReadObjectResolvedRequest, response: _data.ReadObjectResolvedResult }});
  }
  
  /*
    Read an object and subscribe to further updates
    At version 1:
    In parameters - "values_sequence" is added (it was subsequently removed
    without bumping the version, since it wasn't used by any clients)
   */
  async subscribeReadObject({path, object_id, sequence, }) {
    const _request = {path, object_id, sequence, };
    if (!_data.ConnectionSubscribeReadObjectRequest.validate(_request)) {
      throw new Error("Invalid ConnectionSubscribeReadObjectRequest.");
    }
    return await this.transport.callMany({ interfaceName: "Connection", methodName: "subscribe_read_object", request: _request, schemas: { request: _data.ConnectionSubscribeReadObjectRequest, response: _data.SubscribeReadObjectResult }});
  }
  
  /*
    Rename a path
   */
  async rename({source_and_destination, branch, }) {
    const _request = {source_and_destination, branch, };
    if (!_data.ConnectionRenameRequest.validate(_request)) {
      throw new Error("Invalid ConnectionRenameRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "rename", request: _request, schemas: { request: _data.ConnectionRenameRequest, response: _data.MoveResponse }});
  }
  
  /*
    Delete a path
   */
  async delete({uri, }) {
    const _request = {uri, };
    if (!_data.ConnectionDeleteRequest.validate(_request)) {
      throw new Error("Invalid ConnectionDeleteRequest.");
    }
    return await this.transport.callMany({ interfaceName: "Connection", methodName: "delete", request: _request, schemas: { request: _data.ConnectionDeleteRequest, response: _data.DeletedPath }});
  }
  
  /*
    Delete a path, doesn't support recursive removal, doesn't support wildcards
    Supports branches / checkpoints
    Only empty folders can be removed
   */
  async delete2({paths_to_delete, }) {
    const _request = {paths_to_delete, };
    if (!_data.ConnectionDelete2Request.validate(_request)) {
      throw new Error("Invalid ConnectionDelete2Request.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "delete2", request: _request, schemas: { request: _data.ConnectionDelete2Request, response: _data.Delete2Response }});
  }
  
  /*
    Copy a path, doesn't support recursive removal, doesn't support wildcards
    Supports branches / checkpoints
    Only files could be copied
    Doesn't support mounts as source
   */
  async copy2({paths_to_copy, }) {
    const _request = {paths_to_copy, };
    if (!_data.ConnectionCopy2Request.validate(_request)) {
      throw new Error("Invalid ConnectionCopy2Request.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "copy2", request: _request, schemas: { request: _data.ConnectionCopy2Request, response: _data.Copy2Response }});
  }
  
  /*
    
   */
  async createDirectory({path, }) {
    const _request = {path, };
    if (!_data.ConnectionCreateDirectoryRequest.validate(_request)) {
      throw new Error("Invalid ConnectionCreateDirectoryRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "create_directory", request: _request, schemas: { request: _data.ConnectionCreateDirectoryRequest, response: _data.CreateDirectoryResult }});
  }
  
  /*
    Lock an asset
    At version 2:
    Versioning supported - optional "branch" parameter is added.
    At version 1:
    In response - optional "etag" field is added.
    !!!IMPLICIT CHANGE!!! When "etag" is '0' - server uses latest etag
   */
  async lock({uri, etag, duration, branch, }) {
    const _request = {uri, etag, duration, branch, };
    if (!_data.ConnectionLockRequest.validate(_request)) {
      throw new Error("Invalid ConnectionLockRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "lock", request: _request, schemas: { request: _data.ConnectionLockRequest, response: _data.LockResult }});
  }
  
  /*
    Unlock an asset
    At version 1:
    Versioning supported - optional "branch" parameter is added.
   */
  async unlock({uri, force, branch, }) {
    const _request = {uri, force, branch, };
    if (!_data.ConnectionUnlockRequest.validate(_request)) {
      throw new Error("Invalid ConnectionUnlockRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "unlock", request: _request, schemas: { request: _data.ConnectionUnlockRequest, response: _data.UnlockResult }});
  }
  
  /*
    Copy a path
    At version 1:
    !!!IMPLICIT CHANGE!!! When transaction_id is '0' - server uses latest
    transaction_id
   */
  async copy({uri, to, transaction_id, }) {
    const _request = {uri, to, transaction_id, };
    if (!_data.ConnectionCopyRequest.validate(_request)) {
      throw new Error("Invalid ConnectionCopyRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "copy", request: _request, schemas: { request: _data.ConnectionCopyRequest, response: _data.CopyResult }});
  }
  
  /*
    
   */
  async getTransactionId() {
    const _request = {};
    if (!_data.ConnectionGetTransactionIdRequest.validate(_request)) {
      throw new Error("Invalid ConnectionGetTransactionIdRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "get_transaction_id", request: _request, schemas: { request: _data.ConnectionGetTransactionIdRequest, response: _data.TransactionIDResult }});
  }
  
  /*
    Set path options
    At version 1:
    Versioning support - optional "branch" and "checkpoint" parameters are
    added.
   */
  async setPathOptions({uri, created_by, modified_by, created, modified, branch, checkpoint, }) {
    const _request = {uri, created_by, modified_by, created, modified, branch, checkpoint, };
    if (!_data.ConnectionSetPathOptionsRequest.validate(_request)) {
      throw new Error("Invalid ConnectionSetPathOptionsRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "set_path_options", request: _request, schemas: { request: _data.ConnectionSetPathOptionsRequest, response: _data.SetPathOptionsResult }});
  }
  
  /*
    Sets path options by using 'uint64' type of fields, which means 'seconds
    from Epoch'
   */
  async setPathOptions2({path, created_by, modified_by, created, modified, branch, checkpoint, }) {
    const _request = {path, created_by, modified_by, created, modified, branch, checkpoint, };
    if (!_data.ConnectionSetPathOptions2Request.validate(_request)) {
      throw new Error("Invalid ConnectionSetPathOptions2Request.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "set_path_options2", request: _request, schemas: { request: _data.ConnectionSetPathOptions2Request, response: _data.SetPathOptionsResult }});
  }
  
  /*
    
   */
  async getAcl({uri, }) {
    const _request = {uri, };
    if (!_data.ConnectionGetAclRequest.validate(_request)) {
      throw new Error("Invalid ConnectionGetAclRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "get_acl", request: _request, schemas: { request: _data.ConnectionGetAclRequest, response: _data.ACLResult }});
  }
  
  /*
    
   */
  async changeAcl({uri, acl, }) {
    const _request = {uri, acl, };
    if (!_data.ConnectionChangeAclRequest.validate(_request)) {
      throw new Error("Invalid ConnectionChangeAclRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "change_acl", request: _request, schemas: { request: _data.ConnectionChangeAclRequest, response: _data.ResponseWithUri }});
  }
  
  /*
    
   */
  async getAclV2({paths, }) {
    const _request = {paths, };
    if (!_data.ConnectionGetAclV2Request.validate(_request)) {
      throw new Error("Invalid ConnectionGetAclV2Request.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "get_acl_v2", request: _request, schemas: { request: _data.ConnectionGetAclV2Request, response: _data.GetACLResponses }});
  }
  
  /*
    Returns resolved ACLs for a given set of paths.
    Resolved ACLs take into the account rules of ACL inheritance.
   */
  async getAclResolved({paths, }) {
    const _request = {paths, };
    if (!_data.ConnectionGetAclResolvedRequest.validate(_request)) {
      throw new Error("Invalid ConnectionGetAclResolvedRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "get_acl_resolved", request: _request, schemas: { request: _data.ConnectionGetAclResolvedRequest, response: _data.GetACLResolvedResponses }});
  }
  
  /*
    
   */
  async setAclV2({path_and_acls, }) {
    const _request = {path_and_acls, };
    if (!_data.ConnectionSetAclV2Request.validate(_request)) {
      throw new Error("Invalid ConnectionSetAclV2Request.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "set_acl_v2", request: _request, schemas: { request: _data.ConnectionSetAclV2Request, response: _data.SetACLResponse }});
  }
  
  /*
    
   */
  async getGroups() {
    const _request = {};
    if (!_data.ConnectionGetGroupsRequest.validate(_request)) {
      throw new Error("Invalid ConnectionGetGroupsRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "get_groups", request: _request, schemas: { request: _data.ConnectionGetGroupsRequest, response: _data.GroupList }});
  }
  
  /*
    
   */
  async getGroupUsers({group_name, }) {
    const _request = {group_name, };
    if (!_data.ConnectionGetGroupUsersRequest.validate(_request)) {
      throw new Error("Invalid ConnectionGetGroupUsersRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "get_group_users", request: _request, schemas: { request: _data.ConnectionGetGroupUsersRequest, response: _data.GetGroupUsersResponse }});
  }
  
  /*
    
   */
  async getUsers() {
    const _request = {};
    if (!_data.ConnectionGetUsersRequest.validate(_request)) {
      throw new Error("Invalid ConnectionGetUsersRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "get_users", request: _request, schemas: { request: _data.ConnectionGetUsersRequest, response: _data.UserList }});
  }
  
  /*
    
   */
  async getUserGroups({username, }) {
    const _request = {username, };
    if (!_data.ConnectionGetUserGroupsRequest.validate(_request)) {
      throw new Error("Invalid ConnectionGetUserGroupsRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "get_user_groups", request: _request, schemas: { request: _data.ConnectionGetUserGroupsRequest, response: _data.GetUserGroupsResponse }});
  }
  
  /*
    
   */
  async createGroup({group_name, }) {
    const _request = {group_name, };
    if (!_data.ConnectionCreateGroupRequest.validate(_request)) {
      throw new Error("Invalid ConnectionCreateGroupRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "create_group", request: _request, schemas: { request: _data.ConnectionCreateGroupRequest, response: _data.CreateGroupResponse }});
  }
  
  /*
    
   */
  async renameGroup({group_name, new_group_name, }) {
    const _request = {group_name, new_group_name, };
    if (!_data.ConnectionRenameGroupRequest.validate(_request)) {
      throw new Error("Invalid ConnectionRenameGroupRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "rename_group", request: _request, schemas: { request: _data.ConnectionRenameGroupRequest, response: _data.RenameGroupResponse }});
  }
  
  /*
    
   */
  async removeGroup({group_name, }) {
    const _request = {group_name, };
    if (!_data.ConnectionRemoveGroupRequest.validate(_request)) {
      throw new Error("Invalid ConnectionRemoveGroupRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "remove_group", request: _request, schemas: { request: _data.ConnectionRemoveGroupRequest, response: _data.RemoveGroupResponse }});
  }
  
  /*
    
   */
  async addUserToGroup({username, group_name, }) {
    const _request = {username, group_name, };
    if (!_data.ConnectionAddUserToGroupRequest.validate(_request)) {
      throw new Error("Invalid ConnectionAddUserToGroupRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "add_user_to_group", request: _request, schemas: { request: _data.ConnectionAddUserToGroupRequest, response: _data.AddUserToGroupResponse }});
  }
  
  /*
    
   */
  async removeUserFromGroup({username, group_name, }) {
    const _request = {username, group_name, };
    if (!_data.ConnectionRemoveUserFromGroupRequest.validate(_request)) {
      throw new Error("Invalid ConnectionRemoveUserFromGroupRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "remove_user_from_group", request: _request, schemas: { request: _data.ConnectionRemoveUserFromGroupRequest, response: _data.RemoveUserFromGroupResponse }});
  }
  
  /*
    
   */
  async mount({uri, resolver, redirect_url, options, }) {
    const _request = {uri, resolver, redirect_url, options, };
    if (!_data.ConnectionMountRequest.validate(_request)) {
      throw new Error("Invalid ConnectionMountRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "mount", request: _request, schemas: { request: _data.ConnectionMountRequest, response: _data.ResponseWithUri }});
  }
  
  /*
    
   */
  async unmount({uri, }) {
    const _request = {uri, };
    if (!_data.ConnectionUnmountRequest.validate(_request)) {
      throw new Error("Invalid ConnectionUnmountRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "unmount", request: _request, schemas: { request: _data.ConnectionUnmountRequest, response: _data.ResponseWithUri }});
  }
  
  /*
    
   */
  async getMountInfo() {
    const _request = {};
    if (!_data.ConnectionGetMountInfoRequest.validate(_request)) {
      throw new Error("Invalid ConnectionGetMountInfoRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "get_mount_info", request: _request, schemas: { request: _data.ConnectionGetMountInfoRequest, response: _data.MountsInfo }});
  }
  
  /*
    Create a checkpoint
    At version 1:
    added 'force' flag, which makes the server generate the checkpoint anyway,
    even if the path is
    already checkpointed
   */
  async checkpointVersion({path, message, force, }) {
    const _request = {path, message, force, };
    if (!_data.ConnectionCheckpointVersionRequest.validate(_request)) {
      throw new Error("Invalid ConnectionCheckpointVersionRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "checkpoint_version", request: _request, schemas: { request: _data.ConnectionCheckpointVersionRequest, response: _data.CheckpointVersionResponse }});
  }
  
  /*
    
   */
  async replaceVersion({src_path, dst_path, message, }) {
    const _request = {src_path, dst_path, message, };
    if (!_data.ConnectionReplaceVersionRequest.validate(_request)) {
      throw new Error("Invalid ConnectionReplaceVersionRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "replace_version", request: _request, schemas: { request: _data.ConnectionReplaceVersionRequest, response: _data.Response }});
  }
  
  /*
    
   */
  async getCheckpoints({path, }) {
    const _request = {path, };
    if (!_data.ConnectionGetCheckpointsRequest.validate(_request)) {
      throw new Error("Invalid ConnectionGetCheckpointsRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "get_checkpoints", request: _request, schemas: { request: _data.ConnectionGetCheckpointsRequest, response: _data.GetCheckpointsResponse }});
  }
  
  /*
    
   */
  async getBranches({path, }) {
    const _request = {path, };
    if (!_data.ConnectionGetBranchesRequest.validate(_request)) {
      throw new Error("Invalid ConnectionGetBranchesRequest.");
    }
    return await this.transport.call({ interfaceName: "Connection", methodName: "get_branches", request: _request, schemas: { request: _data.ConnectionGetBranchesRequest, response: _data.GetBranchesResponse }});
  }
  
}
Connection[Schema.InterfaceName] = "Connection";
Connection[Schema.InterfaceOrigin] = "omni1.idl.ts";
Connection[Schema.InterfaceCapabilities] = _data.ConnectionClientLocalCapabilities.value;
