import { PathPermission, StatusType } from "@omniverse/api/data";
import { SetACLError } from "../../../util/ACLErrors";
import { Commands } from "../Provider";
import { ISetACLCommandAllowedArguments, ISetACLCommandArguments } from "../types/SetACLCommand";
import { NucleusCommand } from "./index";

export default class NucleusSetACLCommand extends NucleusCommand<
  ISetACLCommandArguments,
  ISetACLCommandAllowedArguments
> {
  public name = Commands.SetACL;

  public async allowed({ path }: ISetACLCommandAllowedArguments): Promise<boolean> {
    return path.canAdmin();
  }

  public async execute({ path, permissions }: ISetACLCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Set ACL for ${path.name}`);

    const connection = await this.provider.getConnection();
    const acl: { [userOrGroup: string]: PathPermission[] } = {};
    for (const [userOrGroup, values] of permissions) {
      acl[userOrGroup] = Array.from(values);
    }

    console.table(acl);
    const result = await connection.changeAcl({ uri: path.path, acl });
    if (result.status !== StatusType.OK) {
      throw new SetACLError(result);
    }
  }
}
