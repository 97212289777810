import { observer } from "mobx-react";
import React, { useState } from "react";
import Content from "../components/Content";
import ContentBrowserMenu from "../components/ContentBrowserMenu";
import ContentHeader from "../components/ContentHeader";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import NucleusAuthDialog from "../components/NucleusAuthDialog";
import Search from "../components/Search";
import ServerGrid from "../components/ServerGrid";
import ServerJoin from "../components/ServerJoin";
import useContentBrowserNavigation from "../hooks/useContentBrowserNavigation";
import useContextMenu from "../hooks/useContextMenu";
import useDialog from "../hooks/useDialog";
import usePathParam from "../hooks/usePathParam";
import useQuery from "../hooks/useQuery";
import useServerParam from "../hooks/useServerParam";
import useStorageList from "../hooks/useStorageList";
import Storage from "../state/Storage";
import { openURL } from "../util/Browser";

const HomePage: React.FC = () => {
  const authentication = useDialog(NucleusAuthDialog);

  function showAuthentication() {
    authentication.show();
  }

  function installLocalServer() {
    openURL(`omniverse-launcher://navigate?path=/collaboration/installation/path`, { target: "" });
  }

  const serverName = useServerParam();
  const path = usePathParam();
  useContentBrowserNavigation(serverName, path);

  const storages = useStorageList();
  const [selectedStorage, setSelectedStorage] = useState<Storage | null>(null);

  const [menu, onContextMenu] = useContextMenu(ContentBrowserMenu);

  const { loading, data: isNucleusInstalled } = useQuery(
    async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_LAUNCHER_API!}/components/${process.env.REACT_APP_LAUNCHER_NUCLEUS_SLUG}`
        );
        return response.ok;
      } catch {
        return true;
      }
    },
    {
      keys: [],
    }
  );

  return (
    <>
      <ContentHeader>
        <Search disabled placeholder={"Select a Server"} />
      </ContentHeader>

      <Content onContextMenu={onContextMenu}>
        {loading ? (
          <Loader />
        ) : (
          <ServerJoin
            visibility={{ join: !storages.readOnly, remote: false, local: !Boolean(isNucleusInstalled) }}
            onJoin={showAuthentication}
            onCreateLocalServer={installLocalServer}
          />
        )}
        <ServerGrid servers={storages.items} selectedServer={selectedStorage} onSelect={setSelectedStorage} />
        {menu}
      </Content>

      <Footer />
    </>
  );
};

export default observer(HomePage);
