import { Credentials } from "@omniverse/auth";
import { useCallback } from "react";
import connect from "../Connection";

export interface CredentialServerCheck {
  ok: boolean;
  errors?: string[];
}

export default function useCredentialServerCheck() {
  return useCallback(async (server: string): Promise<CredentialServerCheck> => {
    if (!server) {
      return {
        ok: false,
        errors: ["You have to specify the server."],
      };
    }

    try {
      const connection = await connect(server, Credentials, { "auth": 0 });
      connection.transport.close();
      return {
        ok: true,
      };
    } catch (error) {
      console.log(error);
      return {
        ok: false,
        errors: ["Failed to connect to the server."],
      };
    }
  }, []);
}
