import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { Icons } from "./Icon";
import Notification from "./Notification";
import { formatFileSize } from "../util/File";
import TaskView from "./TaskView";
import Download from "../state/Download";
import useApp from "../hooks/useApp";

export interface PathUploadNotificationProps {}

const DownloadNotification: React.FC<PathUploadNotificationProps> = () => {
  const app = useApp();
  const [minimized, setMinimized] = useState(false);

  const toggle = () => {
    setMinimized((value) => !value);
  };

  const closeUpload = (id: string) => {
    app.downloads.remove(id);
  };

  const downloads = app.downloads.entries;

  useEffect(() => {
    if (downloads.length > 0) {
      setMinimized(false);
    }
  }, [downloads.length, setMinimized]);

  if (downloads.length === 0) {
    return null;
  }

  return (
    <Notification title={"Download"} minimized={minimized} icon={Icons.Download} onToggle={toggle}>
      {downloads.map(([id, download]) => (
        <DownloadProgress key={id} download={download} onClose={() => closeUpload(id)} onAbort={download.abort} />
      ))}
    </Notification>
  );
};

export interface PathUploadProgressProps {
  download: Download;
  onClose(upload: Download): void;
  onAbort(upload: Download): void;
}

let DownloadProgress: React.FC<PathUploadProgressProps> = ({ download, onClose, onAbort }) => {
  const statusText = useMemo(() => {
    let text = formatFileSize(download.transferredSize);
    if (download.totalSize) {
      text += ` / ${formatFileSize(download.totalSize)}`;
    }
    return text;
  }, [download.totalSize, download.transferredSize]);

  return (
    <TaskView
      process={download}
      percent={(download.transferredSize / download.totalSize) * 100}
      text={download.fileName}
      status={download.status}
      statusText={statusText}
      onClose={onClose}
      onAbort={onAbort}
    />
  );
};
DownloadProgress = observer(DownloadProgress);

export default observer(DownloadNotification);
