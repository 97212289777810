import styled from "styled-components";
import Button from "./Button";

const LoginButton = styled(Button)`
  background: #76b900;
  color: white;
  border: none;
  text-transform: uppercase;
`;

export default LoginButton;
