import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import useMouse from "../hooks/useMouse";
import { StyledRenderer } from "./index";
import { TableColumn, TableHeaderComponentProps } from "./Table";

export interface TableColumnOrderingProps {
  columns: TableColumn[];
  headerComponent: StyledRenderer<TableHeaderComponentProps>;
  children(data: TableColumnOrderingData): JSX.Element;
}

export interface TableColumnOrderingData {
  columns: TableColumn[];
  headerComponent(props: TableHeaderComponentProps): JSX.Element;
}

const TableColumnOrdering: React.FC<TableColumnOrderingProps> = ({ columns, headerComponent, children }) => {
  const [orderedColumns, setOrderedColumns] = useState(columns);
  const [order, setOrder] = useState(() => columns.map((column) => column.name));
  const [draggedColumn, drag] = useState<TableColumn | null>(null);

  const release = useCallback(() => {
    drag(null);
  }, []);

  const reorder = useCallback(
    (source: string, destination: string) => {
      const from = order.indexOf(source);
      const to = order.indexOf(destination);

      if (from === -1 || to === -1) {
        return;
      }

      const newOrder = [...order];
      newOrder.splice(from, 1);

      if (from < to) {
        newOrder.splice(to - 1, 1, order[to], order[from]);
      } else {
        newOrder.splice(to, 1, order[from], order[to]);
      }

      setOrder(newOrder);
      setOrderedColumns(
        newOrder.map((columnName) => columns.find((column) => column.name === columnName) as TableColumn)
      );
    },
    [columns, order]
  );

  useEffect(() => {
    document.addEventListener("mouseup", release);
    return () => document.removeEventListener("mouseup", release);
  }, [release]);

  useEffect(() => {
    setOrderedColumns((orderedColumns) => {
      if (
        orderedColumns.length === columns.length &&
        orderedColumns.every((orderedColumn) => columns.find((column) => column.name === orderedColumn.name))
      ) {
        return orderedColumns.map(
          (orderedColumn) => columns.find((column) => column.name === orderedColumn.name) as TableColumn
        );
      } else {
        setOrder(columns.map((column) => column.name));
        return columns;
      }
    });
  }, [columns]);

  const context = useMemo(
    (): TableColumnOrderingContextState => ({
      order,
      orderedColumns,
      draggedColumn,
      drag,
      header: headerComponent,
      release,
      reorder,
    }),
    [order, orderedColumns, draggedColumn, drag, headerComponent, release, reorder]
  );

  return (
    <TableColumnOrderingContext.Provider value={context}>
      {children({
        columns: orderedColumns,
        headerComponent: TableOrderedHeader,
      })}

      {draggedColumn && <TableColumnDragPanel column={draggedColumn} />}
    </TableColumnOrderingContext.Provider>
  );
};

export interface TableColumnOrderingContextState {
  order: string[];
  orderedColumns: TableColumn[];
  draggedColumn: TableColumn | null;
  header: StyledRenderer<TableHeaderComponentProps>;
  drag(column: TableColumn | null): void;
  reorder(source: string, destination: string): void;
  release(): void;
}

export const TableColumnOrderingContext = React.createContext<TableColumnOrderingContextState>(
  {} as TableColumnOrderingContextState
);

const TableOrderedHeader = ({ column, style, ...props }: TableHeaderComponentProps) => {
  const context = useContext(TableColumnOrderingContext);
  const Header = context.header;

  function drag(e: React.DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    context.drag(column);
  }

  function drop() {
    const source = context.draggedColumn;
    if (!source) {
      return;
    }

    if (source.name !== column.name) {
      context.reorder(source.name, column.name);
    }

    context.release();
  }

  return (
    <ColumnDrag style={style} onDragStart={drag} onMouseUp={drop}>
      <Header column={column} style={style} {...props} />
    </ColumnDrag>
  );
};

const ColumnDrag = styled.div.attrs({ draggable: true })`
  padding: 1px;
`;

const TableColumnDragPanel: React.FC<{
  column: TableColumn;
}> = ({ column }) => {
  const { x, y, ready } = useMouse();
  if (ready) {
    return (
      <StyledTableColumnDragPanel x={x} y={y} width={column.width}>
        {column.title}
      </StyledTableColumnDragPanel>
    );
  }
  return null;
};

interface StyledTableColumnDragPanelProps {
  x: number;
  y: number;
  width?: number;
}

const StyledTableColumnDragPanel = styled.div.attrs(({ x, y, width }: StyledTableColumnDragPanelProps) => ({
  style: {
    left: x + cursorOffset,
    top: y,
    width,
  },
}))<StyledTableColumnDragPanelProps>`
  position: fixed;
  pointer-events: none;
  user-select: none;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.importantText};
  border: 1px solid ${({ theme }) => theme.colors.border};
  height: 30px;
  box-sizing: border-box;
  padding: 5px 10px;
  font-size: 10pt;
  z-index: 30000;
`;

const cursorOffset = 10;

export default TableColumnOrdering;
