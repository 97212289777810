import React, { useCallback } from "react";
import ButtonGroup from "./ButtonGroup";
import Form from "./Form";
import FormErrorList from "./FormErrorList";
import FormGroup from "./FormGroup";
import useCredentialServerCheck from "./hooks/CredentialServerCheck";
import useForm, { FormErrors } from "./hooks/Form";
import { useInput } from "./hooks/Input";
import Input from "./Input";
import LoginButton from "./LoginButton";
import NvidiaLogo from "./NvidiaLogo";
import OmniverseLogo from "./OmniverseLogo";
import Spinner from "./Spinner";

export interface ServerFormProps {
  className?: string;
  loading?: boolean;
  errors?: string[];
  onStart?(fields: ServerFormFields): boolean;
  onSubmit?(fields: ServerFormFields): Promise<ServerFormFields>;
  onSuccess(result: ServerFormFields): void;
  onFail?(errors: string[]): void;
}

export interface ServerFormFields {
  server: string;
}

export type ServerFormResult = ServerFormFields & FormErrors;

const ServerForm: React.FC<ServerFormProps> = ({
  className,
  loading,
  errors,
  onStart,
  onSubmit,
  onSuccess,
  onFail
}) => {
  const [server, setServer] = useInput("");

  const check = useCredentialServerCheck();
  const connect = useCallback(
    async ({ server }: ServerFormFields): Promise<ServerFormResult> => {
      let resolvedServer;

      if (!["127.0.0.1", "localhost"].includes(server)) {
        try {
          const response = await fetch(`http://${server}/_sys/canonical-name`);
          resolvedServer = await response.text();
        } catch (error) {
          console.log(`Cannot find hostname resolver, proceed with ${server}...`);
          resolvedServer = server;
        }
      } else {
        resolvedServer = server;
      }

      const connection = await check(resolvedServer);
      if (connection.ok) {
        return { server: resolvedServer };
      }
      return { server: resolvedServer, errors: connection.errors };
    },
    [check]
  );

  const form = useForm<ServerFormFields, ServerFormResult>({
    fields: {
      server
    },
    onStart,
    onSubmit: onSubmit || connect,
    onSuccess,
    onFail
  });

  return (
    <Form className={className}>
      <NvidiaLogo />
      <OmniverseLogo />

      <FormGroup>
        <FormErrorList errors={form.errors} />
        <FormErrorList errors={errors} />
      </FormGroup>

      <FormGroup>
        <Input
          autoFocus
          name={"server"}
          placeholder={"Type Server Name"}
          value={server}
          onChange={setServer}
        />
      </FormGroup>

      <ButtonGroup>
        <LoginButton disabled={loading || form.loading} onClick={form.submit}>
          {(loading || form.loading) && <Spinner />} Next
        </LoginButton>
      </ButtonGroup>
    </Form>
  );
};

export default ServerForm;
