import React, { useCallback, useContext, useMemo, useRef } from "react";
import styled from "styled-components";
import useContextMenu from "../hooks/useContextMenu";
import { useStateOrProp } from "../hooks/useStateOrProp";
import Icon, { Icons } from "./Icon";
import Menu from "./Menu";
import { MenuItemCheckPlaceholder, MenuItemDivider, MenuRadioButtonItem } from "./MenuItem";
import MenuItemGroup from "./MenuItemGroup";
import { DefaultTheme } from "./Theme";

export interface SelectProps {
  className?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  defaultValue?: string;
  placeholder?: string;
  value?: string;
  onChange?(value: string): void;
  children?: React.ReactNode;
}

const SelectContext = React.createContext<{
  value?: string;
  setValue(value: string): void;
}>({ setValue: noop });

const Select: React.FC<SelectProps> = ({
  className,
  disabled = false,
  defaultValue = "",
  id,
  placeholder = "",
  name,
  value: valueProp,
  onChange,
  children,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [value, changeValue] = useStateOrProp(valueProp, onChange, defaultValue);
  const [options, showOptions, hideOptions] = useContextMenu(Options, {
    portal: null,
    position: OptionsPosition,
    props: { children },
  });

  const setValue = useCallback(
    (value: string) => {
      hideOptions();
      changeValue(value);

      if (ref.current) {
        ref.current.focus();
      }
    },
    [changeValue, hideOptions]
  );

  const context = useMemo(() => {
    return {
      value,
      setValue,
    };
  }, [value, setValue]);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (disabled) return;

      if (ref.current) {
        ref.current.focus();
      }
      showOptions(e);
    },
    [disabled, showOptions]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) return;
      setValue(e.target.value);
    },
    [disabled, setValue]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (disabled) return;

      if (e.key === " " || e.key === "Enter") {
        e.stopPropagation();
        e.preventDefault();

        if (ref.current) {
          ref.current.click();
        }
      }
    },
    [disabled]
  );

  return (
    <StyledSelect
      {...props}
      ref={ref as any}
      aria-disabled={disabled ? "true" : "false"}
      className={className}
      disabled={disabled}
      role={"listbox"}
      tabIndex={disabled ? -1 : 0}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <SelectInputHidden
        id={id}
        name={name}
        disabled={disabled}
        tabIndex={-1}
        placeholder={placeholder}
        readOnly={true}
        value={value}
        onClick={handleClick}
        onChange={handleInputChange}
      />
      <SelectValue>{value || placeholder}</SelectValue>
      <SelectIcon icon={Icons.AngleDown} />
      <SelectContext.Provider value={context}>{options}</SelectContext.Provider>
    </StyledSelect>
  );
};

const StyledSelect = styled.div<{ disabled: boolean }>`
  display: flex;
  font-family: inherit;
  font-size: 10pt;
  padding: 5px 8px;
  box-sizing: border-box;
  background: ${({ disabled, theme }) => (disabled ? theme.colors.disabledBackground : theme.colors.inputBackground)};
  border: none;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.disabledText : theme.colors.inputText)};
  width: 100%;
  position: relative;
  align-items: center;
  user-select: none;
  height: 25px;

  &:active,
  &:focus {
    outline: 2px solid #6ba6f6;
  }
`;
StyledSelect.defaultProps = {
  theme: DefaultTheme,
};

const Options = styled(Menu)`
  z-index: 5;
`;

const OptionsPosition = { x: 0, y: 30 };

const SelectInputHidden = styled.input.attrs({ type: "text" })`
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  background: none;
  color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
`;

const SelectValue = styled.span`
  flex: 1;
`;

const SelectIcon = styled(Icon)`
  margin-left: auto;
`;

export const Option: React.FC<{ label?: string; value?: string }> = ({ value, children }) => {
  const { setValue: setCurrentValue, value: currentValue } = useContext(SelectContext);

  const setValue = useCallback(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, [value, setCurrentValue]);

  const checked = currentValue === value;
  return (
    <MenuRadioButtonItem
      aria-selected={checked ? "true" : "false"}
      role={"option"}
      checked={checked}
      onClick={setValue}
    >
      {children}
    </MenuRadioButtonItem>
  );
};

export const OptionGroup: React.FC<{ label: string }> = ({ label, children }) => {
  return (
    <MenuItemGroup
      label={
        <>
          <MenuItemCheckPlaceholder />
          {label}
        </>
      }
    >
      {children}
    </MenuItemGroup>
  );
};

export const OptionDivider = MenuItemDivider;

function noop() {}

export default Select;
