import { SSOSettings } from "@omniverse/auth/data";
import React, { useCallback } from "react";
import styled from "styled-components";
import Button from "./Button";

export interface SSOButtonProps {
  setting: SSOSettings;
  onClick(setting: SSOSettings): void;
}

const SSOButton: React.FC<SSOButtonProps> = ({ setting, onClick }) => {
  const redirect = useCallback(() => {
    onClick(setting);
  }, [setting, onClick]);

  return (
    <StyledSSOButton onClick={redirect}>
      {setting.image && <StyledPicture src={setting.image} />}
      Log in with {setting.public_name}
    </StyledSSOButton>
  );
};

export const StyledSSOButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #666;
  color: white;
  padding: 0 10px;
  border-color: #909090;
  margin: 0.25rem 0;
  box-sizing: border-box;
`;

const StyledPicture = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin-right: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

export default SSOButton;
