import { AuthStatus } from "@omniverse/auth/data";

const AuthMessages = {
  [AuthStatus.Disabled]: "This account is disabled by administrator.",
  [AuthStatus.Expired]: "The session is expired.",
  [AuthStatus.Exists]: "This user already exists.",
  [AuthStatus.NotFound]: "User is not found.",
  [AuthStatus.ReadOnly]:
    "The setting for this user can be changed only by the " +
    "system administrator or with the service configuration.",
  [AuthStatus.Denied]: "Wrong credentials or the user does not exist.",
  [AuthStatus.UsernameRequired]: "Username is required.",
  [AuthStatus.NotSupported]: "This authentication method is not supported.",
  [AuthStatus.InternalError]:
    "Internal server error has occurred. Please contact administrator or try again later.",
  [AuthStatus.ConnectionError]:
    "Cannot establish a connection to remote authentication servers. Try again later.",
  [AuthStatus.InvalidUsername]:
    "Username can only contain alphanumeric characters and underscores.",
  [AuthStatus.UnknownError]:
    "Unknown error has occurred. Please contact administrator.",
  [AuthStatus.InvalidToken]: "The provided token is invalid.",
  [AuthStatus.Subscribed]: "Failed to subscribe to authentication results.",
};

export default AuthMessages;
