import { Commands } from "../Provider";
import {
  ICreateCheckpointCommandAllowedArguments,
  ICreateCheckpointCommandArguments,
} from "../types/CreateCheckpointCommand";
import { NucleusCommand } from "./index";

export default class NucleusCreateCheckpointCommand extends NucleusCommand<
  ICreateCheckpointCommandArguments,
  ICreateCheckpointCommandAllowedArguments
> {
  name = Commands.Upload;

  public async allowed({ path }: ICreateCheckpointCommandAllowedArguments): Promise<boolean> {
    return path.canWrite() && this.provider.supportsVersioning;
  }

  public async execute({ path }: ICreateCheckpointCommandArguments) {
    throw new Error("Not implemented.");
  }
}
