import React, { useCallback, useRef } from "react";
import { Subtract } from ".";
import { GridContainerProps } from "./Grid";
import SelectionBox, { SelectionBoxProps, SelectionRect } from "./SelectionBox";

export interface GridSelectionBoxProps<T>
  extends GridContainerProps<T>,
    Omit<Subtract<SelectionBoxProps, GridContainerProps<T>>, "onSelect"> {
  onSelect(cells: number[]): void;
}

interface MemoizedVirtualizedSelection {
  [index: number]: boolean;
}

const GridSelectionBox = React.forwardRef<HTMLDivElement, GridSelectionBoxProps<any>>(
  ({ cells, style, onSelect, children, ...props }, forwardRef) => {
    const lastSelection = useRef<MemoizedVirtualizedSelection>({});
    const select = useCallback(
      (box: SelectionRect) => {
        if (!box) {
          onSelect([]);
          lastSelection.current = {};
          return;
        }

        const boxLeft = box.left;
        const boxRight = box.left + box.width;
        const boxTop = box.top;
        const boxBottom = boxTop + box.height;

        const selectedCells = cells.filter(({ left, top, width, height }) => {
          return !(boxLeft > left + width || boxRight < left || boxTop > top + height || boxBottom < top);
        });

        const selection: MemoizedVirtualizedSelection = {};
        const selectedCellsIndexes = [];

        let selectionChanged = selectedCells.length !== Object.keys(lastSelection.current).length;
        for (const cell of selectedCells) {
          if (!lastSelection.current[cell.index]) {
            selectionChanged = true;
          }

          selection[cell.index] = true;
          selectedCellsIndexes.push(cell.index);
        }

        lastSelection.current = selection;
        if (selectionChanged) {
          onSelect(selectedCellsIndexes);
        }
      },
      [cells, onSelect]
    );

    return (
      <SelectionBox forwardedRef={forwardRef} style={style} onSelect={select} {...props}>
        {children}
      </SelectionBox>
    );
  }
);

export default GridSelectionBox;
