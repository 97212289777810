import { observer, useLocalObservable } from "mobx-react";
import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { DropStyle } from "../hooks/useDrop";
import usePathDrop from "../hooks/usePathDrop";
import Path from "../state/Path";
import Grid, { GridContainer, GridContainerProps, GridProps } from "./Grid";
import GridSelectionBox from "./GridSelectionBox";
import PathGridCell, { PathGridCellMeasurer } from "./PathGridCell";

export interface PathGridProps {
  paths: Path[];
  cwd?: Path;
  width?: number;
  height?: number;
  canCreateFolder?: boolean;
  gapBorder?: boolean;
}

export type PathGridCellType = PathCellType | NewFolderCellType;

export type PathCellType = { type: "path"; path: Path };
export type NewFolderCellType = { type: "new-folder"; cwd: Path };

const PathGrid: React.FC<PathGridProps> = ({ paths, cwd, width, height, canCreateFolder, gapBorder }) => {
  const state = useLocalObservable(() => {
    return {
      canCreateFolder,
      cwd,
      paths,
      get cells() {
        const cells: PathGridCellType[] = [];
        if (this.cwd && this.canCreateFolder) {
          cells.push({ type: "new-folder", cwd: this.cwd });
        }
        return cells.concat(this.paths.map((path) => ({ type: "path", path })));
      },

      update(canCreateFolder: boolean | undefined, cwd: Path | undefined, paths: Path[]): void {
        this.canCreateFolder = canCreateFolder;
        this.cwd = cwd;
        this.paths = paths;
      }
    }
  });
  useEffect(() => {
    state.update(canCreateFolder, cwd, paths);
  }, [state, canCreateFolder, cwd, paths])


  const renderer = useCallback(
    (props: GridContainerProps<PathGridCellType>) => {
      if (cwd) {
        return <PathGridRenderer cwd={cwd} {...props} />;
      } else {
        return <GridContainer {...props} />;
      }
    },
    [cwd]
  );

  return (
    <StyledPathGrid
      cells={state.cells}
      cellWidth={150}
      cellHeight={150}
      cellRenderer={PathGridCell}
      cellMeasurer={PathGridCellMeasurer}
      gridRenderer={renderer}
      gap={20}
      gapBorder={gapBorder}
      width={width}
      height={height}
    />
  );
};

const PathGridRenderer: React.FC<GridContainerProps<PathGridCellType> & { cwd: Path }> = ({
  cwd,
  cells,
  offsetTop,
  style,
  children,
}) => {
  const drop = usePathDrop(cwd);
  const selectionBox = useRef(null);

  function select(selectedCells: number[]) {
    const selectedPaths = selectedCells.reduce<Path[]>((selected, index) => {
      const cell = cells.find((cell) => cell.index === index);
      const data = cell?.data;
      if (data?.type === "path") {
        selected.push(data.path);
      }
      return selected;
    }, []);

    cwd.storage.selectedPaths.set(selectedPaths);
  }

  function reset(e: React.MouseEvent<HTMLElement>) {
    if (e.button === 0 && (e.target as HTMLElement).parentNode === selectionBox.current) {
      cwd.storage.selectedPaths.reset();
    }
  }

  return (
    <StyledPathGridRenderer
      {...drop}
      ref={selectionBox}
      cells={cells}
      offsetTop={offsetTop}
      style={style}
      onSelect={select}
      onMouseDown={reset}
    >
      {children}
    </StyledPathGridRenderer>
  );
};

const StyledPathGrid = styled(Grid)<GridProps<PathGridCellType>>`
  background: ${({ theme }) => theme.colors.background};
  flex: 1;
  display: grid;
`;

const StyledPathGridRenderer = styled(GridSelectionBox)`
  grid-area: 1 / 1 / 2 / 2;
  ${DropStyle};
`;

export default observer(PathGrid);
