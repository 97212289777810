import { StatusType } from "@omniverse/api/data";
import { getParent, join } from "../../../util/Path";
import { CopyError } from "../../../util/PathErrors";
import Path, { PathType } from "../../Path";
import { Commands } from "../Provider";
import { ICopyCommandAllowedArguments, ICopyCommandArguments } from "../types/CopyCommand";
import { NucleusCommand } from "./index";
import { compareFiles, CompareResult } from "./util";

export default class NucleusCopyCommand extends NucleusCommand<ICopyCommandArguments, ICopyCommandAllowedArguments> {
  name = Commands.Copy;

  public async allowed({ source, destination }: ICopyCommandAllowedArguments): Promise<boolean> {
    const canCopy = source && destination ? !source.includes(destination) : true;
    const canPaste = destination ? destination.canWrite() : true;
    return canCopy && canPaste;
  }

  public async execute({ source, destination }: ICopyCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Starting a copy for ${source.length} elements...`);

    const connection = await this.provider.getConnection();
    for (const path of source) {
      console.log(`[${this.provider.name}] Copy ${path.path} to ${destination.path}.`);

      if (getParent(path.path) === destination.path) {
        continue;
      }

      let comparedFiles: CompareResult | undefined;
      if (this.provider.supportsVersioning) {
        comparedFiles = await compareFiles(connection, path.path, destination.path);

        for (const file of comparedFiles.intersection) {
          const srcPath = join(comparedFiles.sourceDir, file);
          const dstPath = join(comparedFiles.destinationDir, file);

          console.log(`[${this.provider.name}] Create checkpoint before copy from ${srcPath}"`);

          const res = await connection.stat2({ path: { path: dstPath } });
          if (res.status !== StatusType.OK) {
            throw new Error("Error fetching stats for checkpoint file");
          }

          if (!res.checkpointed) {
            await connection.checkpointVersion({
              path: { path: dstPath },
              message: `Backup before copy from ${srcPath}`,
            });
          }
        }
      }

      const transactionIdResult = await connection.getTransactionId();
      if (transactionIdResult.status !== StatusType.OK) {
        throw new CopyError(transactionIdResult);
      }

      const transactionId = transactionIdResult.transaction_id!.toString();
      const to =
        path.type === PathType.Folder ? join(destination.path, path.name, "/") : join(destination.path, path.name);

      const result = await connection.copy({ uri: path.path, to, transaction_id: transactionId });
      if (result.status !== StatusType.OK) {
        throw new CopyError(result);
      }

      if (this.provider.supportsVersioning && comparedFiles) {
        const checkpointResults: Promise<void>[] = [];
        for (const file of comparedFiles.sourceFiles) {
          const srcPath = join(comparedFiles.sourceDir, file);
          const dstPath = join(comparedFiles.destinationDir, file);
          const message = comparedFiles.destinationFiles.includes(file)
            ? `Replaced by ${srcPath}`
            : `Copied from ${srcPath}`;
          checkpointResults.push(
            connection
              .checkpointVersion({
                path: { path: dstPath },
                message,
              })
              .then((result) => {
                if (result.status !== StatusType.OK) {
                  throw new Error(`Error in  checkpoint creation for ${dstPath}`);
                }
              })
          );
        }
        await Promise.all(checkpointResults);
      }

      const child = new Path(
        to,
        path.type,
        path.storage,
        path.dateCreated,
        path.dateModified,
        this.provider.session.username,
        this.provider.session.username,
        path.size,
        path.mounted,
      );
      child.permissions = path.permissions;
      destination.add(child);
    }

    await destination.load();
  }
}
