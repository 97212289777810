import { observer, useLocalObservable } from "mobx-react";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import useContextMenu from "../hooks/useContextMenu";
import useSelectionReset from "../hooks/useSelectionReset";
import User from "../state/User";
import Users from "../state/Users";
import { compareBoolean } from "../util/Boolean";
import { TableColumn } from "./Table";
import TableColumnResizing from "./TableColumnResizing";
import { TableDiv, TableDivBody, TableDivHead, TableDivRow } from "./TableDiv";
import TableSorter from "./TableSorter";
import TableSorterHeader from "./TableSorterHeader";
import UserListMenu from "./UserListMenu";
import UserCell from "./UserTableCell";
import VirtualizedTable from "./VirtualizedTable";

export interface UserTableProps {
  users: Users;
  selectedUser?: User | null;
  onSelect?(user: User | null): void;
}

enum UserTableColumn {
  Name = "name",
  FirstName = "firstName",
  LastName = "lastName",
  Email = "email",
  Provider = "provider",
  Admin = "admin",
  ReadOnly = "nucleusRo",
  Disabled = "disabled",
}

const UserTable: React.FC<UserTableProps> = ({ users, selectedUser, onSelect }) => {
  const { rows } = useLocalObservable(() => {
    return {
      get rows() {
        return [...users.items];
      },
    };
  });

  const selectedRows = useMemo(() => (selectedUser ? [selectedUser] : []), [selectedUser]);
  const [menu, onContextMenu] = useContextMenu(UserListMenu, { props: { users } });
  const resetSelection = useSelectionReset(onSelect);

  const [sorting, setSorting] = useState(UserTableColumn.Name);
  const [direction, setDirection] = useState<"ASC" | "DESC">("ASC");

  function sortRows(rows: User[]): User[] {
    return [...rows].sort((a, b) => {
      const comparisonDirection = direction === "ASC" ? 1 : -1;
      if (sorting === UserTableColumn.Name) {
        return comparisonDirection * a.name.localeCompare(b.name);
      }

      if (sorting === UserTableColumn.FirstName) {
        if (!a.profile?.firstName) {
          return comparisonDirection;
        }
        if (!b.profile?.firstName) {
          return -comparisonDirection;
        }
        return comparisonDirection * a.profile.firstName.localeCompare(b.profile.firstName);
      }

      if (sorting === UserTableColumn.LastName) {
        if (!a.profile?.lastName) {
          return comparisonDirection;
        }
        if (!b.profile?.lastName) {
          return -comparisonDirection;
        }
        return comparisonDirection * a.profile.lastName.localeCompare(b.profile.lastName);
      }

      if (sorting === UserTableColumn.Email) {
        if (!a.profile?.email) {
          return comparisonDirection;
        }
        if (!b.profile?.email) {
          return -comparisonDirection;
        }
        return comparisonDirection * a.profile.email.localeCompare(b.profile.email);
      }

      if (sorting === UserTableColumn.Provider) {
        if (!a.profile?.provider) {
          return comparisonDirection;
        }
        if (!b.profile?.provider) {
          return -comparisonDirection;
        }
        return comparisonDirection * a.profile.provider.localeCompare(b.profile.provider);
      }

      if (sorting === UserTableColumn.Disabled) {
        return comparisonDirection * compareBoolean(a.profile?.enabled, b.profile?.enabled);
      }

      if (sorting === UserTableColumn.Admin) {
        return comparisonDirection * compareBoolean(a.profile?.admin, b.profile?.admin);
      }

      if (sorting === UserTableColumn.ReadOnly) {
        return comparisonDirection * compareBoolean(a.profile?.nucleusRo, b.profile?.nucleusRo);
      }

      return 0;
    });
  }

  function selectColumn(column: TableColumn) {
    setDirection(sorting === column.name ? (direction === "ASC" ? "DESC" : "ASC") : "ASC");
    setSorting(column.name as UserTableColumn);
  }

  return (
    <TableSorter<User> rows={rows} sorting={sorting} direction={direction} sort={sortRows}>
      {({ rows }) => (
        <TableColumnResizing columns={UserTableColumns} headerComponent={UserHeader}>
          {({ columns, headerComponent }) => (
            <>
              <VirtualizedTable
                columns={columns}
                rows={rows}
                selectedRows={selectedRows}
                rowHeight={35}
                tableComponent={TableDiv}
                bodyComponent={TableDivBody}
                headComponent={TableDivHead}
                headTableComponent={TableDiv}
                headerComponent={headerComponent}
                rowComponent={UserRow}
                cellComponent={UserCell}
                emptyRows={0}
                onClick={resetSelection}
                onSelect={onSelect}
                onContextMenu={onContextMenu}
                onHeaderSelect={selectColumn}
              />
              {menu}
            </>
          )}
        </TableColumnResizing>
      )}
    </TableSorter>
  );
};

const UserTableColumns: TableColumn[] = [
  { name: UserTableColumn.Name, title: "User", width: 250 },
  { name: UserTableColumn.FirstName, title: "First Name", width: 200 },
  { name: UserTableColumn.LastName, title: "Last Name", width: 200 },
  { name: UserTableColumn.Email, title: "Email", width: 100 },
  { name: UserTableColumn.Provider, title: "Account Type", width: 100 },
  { name: UserTableColumn.Admin, title: "Administrator", width: 100 },
  { name: UserTableColumn.ReadOnly, title: "Read Only", width: 100 },
  { name: UserTableColumn.Disabled, title: "Disabled", width: 100 },
];

const UserHeader = styled(TableSorterHeader)`
  padding-left: ${({ column }) => (column.name === "name" ? "35px" : "10px")};
`;

const UserRow = styled(TableDivRow)`
  svg {
    fill: ${({ theme, selected }) => (selected ? theme.colors.selectedItemForeground : theme.colors.foreground)};
  }
`;

export default observer(UserTable);
