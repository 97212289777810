import React, { useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../static/check.svg";
import Icon, { IconProps } from "./Icon";
import { MenuContext, MenuItemEntry } from "./Menu";
import Picture, { PictureSource } from "./Picture";
import { DefaultTheme } from "./Theme";

export interface MenuItemProps extends React.ComponentPropsWithoutRef<"li"> {
  disabled?: boolean;
  visible?: boolean;
  onEntryAttach?(value: MenuItemEntry): void;
}

export const MenuItem = React.forwardRef(
  (
    {
      onClick,
      disabled,
      visible = true,
      className,
      children,
      onMouseOver,
      onMouseLeave,
      onEntryAttach,
      ...props
    }: MenuItemProps,
    ref
  ) => {
    const innerRef = useRef<HTMLLIElement>(null);
    useImperativeHandle(ref, () => innerRef.current!);

    const onSelect = useCallback(() => {
      innerRef.current?.click();
    }, []);
    const entry = useMenuItem({ visible, onSelect, onAttach: onEntryAttach });

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLLIElement>) => {
        if (!disabled && onClick) {
          onClick(e);
        }
      },
      [disabled, onClick]
    );

    const handlePreventContextMenu = useCallback((e: React.MouseEvent<HTMLLIElement>) => {
      e.stopPropagation();
      e.preventDefault();
    }, []);

    const { index: currentIndex, setIndex: setCurrentIndex } = useContext(MenuContext);
    const highlighted = currentIndex === entry?.index;

    const highlight = useCallback(
      (e: React.MouseEvent<HTMLLIElement>) => {
        onMouseOver?.(e);

        e.stopPropagation();
        if (entry) {
          setCurrentIndex(entry.index);
        }
      },
      [entry, onMouseOver, setCurrentIndex]
    );

    const resetHighlighting = useCallback(
      (e: React.MouseEvent<HTMLLIElement>) => {
        onMouseLeave?.(e);

        e.stopPropagation();
        if (currentIndex === entry?.index) {
          setCurrentIndex(-1);
        }
      },
      [currentIndex, entry, onMouseLeave, setCurrentIndex]
    );

    if (!visible) {
      return null;
    }

    return (
      <StyledMenuItem
        ref={innerRef as any}
        className={className}
        aria-disabled={disabled ? "true" : "false"}
        role={"menuitem"}
        highlighted={highlighted}
        onClick={handleClick}
        onContextMenu={handlePreventContextMenu}
        onMouseOver={highlight}
        onMouseLeave={resetHighlighting}
        {...props}
      >
        {children}
      </StyledMenuItem>
    );
  }
);

interface UseMenuItemArguments {
  visible?: boolean;
  onAttach?(entry: MenuItemEntry): void;
  onSelect?(): void;
  onExpand?(): void;
  onCollapse?(): void;
}

export function useMenuItem({
  visible,
  onAttach,
  onSelect,
  onExpand,
  onCollapse,
}: UseMenuItemArguments = {}): MenuItemEntry | null {
  const [entry, setEntry] = useState<MenuItemEntry | null>(null);
  const { registerItem, unregisterItem } = useContext(MenuContext);
  const callbacks = useRef({ onSelect, onExpand, onCollapse });
  callbacks.current = { onSelect, onExpand, onCollapse };

  useEffect(() => {
    if (!visible) return;

    const entry = registerItem();
    setEntry(entry);

    entry.onUpdate = () => {
      setEntry({ ...entry });
    };

    const onSelect = entry.onSelect;
    entry.onSelect = () => {
      onSelect?.();
      callbacks.current.onSelect?.();
    };

    const onExpand = entry.onExpand;
    entry.onExpand = () => {
      onExpand?.();
      callbacks.current.onExpand?.();
    };

    const onCollapse = entry.onCollapse;
    entry.onCollapse = () => {
      onCollapse?.();
      callbacks.current.onCollapse?.();
    };

    onAttach?.(entry);
    return () => {
      unregisterItem(entry);
    };
  }, [registerItem, unregisterItem, onAttach, visible]);

  return entry;
}

const StyledMenuItem = styled.li<{ highlighted?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 9pt;
  cursor: default;
  padding: 8px 15px;
  user-select: none;
  background: ${({ theme, highlighted }) => (highlighted ? theme.colors.menuHover : "inherit")};

  &:hover {
    background: ${({ theme, highlighted }) =>
      typeof highlighted === "undefined"
        ? theme.colors.menuHover
        : highlighted
        ? theme.colors.menuHover
        : "inherit"};
  }
  
  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
StyledMenuItem.defaultProps = {
  theme: DefaultTheme,
};

export const MenuItemDivider = styled.div<{
  visible?: boolean;
}>`
  display: ${({ visible }) => (visible ? "block" : "none")};
  outline: none;
  user-select: none;
  border-top: 1px solid ${({ theme }) => theme.colors.menuSeparator};
`;
MenuItemDivider.defaultProps = {
  theme: DefaultTheme,
  visible: true,
};

export type MenuIconItemProps = { icon: IconProps["icon"] } & MenuItemProps;
export const MenuIconItem = ({ icon, children, ...props }: MenuIconItemProps) => (
  <MenuItem {...props}>
    <MenuItemIcon icon={icon} />
    <MenuItemLabel>{children}</MenuItemLabel>
  </MenuItem>
);

export type MenuCheckboxItemProps = { checked: boolean } & MenuItemProps;
export const MenuCheckboxItem = ({ checked, children, ...props }: MenuCheckboxItemProps) => (
  <MenuItem {...props}>
    <MenuCheckbox checked={checked} readOnly />
    <MenuItemLabel>{children}</MenuItemLabel>
  </MenuItem>
);

const MenuCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  width: 15px;
  flex: 0 0 15px;
  margin: 0;
  box-sizing: border-box;
  cursor: pointer;
`;

const MenuItemIcon = styled(Icon)`
  font-size: 11pt;
  width: 11pt;
  flex: 0 0 auto;
`;

const MenuItemLabel = styled.span`
  margin-left: 15px;
  flex: 1;
`;

export type MenuImageItemProps = { src: PictureSource } & MenuItemProps;
export const MenuImageItem = ({ src, children, ...props }: MenuImageItemProps) => (
  <MenuItem {...props}>
    <MenuItemPicture src={src} />
    <MenuItemLabel>{children}</MenuItemLabel>
  </MenuItem>
);

const MenuItemPicture = styled(Picture)`
  font-size: 11pt;
  width: 11pt;
  flex: 0 0 auto;
  fill: ${({ theme }) => theme.colors.foreground};
`;
MenuItemPicture.defaultProps = {
  theme: DefaultTheme,
};

export type MenuCustomItemProps = { placeholder: JSX.Element } & MenuItemProps;
export const MenuCustomItem = ({ placeholder, children, ...props }: MenuCustomItemProps) => (
  <MenuItem {...props}>
    <MenuItemPlaceholder>{placeholder}</MenuItemPlaceholder>
    <MenuItemLabel>{children}</MenuItemLabel>
  </MenuItem>
);

const MenuItemPlaceholder = styled.div`
  width: 15px;
  flex: 0 0 15px;
  margin: 0;
  box-sizing: border-box;
  cursor: pointer;
`;

interface MenuRadioButtonItemProps extends MenuItemProps {
  checked?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const MenuRadioButtonItem = React.forwardRef(
  ({ checked, disabled, children, ...props }: MenuRadioButtonItemProps, ref) => {
    const check = checked ? <MenuItemCheck $disabled={disabled} /> : <MenuItemCheckPlaceholder />;
    return (
      <MenuItem ref={ref as any} role={"radio"} disabled={disabled} aria-checked={checked} {...props}>
        {check}
        {children}
      </MenuItem>
    );
  }
);

const MenuItemCheck = styled(CheckIcon)<{ $disabled?: boolean }>`
  display: inline-block;
  min-width: 0;
  width: 15px;
  flex: 0 0 15px;
  margin-left: -0.25rem;
  margin-right: 0.5rem;

  path {
    fill: ${({ $disabled, theme }) => ($disabled ? theme.colors.disabledText : theme.colors.attentionBackground)};
  }
`;
MenuItemCheck.defaultProps = {
  theme: DefaultTheme,
};

export const MenuItemCheckPlaceholder = styled.span`
  flex: 0 0 15px;
  margin-left: -0.25rem;
  margin-right: 0.5rem;
`;

export default MenuItem;
