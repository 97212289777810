import Icon, { Icons } from "./Icon";
import ProgressBar from "./ProgressBar";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import TaskStatus from "../state/TaskStatus";
import TaskStatusIcon from "./TaskStatusIcon";

export interface TaskViewProps {
  process: any;
  percent?: number;
  status: TaskStatus;
  text: string;
  statusText: string;
  retryable?: boolean;
  onAbort(process: any): void;
  onClose(process: any): void;
  onRetry?(process: any): void;
}

const TaskView: React.FC<TaskViewProps> = ({
  process,
  percent,
  status,
  text,
  statusText,
  retryable,
  onClose,
  onRetry,
  onAbort,
}) => {
  const [titleExpanded, setTitleExpanded] = useState(false);

  const close = useCallback(() => {
    onClose(process);
  }, [process, onClose]);

  const cancel = useCallback(() => {
    onAbort(process);
  }, [process, onAbort]);

  const retry = useCallback(() => {
    onRetry!(process);
  }, [process, onRetry]);

  const toggleError = useCallback(() => {
    if (document.getSelection()?.type !== "Range") setTitleExpanded((value) => !value);
  }, []);

  return (
    <Container>
      <TaskHeader>
        <TaskStatusIcon status={status} retryable={retryable} onRetry={retry} />
        <TaskTitle isOpen={titleExpanded} onClick={toggleError} title={text}>
          {text}
        </TaskTitle>

        {status !== TaskStatus.Loading ? <TaskCloseIcon onClick={close} /> : <TaskCancelIcon onClick={cancel} />}
      </TaskHeader>
      {status === TaskStatus.Loading && <TaskProgressBar progress={percent!} />}
      <TaskStatusText title={statusText}>{statusText}</TaskStatusText>
    </Container>
  );
};

const Container = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 5px 0 5px;
  box-sizing: border-box;

  color: ${({ theme }) => theme.colors.foreground};
`;

const TaskHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TaskProgressBar = styled(ProgressBar)`
  margin: 5px 0;
  width: 100%;
`;

const TaskToggleIcon = styled(Icon).attrs({ clickable: true })`
  margin: 0 5px 0 10px;
  line-height: 20px;
`;

const TaskCancelIcon = styled(TaskToggleIcon).attrs({
  title: "Abort",
  icon: Icons.Abort,
})`
  font-size: 14pt;
  color: ${({ theme }) => theme.colors.dangerText};
`;

const TaskCloseIcon = styled(TaskToggleIcon).attrs({
  title: "Close",
  icon: Icons.Close,
})`
  font-size: 12pt;
  color: ${({ theme }) => theme.colors.foreground};
`;

const TaskText = styled.div`
  font-size: 9pt;
  margin-right: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TaskTitle = styled(TaskText)<{ isOpen: boolean }>`
  flex: 1;
  margin: 0 5px;
  color: ${({ theme }) => theme.colors.foreground};

  cursor: pointer;
  ${({ isOpen }) =>
    isOpen
      ? "" +
        `
      text-overflow: inherit;
      overflow: inherit;
      white-space: inherit;
      word-break: break-word;
    `
      : ""}
`;

const TaskStatusText = styled(TaskText)`
  min-width: 100%;
  flex: 0 0 100%;
  color: ${({ theme }) => theme.colors.hint};
`;

export default TaskView;
