import React, { useCallback } from "react";
import styled from "styled-components";
import { DefaultTheme } from "./Theme";

const Breadcrumb = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const onClick = props && props.onClick;
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );

  return <StyledBreadcrumb {...props} onClick={handleClick} />;
};

const StyledBreadcrumb = styled.div`
  padding: 5px;
  border-radius: 5px;
  text-decoration: none;
  color: inherit;
  text-overflow: ellipsis;
  flex: 0 1 auto;
  min-width: 18px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.selectedBreadcrumb};
    color: white;
  }
`;
StyledBreadcrumb.defaultProps = {
  theme: DefaultTheme
};

export default Breadcrumb;
