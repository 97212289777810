import React from "react";
import styled from "styled-components";
import Spinner from "./Spinner";
import { DefaultTheme } from "./Theme";

export interface LoaderProps {
  className?: string;
  visible?: boolean;
  children?: React.ReactNode;
}

const Loader = ({ className, visible = true, children, ...props }: LoaderProps) => {
  if (!visible) {
    return null;
  }

  return (
    <LoaderWrapper {...props} className={className}>
      <LoaderSpinner title={"Loading..."} />
      <LoaderProcessName>{children}</LoaderProcessName>
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div`
  position: relative;
  padding: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const LoaderSpinner = styled(Spinner)`
  font-size: 18pt;
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.hint};
`;
LoaderSpinner.defaultProps = {
  theme: DefaultTheme
};

export const LoaderProcessName = styled.span`
  font-size: 9pt;
  color: ${({ theme }) => theme.colors.hint};
`;
LoaderProcessName.defaultProps = {
  theme: DefaultTheme
};

export default Loader;
