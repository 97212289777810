import React from "react";
import styled from "styled-components";
import { DefaultTheme } from "./Theme";

export const Input = styled.input`
  display: block;
  padding: 5px 8px;
  font-family: unset;
  font-size: 10pt;
  box-sizing: border-box;
  border: none;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.disabledText : theme.colors.inputText)};
  width: 100%;
  background: ${({ disabled, theme }) => (disabled ? theme.colors.disabledBackground : theme.colors.inputBackground)};
  height: 25px;
  border-radius: 1px;
  vertical-align: middle;

  &::placeholder {
    font-style: italic;
    color: ${({ theme }) => theme.colors.placeholders};
  }
`;

Input.defaultProps = {
  theme: DefaultTheme,
  type: "text",
};

export default Input;
