import React from "react";
import styled from "styled-components";
import useDialog from "../hooks/useDialog";
import Path from "../state/Path";
import Storage from "../state/Storage";
import Icon, { Icons } from "./Icon";
import Input from "./Input";
import PathBrowserDialog from "./PathBrowserDialog";
import { DefaultTheme } from "./Theme";

export interface PathInputProps {
  disabled?: boolean;
  id?: string;
  initial?: Path | null;
  name?: string;
  placeholder?: string;
  storages: Storage[];
  tabIndex?: number;
  value: Path | null;
  onChange(value: Path | null): void;
}

const PathInput: React.FC<PathInputProps> = ({
  disabled,
  id,
  initial = null,
  name,
  placeholder,
  storages,
  tabIndex = 0,
  value,
  onChange,
  ...props
}) => {
  const browser = useDialog(PathBrowserDialog, { initial: value, onSelect: selectPath });

  function showBrowser() {
    browser.show();
  }

  function selectPath(value: Path | null) {
    onChange(value);
    browser.hide();
  }

  return (
    <PathSelectGroup {...props}>
      <StyledPathInput
        name={name}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        readOnly
        tabIndex={tabIndex}
        value={value?.path ?? ""}
        onClick={showBrowser}
      />
      <BrowseIcon tabIndex={0} title={"Select path..."} onClick={showBrowser} />
    </PathSelectGroup>
  );
};

const PathSelectGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledPathInput = styled(Input)`
  flex: 1;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

const BrowseIcon = styled(Icon).attrs({
  icon: Icons.Folder,
})`
  color: ${({ theme }) => theme.colors.headerForeground};
  flex: 0 0 25px;
  text-align: center;
  cursor: pointer;
`;
BrowseIcon.defaultProps = {
  theme: DefaultTheme,
};

export default PathInput;
