import { useEffect } from "react";
import NucleusAuthDialog from "../components/NucleusAuthDialog";
import { Commands } from "../state/commands/Provider";
import { ISubscribeCommand } from "../state/commands/types/SubscribeCommand";
import { IUnsubscribeCommand } from "../state/commands/types/UnsubscribeCommand";
import useAppLocation from "./useAppLocation";
import useCwd from "./useCwd";
import useDialog from "./useDialog";
import usePathNavigate from "./usePathNavigate";
import useQuery, { UseQueryOptions } from "./useQuery";
import useStorageList from "./useStorageList";

export default function useContentBrowserNavigation(server: string, path: string, options: UseQueryOptions = {}) {
  const location = useAppLocation();
  const storageList = useStorageList();
  const storage = storageList.find(server);
  const navigate = usePathNavigate();
  const authentication = useDialog(NucleusAuthDialog);

  const query = useQuery(
    async () => {
      if (storage) {
        storageList.select(storage);
        return storage.cd(path);
      } else if (path || server) {
        if (!storageList.readOnly) {
          return authentication.show({ serverName: server, path });
        }
      } else if (storageList.selected && storageList.selected.cwd) {
        return navigate(storageList.selected.cwd);
      }
    },
    {
      ...options,
      keys: [location, path, server, storageList],
    }
  );

  const cwd = useCwd();

  useEffect(() => {
    if (!cwd) {
      return;
    }

    const subscribe = storage?.commands.get<ISubscribeCommand>(Commands.Subscribe);
    subscribe?.allowed().then((allowed) => {
      if (allowed) {
        subscribe.execute({ path: cwd }).catch((err) => console.warn(`Could not subscribe to ${cwd.path}: ${err}`));
      }
    });

    return () => {
      const unsubscribe = storage?.commands.get<IUnsubscribeCommand>(Commands.Unsubscribe);
      unsubscribe?.allowed().then((allowed) => {
        if (allowed) {
          unsubscribe
            .execute({ path: cwd })
            .catch((err) => console.warn(`Could not unsubscribe from ${cwd.path}: ${err}`));
        }
      });
    };
  }, [storage, cwd]);

  return {
    storage,
    ...query,
  };
}
