import styled from "styled-components";
import { DefaultTheme } from "./Theme";

const RightPanelHeader = styled.div`
  flex: 0 0 auto;
  background: ${({ theme }) => theme.colors.panelBackground};
  color: ${({ theme }) => theme.colors.panelText};
  padding: 10px 0;
  display: flex;
  flex-direction: column;
`;
RightPanelHeader.defaultProps = {
  theme: DefaultTheme,
};

export default RightPanelHeader;
