import { useParams } from "react-router-dom";
import { join } from "../util/Path";
import useAppLocation from "./useAppLocation";

export default function usePathParam(): string {
  const location = useAppLocation();

  const params = useParams();
  let path = params["server"] ? params["*"] || "/" : "";
  if (path && !path.startsWith("/")) {
    path = "/" + path;
  }

  if (path && location.pathname.endsWith("/")) {
    path = join(path, "/");
  }

  return path ?? "";
}
