import { observer } from "mobx-react";
import React, { useState } from "react";
import useCwd from "../hooks/useCwd";
import useDialog from "../hooks/useDialog";
import useContextMenu from "../hooks/useContextMenu";
import usePathDrag from "../hooks/usePathDrag";
import usePathDrop from "../hooks/usePathDrop";
import usePathNavigate from "../hooks/usePathNavigate";
import useStorageList from "../hooks/useStorageList";
import ContentBrowserMenu from "./ContentBrowserMenu";
import NewServerTreeItem from "./NewServerTreeItem";
import NucleusAuthDialog from "./NucleusAuthDialog";
import PathMenu from "./PathMenu";
import PathTree, { PathTreePath } from "./PathTree";
import PathTreeItem, { PathTreeItemEvent, PathTreeItemProps } from "./PathTreeItem";
import PathTreeRoot from "./PathTreeRoot";

export interface ContentBrowserTreeProps {
  width?: number;
  height?: number;
}

const ContentBrowserTree: React.FC<ContentBrowserTreeProps> = ({ width, height }) => {
  const authentication = useDialog(NucleusAuthDialog);
  const navigate = usePathNavigate();
  const cwd = useCwd();
  const storageList = useStorageList();
  const [menu, onContextMenu] = useContextMenu(ContentBrowserMenu);
  const [toggled, setToggled] = useState(true);

  function handleSelect(e: React.MouseEvent & PathTreeItemEvent) {
    const item = e.item;
    if (item.type === "path") {
      const path = item.path;
      if (!path.isRoot || path.storage.session.established) {
        navigate(path);
      } else {
        authentication.show({ serverName: path.storage.publicName });
      }
    }
  }

  function handleToggle(e: React.MouseEvent & PathTreeItemEvent) {
    const item = e.item;
    if (item.type === "root") {
      setToggled((value) => !value);
    }
  }

  return (
    <>
      <PathTree
        itemComponent={ContentBrowserPathTreeItem}
        storages={toggled ? storageList.items : []}
        canAddStorage={!storageList.readOnly}
        selectedPath={cwd}
        toggled={toggled}
        width={width}
        height={height}
        onSelect={handleSelect}
        onToggle={handleToggle}
        onContextMenu={onContextMenu}
      />
      {menu}
    </>
  );
};

const ContentBrowserPathTreeItem: React.FC<PathTreeItemProps> = ({ item, ...props }) => {
  if (item.type === "root") {
    return <PathTreeRoot item={item} {...props} />;
  }
  if (item.type === "new-server") {
    return <NewServerTreeItem item={item} {...props} />;
  }
  if (item.type === "path") {
    return <ContentBrowserPathItem item={item} {...props} />;
  }
  return null;
};

const ContentBrowserPathItem: React.FC<PathTreeItemProps<PathTreePath>> = ({ item, ...props }) => {
  const path = item.path;
  const [menu, onContextMenu] = useContextMenu(PathMenu, { props: { path } });
  const drag = usePathDrag(path);
  const drop = usePathDrop(path);

  async function toggle() {
    try {
      await path.toggle();
    } catch {}
  }

  return (
    <>
      <PathTreeItem
        item={item}
        {...drag}
        {...drop}
        {...props}
        highlighted={menu != null}
        onContextMenu={onContextMenu}
        onToggle={toggle}
      />
      {menu}
    </>
  );
};

export default observer(ContentBrowserTree);
