import React, { useCallback, useContext } from "react";
import { DialogContext } from "../components/Dialogs";

function useDialog<P>(Dialog: React.ComponentType<P>, staticProps?: Partial<P>) {
  const dialogs = useContext(DialogContext);

  const show = useCallback(
    (props?: Partial<P>) => {
      const allProps = { ...staticProps, ...props } as P;
      dialogs.show(<Dialog {...allProps} />);
    },
    [Dialog, dialogs, staticProps]
  );

  const hide = useCallback(() => {
    dialogs.hide();
  }, [dialogs]);

  return {
    visible: dialogs.visible,
    show,
    hide,
  };
}

export default useDialog;
