import React, { useCallback, useRef, useState } from "react";
import { css } from "styled-components";

export type DropCallback = (e: React.DragEvent) => void;
export type DropAllowedCallback = (e: React.DragEvent) => boolean;

export default function useDrop(drop: DropCallback, allowed: DropAllowedCallback = allow) {
  const refCount = useRef(0);
  const [isDropTarget, setTargeted] = useState(false);
  const onDragOver = useCallback(
    (e: React.DragEvent) => {
      const allow = allowed(e);
      if (allow) {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
      }
    },
    [allowed]
  );

  const onDragEnter = useCallback((e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (refCount.current++ === 0) {
      setTargeted(true);
    }
  }, []);

  const onDragLeave = useCallback((e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (--refCount.current === 0) {
      setTargeted(false);
    }
  }, []);

  const onDrop = useCallback(
    async (e: React.DragEvent) => {
      refCount.current = 0;
      setTargeted(false);

      e.stopPropagation();
      e.preventDefault();

      drop(e);

      // TODO: check its probably against standard, firefox crashes
      e.dataTransfer.clearData();
    },
    [drop]
  );

  return {
    "data-dnd": isDropTarget,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDrop,
  };
}

const allow = () => true;

export const DropStyle = css`
  &[data-dnd="true"] {
    background: ${({ theme }) => theme.colors.hoverItemBackground};
    color: ${({ theme }) => theme.colors.foreground};
  }
`;
