import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { toPublicName } from "../state/commands/Provider";
import { isElectron } from "../util/Electron";
import Dialog from "./Dialog";
import { DialogContext } from "./Dialogs";
import DialogTitle from "./DialogTitle";
import NucleusExternalAuthForm from "./NucleusExternalAuthForm";
import NucleusLoginForm from "./NucleusLoginForm";
import NucleusRegistrationForm from "./NucleusRegistrationForm";

export interface NucleusAuthDialogProps {
  path?: string;
  serverName?: string;
}

const NucleusAuthDialog: React.FC<NucleusAuthDialogProps> = ({ path = "/", serverName }) => {
  const { hide } = useContext(DialogContext);
  const [registration, setRegistration] = useState("");

  const initial = useMemo(
    () => ({
      server: serverName ? toPublicName(serverName) : "",
    }),
    [serverName]
  );

  const extras = useMemo(() => ({ path }), [path]);

  function goToRegistration(server: string) {
    setRegistration(server);
  }

  function goToLogIn() {
    setRegistration("");
  }

  return (
    <StyledAuthDialog onClose={hide}>
      <DialogTitle title={"Authenticate"} onClose={hide} />
      {isElectron() ? (
        <StyledNucleusExternalAuthForm path={path} serverName={initial.server} onClose={hide} />
      ) : registration ? (
        <StyledRegistrationForm server={registration} extras={extras} onSuccess={hide} onCancel={goToLogIn} />
      ) : (
        <StyledLoginForm initial={initial} extras={extras} onSuccess={hide} onRegister={goToRegistration} />
      )}
    </StyledAuthDialog>
  );
};

const StyledAuthDialog = styled(Dialog)`
  width: 275px;
  min-width: 275px;
  overflow: hidden;
`;

const StyledLoginForm = styled(NucleusLoginForm)`
  border-radius: 0;
  box-shadow: none;
  min-height: 200px;
`;

const StyledRegistrationForm = styled(NucleusRegistrationForm)`
  border-radius: 0;
  box-shadow: none;
  min-height: 200px;
`;

const StyledNucleusExternalAuthForm = styled(NucleusExternalAuthForm)`
  border-radius: 0;
  box-shadow: none;
  min-height: 200px;
`;

export default NucleusAuthDialog;
