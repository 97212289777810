import { useCallback, useState } from "react";


export default function useError() {
  const [error, setError] = useState("");

  const clearError = useCallback(() => {
    setError("");
  }, []);

  return [error, setError, clearError] as const;
}