import { StatusType } from "@omniverse/api/data";
import { AddUserToGroupError } from "../../../util/UserErrors";
import { Commands } from "../Provider";
import { IAddUserToGroupCommandArguments } from "../types/AddUserToGroupCommand";
import { NucleusCommand } from "./index";

export default class NucleusAddUserToGroupCommand extends NucleusCommand<IAddUserToGroupCommandArguments> {
  public name = Commands.AddUserToGroup;

  public async allowed(): Promise<boolean> {
    return Boolean(this.provider.session.isSuperUser);
  }

  public async execute({ user, group }: IAddUserToGroupCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Add user ${user.name} to group "${group.name}"`);

    const connection = await this.provider.getConnection();
    const result = await connection.addUserToGroup({ username: user.name, group_name: group.name });
    if (result.status !== StatusType.OK) {
      throw new AddUserToGroupError(result);
    }

    user.addGroup(group);
    group.addUser(user);
  }
}
