import { observer } from "mobx-react";
import React, { useCallback } from "react";
import usePathNavigate from "../hooks/usePathNavigate";
import Path from "../state/Path";
import search from "../state/PathSearch";
import Button from "./Button";
import Icon, { Icons } from "./Icon";

export interface PathNavigateButtonProps {
  path: Path;
}

const PathNavigateButton: React.FC<PathNavigateButtonProps> = ({ path }) => {
  const navigate = usePathNavigate({ includeSearch: false });

  const navigateTo = useCallback(() => {
    search.clear();
    navigate(path);
  }, [navigate, path]);

  if (search.active) {
    return (
      <Button onClick={navigateTo}>
        <Icon icon={Icons.Link} /> Navigate to
      </Button>
    );
  }

  return null;
};

export default observer(PathNavigateButton);
