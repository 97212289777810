import { StatusType } from "@omniverse/api/data";
import { AuthStatus } from "@omniverse/auth/data";
import AuthMessages from "@omniverse/auth/react/AuthMessages";

export class GetUsersError extends Error {
  public readonly messages: { [status: string]: string } = {
    ...AuthMessages,
    [AuthStatus.Disabled]: "Your account is disabled on this server.",
  };
  public readonly result: { status: AuthStatus };

  constructor(result: { status: AuthStatus }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}

export class ManageUsersError extends Error {
  public readonly messages: { [status: string]: string } = {
    ...AuthMessages,
    [AuthStatus.Disabled]: "Your account is disabled on this server.",
    [AuthStatus.Denied]: "You can't manage permissions on this server.",
  };
  public readonly result: { status: AuthStatus };

  constructor(result: { status: AuthStatus }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}

export class GetUserGroupsError extends Error {
  public readonly messages: { [status: string]: string } = {
    [StatusType.Denied]: "You don't have access to read groups of this user.",
  };
  public readonly result: { status: StatusType };

  constructor(result: { status: StatusType }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}

export class AddUserToGroupError extends Error {
  public readonly messages: { [status: string]: string } = {
    [StatusType.Denied]: "Permission denied.",
  };
  public readonly result: { status: StatusType };

  constructor(result: { status: StatusType }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}

export class RemoveUserFromGroupError extends Error {
  public readonly messages: { [status: string]: string } = {
    [StatusType.Denied]: "Permission denied.",
  };
  public readonly result: { status: StatusType };

  constructor(result: { status: StatusType }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}

export class GetProfileError extends Error {
  public readonly messages: { [status: string]: string } = {
    ...AuthMessages,
    [AuthStatus.Denied]: "You don't have access to read this profile.",
    [AuthStatus.Disabled]: "This account is disabled.",
  };
  public readonly result: { status: AuthStatus };

  constructor(result: { status: AuthStatus }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}

export class SetProfileError extends Error {
  public readonly messages: { [status: string]: string } = {
    ...AuthMessages,
    [AuthStatus.Denied]: "You don't have access to change this profile.",
    [AuthStatus.Disabled]: "This account is disabled.",
  };
  public readonly result: { status: AuthStatus };

  constructor(result: { status: AuthStatus }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}

export class ResetPasswordError extends Error {
  public readonly messages: { [status: string]: string } = {
    ...AuthMessages,
    [AuthStatus.Expired]: "The link has expired.",
  };
  public readonly result: { status: AuthStatus };

  constructor(result: { status: AuthStatus }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}

export class GenerateResetPasswordPayloadError extends Error {
  public readonly messages: { [status: string]: string } = {
    ...AuthMessages,
    [AuthStatus.Denied]: "You don't have access to generate tokens for this user.",
  };
  public readonly result: { status: AuthStatus };

  constructor(result: { status: AuthStatus }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}

export const DefaultMessage = (status: string) => `Unknown error, please contact administrator (${status}).`;
