import React from "react";
import styled from "styled-components";
import CreateLocalServerImage from "../static/create-local-server.png";
import CreateRemoteServerImage from "../static/create-remote-server.png";
import JoinServerImage from "../static/join-server.png";
import DetailedGridCell from "./DetailedGridCell";

export interface ServerJoinProps {
  visibility?: {
    join?: boolean;
    local?: boolean;
    remote?: boolean;
  };
  onJoin?(): void;
  onCreateLocalServer?(): void;
  onCreateRemoteServer?(): void;
}

const ServerJoin: React.FC<ServerJoinProps> = ({
  visibility: { join = true, local = true, remote = true } = {},
  onJoin,
  onCreateLocalServer,
  onCreateRemoteServer,
}) => {
  return (
    <StyledServerJoin>
      {join && (
        <DetailedGridCell
          title={"Connect to a Server"}
          image={JoinServerImage}
          details={"Join a friend's or Company's External server"}
          onClick={onJoin}
        />
      )}
      {local && (
        <DetailedGridCell
          title={"Create Local Server"}
          image={CreateLocalServerImage}
          details={"Sharing content from my PC with others"}
          onClick={onCreateLocalServer}
        />
      )}
      {remote && (
        <DetailedGridCell
          title={"Create Cloud Server"}
          image={CreateRemoteServerImage}
          details={"Sharing content from the cloud with others"}
          onClick={onCreateRemoteServer}
        />
      )}
    </StyledServerJoin>
  );
};

const StyledServerJoin = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(150px, 300px));
  position: relative;
  grid-gap: 20px;
  margin: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  z-index: 1;
`;

export default ServerJoin;
