import { action, computed, makeObservable, observable } from "mobx";
import Storage from "./Storage";
import User from "./User";

class Users {
  public items: User[];
  public loading: boolean;

  public constructor(public readonly storage: Storage) {
    this.items = [];
    this.loading = false;

    makeObservable(this, {
      items: observable,
      loading: observable,
      names: computed,
      add: action.bound,
      set: action.bound,
      delete: action.bound,
      setLoading: action.bound,
    });
  }

  public get names(): string[] {
    return this.items.map((user) => user.name);
  }

  public add(user: User): void {
    this.items.push(user);
    this.items = this.items.sort((a, b) => a.name.localeCompare(b.name));
  }

  public set(users: User[]): void {
    this.items = users;
  }

  public delete(user: User): void {
    this.items = this.items.filter((u) => u.name !== user.name);
  }

  public setLoading(value: boolean): void {
    this.loading = value;
  }
}

export default Users;
