import { observer } from "mobx-react";
import React, { useCallback } from "react";
import Path from "../state/Path";
import Button from "./Button";
import Icon, { Icons } from "./Icon";

const PathOpenButton: React.FC<{
  path: Path;
}> = ({ path }) => {
  const open = useCallback(async () => {
    window.open(path.link, "_self");
  }, [path.link]);

  return (
    <Button name={"copy"} onClick={open}>
      <Icon icon={Icons.Open} /> Open in Launcher
    </Button>
  );
};

export default observer(PathOpenButton);
