import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import { TagCommands } from "../state/commands/Provider";
import { IAddTagCommand } from "../state/commands/types/tags/AddTagCommand";
import { IDeleteTagCommand } from "../state/commands/types/tags/DeleteTagCommand";
import { IEditTagCommand } from "../state/commands/types/tags/EditTagCommand";
import Tags, { Tag } from "../state/Tags";
import FormToggleable, { FormToggleableContainer, FormToggleableLabel } from "./FormToggleable";
import List, { ListAutoComplete, ListItem } from "./List";
import Scrollable from "./Scrollable";

export interface AdvancedTagEditorProps {
  tags: Tags;
  readOnly: boolean;
}

const AdvancedTagEditor: React.FC<AdvancedTagEditorProps> = ({ tags, readOnly }) => {
  const appearanceTags = tags.appearance.map((tag) => tag.fullName);
  const systemTags = tags.system.map((tag) => tag.fullName);

  function addTag(fullName: string) {
    const tag = Tag.fromFullName(fullName);
    const command = tags.storage.commands.get<IAddTagCommand>(TagCommands.Add);
    if (command) {
      return command.execute({ tags, tag });
    } else {
      throw new Error("Tags are not supported.");
    }
  }

  function editTag(oldFullName: string, newFullName: string) {
    const oldTag = Tag.fromFullName(oldFullName);
    const newTag = Tag.fromFullName(newFullName);
    const command = tags.storage.commands.get<IEditTagCommand>(TagCommands.Edit);
    if (command) {
      return command.execute({ tags, oldTag, newTag });
    } else {
      throw new Error("Tags are not supported.");
    }
  }

  function deleteTag(fullName: string) {
    const deletedTag = Tag.fromFullName(fullName);
    const command = tags.storage.commands.get<IDeleteTagCommand>(TagCommands.Delete);
    if (command) {
      return command.execute({ tags, tag: deletedTag });
    } else {
      throw new Error("Tags are not supported.");
    }
  }

  return (
    <Scrollable>
      <AdvancedViewToggleable label={"Appearance"} initial={true}>
        <AdvancedViewList
          items={appearanceTags}
          disabled={readOnly || tags.loading}
          canEdit
          onAdd={addTag}
          onEdit={editTag}
          onDelete={deleteTag}
        />
      </AdvancedViewToggleable>

      <AdvancedViewToggleable label={"System"} initial={true}>
        <AdvancedViewList
          items={systemTags}
          disabled={readOnly || tags.loading}
          canEdit
          onAdd={addTag}
          onEdit={editTag}
          onDelete={deleteTag}
        />
      </AdvancedViewToggleable>
    </Scrollable>
  );
};

const AdvancedViewToggleable = styled(FormToggleable).attrs(() => ({
  container: AdvancedViewContainer,
  labelComponent: AdvancedViewLabel,
}))`
  margin: 15px;
`;

const AdvancedViewContainer = styled(FormToggleableContainer)`
  padding: 5px 0 5px 15px;
`;

const AdvancedViewLabel = styled(FormToggleableLabel)`
  margin-left: 7px;
`;

const AdvancedViewList = styled(List).attrs(() => ({
  itemComponent: AdvancedViewListItem,
  inputComponent: AdvancedViewListInput,
}))`
  background: ${({ theme }) => theme.colors.background};
  font-size: 10pt;
`;

const AdvancedViewListItem = styled(ListItem)`
  padding: 5px;
  box-sizing: border-box;
  height: 30px;
  color: ${({ theme }) => theme.colors.foreground};
`;

const AdvancedViewListInput = styled(ListAutoComplete).attrs({
  placeholder: "namespace.key=value",
})`
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.foreground};
  font-size: 10pt;
  padding: 5px 50px 5px 5px;
  height: 30px;
  border-top: none;
`;

export default observer(AdvancedTagEditor);
