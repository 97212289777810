import React, { useMemo, useState } from "react";

export interface IDialogContext {
  visible: boolean;
  show(dialog: JSX.Element): void;
  hide(): void;
}

export const DialogContext = React.createContext<IDialogContext>({
  visible: false,
} as IDialogContext);

const Dialogs: React.FC = ({ children }) => {
  const [dialog, setDialog] = useState<JSX.Element | null>(null);

  function show(dialog: JSX.Element) {
    setDialog(dialog);
  }

  function hide() {
    setDialog(null);
  }

  const context = useMemo<IDialogContext>(
    () => ({
      visible: !!dialog,
      show,
      hide,
    }),
    [dialog]
  );

  return (
    <DialogContext.Provider value={context}>
      {dialog}
      {children}
    </DialogContext.Provider>
  );
};

export default Dialogs;
