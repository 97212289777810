import { useEffect, useState } from "react";


export default function useMouse() {
  const [x, setX] = useState(Number.MIN_VALUE);
  const [y, setY] = useState(Number.MIN_VALUE);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    function setPosition(e: MouseEvent) {
      setX(e.clientX);
      setY(e.clientY);
      setReady(true);
    }

    document.body.addEventListener("mousemove", setPosition);
    return () => document.body.removeEventListener("mousemove", setPosition);
  }, []);

  return { x, y, ready };
}