import { action, makeObservable, observable } from "mobx";
import PathUpload from "./PathUpload";

class PathUploadList {
  public items: PathUpload[];

  public constructor() {
    this.items = [];

    makeObservable(this, {
      items: observable,
      add: action.bound,
      abort: action.bound,
      remove: action.bound,
    });
  }

  public add(upload: PathUpload): PathUpload {
    this.items.push(upload);
    return upload;
  }

  public abort(upload: PathUpload) {
    upload.abort();
  }

  public remove(upload: PathUpload) {
    this.abort(upload);

    const index = this.items.indexOf(upload);
    if (index !== -1) {
      this.items.splice(index, 1);
    }
  }
}

export default PathUploadList;
