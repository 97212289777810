import React from "react";
import styled from "styled-components";
import Spinner from "./Spinner";
import { DefaultTheme, Theme } from "./Theme";

export interface ButtonProps {
  disabled?: boolean;
  kind?: "default" | "main" | "success" | "attention" | "warning" | "error" | "interaction";
  children?: React.ReactNode;
}

const Button = styled.button<ButtonProps>`
  position: relative;
  background: ${({ disabled, kind = "default", theme }) =>
    disabled ? theme.colors.disabledBackground : backgrounds(theme, kind)};
  color: ${({ disabled, kind = "default", theme }) => (disabled ? theme.colors.disabledText : colors(theme, kind))};
  text-transform: uppercase;
  text-align: center;
  border: none;
  border-radius: 2px;
  font: 11pt "Open Sans", sans-serif;
  height: 25px;
  line-height: 21px;
  padding: 2px 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s filter;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
  flex: 0 0 auto;

  &:hover {
    filter: ${({ disabled }) => (disabled ? "none" : "contrast(120%)")};
  }

  &:active {
    transform: ${({ disabled }) => (disabled ? "none" : "translateY(1px)")};
  }

  ${Spinner} {
    color: ${({ disabled, kind = "default", theme }) => (disabled ? theme.colors.disabledText : colors(theme, kind))};
    margin-right: 5px;
  }
`;

const backgrounds = (theme: Theme, kind: ButtonProps["kind"]): string => {
  const variants: { [kind: string]: string } = {
    default: theme.colors.inputBackground,
    main: theme.colors.inputBackground,
    interaction: theme.colors.interaction,
    success: theme.colors.successBackground,
    attention: theme.colors.attentionBackground,
    warning: theme.colors.warningBackground,
    error: theme.colors.dangerBackground,
  };
  return kind ? variants[kind] : variants["default"];
};

const colors = (theme: Theme, kind: ButtonProps["kind"]): string => {
  const variants = {
    default: theme.colors.inputText,
    main: theme.colors.inputText,
    interaction: theme.colors.successText,
    success: theme.colors.successText,
    attention: theme.colors.attentionText,
    warning: theme.colors.warningText,
    error: theme.colors.dangerText,
  };
  return kind ? variants[kind] : variants["default"];
};

Button.defaultProps = {
  kind: "default",
  type: "button",
  theme: DefaultTheme,
};

export default Button;
