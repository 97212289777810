import { AuthStatus } from "@omniverse/auth/data";
import { OfflineModeError } from "../../../util/SessionErrors";
import { SetProfileError } from "../../../util/UserErrors";
import { Commands } from "../Provider";
import { ISetUserReadOnlyAccessCommandArguments } from "../types/SetUserReadOnlyAccessCommand";
import { NucleusCommand } from "./index";
import { parseNucleusProfile } from "./Nucleus";

export default class NucleusSetUserReadOnlyAccessCommand extends NucleusCommand<ISetUserReadOnlyAccessCommandArguments> {
  name = Commands.SetUserReadOnlyAccess;

  public async allowed(): Promise<boolean> {
    return (
      Boolean(this.provider.session.isSuperUser) &&
      typeof this.provider.profileCapabilities?.set_nucleus_ro === "number"
    );
  }

  public async execute({ user, isReadonly }: ISetUserReadOnlyAccessCommandArguments): Promise<void> {
    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }

    console.log(
      `[${this.provider.name}] Set user "${user.name}" ${isReadonly ? "as read-only" : "as regular account"}`
    );
    const profiles = await this.provider.createProfilesClient();
    try {
      const result = await profiles.setNucleusRo({
        token: this.provider.session.accessToken!,
        username: user.name,
        nucleus_ro: isReadonly,
      });

      if (result.status === AuthStatus.Expired) {
        await this.provider.session.refresh();
        return this.execute({ user, isReadonly });
      }

      if (result.status !== AuthStatus.OK) {
        throw new SetProfileError(result);
      }

      const profile = parseNucleusProfile(result.profile!);
      user.setProfile(profile!);
    } finally {
      await profiles.transport.close();
    }
  }
}
