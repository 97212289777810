import { resetPassword } from "@omniverse/auth/react/hooks/ResetPassword";
import { Commands } from "../Provider";
import { IResetPasswordCommandArguments } from "../types/ResetPasswordCommand";
import { NucleusCommand } from "./index";

export default class NucleusResetPasswordCommand extends NucleusCommand<IResetPasswordCommandArguments> {
  name = Commands.ResetPassword;

  public async allowed(): Promise<boolean> {
    return true;
  }

  public async execute({ user, payload, newPassword }: IResetPasswordCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Reset password for user "${user.name}"`);
    const auth = await resetPassword(newPassword, payload);
    this.provider.session.authenticate(auth.username!, auth.access_token!, auth.refresh_token!, auth.profile?.admin);
  }
}
