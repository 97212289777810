import { action, computed, makeObservable, observable } from "mobx";
import Storage from "./Storage";

class StorageList {
  public map: Map<string, Storage>;
  public selected: Storage | null;
  public readOnly: boolean;

  constructor(storages: Storage[] = []) {
    this.map = new Map<string, Storage>();
    this.selected = null;
    this.readOnly = false;
    this.addMany(storages);

    makeObservable(this, {
      map: observable,
      selected: observable,
      readOnly: observable,
      items: computed,
      add: action.bound,
      remove: action.bound,
      select: action.bound,
      setReadOnly: action.bound,
    });
  }

  public get items(): Storage[] {
    return Array.from(this.map.values());
  }

  public static fromJSON(json: any): StorageList {
    const storageList = new StorageList();
    if (typeof json.readOnly !== "undefined") {
      storageList.readOnly = json.readOnly;
    }

    if (json.items) {
      json.items
        .map((storage: any) => {
          try {
            return Storage.fromJSON(storage);
          } catch (error) {
            console.error(error);
            return null;
          }
        })
        .filter((storage: Storage | null) => Boolean(storage))
        .forEach((storage: Storage) => {
          storageList.add(storage);
        });
    }

    const selected = json.selected ? storageList.find(json.selected.name) : null;
    if (selected) {
      storageList.selected = selected;
    }
    return storageList;
  }

  public add(storage: Storage) {
    this.map.set(storage.name, storage);
  }

  public addMany(storages: Storage[]) {
    for (const storage of storages) {
      this.add(storage);
    }
  }

  public remove(storage: Storage) {
    this.map.delete(storage.name);
    if (this.selected === storage) {
      this.selected = null;
    }
  }

  public select(storage: Storage | null = null) {
    this.selected = storage;
  }

  public find(storage: string): Storage | null {
    return this.map.get(storage) ?? null;
  }

  public setReadOnly(readOnly: boolean): void {
    this.readOnly = readOnly;
  }

  public toJSON() {
    return {
      items: Array.from(this.map.values()).map((storage) => storage.toJSON()),
      selected: this.selected ? this.selected.toJSON() : null,
      readOnly: this.readOnly,
    };
  }
}

export default StorageList;
