import { action, makeObservable, observable } from "mobx";
import type { DownloadProgress } from "../electron/ipc/DownloadList";
import TaskStatus from "./TaskStatus";

class Download {
  public percent: number = 0;
  public transferredSize: number = 0;
  public totalSize: number = 0;
  public status: TaskStatus = TaskStatus.Idle;

  readonly abortCb: () => void;

  public constructor(public fileName: string, abortCb: () => void) {
    this.abortCb = abortCb;

    makeObservable(this, {
      percent: observable,
      transferredSize: observable,
      totalSize: observable,
      status: observable,

      setCompleted: action.bound,
      setProgress: action.bound,
      setFailed: action.bound,
      abort: action.bound,
    });
  }

  public setProgress(progress: DownloadProgress) {
    this.status = TaskStatus.Loading;
    this.transferredSize = progress.downloadedBytes;
    this.totalSize = progress.totalBytes;
  }

  public abort() {
    this.status = TaskStatus.Aborted;
    this.abortCb();
  }

  public setCompleted() {
    this.status = TaskStatus.Done;
  }

  public setFailed() {
    this.status = TaskStatus.Error;
  }
}

export default Download;
