import React, {useCallback, useEffect, useMemo, useState} from "react";

export interface TableSorterProps<TRow> {
  rows: TRow[];
  sorting: string;
  direction: SortingDirection;
  sort?(rows: TRow[]): TRow[];
  children(data: TableSorterData<TRow>): JSX.Element;
}

export type SortingDirection = "ASC" | "DESC";

export interface TableSorterData<TRow> {
  rows: TRow[];
  sorting: string;
  direction: SortingDirection;
}

export default function TableSorter<TRow>({ rows, sorting, direction, sort, children }: TableSorterProps<TRow>) {
  const sortRows = useCallback(
    (rows: any[]): any[] => {
      if (sort) {
        return sort(rows);
      }

      const column = sorting;
      return [...rows].sort((previousRow, currentRow) => {
        const comparisonDirection = direction === "ASC" ? 1 : -1;
        const previous = previousRow[column];
        if (previous == null) {
          return comparisonDirection;
        }

        const current = currentRow[column];
        if (current == null) {
          return -comparisonDirection;
        }

        return comparisonDirection * previous.toString().localeCompare(current.toString());
      });
    },
    [sorting, direction, sort]
  );

  const [sortedRows, setSortedRows] = useState<TRow[]>([]);
  useEffect(() => {
    setSortedRows(sortRows(rows));
  }, [rows, sortRows]);

  const data = useMemo((): TableSorterData<TRow> => {
    return {
      sorting,
      direction,
      rows: sortedRows
    };
  }, [sorting, direction, sortedRows]);

  return (
    <TableSorterContext.Provider value={data}>
      {children(data)}
    </TableSorterContext.Provider>
  );
}

export const TableSorterContext = React.createContext<TableSorterData<any>>({
  sorting: "",
  direction: "ASC",
  rows: []
});
