import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import useAppLocation from "../hooks/useAppLocation";
import useAppNavigate from "../hooks/useAppNavigate";
import useContextMenu, { UseContextMenuOptions } from "../hooks/useContextMenu";
import usePathDropSource from "../hooks/usePathDropSource";
import useQuery from "../hooks/useQuery";
import useSubmit from "../hooks/useSubmit";
import { Commands, parseLink, TagCommands } from "../state/commands/Provider";
import { IGetSearchPrefixesCommand } from "../state/commands/types/GetSearchPrefixesCommand";
import { ISearchCommand } from "../state/commands/types/SearchCommand";
import { IGetTagSuggestionsCommand } from "../state/commands/types/tags/GetTagSuggestionsCommand";
import Path, { PathType } from "../state/Path";
import search, { hasAnySearchOptions, PathSearchQuery, sanitizeTags } from "../state/PathSearch";
import Storage from "../state/Storage";
import { acceptedImageFormats } from "../util/Image";
import Button from "./Button";
import Icon, { Icons } from "./Icon";
import Search, { SearchAutoComplete, SearchAutoCompleteMatch, SearchInputContainer, SearchWrapper } from "./Search";
import SearchByImage, { SearchByImageProps } from "./SearchByImage";
import SearchOptions, { SearchOptionsProps } from "./SearchOptions";
import Tag from "./Tag";
import { TagAutoCompleteContainer } from "./TagAutoComplete";
import { DefaultTheme } from "./Theme";

export interface ContentBrowserSearchProps {
  storage: Storage;
  onError?(error: string): void;
}

const ContentBrowserSearch: React.FC<ContentBrowserSearchProps> = ({ storage, onError }) => {
  const location = useAppLocation();
  const navigate = useAppNavigate();

  useQuery(setSearchQueryFromLocation, {
    keys: [location, storage],
    onSubmit: () => onError?.(""),
    onError,
  });

  async function setSearchQueryFromLocation() {
    const params = new URLSearchParams(location.search);
    const queryString = params.get("query");
    if (!queryString) {
      search.clear();
      return;
    }

    const command = storage.commands.get<ISearchCommand>(Commands.Search);
    if (!command) {
      return;
    }

    const { query, text } = await command.deserialize(queryString);
    search.setQuery(query);
    search.setText(text);
    if (search.hasQuery && storage) {
      return await search.run(storage);
    }
  }

  async function runSearch(query: PathSearchQuery = search.query, text = search.text) {
    const hasOptions = hasAnySearchOptions(query);
    if (!hasOptions && !text) {
      navigate(location.pathname);
      return;
    }

    const command = storage.commands.get<ISearchCommand>(Commands.Search);
    if (command) {
      const queryString = await command.serialize(query, { text });
      if (query.image || query.sample) {
        // Images can't be cached in the URL so such search is shown immediately w/o changing the URL.
        search.setQuery(query);
        search.setText(queryString);

        if (storage) {
          return await search.run(storage);
        } else {
          return;
        }
      } else {
        navigate(`${location.pathname}?query=${queryString}`);
      }
    }
  }

  const { ok: prefixesLoaded, data: prefixes } = useQuery(
    async () => {
      const command = storage.commands.get<IGetSearchPrefixesCommand>(Commands.GetSearchPrefixes);
      if (command) {
        return await command.execute();
      } else {
        return {};
      }
    },
    {
      keys: [storage],
    }
  );

  const { submit: loadSuggestions } = useSubmit(async () => {
    const tags = storage?.cwd?.tags;
    if (!tags) {
      return [];
    }

    const command = storage?.commands.get<IGetTagSuggestionsCommand>(TagCommands.GetSuggestions);
    if (command) {
      return command.execute({ tags });
    } else {
      return [];
    }
  });

  const suggestions = storage?.cwd?.tags.suggestions;
  const text = search.text;
  const tags = search.query.tags;
  const image = search.query.image;
  const sample = getSampleFile(search.query.sample, storage);
  const isActive = search.hasQuery;
  const [focused, setFocused] = useState(false);
  const loading = search.loading;
  const placeholder = `Search on ${storage.publicName}`;

  const [searchByImage, showSearchByImageMenu, hideSearchByImage] = useContextMenu(SearchByImage, {
    ...SearchByImageOptions,
    props: {
      initial: image || sample,
      onSubmit: async ({ image, sample }) => {
        hideSearchByImage();
        await runSearch({ ...search.query, image, sample }, "");
      },
      onClose: () => {
        hideSearchByImage();
      },
    },
  });

  const [searchOptions, showOptionsMenu, hideOptions] = useContextMenu(SearchOptions, {
    ...SearchByMetadataOptions,
    props: {
      initial: search.query,
      prefixes: prefixes ?? {},
      tagSuggestions: suggestions,
      onSubmit: async (options) => {
        hideOptions();
        await runSearch({ ...search.query, ...options }, "");
      },
      onClose: () => {
        hideOptions();
      },
    },
  });

  const showOptions = useCallback(
    (e: React.MouseEvent) => {
      hideSearchByImage();
      showOptionsMenu(e);
      loadSuggestions().catch((error) => console.error(error));
    },
    [hideSearchByImage, loadSuggestions, showOptionsMenu]
  );

  const showSearchByImage = useCallback(
    (e: React.MouseEvent) => {
      hideOptions();
      showSearchByImageMenu(e);
    },
    [hideOptions, showSearchByImageMenu]
  );

  async function change(text: string, tags: string[] | undefined = search.query.tags) {
    if (tags) {
      tags = sanitizeTags(tags);
    } else {
      tags = [];
    }

    search.setText(text);
    search.setQuery({ ...search.query, tags });

    if (!text && (!tags || tags.length === 0)) {
      clear();
    }
  }

  function clear() {
    navigate(location.pathname);
  }

  async function handleSearch(text: string, tags?: string[]) {
    if (!text && (!tags || tags.length === 0)) {
      clear();
    } else {
      await change(text, tags);
      await runSearch();
    }
  }

  function handleImageSearchClick(e: React.MouseEvent) {
    if (searchByImage) {
      hideSearchByImage();
    } else {
      showSearchByImage(e);
    }
  }

  function handleSearchOptionClick(e: React.MouseEvent) {
    if (searchOptions) {
      hideOptions();
    } else {
      showOptions(e);
    }
  }

  async function removeImage() {
    await runSearch({ ...search.query, image: undefined }, "");
  }

  async function removeSample() {
    await runSearch({ ...search.query, sample: undefined }, "");
  }

  function handleBlurOut(e: React.FocusEvent<HTMLDivElement>) {
    if (!e.currentTarget.contains(e.relatedTarget) && e.currentTarget !== e.relatedTarget) {
      setFocused(false);
    }
  }

  async function handleFocusIn() {
    setFocused(true);
    hideOptions();
    hideSearchByImage();
    await loadSuggestions();
  }

  const { allowed: pathDropAllowed, onMouseLeave, onMouseUp, onMouseEnter } = usePathDropSource(
    async ([path]) => {
      hideOptions();
      hideSearchByImage();
      await runSearch({ ...search.query, sample: path.link });
    },
    ([path, ...rest]) => path?.type === PathType.File && rest.length === 0
  );

  const { isDragActive: fileDropAllowed, getRootProps: getFileDropProps } = useDropzone({
    accept: acceptedImageFormats,
    disabled: Boolean(!prefixesLoaded || !prefixes?.image),
    maxFiles: 1,
    noClick: true,
    noDragEventsBubbling: true,
    noKeyboard: true,
    onDrop: async ([file]) => {
      await runSearch({ ...search.query, image: file });
    },
  });

  const { onDragEnter, onDragLeave, onDrop, onDragOver } = getFileDropProps();
  return (
    <ContentBrowserSearchContainer onBlur={handleBlurOut}>
      <StyledContentBrowserSearch
        dropAllowed={pathDropAllowed || fileDropAllowed}
        isActive={isActive}
        loading={loading}
        placeholder={placeholder}
        searchButton={ContentBrowserSearchButton}
        suggestions={suggestions}
        tags={tags}
        value={text}
        onChange={change}
        onFocusIn={handleFocusIn}
        onSearch={handleSearch}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseUp={onMouseUp}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
        after={
          <>
            {prefixesLoaded && prefixes?.image && (
              <ContentSearchIcon
                icon={Icons.Camera}
                active={Boolean(searchByImage)}
                clickable
                focused={isActive || Boolean(searchByImage) || Boolean(searchOptions)}
                title={"Search by image"}
                onClick={handleImageSearchClick}
              />
            )}
            {prefixesLoaded && prefixes?.name && (
              <ContentSearchIcon
                icon={Icons.Options}
                active={Boolean(searchOptions)}
                clickable
                focused={isActive || Boolean(searchByImage) || Boolean(searchOptions)}
                title={"Search options"}
                onClick={handleSearchOptionClick}
              />
            )}
          </>
        }
      >
        {image && <SearchedImageTag fileName={image.name} onRemove={removeImage} />}
        {sample && <SearchedImageTag fileName={sample.name} onRemove={removeSample} />}
      </StyledContentBrowserSearch>

      {isActive && focused && <ContentBrowserSearchButton onClick={() => runSearch()} />}

      {searchOptions}
      {searchByImage}
    </ContentBrowserSearchContainer>
  );
};

const ContentBrowserSearchContainer = styled.div`
  position: relative;
  width: 400px;
  flex: 0 0 400px;
`;

const StyledContentBrowserSearch = styled(Search).attrs(() => ({
  input: StyledPathSearchInput,
}))<{ dropAllowed: true }>`
  flex: 0 0 400px;
  margin-left: auto;
  height: 25px;
  outline: ${({ dropAllowed, theme }) => (dropAllowed ? `2px solid ${theme.colors.interaction}` : "none")};
`;
StyledContentBrowserSearch.defaultProps = {
  theme: DefaultTheme,
};

const StyledPathSearchInput = styled(SearchAutoComplete).attrs(() => ({
  containerComponent: StyledPathSearchContainer,
  matchComponent: StyledPathSearchMatch,
}))``;

const StyledPathSearchContainer = styled(SearchInputContainer)`
  margin-right: 90px;
`;

const StyledPathSearchMatch = styled(SearchAutoCompleteMatch)`
  z-index: 2;
`;

const ContentSearchIcon = styled(Icon).attrs({
  tabIndex: 0,
})<{ active: boolean; focused: boolean }>`
  color: ${({ active, theme }) => (active ? theme.colors.interaction : theme.colors.toolbarForeground)};
  opacity: ${({ focused }) => (focused ? "1" : "0")};
  transition: 0.3s all;

  ${TagAutoCompleteContainer}:hover &,
  ${TagAutoCompleteContainer}:focus-within &,
  &:focus {
    opacity: 1;
  }
`;
ContentSearchIcon.defaultProps = {
  theme: DefaultTheme,
};

const SearchByMetadataOptions: Partial<UseContextMenuOptions<SearchOptionsProps>> = {
  anchors: ["top", "right"],
  portal: null,
  position: { x: 30, y: 25 },
  hideOnBlur: false,
  hideOnClick: false,
  hideOnClickOutside: false,
};

const SearchByImageOptions: Partial<UseContextMenuOptions<SearchByImageProps>> = {
  anchors: ["top", "right"],
  portal: null,
  position: { x: 65, y: 25 },
  hideOnBlur: false,
  hideOnClick: false,
  hideOnClickOutside: false,
};

const SearchedImageTag: React.FC<{ fileName: string; onRemove(): void }> = ({ fileName, onRemove }) => (
  <StyledSearchedImageTag tag={fileName} disabled={false} onDelete={onRemove}>
    <Icon icon={Icons.Image} /> {fileName}
  </StyledSearchedImageTag>
);

const StyledSearchedImageTag = styled(Tag)`
  margin: 0 5px;
`;

const ContentBrowserSearchButton: React.FC<{ onClick?(e: React.MouseEvent): void }> = ({ onClick }) => {
  return (
    <StyledContentBrowserSearchButton>
      <Button kind="attention" onClick={onClick}>
        <Icon icon={Icons.ArrowRight} /> Start search
      </Button>
    </StyledContentBrowserSearchButton>
  );
};

const StyledContentBrowserSearchButton = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  width: calc(100% - 1em);
  box-sizing: content-box;
  padding: 0.5em;
  background: ${({ theme }) => theme.colors.headerBackground};
  z-index: 1;
  box-shadow: ${({ theme }) => theme.shadows.default};

  ${Button} {
    width: 100%;
  }
`;
StyledContentBrowserSearchButton.defaultProps = {
  theme: DefaultTheme,
};

function getSampleFile(url: string | undefined, storage: Storage): Path | null {
  if (!url) {
    return null;
  }

  const link = parseLink(url, "omniverse");
  return link ? storage.get(link.path) : null;
}

export default observer(ContentBrowserSearch);
