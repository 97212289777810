import { observer } from "mobx-react";
import React from "react";
import useDialog from "../hooks/useDialog";
import usePreference from "../hooks/usePreference";
import { Preferences } from "../state/Preferences";
import AboutDialog from "./AboutDialog";
import { Icons } from "./Icon";
import Menu from "./Menu";
import { MenuCheckboxItem, MenuIconItem, MenuItemDivider } from "./MenuItem";

const SettingsMenu = () => {
  const [theme, setTheme] = usePreference<string>(Preferences.Theme, "dark");
  const about = useDialog(AboutDialog);

  function toggleTheme() {
    setTheme((theme) => (theme === "dark" ? "default" : "dark"));
  }

  function showAboutDialog() {
    about.show();
  }

  return (
    <Menu>
      <MenuCheckboxItem checked={theme === "dark"} onClick={toggleTheme}>
        Use dark theme
      </MenuCheckboxItem>

      <MenuItemDivider />

      <MenuIconItem icon={Icons.Info} onClick={showAboutDialog}>
        About
      </MenuIconItem>
    </Menu>
  );
};

export default observer(SettingsMenu);
