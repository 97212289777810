import { encodeInvitationPayload } from "@omniverse/auth/react/hooks/InviteUser";
import { isElectron } from "../../../util/Electron";
import { joinURL } from "../../../util/Path";
import { OfflineModeError } from "../../../util/SessionErrors";
import { Commands } from "../Provider";
import { IGenerateInvitationLinkCommandArguments } from "../types/GenerateInvitationLinkCommand";
import { NucleusCommand } from "./index";

export default class NucleusGenerateInvitationLinkCommand extends NucleusCommand<
  IGenerateInvitationLinkCommandArguments,
  void,
  string
> {
  name = Commands.GenerateInvitationLink;

  public async allowed(): Promise<boolean> {
    return Boolean(this.provider.session.isSuperUser);
  }

  public async execute({ user }: IGenerateInvitationLinkCommandArguments): Promise<string> {
    console.log(`[${this.provider.name}] Generate invitation link for user "${user.name}"`);

    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }

    const payload = await encodeInvitationPayload(this.provider.name, user.name, this.provider.session.accessToken!);

    if (isElectron()) {
      const loginURL = await this.provider.session.getExternalLoginURL();
      return joinURL(loginURL, `/invitation?payload=${payload}`);
    } else {
      return joinURL(`/invitation?payload=${payload}`);
    }
  }
}
