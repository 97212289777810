import { observer } from "mobx-react";
import React from "react";
import useDialogForm from "../hooks/useDialogForm";
import useInput from "../hooks/useInput";
import { Commands } from "../state/commands/Provider";
import { ICreateGroupCommand } from "../state/commands/types/CreateGroupCommand";
import { IRenameGroupCommand } from "../state/commands/types/RenameGroupCommand";
import Group from "../state/Group";
import Groups from "../state/Groups";
import { ReactComponent as GroupSVG } from "../static/group.svg";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import Dialog from "./Dialog";
import DialogForm from "./DialogForm";
import DialogTitle from "./DialogTitle";
import FormErrorList from "./FormErrorList";
import FormGroup from "./FormGroup";
import Input from "./Input";
import Label from "./Label";

export interface GroupFormProps {
  groups: Groups;
  group?: Group;
}

interface GroupFormDialogFields {
  groupName: string;
}

const GroupFormDialog: React.FC<GroupFormProps> = ({ groups, group }) => {
  const [groupName, setGroupName] = useInput(group?.name ?? "");

  const form = useDialogForm({
    fields: {
      groupName,
    },
    onSubmit: submit,
  });

  async function submit({ groupName }: GroupFormDialogFields) {
    if (group) {
      const command = groups.storage.commands.get<IRenameGroupCommand>(Commands.RenameGroup);
      if (command) {
        await command.execute({ group, newName: groupName });
      }
    } else {
      const command = groups.storage.commands.get<ICreateGroupCommand>(Commands.CreateGroup);
      if (command) {
        await command.execute({ groups, name: groupName });
      }
    }
  }

  return (
    <Dialog onClose={form.cancel}>
      <DialogTitle image={GroupSVG} title={group ? "Edit Group" : "Add Group"} onClose={form.cancel} />
      <DialogForm>
        {form.errors && <FormErrorList errors={form.errors} />}

        <FormGroup>
          <Label>
            Name:
            <Input
              name={"group"}
              placeholder={"Group name"}
              autoFocus
              disabled={form.loading}
              value={groupName}
              onChange={setGroupName}
            />
          </Label>
        </FormGroup>

        <ButtonGroup>
          <Button kind={"attention"} disabled={form.loading} onClick={form.submit}>
            Submit
          </Button>
          <Button disabled={form.loading} onClick={form.cancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </DialogForm>
    </Dialog>
  );
};

export default observer(GroupFormDialog);
