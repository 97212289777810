import styled from "styled-components";
import { DefaultTheme } from "./Theme";

const Label = styled.label<{ disabled?: boolean }>`
  display: block;
  font-size: 10pt;
  color: ${({ theme, disabled }) => disabled ? theme.colors.disabledText : "inherit"};
`;
Label.defaultProps = {
  theme: DefaultTheme
};

export default Label;
