import Button from "./Button";
import Icon, { Icons } from "./Icon";
import React from "react";
import styled from "styled-components";
import useDialog from "../hooks/useDialog";
import NucleusAuthDialog from "./NucleusAuthDialog";
import ContentPlaceholder from "./ContentPlaceholder";

export interface ServerNotFoundProps {
  serverName: string;
}

const ServerNotFound: React.FC<ServerNotFoundProps> = ({ serverName }) => {
  const authDialog = useDialog(NucleusAuthDialog);

  function addServer() {
    authDialog.show({ serverName });
  }

  return (
    <ContentPlaceholder>
      <Text>Server is not found.</Text>
      <Button onClick={addServer}>
        <Icon icon={Icons.Cloud}/> Add
      </Button>
    </ContentPlaceholder>
  )
}

const Text = styled.div`
  padding: 1rem;
`

export default ServerNotFound;