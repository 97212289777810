import { observer } from "mobx-react";
import React from "react";
import usePreference from "../hooks/usePreference";
import { Preferences } from "../state/Preferences";
import Breadcrumbs from "./ContentBreadcrumbs";
import ContentBrowserViewToggle, { ContentBrowserView } from "./ContentBrowserViewToggle";
import StyledHeader from "./Header";
import RightPanelToggle from "./RightPanelToggle";
import Settings from "./Settings";

const ContentHeader: React.FC = ({ children }) => {
  const [view, setView] = usePreference<ContentBrowserView>(Preferences.ContentView, ContentBrowserView.Grid);
  const [rightPanelActive, setRightPanelActive] = usePreference<boolean>(Preferences.RightPanelOpened, true);

  return (
    <StyledHeader>
      <Breadcrumbs />
      {children}
      <ContentBrowserViewToggle view={view} onChange={setView} />
      <Settings />
      <RightPanelToggle active={rightPanelActive} onToggle={setRightPanelActive} />
    </StyledHeader>
  );
};

export default observer(ContentHeader);
