import React from "react";
import useDialog from "../hooks/useDialog";
import usePermission from "../hooks/usePermission";
import { Commands } from "../state/commands/Provider";
import { ICreateGroupCommand } from "../state/commands/types/CreateGroupCommand";
import Groups from "../state/Groups";
import GroupFormDialog from "./GroupFormDialog";
import { Icons } from "./Icon";
import Loader from "./Loader";
import Menu from "./Menu";
import { MenuIconItem } from "./MenuItem";

export interface GroupListMenuProps {
  groups: Groups;
}

const GroupListMenu: React.FC<GroupListMenuProps> = ({ groups }) => {
  const groupDialog = useDialog(GroupFormDialog, { groups });

  function addGroup() {
    groupDialog.show();
  }

  const canAddNewGroups = usePermission(
    () => groups.storage.commands.allowed<ICreateGroupCommand>(Commands.CreateGroup),
    [groups]
  );

  const loading = canAddNewGroups === "loading";
  if (loading) {
    return (
      <Menu>
        <Loader />
      </Menu>
    )
  }

  const visible = canAddNewGroups === "allowed";
  if (!visible) {
    return null;
  }

  return (
    <Menu>
      <MenuIconItem icon={Icons.Group} visible={canAddNewGroups === "allowed"} onClick={addGroup}>
        Add Group
      </MenuIconItem>
    </Menu>
  );
};

export default GroupListMenu;
