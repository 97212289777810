import styled from "styled-components";
import Picture from "./Picture";
import { DefaultTheme } from "./Theme";

const RightPanelHeaderImage = styled(Picture)`
  flex: 0 0 24pt;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1em;
  width: 24pt;
  height: 24pt;
  fill: ${({ theme }) => theme.colors.selectedItemForeground};
`;
RightPanelHeaderImage.defaultProps = {
  theme: DefaultTheme,
};

export default RightPanelHeaderImage;
