import { TaggingService } from "@omniverse/tagging";
import { TagModifyType } from "@omniverse/tagging/data";
import { TagCommands } from "../../Provider";
import { IDeleteTagCommandArguments } from "../../types/tags/DeleteTagCommand";
import { NucleusCommand } from "../index";
import { convertTagToNucleus, ensureNucleusTagResults } from "./index";

export default class NucleusDeleteTagCommand extends NucleusCommand<IDeleteTagCommandArguments> {
  name = TagCommands.Delete;

  public async allowed(): Promise<boolean> {
    return true;
  }

  public async execute({ tags, tag }: IDeleteTagCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Delete tag ${tag.fullName} from ${tags.path}`);

    tags.delete(tag);

    let service;
    try {
      service = await this.provider.createServiceClient(TaggingService, "TAGS", { capabilities: { modify_tags: 1 } });

      const values = [tag];
      const response = await service.modifyTags({
        paths: [tags.path],
        tags: values.map(convertTagToNucleus),
        auth_token: this.provider.session.accessToken!,
        modify_type: TagModifyType.Remove,
      });
      const [result] = response.path_result;
      ensureNucleusTagResults(result, values);
    } finally {
      if (service) {
        await service.transport.close();
      }
    }
  }
}
