import Icon from "./Icon";
import React from "react";
import styled from "styled-components";
import SettingsMenu from "./SettingsMenu";
import useContextMenu from "../hooks/useContextMenu";


const Settings = () => {
  const [menu, showMenu] = useContextMenu(SettingsMenu);
  return (
    <StyledSettings onClick={showMenu}>
      <SettingsIcon icon={"fa fa-bars"} />
      {menu}
    </StyledSettings>
  )
}

const StyledSettings = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
`;

const SettingsIcon = styled(Icon)`
  display: flex;
  align-items: center;
  font-size: 20pt;
  margin: 2px 5px 0 5px;
  cursor: pointer;
`;

export default Settings;