import { TaggingService } from "@omniverse/tagging";
import { TagModifyType } from "@omniverse/tagging/data";
import { TagCommands } from "../../Provider";
import { IEditTagCommandArguments } from "../../types/tags/EditTagCommand";
import { NucleusCommand } from "../index";
import { convertTagToNucleus, ensureNucleusTagResults } from "./index";

export default class NucleusEditTagCommand extends NucleusCommand<IEditTagCommandArguments> {
  name = TagCommands.Edit;

  public async allowed(): Promise<boolean> {
    return true;
  }

  public async execute({ tags, oldTag, newTag }: IEditTagCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Rename tag ${oldTag.fullName} to ${newTag.fullName} for ${tags.path}`);

    tags.edit(oldTag, newTag);

    const service = await this.provider.createServiceClient(TaggingService, "TAGS", {
      capabilities: { modify_tags: 1 },
    });
    try {
      const removedTags = [oldTag];
      const removedResponse = await service.modifyTags({
        paths: [tags.path],
        tags: removedTags.map(convertTagToNucleus),
        auth_token: this.provider.session.accessToken!,
        modify_type: TagModifyType.Remove,
      });
      const [removedResult] = removedResponse.path_result;
      ensureNucleusTagResults(removedResult, removedTags);

      const addedTags = [newTag];
      const addedResponse = await service.modifyTags({
        paths: [tags.path],
        tags: addedTags.map(convertTagToNucleus),
        auth_token: this.provider.session.accessToken!,
        modify_type: TagModifyType.Add,
      });
      const [addedResult] = addedResponse.path_result;
      ensureNucleusTagResults(addedResult, addedTags);
    } finally {
      await service.transport.close();
    }
  }
}
