import React from "react";
import styled from "styled-components";
import FormGroup from "./FormGroup";
import { Icons } from "./Icon";
import { StyledRenderer } from "./index";
import { DefaultTheme } from "./Theme";
import Toggleable, { Toggle, ToggleableProps } from "./Toggleable";

export type FormToggleableProps = ToggleableProps & {
  container?: StyledRenderer;
};

export const FormToggleable = ({
  container: Container = FormToggleableContainer,
  children,
  ...props
}: FormToggleableProps) => {
  return (
    <StyledFormToggleable labelComponent={FormToggleableLabel} toggle={FormToggle} {...props}>
      <Container>{children}</Container>
    </StyledFormToggleable>
  );
};

export const FormToggleableContainer = styled.div`
  margin-top: 8px;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.border};
`;
FormToggleableContainer.defaultProps = {
  theme: DefaultTheme,
};

const StyledFormToggleable = styled(Toggleable)`
  margin: 10px 0 20px 0;
  box-sizing: border-box;

  ${FormGroup} {
    margin: 10px 0;
  }
`;

export const FormToggleableLabel = styled.div`
  line-height: 15px;
  font-size: 10pt;
  color: ${({ theme }) => theme.colors.foreground};
  order: 2;
`;
FormToggleableLabel.defaultProps = {
  theme: DefaultTheme,
};

export const FormToggle = styled(Toggle)`
  flex: 0 0 10px;
  margin: 0 10px 0 0;
  color: ${({ theme }) => theme.colors.foreground};
  order: 1;
`;
FormToggle.defaultProps = {
  theme: DefaultTheme,
  openIcon: Icons.Right,
  closeIcon: Icons.Down,
};

export default FormToggleable;
