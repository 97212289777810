import { action, computed, makeObservable, observable } from "mobx";
import { FileWithPath } from "../util/Browser";
import { join, traverse } from "../util/Path";
import { Commands } from "./commands/Provider";
import { IUploadCommand } from "./commands/types/UploadCommand";
import Path from "./Path";
import Storage from "./Storage";
import TaskStatus from "./TaskStatus";

export enum PathUploadType {
  files,
  directory,
}

class PathUpload {
  public static readonly maxRetries = 3;

  public readonly storage: Storage;
  public readonly destination: Path;
  public readonly type: PathUploadType;
  public status: TaskStatus;
  public loaded: number;
  public total: number;
  public error: string;
  public files: FileWithPath[];
  public description?: string;

  public onAbort?: () => void;

  public constructor(
    destination: Path,
    storage: Storage,
    files: FileWithPath[],
    type: PathUploadType = PathUploadType.files,
    description?: string
  ) {
    this.destination = destination;
    this.type = type;
    this.status = TaskStatus.Idle;
    this.files = files;
    this.loaded = 0;
    this.total = this.files.reduce((total, file) => total + file.size, 0);
    this.error = "";
    this.storage = storage;
    this.description = description;

    makeObservable(this, {
      destination: observable,
      target: computed,
      status: observable,
      error: observable,
      loaded: observable,
      total: observable,
      start: action.bound,
      abort: action.bound,
      setLoaded: action.bound,
      setStatus: action.bound,
      setError: action.bound,
    });
  }

  public get target(): string {
    const prefix = `omniverse://${this.storage.publicName}`;

    if (this.type === PathUploadType.files) {
      if (this.files.length === 1) {
        const file = this.files[0];
        const filepath = file?.path ?? file?.name ?? "";
        return prefix + this.destination.path + filepath;
      }
      return prefix + this.destination.path;
    }

    if (this.type === PathUploadType.directory) {
      if (this.files.length > 1) {
        const file = this.files[0];
        const filepath = file?.path ?? file?.name ?? "";
        if (filepath) {
          const root = traverse(filepath, (path) => {
            if (path === "/") return;
            return path;
          });
          if (root) {
            return prefix + join(this.destination.path, root);
          }
        }
      }
    }

    return prefix + this.destination.path;
  }

  public async start(): Promise<void> {
    const command = this.storage.commands.get<IUploadCommand>(Commands.Upload);
    if (command) {
      await command.execute({ upload: this });
    } else {
      throw new Error(`${Commands.Upload} command is not supported.`);
    }
  }

  public abort() {
    if (this.onAbort) {
      this.onAbort();
    }
  }

  public setLoaded(loaded: number): void {
    this.loaded = loaded;
  }

  public setStatus(status: TaskStatus) {
    this.status = status;
  }

  public setError(error: string) {
    this.status = TaskStatus.Error;
    this.error = error;

    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
    }
  }
}

export default PathUpload;
