import { StatusType } from "@omniverse/api/data";
import { DeleteError } from "../../../util/PathErrors";
import { Commands } from "../Provider";
import { IDeleteCommandAllowedArguments, IDeleteCommandArguments } from "../types/DeleteCommand";
import { NucleusCommand } from "./index";
import { isDirectory } from "../../../util/Path";
import { listAssetsRecursively } from "./util";

export default class NucleusDeleteCommand extends NucleusCommand<
  IDeleteCommandArguments,
  IDeleteCommandAllowedArguments
> {
  public name = Commands.Delete;

  public async allowed({ path }: IDeleteCommandAllowedArguments): Promise<boolean> {
    return path.canWrite();
  }

  public async execute({ path }: IDeleteCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Delete ${path.path}`);

    const connection = await this.provider.getConnection();

    if (this.provider.supportsVersioning) {
      if (isDirectory(path.path)) {
        const files = await listAssetsRecursively(connection, path.path);
        await Promise.all(files.map(deleteCheckpoints));
      } else {
        await deleteCheckpoints(path.path);
      }

      async function deleteCheckpoints(path: string) {
        const checkpoints = await connection.getCheckpoints({ path: { path: path } });
        if (checkpoints.status !== StatusType.OK) {
          throw new Error(`Failed to download checkpoints from the server for ${path}:  ${checkpoints.status}.`);
        }

        const deleteResponse = await connection.delete2({
          paths_to_delete: checkpoints.checkpoints.map((checkpoint) => ({
            path: path,
            checkpoint: checkpoint.checkpoint_id,
          })),
        });

        if (deleteResponse.status !== StatusType.OK) {
          throw new Error(`Failed to delete checkpoints from the server for ${path}: ${deleteResponse.status}.`);
        }
      }
    }

    const results = await connection.delete({ uri: path.path });

    for await (const result of results) {
      if (result.status === StatusType.Done || result.status === StatusType.InvalidPath) {
        break;
      }
      if (result.status !== StatusType.OK) {
        throw new DeleteError(result);
      }
    }

    if (path.parent) {
      path.parent.remove(path.path);
    }
  }
}
