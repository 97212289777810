import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Popup, { PopupAnimationTime } from "./Popup";
import { DefaultTheme } from "./Theme";

export interface InfoPopupProps {
  className?: string;
  duration?: number;
  onClose?(): void;
}

const InfoPopup: React.FC<InfoPopupProps> = React.memo(({ className, duration, onClose, children, ...props }) => {
  const [visible, setVisible] = useState(true);
  const [paused, setPaused] = useState(false);

  const close = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const pause = useCallback(() => {
    setPaused(true);
  }, []);

  const resume = useCallback(() => {
    setPaused(false);
  }, []);

  useEffect(() => {
    if (!paused && duration) {
      const timeout = window.setTimeout(() => {
        setVisible(false);
      }, duration);
      return () => clearTimeout(timeout);
    }
  }, [paused, duration]);

  useEffect(() => {
    if (!visible) {
      const timeout = window.setTimeout(close, PopupAnimationTime);
      return () => clearTimeout(timeout);
    }
  }, [visible, close]);

  return (
    <StyledPopup {...props} className={className} visible={visible} onMouseEnter={pause} onMouseLeave={resume}>
      {children}
    </StyledPopup>
  );
});

const StyledPopup = styled(Popup)`
  box-shadow: ${({ theme }) => `0 0 10px -6px ${theme.colors.shadow}`};
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.foreground};
  max-width: 400px;
  font-size: 11pt;
`;
StyledPopup.defaultProps = {
  theme: DefaultTheme
};

export default InfoPopup;
