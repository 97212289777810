import styled from "styled-components";
import { DropStyle } from "../hooks/useDrop";
import {
  TableBodyComponentProps,
  TableCellComponentProps,
  TableComponentProps,
  TableHeadComponentProps,
  TableHeaderComponentProps,
  TableHeaderStyle,
  TableRowComponentProps,
} from "./Table";
import { DefaultTheme } from "./Theme";

/**
 * It's important to have a fixed width for div tables.
 * Otherwise, each row may have a different column width based on its content.
 */

export const TableDiv = styled.div.attrs({ role: "table" })<TableComponentProps>`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  width: fit-content;
  height: 100%;
`;

export const TableDivHead = styled.div.attrs({ role: "row" })<TableHeadComponentProps>`
  display: inline-flex;
  min-width: 100%;
  background: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;
TableDivHead.defaultProps = {
  theme: DefaultTheme,
};

export const TableDivHeader = styled.div.attrs({ role: "columnheader" })<TableHeaderComponentProps>`
  display: flex;
  align-items: center;

  height: 30px;
  padding: 10px;
  cursor: pointer;
  flex: 0 0 auto;
  box-sizing: border-box;

  ${TableHeaderStyle};
`;
TableDivHeader.defaultProps = {
  theme: DefaultTheme,
};

export const TableDivBody = styled.div<TableBodyComponentProps<any>>`
  display: inline-block;
  height: 100%;
  ${DropStyle};
`;
TableDivBody.defaultProps = {
  theme: DefaultTheme,
};

export const TableDivRow = styled.div.attrs(({ selected }: TableRowComponentProps<any>) => ({
  "aria-selected": selected,
  role: "row",
}))<TableRowComponentProps<any>>`
  width: 100%;
  display: flex;
  background: ${({ selected, highlighted, theme }) =>
    selected ? theme.colors.selectedItemBackground : highlighted ? theme.colors.hoverItemBackground : "inherit"};
  color: ${({ selected, theme }) => (selected ? theme.colors.selectedItemForeground : theme.colors.foreground)};

  &:hover {
    background: ${({ selected, theme }) =>
      selected ? theme.colors.selectedItemHover : theme.colors.hoverItemBackground};
  }

  svg {
    fill: ${({ selected, theme }) => (selected ? theme.colors.selectedIcon : theme.colors.icon)};
  }
`;
TableDivRow.defaultProps = {
  theme: DefaultTheme,
};

export const TableDivCell = styled.div.attrs({ role: "cell" })<TableCellComponentProps<any>>`
  font-size: 10pt;
  line-height: 15px;
  height: 35px;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 0 0 auto;
  position: relative;
`;
