import styled from "styled-components";
import ButtonGroup from "./ButtonGroup";
import Form from "./Form";

const DialogForm = styled(Form)`
  padding: 0 25px 1rem 25px;

  & > ${ButtonGroup} {
    margin-top: auto;
  }
`;

export default DialogForm;
