import { action, makeObservable, observable } from "mobx";

export type PreferenceType = string | boolean | number | null;

export class Preference<T extends PreferenceType = string> {
  public readonly name: string;
  public value: T;

  public constructor(name: string, value: T) {
    this.name = name;
    this.value = value;

    makeObservable(this, {
      value: observable,
      set: action.bound
    });
  }

  public static fromJSON(data: any): Preference {
    const name = data.name;
    const value = data.value;
    return new Preference(name, value);
  }

  public set(value: T): void {
    this.value = value;
  }

  public toJSON() {
    return {
      name: this.name,
      value: this.value,
    };
  }
}

class PreferenceStore {
  public map: Map<string, Preference<PreferenceType>>;

  public constructor() {
    this.map = new Map<string, Preference>();

    makeObservable(this, {
      map: observable,
      create: action.bound,
      delete: action.bound,
    });
  }

  public static fromJSON(data: any): PreferenceStore {
    const preferences = new PreferenceStore();
    for (const preferenceData of data.map) {
      const preference = Preference.fromJSON(preferenceData);
      preferences.map.set(preference.name, preference);
    }
    return preferences;
  }

  public has(name: string): boolean {
    return this.map.has(name);
  }

  public get<T extends PreferenceType>(name: string): Preference<T> | undefined {
    return this.map.get(name) as Preference<T>;
  }

  public create<T extends PreferenceType>(name: string, value: T): Preference<T> {
    const preference = new Preference<T>(name, value);
    this.map.set(preference.name, preference);
    return preference;
  }

  public delete(name: string): void {
    this.map.delete(name);
  }

  public toJSON() {
    return {
      map: Array.from(this.map.values()).map((preference) => preference.toJSON()),
    };
  }
}


export enum Preferences {
  ContentLayoutTab = "ContentLayoutTab",
  ContentView = "ContentView",
  LeftPanelWidth = "LeftPanelWidth",
  RightPanelOpened = "RightPanelOpened",
  RightPanelWidth = "RightPanelWidth",
  ShowCheckpoints = "ShowCheckpoints",
  ShowTags = "ShowTags",
  Theme = "Theme",
  UploadsVisible = "UploadsVisible",
  UserManagementHeight = "UserManagementHeight"
}

export default PreferenceStore;
