import { AuthStatus } from "@omniverse/auth/data";
import { OfflineModeError } from "../../../util/SessionErrors";
import { SetProfileError } from "../../../util/UserErrors";
import { Commands } from "../Provider";
import {
  ISetUserProfileCommandAllowedArguments,
  ISetUserProfileCommandArguments,
} from "../types/SetUserProfileCommand";
import { NucleusCommand } from "./index";
import { convertToNucleusProfile } from "./Nucleus";

export default class NucleusSetUserProfileCommand extends NucleusCommand<
  ISetUserProfileCommandArguments,
  ISetUserProfileCommandAllowedArguments
> {
  name = Commands.SetUserProfile;

  public async allowed({ user }: ISetUserProfileCommandAllowedArguments): Promise<boolean> {
    return Boolean(user.name === this.provider.session.username || this.provider.session.isSuperUser);
  }

  public async execute({ user, profile }: ISetUserProfileCommandArguments): Promise<void> {
    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }

    console.log(`[${this.provider.name}] Set profile for user "${user.name}"`);
    const profiles = await this.provider.createProfilesClient();
    try {
      const nucleusProfile = convertToNucleusProfile(profile);
      const result = await profiles.setInfo({
        username: user.name,
        token: this.provider.session.accessToken!,
        ...nucleusProfile,
      });

      if (result.status === AuthStatus.Expired) {
        await this.provider.session.refresh();
        return this.execute({ user, profile });
      }

      if (result.status !== AuthStatus.OK) {
        throw new SetProfileError(result);
      }

      user.setProfile(profile);
    } finally {
      await profiles.transport.close();
    }
  }
}
