import Form from "@omniverse/auth/react/Form";
import NvidiaLogo from "@omniverse/auth/react/NvidiaLogo";
import OmniverseLogo from "@omniverse/auth/react/OmniverseLogo";
import ServerForm, { ServerFormFields } from "@omniverse/auth/react/ServerForm";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useAppNavigate from "../hooks/useAppNavigate";
import useStorageList from "../hooks/useStorageList";
import Nucleus from "../state/commands/nucleus/Nucleus";
import NucleusSession, { NucleusExternalAuthCancelled } from "../state/commands/nucleus/NucleusSession";
import { IExternalAuth, parsePublicName } from "../state/commands/Provider";
import Storage from "../state/Storage";
import { joinURL } from "../util/Path";
import Loader from "./Loader";

export interface NucleusExternalAuthFormProps {
  className?: string;
  path?: string;
  serverName?: string;
  onClose(): void;
}

const NucleusExternalAuthForm: React.FC<NucleusExternalAuthFormProps> = ({ className, path, serverName, onClose }) => {
  const storages = useStorageList();
  const navigate = useAppNavigate();

  const [status, setStatus] = useState<"idle" | "loading" | "success">("idle");
  const [errors, setErrors] = useState<string[]>([]);

  const authentication = useRef<IExternalAuth>();

  const startAuthentication = useCallback(
    async ({ server }: ServerFormFields) => {
      server = parsePublicName(server!);

      setStatus("loading");
      setErrors([]);
      try {
        const session = new NucleusSession(server);
        const auth = (authentication.current = session.openExternal());
        await auth.run();

        const oldStorage = storages.find(server!);
        if (oldStorage) {
          storages.remove(oldStorage);
        }

        const provider = new Nucleus(server, session);
        const storage = new Storage(provider);
        await storage.init();
        storages.add(storage);

        setStatus("success");
        navigate(joinURL(storage.root.link, path ?? "/"));
      } catch (error) {
        if (error instanceof NucleusExternalAuthCancelled) {
          return;
        }

        console.error(error);
        setStatus("idle");
        setErrors([error.toString()]);
      }
    },
    [storages, navigate, path]
  );

  useEffect(() => {
    if (serverName) {
      startAuthentication({ server: serverName }).catch(console.error);
    }
  }, [startAuthentication, serverName]);

  useEffect(() => {
    if (status === "success") {
      onClose();
    }
  }, [status, onClose]);

  useEffect(() => {
    return () => {
      authentication.current?.cancel();
    };
  }, []);

  if (status === "loading") {
    return (
      <Form className={className}>
        <NvidiaLogo />
        <OmniverseLogo />
        <StyledLoader>Please finish your authentication in a web browser...</StyledLoader>
      </Form>
    );
  } else if (status === "idle") {
    return <ServerForm className={className} errors={errors} onSuccess={startAuthentication} />;
  } else {
    return null;
  }
};

const StyledLoader = styled(Loader)`
  white-space: pre-wrap;
  text-align: center;
`;

export default NucleusExternalAuthForm;
