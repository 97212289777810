import Icon from "./Icon";
import styled from "styled-components";


const TreeIcon = styled(Icon)`
  width: 25px;
  font-size: 14pt;
  margin-right: 5px;
  text-align: center;
  
  &:last-child {
    margin-right: 10px;
  }
`;

export default TreeIcon;