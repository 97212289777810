import React, { useCallback, useContext, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { DefaultTheme } from "./Theme";

interface MenuContextData {
  menu?: React.RefObject<HTMLDivElement>;
  index?: number;
  setIndex(value: React.SetStateAction<number>): void;
  registerItem(parent?: MenuItemEntry): MenuItemEntry;
  unregisterItem(entry: MenuItemEntry): void;
}

export const MenuContext = React.createContext<MenuContextData>({
  registerItem: noop as any,
  unregisterItem: noop as any,
  setIndex: noop,
});

export interface MenuItemEntry {
  index: number;
  onUpdate?(): void;
  onSelect?(): void;
  onExpand?(): void;
  onCollapse?(): void;
}

export interface MenuProps extends React.ComponentPropsWithoutRef<"div"> {
  onHide?(): void;
  onItemAdded?(item: MenuItemEntry): void;
  onItemRemoved?(item: MenuItemEntry): void;
}

function Menu({ onItemAdded, onItemRemoved, onHide, ...props }: MenuProps, ref: React.Ref<HTMLDivElement>) {
  const innerRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => innerRef.current!);

  const { menu: parent } = useContext(MenuContext);
  const [index, setIndex] = useState(-1);
  const itemRegistry = useRef<MenuItemEntry[]>([]);

  const goToNextItem = useCallback(() => {
    setIndex((index) => (index + 1 <= itemRegistry.current.length - 1 ? index + 1 : itemRegistry.current.length - 1));
  }, [setIndex]);

  const goToPreviousItem = useCallback(() => {
    setIndex((index) => (index - 1 >= 0 ? index - 1 : 0));
  }, [setIndex]);

  const selectCurrentItem = useCallback(() => {
    const entry = itemRegistry.current[index];
    if (entry) {
      entry.onSelect?.();
    }
  }, [index]);

  const expandCurrentItem = useCallback(() => {
    const entry = itemRegistry.current[index];
    if (entry) {
      entry.onExpand?.();
    }
  }, [index]);

  const collapseCurrentItem = useCallback(() => {
    const entry = itemRegistry.current[index];
    if (entry) {
      entry.onCollapse?.();
    } else {
      onHide?.();
    }
  }, [index, onHide]);

  const registerItem = useCallback(
    (parent?: MenuItemEntry) => {
      let entry: MenuItemEntry;
      if (parent) {
        const index = itemRegistry.current.indexOf(parent);
        entry = { index };
        itemRegistry.current.splice(index, 0, entry);
      } else {
        const index = itemRegistry.current.length;
        entry = { index };
        itemRegistry.current.push(entry);
      }

      onItemAdded?.(entry);
      return entry;
    },
    [onItemAdded]
  );

  const unregisterItem = useCallback(
    (entry: MenuItemEntry) => {
      const index = itemRegistry.current.indexOf(entry);
      if (index !== -1) {
        itemRegistry.current.splice(index, 1);
      }

      itemRegistry.current.slice(index).forEach((entry, index) => {
        entry.index = index;
        entry.onUpdate?.();
      });

      onItemRemoved?.(entry);
    },
    [onItemRemoved]
  );

  const context = useMemo(() => {
    return {
      menu: innerRef,
      index,
      setIndex,
      registerItem,
      unregisterItem,
    };
  }, [index, setIndex, registerItem, unregisterItem]);

  const onKeyDown = props.onKeyDown;
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      onKeyDown?.(e);

      if (e.isPropagationStopped()) {
        return;
      }

      if (e.key === "Enter" || e.key === " ") {
        e.stopPropagation();
        e.preventDefault();

        selectCurrentItem();
      } else if (e.key === "ArrowDown") {
        e.stopPropagation();
        e.preventDefault();

        goToNextItem();
      } else if (e.key === "ArrowUp") {
        e.stopPropagation();
        e.preventDefault();

        goToPreviousItem();
      } else if (e.key === "ArrowRight") {
        e.stopPropagation();
        e.preventDefault();

        expandCurrentItem();
      } else if (e.key === "ArrowLeft") {
        e.stopPropagation();
        e.preventDefault();

        collapseCurrentItem();
      }
    },
    [selectCurrentItem, goToPreviousItem, goToNextItem, expandCurrentItem, collapseCurrentItem, onKeyDown]
  );

  useLayoutEffect(() => {
    const parentMenu = parent?.current;

    innerRef.current?.focus();
    return () => {
      if (parentMenu) {
        parentMenu.focus();
      }
    };
  }, [parent]);

  return (
    <MenuContext.Provider value={context}>
      <StyledMenu {...props} role={"menu"} ref={innerRef as any} tabIndex={0} onKeyDown={handleKeyDown} />
    </MenuContext.Provider>
  );
}

export const StyledMenu = styled.menu`
  background: ${({ theme }) => theme.colors.menuBackground};
  color: ${({ theme }) => theme.colors.menuText};
  border-radius: 5px;
  z-index: 5000;
  outline: none;
  overflow: visible;
  min-width: 175px;
  box-shadow: 0 2px 7px -1px ${({ theme }) => theme.colors.shadow};
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 0;
  margin: 0;
  display: block;

  &:focus {
    outline: none;
  }
`;
StyledMenu.defaultProps = {
  theme: DefaultTheme,
};

function noop() {}

export default React.forwardRef(Menu);
