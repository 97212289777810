import React from "react";
import styled from "styled-components";
import Icon, { Icons } from "./Icon";
import { DefaultTheme, ThemedComponentProps } from "./Theme";

interface RightPanelToggleProps extends ThemedComponentProps {
  className?: string;
  active: boolean;
  onToggle(active: boolean): void;
}

const RightPanelToggle: React.FC<RightPanelToggleProps> = ({ className, active, onToggle }) => {
  function toggle() {
    onToggle(!active);
  }

  return (
    <StyledRightPanelToggle className={className}>
      <StyledRightPanelToggleIcon icon={`${active ? Icons.ToggleOn : Icons.ToggleOff} fa-fw`} onClick={toggle} />
    </StyledRightPanelToggle>
  );
};

const StyledRightPanelToggle = styled.div`
  height: 100%;
  display: flex;
  margin-left: 0.5rem;
`;

const StyledRightPanelToggleIcon = styled(Icon).attrs({ clickable: true })<{ active?: boolean }>`
  color: ${({ theme, active }) => (active ? theme.colors.toolbarSelectedForeground : theme.colors.toolbarForeground)};
  font-size: 18pt;
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  margin: 1px 5px 0 5px;
`;
StyledRightPanelToggleIcon.defaultProps = {
  theme: DefaultTheme,
};

export default RightPanelToggle;
