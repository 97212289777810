import { observer } from "mobx-react";
import React from "react";
import useDialogForm from "../hooks/useDialogForm";
import { Commands } from "../state/commands/Provider";
import { IRemoveUserFromGroupCommand } from "../state/commands/types/RemoveUserFromGroupCommand";
import Group from "../state/Group";
import User from "../state/User";
import { ReactComponent as UserSVG } from "../static/user.svg";
import ConfirmationDialog from "./ConfirmationDialog";
import ImportantText from "./ImportantText";

export interface GroupUserDeleteConfirmationProps {
  group: Group;
  user: User;
}

const GroupUserDeleteConfirmation: React.FC<GroupUserDeleteConfirmationProps> = ({ group, user }) => {
  const form = useDialogForm({
    fields: {},
    onSubmit: removeUserFromGroup,
  });

  async function removeUserFromGroup() {
    const command = group.storage.commands.get<IRemoveUserFromGroupCommand>(Commands.RemoveUserFromGroup);
    if (await command?.allowed()) {
      await command!.execute({ group, user });
    }
  }

  return (
    <ConfirmationDialog title={"Remove User from Group"} image={UserSVG} onConfirm={form.submit} onCancel={form.cancel}>
      Do you really want to remove <ImportantText>{user.name}</ImportantText> user from{" "}
      <ImportantText>{group.name}</ImportantText> group?
    </ConfirmationDialog>
  );
};

export default observer(GroupUserDeleteConfirmation);
