import { action, computed, makeObservable, observable } from "mobx";
import { daysInWeek, getDaysInMonth } from "../util/DateTime";
import Storage from "./Storage";

export interface Checkpoint {
  id: number;
  message?: string;
  date: Date;
  size?: number;
  author?: string;
}

export interface CheckpointsByDates {
  today: Checkpoint[];
  thisWeek: { [dayOfTheWeek: number]: Checkpoint[] };
  thisMonth: Checkpoint[];
  thisYear: { [month: number]: Checkpoint[] };
  others: { [year: number]: { [month: number]: Checkpoint[] } };
}

class Checkpoints {
  public map: Map<number, Checkpoint>;
  public loading: boolean;

  constructor(public readonly path: string, public readonly storage: Storage) {
    this.path = path;
    this.map = new Map();
    this.loading = false;

    makeObservable(this, {
      loading: observable,
      map: observable,
      byDates: computed,
      described: computed,
      setLoading: action.bound,
      setCheckpoints: action.bound,
    });
  }

  public get described(): Checkpoint[] {
    return Array.from(this.map.values())
      .filter((checkpoint) => checkpoint.message)
      .sort(byID);
  }

  public get byDates(): CheckpointsByDates {
    const dates: CheckpointsByDates = {
      today: [],
      thisWeek: {},
      thisMonth: [],
      thisYear: {},
      others: {},
    };

    const now = new Date(Date.now());
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const currentDay = now.getDate();
    const currentDayOfTheWeek = now.getDay();
    const currentWeekStart = Math.max(currentDay - currentDayOfTheWeek, 1);
    const currentWeekEnd = Math.min(
      currentDay + (daysInWeek - currentDayOfTheWeek - 1),
      getDaysInMonth(currentMonth, currentYear)
    );

    for (const checkpoint of this.map.values()) {
      if (!checkpoint.date) {
        continue;
      }

      const year = checkpoint.date.getFullYear();
      const month = checkpoint.date.getMonth();
      const day = checkpoint.date.getDate();

      if (year === currentYear) {
        if (month === currentMonth) {
          if (day === currentDay) {
            dates.today.push(checkpoint);
          } else {
            if (day >= currentWeekStart && day < currentWeekEnd) {
              const dayOfTheWeek = checkpoint.date.getDay();
              if (!(dayOfTheWeek in dates.thisWeek)) {
                dates.thisWeek[dayOfTheWeek] = [];
              }
              dates.thisWeek[dayOfTheWeek].push(checkpoint);
            } else {
              dates.thisMonth.push(checkpoint);
            }
          }
        } else {
          if (!(month in dates.thisYear)) {
            dates.thisYear[month] = [];
          }
          dates.thisYear[month].push(checkpoint);
        }
      } else {
        if (!(year in dates.others)) {
          dates.others[year] = {};
        }
        if (!(month in dates.others[year])) {
          dates.others[year][month] = [];
        }

        dates.others[year][month].push(checkpoint);
      }
    }

    dates.today = dates.today.sort(byDate);
    dates.thisMonth = dates.thisMonth.sort(byDate);

    for (const day in dates.thisWeek) {
      dates.thisWeek[day] = dates.thisWeek[day].sort(byDate);
    }

    for (const month in dates.thisYear) {
      dates.thisYear[month] = dates.thisYear[month].sort(byDate);
    }

    for (const year in dates.others) {
      for (const month in dates.others[year]) {
        dates.others[year][month] = dates.others[year][month].sort(byDate);
      }
    }

    return dates;
  }

  public setLoading(loading: boolean): void {
    this.loading = loading;
  }

  public setCheckpoints(checkpoints: Checkpoint[]): void {
    for (const checkpoint of checkpoints) {
      this.map.set(checkpoint.id, checkpoint);
    }
  }
}

const byID = (a: Checkpoint, b: Checkpoint) => b.id - a.id;
const byDate = (a: Checkpoint, b: Checkpoint) => b.date.getTime() - a.date.getTime();

export default Checkpoints;
