import { observer } from "mobx-react";
import React, { useContext } from "react";
import useDialogForm from "../hooks/useDialogForm";
import useInput from "../hooks/useInput";
import { Commands } from "../state/commands/Provider";
import { ICreateFolderCommand } from "../state/commands/types/CreateFolderCommand";
import Path from "../state/Path";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import Dialog from "./Dialog";
import DialogForm from "./DialogForm";
import { DialogContext } from "./Dialogs";
import DialogSpinner from "./DialogSpinner";
import DialogTitle from "./DialogTitle";
import FormErrorList from "./FormErrorList";
import FormGroup from "./FormGroup";
import Input from "./Input";
import Label from "./Label";

export interface NewFolderDialogProps {
  path: Path;
}

interface NewFolderDialogFields {
  name: string;
}

const NewFolderDialog: React.FC<NewFolderDialogProps> = ({ path }) => {
  const [name, setName] = useInput("");

  async function submit({ name }: NewFolderDialogFields): Promise<Path> {
    const command = path.storage.commands.get<ICreateFolderCommand>(Commands.CreateFolder);
    if (command) {
      return await command.execute({ path, name });
    } else {
      throw new Error(`${Commands.CreateFolder} command is not supported.`);
    }
  }

  const form = useDialogForm({
    fields: {
      name,
    },
    onSubmit: submit,
  });

  const { hide } = useContext(DialogContext);
  return (
    <Dialog aria-label={"New Folder"} onClose={hide}>
      <DialogTitle title={"New Folder"} onClose={hide} />
      <DialogSpinner loading={form.loading} />
      <DialogForm>
        <FormErrorList errors={form.errors} />
        <FormGroup>
          <Label>
            Folder Name
            <Input autoFocus required name={"name"} disabled={form.loading} value={name} onChange={setName} />
          </Label>
        </FormGroup>

        <ButtonGroup>
          <Button kind={"attention"} disabled={form.loading} onClick={form.submit}>
            Create
          </Button>
          <Button disabled={form.loading} onClick={form.cancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </DialogForm>
    </Dialog>
  );
};

export default observer(NewFolderDialog);
