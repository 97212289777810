import { observer } from "mobx-react";
import React from "react";
import useDialogForm from "../hooks/useDialogForm";
import useInput from "../hooks/useInput";
import { Commands } from "../state/commands/Provider";
import { ICreateUserCommand } from "../state/commands/types/CreateUserCommand";
import User from "../state/User";
import Users from "../state/Users";
import { ReactComponent as UserSVG } from "../static/user.svg";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import Dialog from "./Dialog";
import DialogForm from "./DialogForm";
import DialogTitle from "./DialogTitle";
import FormErrorList from "./FormErrorList";
import FormGroup from "./FormGroup";
import Input from "./Input";
import Label from "./Label";

interface UserFormDialogFields {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface UserFormDialogProps {
  users: Users;
}

const UserFormDialog: React.FC<UserFormDialogProps> = ({ users }) => {
  const [username, setUsername] = useInput("");
  const [firstName, setFirstName] = useInput("");
  const [lastName, setLastName] = useInput("");
  const [email, setEmail] = useInput("");

  const form = useDialogForm({
    fields: {
      username,
      firstName,
      lastName,
      email,
    },
    onSubmit: submit,
  });

  async function submit({ username, firstName, lastName, email }: UserFormDialogFields) {
    const command = users.storage.commands.get<ICreateUserCommand>(Commands.CreateUser);
    if (await command?.allowed()) {
      await command!.execute({ users, user: new User(username, users.storage, { firstName, lastName, email }) });
    }
  }

  return (
    <Dialog onClose={form.cancel}>
      <DialogTitle image={UserSVG} title={"Create New User"} onClose={form.cancel} />
      <DialogForm>
        {form.errors && <FormErrorList errors={form.errors} />}

        <FormGroup>
          <Label>
            Username:
            <Input name={"username"} disabled={form.loading} value={username} onChange={setUsername} />
          </Label>
        </FormGroup>

        <FormGroup>
          <Label>
            First name:
            <Input name={"firstName"} disabled={form.loading} value={firstName} onChange={setFirstName} />
          </Label>
        </FormGroup>

        <FormGroup>
          <Label>
            Last name:
            <Input name={"lastName"} disabled={form.loading} value={lastName} onChange={setLastName} />
          </Label>
        </FormGroup>

        <FormGroup>
          <Label>
            Email:
            <Input name={"email"} disabled={form.loading} value={email} onChange={setEmail} />
          </Label>
        </FormGroup>

        <ButtonGroup>
          <Button kind={"attention"} disabled={form.loading} onClick={form.submit}>
            Submit
          </Button>

          <Button disabled={form.loading} onClick={form.cancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </DialogForm>
    </Dialog>
  );
};

export default observer(UserFormDialog);
