import { Resizable, NumberSize, ResizeDirection, ResizableProps } from "re-resizable";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { DefaultTheme, Theme } from "./Theme";

export interface PanelProps {
  directions?: {
    top?: boolean;
    right?: boolean;
    bottom?: boolean;
    left?: boolean;
    topRight?: boolean;
    bottomRight?: boolean;
    bottomLeft?: boolean;
    topLeft?: boolean;
  };
  resizable?: boolean;
  visible?: boolean;
  className?: string;
  style?: React.CSSProperties;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  defaultWidth?: "auto" | number;
  defaultHeight?: "auto" | number;
  children?: React.ReactNode;

  /**
   * Fired on resize tick. Use with caution, you will probably need to throttle this callback.
   */
  onResize?: (
    event: MouseEvent | TouchEvent,
    direction: ResizeDirection,
    elementRef: HTMLElement,
    delta: NumberSize
  ) => void;

  /**
   * Fired when resize stopped.
   */
  onResizeStop?: ({ width, height }: { width: number; height: number }) => void;
}

const Panel = ({
  resizable,
  directions,
  visible = true,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  defaultWidth,
  defaultHeight,
  onResizeStop,
  ...props
}: PanelProps) => {
  maxWidth = visible ? maxWidth : 0;
  minWidth = visible ? minWidth : 0;
  maxHeight = visible ? maxHeight : 0;
  minHeight = visible ? minHeight : 0;

  const handleResizeStop = useCallback(
    (event: MouseEvent | TouchEvent, direction: ResizeDirection, elementRef: HTMLElement) => {
      if (onResizeStop) {
        onResizeStop({
          width: elementRef.clientWidth,
          height: elementRef.clientHeight,
        });
      }
    },
    [onResizeStop]
  );

  const defaultSize = useMemo(() => ({
    width: defaultWidth ?? 'auto',
    height: defaultHeight ?? 'auto',
  }), [defaultWidth, defaultHeight]);

  return (
    <StyledPanel
      enable={resizable ? directions : noDirections}
      visible={visible}
      maxWidth={maxWidth}
      minWidth={minWidth}
      maxHeight={maxHeight}
      minHeight={minHeight}
      defaultSize={defaultSize}
      onResizeStop={handleResizeStop}
      handleWrapperClass={"panel-handle"}
      {...props}
    />
  );
};

const noDirections = {};

interface StyledPanelProps extends ResizableProps {
  visible: boolean;
  theme?: Theme;
}

const StyledPanel = styled(({ visible, theme, ...props }: StyledPanelProps) => <Resizable {...props} />)`
  display: flex;
  transition: ${({ visible }) =>
    visible
      ? "max-width 0.4s, min-width 0.4s, max-height 0.4s, min-height 0.4s, opacity 0.2s ease-out"
      : "max-width 0.4s, min-width 0.4s, max-height 0.4s, min-height 0.4s"};
  transition-delay: 0s, 0s, 0s, 0s, 0.2s;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  will-change: transform;
  
  & > .panel-handle > div:hover {
    background-color: ${({ theme }) => theme.colors.interaction};
  }
`;
StyledPanel.defaultProps = {
  theme: DefaultTheme,
};

export default Panel;
