import { decodeResetPasswordPayload } from "@omniverse/auth/react/hooks/ResetPassword";
import ResetPasswordForm from "@omniverse/auth/react/ResetPasswordForm";
import { observer } from "mobx-react";
import React from "react";
import FormPageLayout from "../components/FormPageLayout";
import useAppLocation from "../hooks/useAppLocation";
import useAppNavigate from "../hooks/useAppNavigate";
import useStorageList from "../hooks/useStorageList";
import Nucleus from "../state/commands/nucleus/Nucleus";
import NucleusSession from "../state/commands/nucleus/NucleusSession";
import { Commands, parsePublicName } from "../state/commands/Provider";
import { IResetPasswordCommand } from "../state/commands/types/ResetPasswordCommand";
import Storage from "../state/Storage";
import User from "../state/User";
import AppNavigate from "../components/AppNavigate";

const ResetPasswordPage: React.FC = () => {
  const storageList = useStorageList();
  const navigate = useAppNavigate();
  const location = useAppLocation();
  const search = new URLSearchParams(location.search);

  async function resetPassword(newPassword: string, payloadString: string) {
    const payload = decodeResetPasswordPayload(payloadString);
    if (!payload) {
      throw new Error("Invalid reset password link.");
    }

    payload.server = parsePublicName(payload.server);

    let storage = storageList.find(payload.server);
    if (storage) {
      storageList.remove(storage);
    }

    const provider = new Nucleus(payload.server, new NucleusSession(payload.server));
    storage = new Storage(provider);
    await storage.init();

    const user = new User(payload.username, storage);
    const command = storage.commands.get<IResetPasswordCommand>(Commands.ResetPassword);
    if (command) {
      await command.execute({ user, newPassword, payload });
    } else {
      throw new Error(`Cannot reset password -- ${Commands.ResetPassword} command is not supported.`);
    }

    storageList.add(storage);
    navigate("/");
  }

  if (!search.has("payload")) {
    return <AppNavigate to={"/"} />;
  }

  return (
    <FormPageLayout>
      <ResetPasswordForm payload={search.get("payload")!} onSubmit={resetPassword} />
    </FormPageLayout>
  );
};

export default observer(ResetPasswordPage);
