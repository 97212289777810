import { StatusType } from "@omniverse/api/data";
import { MountError } from "../../../util/PathErrors";
import { Commands } from "../Provider";
import { IUnmountCommandAllowedArguments, IUnmountCommandArguments } from "../types/UnmountCommand";
import { NucleusCommand } from "./index";

export default class NucleusUnmountCommand extends NucleusCommand<
  IUnmountCommandArguments,
  IUnmountCommandAllowedArguments
> {
  public name = Commands.Unmount;

  public async allowed(): Promise<boolean> {
    return Boolean(this.provider.session.isSuperUser);
  }

  public async execute({ path }: IUnmountCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Unmount ${path.path}`);

    const connection = await this.provider.getConnection();
    const response = await connection.unmount({ uri: path.path });
    if (response.status !== StatusType.OK) {
      throw new MountError(response);
    }

    if (path.parent) {
      path.parent.remove(path.path);
    }
  }
}
