import { action, makeObservable, observable } from "mobx";
import { Commands } from "./commands/Provider";
import { ICopyCommand } from "./commands/types/CopyCommand";
import Path from "./Path";
import Storage from "./Storage";

class PathClipboard {
  public items: Path[];

  public constructor(public readonly storage: Storage) {
    this.items = [];
    makeObservable(this, {
      items: observable,
      copy: action.bound,
      paste: action.bound,
    });
  }

  public copy(items: Path[]) {
    this.items = items;
  }

  public async canPaste(destination: Path): Promise<boolean> {
    if (!this.hasItems()) {
      return false;
    }
    return this.storage.commands.allowed(Commands.Copy, { source: this.items, destination });
  }

  public async paste(destination: Path): Promise<void> {
    const command = this.storage.commands.get<ICopyCommand>(Commands.Copy);
    await command?.execute({ source: this.items, destination });
  }

  public hasItems(): boolean {
    return Boolean(this.items.length);
  }
}

export default PathClipboard;
