import styled from "styled-components";

const Link = styled.div`
  font-size: 9pt;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 1rem;
`;

export default Link;