import { SetStateAction, useCallback, useEffect, useRef, useState } from "react";

/**
 * Allows to control component state with prop and setter or use it uncontrolled if
 * prop wasn't specified.
 *
 * Returns three values -- current state, state update function and flag that shows whether this component is controlled.
 */
export function useStateOrProp<T, Setter extends ((value: SetStateAction<T>) => void) | ((value: T) => void)>(
  prop: T | undefined,
  setProp?: Setter,
  initial?: T
): [T, Setter, boolean] {
  const [state, setState] = useState(initial);
  const wasControlled = useRef(typeof prop !== "undefined");

  useEffect(() => {
    const isControlled = typeof prop !== "undefined";
    if ((wasControlled.current && !isControlled) || (!wasControlled.current && isControlled)) {
      console.warn(
        "The component should not switch from uncontrolled to controlled (or vice versa). " +
          "Decide between using a controlled or uncontrolled element for the lifetime of the component."
      );
    }
  }, [prop]);

  if (wasControlled.current) {
    return [prop!, setProp!, wasControlled.current];
  } else {
    return [state!, setState as Setter, wasControlled.current];
  }
}

/**
 * Hook that tracks the value changes between re-renders.
 * Allows to understand that some prop has been changed externally and show notifications to users for forms that
 * have this prop as an initial value.
 * @param value
 */
export function useChangeListener<T>(value: T) {
  const cached = useRef(value);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (value !== cached.current) {
      setChanged(true);
    }
    cached.current = value;
  }, [value]);

  const reset = useCallback(() => {
    setChanged(false);
  }, []);

  return {
    value,
    changed,
    reset,
  };
}
