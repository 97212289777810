import React from "react";
import styled from "styled-components";
import { StyledRenderer } from "./index";
import { DefaultTheme, ThemedComponentProps } from "./Theme";

type Percent = number;

export interface ProgressBarProps {
  className?: string;
  progress: Percent;
  valueComponent?: StyledRenderer<ProgressBarValueProps>;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  className,
  valueComponent: Value = ProgressBarValue,
  children,
  ...props
}) => (
  <StyledProgressBar {...props} className={className}>
    <Value progress={progress} />
    {children && <Children>{children}</Children>}
  </StyledProgressBar>
);

const StyledProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-sizing: border-box;
`;
StyledProgressBar.defaultProps = {
  theme: DefaultTheme,
};

export interface ProgressBarValueProps extends ThemedComponentProps {
  progress: Percent;
}

export const ProgressBarValue = styled(({ progress, theme, ...props }: ProgressBarValueProps) => (
  <div style={{ width: `${progress}%` }} {...props} />
))`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: ${({ theme }: ProgressBarValueProps) => theme!.colors.toolbarBackground};
  border-radius: 5px;
  max-width: 100%;
  z-index: 0;
`;
ProgressBarValue.defaultProps = {
  theme: DefaultTheme,
};

const Children = styled.div`
  position: relative;
`;

export default ProgressBar;
