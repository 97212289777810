import { ClientFactory } from "@omniverse/idl/connection/transport";
import WebSocketClient from "@omniverse/idl/connection/transport/websocket";
import { DiscoverySearch as DiscoverySearchClient } from "@omniverse/discovery/client";
import {
  InterfaceCapabilities,
  InterfaceName,
  InterfaceOrigin,
} from "@omniverse/idl/schema";

// We will allow insecure contexts in a few cases cases:
// 1) If we are on an HTTPS site, we cannot mix content with http:/ws: so don't even try.
// 2) We are on localhost which is special cased to allow mixed content
// 3) We are in nodejs context
const SUPPORTS_INSECURE_CONTEXT =
  window?.location?.protocol !== "https:" ||
  window?.location?.hostname === "localhost" ||
  window?.location?.hostname === "127.0.0.1";

export default class DiscoverySearch {
  constructor(uri, { timeout } = {}) {
    this.uri = uri;

    if (typeof timeout === "undefined") {
      // OM-42105 - Firefox does not support parallel WebSocket connections to the same host,
      // therefore we have to drastically increase the default timeout
      // to let the library sequentially check which deployment is currently available.
      // https://nvidia-omniverse.atlassian.net/browse/OM-42105
      timeout = navigator?.userAgent?.match(/Firefox\//i) ? 30000 : 5000;
    }
    this.timeout = timeout;
    this._ws = null;
  }

  find = async (clientType, meta, supportedTransport, capabilities) => {
    if (!supportedTransport) {
      supportedTransport = ClientFactory.getSupported();
    }

    const discovery = await this._connect();
    const origin = clientType[InterfaceOrigin];
    const interfaceName = clientType[InterfaceName];

    if (!capabilities) {
      capabilities = clientType[InterfaceCapabilities];
    }

    let response;
    try {
      response = await discovery.find({
        query: {
          service_interface: {
            origin,
            name: interfaceName,
            capabilities,
          },
          supported_transport: supportedTransport,
          meta,
        },
      });
    } catch (error) {
      console.error(error);
      throw new DiscoveryError(
        `Failed to communicate with the discovery service ${this.uri}.`
      );
    }

    if (!response.found) {
      throw new DiscoveryError(
        `Interface "${interfaceName}" from "${origin}" has not been found.`
      );
    }

    const clientTransport = ClientFactory.create(response.transport);
    clientTransport.once("error", () => clientTransport.close());

    await clientTransport.prepare();
    const instance = new clientType(clientTransport);
    if (response.service_interface?.capabilities) {
      instance[InterfaceCapabilities] = response.service_interface.capabilities;
    }

    if (response.meta) {
      instance[ServiceMeta] = response.meta;
    }
    return instance;
  };

  close = () => {
    if (this._ws) {
      this._ws.close();
      this._ws = null;
    }
  };

  _connect = async () => {
    if (!this._ws) {
      const ws = await connect(this.uri, { timeout: this.timeout });
      ws.once("close", () => {
        if (this._ws === ws) {
          this._ws = null;
        }
      });

      this._ws = ws;
    }
    return new DiscoverySearchClient(this._ws);
  };
}

function ensureWebsocketCloses(wsPromise) {
  return wsPromise.then((ws) => {
    // The createXBasedClient will return undefined if there is some error establishing the websocket
    if (ws && ws.close) {
      ws.close();
    }
  });
}

export async function connect(uri, { timeout = 5000 } = {}) {
  const url = createURL(uri);
  const tasks = [];

  // The priority order of websockets we wish to use is `wss`, `ws`, `port` so we append
  // them to our task array in priority order as appropriate for the environment.

  // Path-based routing is not used on Workstation setup:
  // https://nvidia-omniverse.atlassian.net/browse/OM-32965
  if (!["localhost", "127.0.0.1", "::1"].includes(url.hostname)) {
    tasks.push(testPathBasedClient(uri, "wss:"));

    if (SUPPORTS_INSECURE_CONTEXT) {
      tasks.push(testPathBasedClient(uri, "ws:"));
    }
  }

  // This is the only approach used in workstation mode, but it is the lowest priority approach.
  if (SUPPORTS_INSECURE_CONTEXT) {
    tasks.push(testPortBasedClient(uri));
  }

  // Since all of the websocket connection promises are run in parallel we can share
  // one global timeout to use in our `Promise.race` checks to find the first websocket
  // to resolve within the timeout window.
  const timeoutTask = wait(timeout);
  let ws;

  // We want to use the websockets in priority order, so find the
  // first one that works and use that.
  for (const task of tasks) {
    // We want to use the websockets in priority order, so if a previous
    // higher priority websocket resolved, then we want to close (but not await)
    // our connection if/when it does resolve.
    if (ws) {
      ensureWebsocketCloses(task);
    } else {
      ws = await Promise.race([task, timeoutTask]);

      // If this task was not resolved before the timeout ensure we clean
      // it up as well if it ever resolves.
      if (!ws) {
        ensureWebsocketCloses(task);
      }
    }
  }

  if (!ws) {
    throw new DiscoveryError(
      `Failed to connect to the discovery service: ${uri}`
    );
  }

  return ws;
}

export async function testPathBasedClient(uri, protocol = "wss:") {
  const test = createPathBasedURL(
    uri,
    protocol === "wss:" ? "https:" : "http:"
  );
  try {
    const response = await fetch(test + healthcheckEndpoint);
    // HTTP426 - Update Required, means that the server requires a WebSocket connection
    if (response.status === 426 || response.ok) {
      console.info(`Found the (${protocol}) path-based deployment via HTTP.`);
      return createPathBasedClient(uri, protocol);
    }
  } catch {
    return false;
  }
}

/**
 * Connects to the discovery service using path-based routing.
 * @param {string} uri
 * @param {string} protocol Specifies the default protocol used to create a connection if it's missing in the uri.
 */
export async function createPathBasedClient(uri, protocol = "wss:") {
  uri = createPathBasedURL(uri, protocol);
  try {
    const ws = new WebSocketClient({ uri });
    ws.once("error", () => ws.close());

    await ws.prepare();
    return ws;
  } catch (err) {
    console.debug(
      `Failed to connect to the discovery service using path-based routing: (${uri}): `,
      err
    );
  }
}

export async function testPortBasedClient(uri) {
  const test = createPortBasedURL(uri, "http:");
  try {
    const response = await fetch(test + healthcheckEndpoint);
    // HTTP426 - Update Required, means that the server requires a WebSocket connection
    if (response.status === 426 || response.ok) {
      console.info("Found the port-based deployment via HTTP.");
      return createPortBasedClient(uri);
    }
  } catch {
    return createPortBasedClient(uri);
  }
}

/**
 * Connects to the discovery service using port-based routing.
 * @param {string} uri
 */
export async function createPortBasedClient(uri) {
  uri = createPortBasedURL(uri);
  try {
    const ws = new WebSocketClient({ uri });
    ws.once("error", () => ws.close());

    await ws.prepare();
    return ws;
  } catch (err) {
    console.debug(
      `Failed to connect to the discovery service using port-based routing: (${uri}): `,
      err
    );
  }
}

function createURL(value, { defaultProtocol = "ws:" } = {}) {
  const serverPattern = /^([\w\d\-_.]+)(:(\d+))?(\/[\w\d-_.]+)*\/?$/;
  if (serverPattern.test(value)) {
    return new URL(`${defaultProtocol}//${value}`);
  }
  return new URL(value);
}

function createPathBasedURL(uri, protocol) {
  const url = createURL(uri, { defaultProtocol: protocol });
  if (!url.pathname.endsWith("/")) {
    url.pathname += "/";
  }

  url.pathname += endpoint;
  return url.toString();
}

function createPortBasedURL(uri, protocol = "ws:") {
  const url = createURL(uri, { defaultProtocol: protocol });
  protocol = url.protocol;
  const hostname = url.hostname;
  const port = url.port || "3333";
  return `${protocol}//${hostname}:${port}/`;
}

function wait(timeout) {
  return new Promise((resolve) => setTimeout(() => resolve(), timeout));
}

const endpoint = "omni/discovery/";
const healthcheckEndpoint = "healthcheck";

export class DiscoveryError extends Error {}

export const ServiceMeta = Symbol("ServiceMeta");
