import React from "react";
import styled from "styled-components";
import Spinner from "./Spinner";

export interface DialogSpinnerProps {
  loading: boolean;
}

const DialogSpinner: React.FC<DialogSpinnerProps> = ({ loading, children, ...props }) => {
  if (loading) {
    return <StyledDialogSpinner {...props} />;
  }
  return null;
};

const StyledDialogSpinner = styled(Spinner)`
  position: fixed;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 16px;
  height: 16px;
  color: white;
`;

export default DialogSpinner;
