import { observer } from "mobx-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useCwd from "../hooks/useCwd";
import useStorageList from "../hooks/useStorageList";
import Path from "../state/Path";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import FormError from "./FormError";
import Label from "./Label";
import PathInput from "./PathInput";
import SearchDropArea from "./SearchDropArea";
import { DefaultTheme } from "./Theme";

export interface SearchByImageProps {
  initial?: File | Path | null;
  onSubmit(data: SearchByImage): void;
  onClose(): void;
}

export interface SearchByImage {
  image?: File;
  sample?: string;
}

const SearchByImage: React.FC<SearchByImageProps> = ({ initial, onSubmit, onClose }) => {
  const ref = useRef<HTMLDivElement>(null);
  const storages = useStorageList();
  const [file, setFile] = useState<File | Path | null>(initial ?? null);
  const cwd = useCwd();
  const setPath = useCallback(
    (path: Path) => {
      if (path) {
        storages.selected?.cd(path.path);
      }
    },
    [storages]
  );

  const [error, setError] = useState("");

  function submit() {
    onSubmit?.({
      image: file instanceof File ? file : undefined,
      sample: file instanceof Path ? file.link : undefined,
    });
  }

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <StyledSearchByImage ref={ref} tabIndex={-1}>
      {error && <FormError>{error}</FormError>}

      <SearchByImageDropArea image={file} onDrop={setFile} onError={setError} />

      <Label>Search Path</Label>
      <PathInput storages={storages.items} value={cwd} onChange={setPath} />

      <SearchByImageButtons placement={"right"}>
        <Button onClick={submit}>Search by image</Button>
        <Button onClick={onClose}>Close</Button>
      </SearchByImageButtons>
    </StyledSearchByImage>
  );
};

const StyledSearchByImage = styled.div`
  display: grid;
  padding: 1em;
  background-color: ${({ theme }) => theme.colors.headerBackground};
  color: ${({ theme }) => theme.colors.headerForeground};
  grid-template-columns: 100px 1fr;
  gap: 0.5em;
  box-shadow: ${({ theme }) => theme.shadows.default};
  align-items: center;
  min-width: 400px;
`;
StyledSearchByImage.defaultProps = {
  theme: DefaultTheme,
};

const SearchByImageDropArea = styled(SearchDropArea)`
  grid-column: 1 / 3;
`;

const SearchByImageButtons = styled(ButtonGroup)`
  margin-top: 0.5em;
  grid-column: 1 / 3;
`;

export default observer(SearchByImage);
