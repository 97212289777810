import { AuthenticationResult } from "@omniverse/auth/react/AuthForm";
import { useCallback } from "react";
import Nucleus from "../state/commands/nucleus/Nucleus";
import NucleusSession from "../state/commands/nucleus/NucleusSession";
import { parsePublicName } from "../state/commands/Provider";
import Storage from "../state/Storage";
import { joinURL } from "../util/Path";
import useAppNavigate from "./useAppNavigate";
import useStorageList from "./useStorageList";

export interface UseNucleusSignInOptions {
  onSuccess?(result: AuthenticationResult): void;
}

export default function useNucleusSignIn({ onSuccess }: UseNucleusSignInOptions = {}) {
  const navigate = useAppNavigate();
  const storageList = useStorageList();

  return useCallback(
    async ({ username, server, accessToken, refreshToken, profile, status, errors, extras }: AuthenticationResult) => {
      server = parsePublicName(server!);

      let storage = storageList.find(server!);
      if (storage) {
        storageList.remove(storage);
      }

      const authentication = new NucleusSession(
        server!,
        username!,
        accessToken!,
        refreshToken!,
        profile?.admin,
        profile?.nucleus_ro
      );
      const provider = new Nucleus(server!, authentication);
      storage = new Storage(provider);
      await storage.init();

      storageList.add(storage);

      const path = extras?.path ?? "/";
      navigate(joinURL(storage.root.link, path));

      if (onSuccess) {
        onSuccess({ username, server, accessToken, refreshToken, profile, status, errors, extras });
      }
    },
    [navigate, storageList, onSuccess]
  );
}
