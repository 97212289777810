import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import User from "../state/User";
import { ReactComponent as UserSVG } from "../static/user.svg";
import { GridCellProps } from "./Grid";
import GridCell, { GridCellAligner } from "./GridCell";
import Icon, { Icons } from "./Icon";

export type UserGridCellProps = GridCellProps<User, UserGridCellExtraProps>;

export interface UserGridCellExtraProps {
  selectedUser?: User | null;
  menu?: React.ReactNode;
  onSelect?(user: User | null): void;
  onContextMenu?(e: React.MouseEvent & { data: User }): void;
}

const UserGridCell: React.FC<UserGridCellProps> = ({
  style,
  width,
  height,
  data: user,
  selectedUser,
  menu,
  onSelect,
  onContextMenu,
}) => {
  function selectUser() {
    if (onSelect) {
      onSelect(user);
    }
  }

  function showContextMenu(e: React.MouseEvent) {
    const event = e as React.MouseEvent & { data: User };
    event.data = user;

    if (onContextMenu) {
      onContextMenu(event);
    }
  }

  return (
    <GridCellAligner style={style} cellWidth={width} onClick={selectUser} onContextMenu={showContextMenu}>
      <GridCell
        name={user.name}
        selected={selectedUser === user}
        highlighted={Boolean(menu)}
        bordered={false}
        scaling
        thumbnail={UserSVG}
        actualHeight={height}
        cellHeight={150}
      />
      {user.profile?.admin ? <Admin /> : user.profile?.nucleusRo && <ReadOnly />}

      {menu}
    </GridCellAligner>
  );
};

const ReadOnly = () => {
  return (
    <UserStatusIcon title={"Read-only"}>
      <Icon icon={Icons.Eye} />
    </UserStatusIcon>
  );
};

const Admin = () => {
  return (
    <UserStatusIcon title={"Admin"}>
      <Icon icon={Icons.Wrench} />
    </UserStatusIcon>
  );
};

const UserStatusIcon = styled.div`
  position: absolute;
  bottom: 8px;
  right: 4px;
  user-select: none;
  color: ${({ theme }) => theme.colors.foreground};
`;

export default observer(UserGridCell);
