import React, { useCallback } from "react";
import styled from "styled-components";
import { DefaultTheme } from "./Theme";

export interface FormProps extends React.HTMLAttributes<HTMLFormElement> {}

export const Form = ({ onSubmit, ...props }: FormProps) => {
  const submit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (onSubmit) {
        onSubmit(e);
      }
    },
    [onSubmit]
  );

  return <StyledForm {...props} onSubmit={submit} />;
};

const StyledForm = styled.form`
  color: ${({ theme }) => theme.colors.foreground};
`;
StyledForm.defaultProps = {
  theme: DefaultTheme,
};

export default Form;
