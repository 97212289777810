import Schema from "@omniverse/idl/schema";


export const Capabilities = Schema.Map(Schema.Number);

export const TagSuggestionsGetSuggestionsServerRemoteVersion = Schema.Number;

export const TagSuggestionsGetSuggestionsServerLocalVersion = Schema.Literal(2);

export const TagSuggestionsGetSuggestionsClientRemoteVersion = Schema.Number;

export const TagSuggestionsGetSuggestionsClientLocalVersion = Schema.Literal(2);

export const TagSuggestionsModifySuggestionsServerRemoteVersion = Schema.Number;

export const TagSuggestionsModifySuggestionsServerLocalVersion = Schema.Literal(2);

export const TagSuggestionsModifySuggestionsClientRemoteVersion = Schema.Number;

export const TagSuggestionsModifySuggestionsClientLocalVersion = Schema.Literal(2);

export const TagSuggestionsCheckCapabilitiesServerRemoteVersion = Schema.Number;

export const TagSuggestionsCheckCapabilitiesServerLocalVersion = Schema.Literal(2);

export const TagSuggestionsCheckCapabilitiesClientRemoteVersion = Schema.Number;

export const TagSuggestionsCheckCapabilitiesClientLocalVersion = Schema.Literal(2);

export const TagSubscriptionSubscribeServerRemoteVersion = Schema.Number;

export const TagSubscriptionSubscribeServerLocalVersion = Schema.Literal(3);

export const TagSubscriptionSubscribeClientRemoteVersion = Schema.Number;

export const TagSubscriptionSubscribeClientLocalVersion = Schema.Literal(3);

export const TagSubscriptionCheckCapabilitiesServerRemoteVersion = Schema.Number;

export const TagSubscriptionCheckCapabilitiesServerLocalVersion = Schema.Literal(2);

export const TagSubscriptionCheckCapabilitiesClientRemoteVersion = Schema.Number;

export const TagSubscriptionCheckCapabilitiesClientLocalVersion = Schema.Literal(2);

export const TaggingServiceTagQueryServerRemoteVersion = Schema.Number;

export const TaggingServiceTagQueryServerLocalVersion = Schema.Literal(2);

export const TaggingServiceTagQueryClientRemoteVersion = Schema.Number;

export const TaggingServiceTagQueryClientLocalVersion = Schema.Literal(2);

export const TaggingServiceGetTagsServerRemoteVersion = Schema.Number;

export const TaggingServiceGetTagsServerLocalVersion = Schema.Literal(2);

export const TaggingServiceGetTagsClientRemoteVersion = Schema.Number;

export const TaggingServiceGetTagsClientLocalVersion = Schema.Literal(2);

export const TaggingServiceModifyTagsServerRemoteVersion = Schema.Number;

export const TaggingServiceModifyTagsServerLocalVersion = Schema.Literal(2);

export const TaggingServiceModifyTagsClientRemoteVersion = Schema.Number;

export const TaggingServiceModifyTagsClientLocalVersion = Schema.Literal(2);

export const TaggingServiceGenerateClientIdServerRemoteVersion = Schema.Number;

export const TaggingServiceGenerateClientIdServerLocalVersion = Schema.Literal(2);

export const TaggingServiceGenerateClientIdClientRemoteVersion = Schema.Number;

export const TaggingServiceGenerateClientIdClientLocalVersion = Schema.Literal(2);

export const TaggingServiceCheckCapabilitiesServerRemoteVersion = Schema.Number;

export const TaggingServiceCheckCapabilitiesServerLocalVersion = Schema.Literal(2);

export const TaggingServiceCheckCapabilitiesClientRemoteVersion = Schema.Number;

export const TaggingServiceCheckCapabilitiesClientLocalVersion = Schema.Literal(2);

export const TagSuggestionsServerRemoteCapabilities = Capabilities;

export const TagSuggestionsServerLocalCapabilities = Schema.Literal({'check_capabilities': 2, 'modify_suggestions': 2, 'get_suggestions': 2});

export const TagSuggestionsClientRemoteCapabilities = Capabilities;

export const TagSuggestionsClientLocalCapabilities = Schema.Literal({'check_capabilities': 2, 'modify_suggestions': 2, 'get_suggestions': 2});

export const TagSubscriptionServerRemoteCapabilities = Capabilities;

export const TagSubscriptionServerLocalCapabilities = Schema.Literal({'check_capabilities': 2, 'subscribe': 3});

export const TagSubscriptionClientRemoteCapabilities = Capabilities;

export const TagSubscriptionClientLocalCapabilities = Schema.Literal({'check_capabilities': 2, 'subscribe': 3});

export const TaggingServiceServerRemoteCapabilities = Capabilities;

export const TaggingServiceServerLocalCapabilities = Schema.Literal({'check_capabilities': 2, 'generate_client_id': 2, 'modify_tags': 2, 'get_tags': 2, 'tag_query': 2});

export const TaggingServiceClientRemoteCapabilities = Capabilities;

export const TaggingServiceClientLocalCapabilities = Schema.Literal({'check_capabilities': 2, 'generate_client_id': 2, 'modify_tags': 2, 'get_tags': 2, 'tag_query': 2});

export const Category = Schema.Object({
  name: Schema.String,
  tags: Schema.Array(Schema.String),
});

export const StatusCode = Schema.Enum({
  NotInitialized: "NotInitialized",
  Ok: "OK",
  AlreadyExists: "AlreadyExists",
  DoesNotExist: "DoesNotExist",
  ConnectionError: "ConnectionError",
  InvalidPath: "InvalidPath",
  ResultsClamped: "ResultsClamped",
  Denied: "Denied",
  TokenExpired: "TokenExpired",
});

export const TagSuggestionsGetSuggestionsServerVersion = TagSuggestionsGetSuggestionsServerRemoteVersion;

export const CategoryResult = Schema.Object({
  tag_status: Schema.Array(StatusCode),
});

export const TagSuggestionsModifySuggestionsServerVersion = TagSuggestionsModifySuggestionsServerRemoteVersion;

export const TagSuggestionsServerCapabilities = TagSuggestionsServerRemoteCapabilities;

export const TagSuggestionsCheckCapabilitiesServerVersion = TagSuggestionsCheckCapabilitiesServerRemoteVersion;

export const PathEventType = Schema.Enum({
  Copy: 0,
  Rename: 1,
  Delete: 2,
});

export const TagModifyType = Schema.Enum({
  Add: 0,
  Set: 1,
  Reset: 2,
  Remove: 3,
});

export const Tag = Schema.Object({
  name: Schema.Optional(Schema.String),
  tag_namespace: Schema.String,
  value: Schema.Optional(Schema.String),
});

export const TagSubscriptionSubscribeServerVersion = TagSubscriptionSubscribeServerRemoteVersion;

export const TagSubscriptionServerCapabilities = TagSubscriptionServerRemoteCapabilities;

export const TagSubscriptionCheckCapabilitiesServerVersion = TagSubscriptionCheckCapabilitiesServerRemoteVersion;

export const GetTagsResultPath = Schema.Object({
  connection_status: Schema.Number,
  connection_status_string: Schema.String,
  tags: Schema.Array(Tag),
});

export const TaggingServiceGetTagsServerVersion = TaggingServiceGetTagsServerRemoteVersion;

export const ModifyTagsResultPath = Schema.Object({
  connection_status: Schema.Number,
  connection_status_string: Schema.String,
  status: Schema.Array(StatusCode),
});

export const TaggingServiceModifyTagsServerVersion = TaggingServiceModifyTagsServerRemoteVersion;

export const TagFiltered = Schema.Object({
  name: Schema.Optional(Schema.String),
  tag_namespace: Schema.Optional(Schema.String),
  value: Schema.Optional(Schema.String),
});

export const TaggingServiceTagQueryServerVersion = TaggingServiceTagQueryServerRemoteVersion;

export const TaggingClientId = Schema.String;

export const TaggingServiceGenerateClientIdServerVersion = TaggingServiceGenerateClientIdServerRemoteVersion;

export const TaggingServiceServerCapabilities = TaggingServiceServerRemoteCapabilities;

export const TaggingServiceCheckCapabilitiesServerVersion = TaggingServiceCheckCapabilitiesServerRemoteVersion;

export const GetSuggestionsResult = Schema.Object({
  version: TagSuggestionsGetSuggestionsServerVersion,
  status: StatusCode,
  connection_status: Schema.Number,
  connection_status_string: Schema.String,
  categories: Schema.Array(Category),
});

export const TagSuggestionsGetSuggestionsClientVersion = TagSuggestionsGetSuggestionsClientLocalVersion;

export const ModifySuggestionsResult = Schema.Object({
  version: TagSuggestionsModifySuggestionsServerVersion,
  connection_status: Schema.Number,
  connection_status_string: Schema.String,
  category_results: Schema.Array(CategoryResult),
});

export const TagSuggestionsModifyType = Schema.Enum({
  Add: 0,
  Reset: 1,
  Remove: 2,
});

export const TagSuggestionsModifySuggestionsClientVersion = TagSuggestionsModifySuggestionsClientLocalVersion;

export const TagSuggestionsCheckCapabilitiesResult = Schema.Object({
  version: TagSuggestionsCheckCapabilitiesServerVersion,
  capabilities: TagSuggestionsServerCapabilities,
  status: StatusCode,
});

export const TagSuggestionsClientCapabilities = TagSuggestionsClientLocalCapabilities;

export const TagSuggestionsCheckCapabilitiesClientVersion = TagSuggestionsCheckCapabilitiesClientLocalVersion;

export const SubscriptionEvent = Schema.Object({
  version: TagSubscriptionSubscribeServerVersion,
  status: StatusCode,
  path: Schema.String,
  tags: Schema.Array(Tag),
  type: TagModifyType,
  path_event: Schema.Optional(PathEventType),
  source_path: Schema.Optional(Schema.String),
});

export const Query = Schema.Object({
  path: Schema.Optional(Schema.String),
  tag_name: Schema.Optional(Schema.String),
  tag_namespace: Schema.Optional(Schema.String),
  value: Schema.Optional(Schema.String),
});

export const TagSubscriptionSubscribeClientVersion = TagSubscriptionSubscribeClientLocalVersion;

export const TagSubscriptionCheckCapabilitiesResult = Schema.Object({
  version: TagSubscriptionCheckCapabilitiesServerVersion,
  capabilities: TagSubscriptionServerCapabilities,
  status: StatusCode,
});

export const TagSubscriptionClientCapabilities = TagSubscriptionClientLocalCapabilities;

export const TagSubscriptionCheckCapabilitiesClientVersion = TagSubscriptionCheckCapabilitiesClientLocalVersion;

export const QueryResult = Schema.Object({
  version: TaggingServiceTagQueryServerVersion,
  paths: Schema.Array(Schema.String),
  tags: Schema.Array(TagFiltered),
  status: StatusCode,
});

export const ReturnFilter = Schema.Object({
  return_tags: Schema.Boolean,
  return_values: Schema.Boolean,
  return_namespaces: Schema.Boolean,
  return_paths: Schema.Boolean,
  exclude_hidden: Schema.Boolean,
});

export const TaggingServiceTagQueryClientVersion = TaggingServiceTagQueryClientLocalVersion;

export const GetTagsResult = Schema.Object({
  version: TaggingServiceGetTagsServerVersion,
  status: StatusCode,
  path_result: Schema.Array(GetTagsResultPath),
});

export const TaggingServiceGetTagsClientVersion = TaggingServiceGetTagsClientLocalVersion;

export const ModifyTagsResult = Schema.Object({
  version: TaggingServiceModifyTagsServerVersion,
  path_result: Schema.Array(ModifyTagsResultPath),
});

export const TaggingServiceModifyTagsClientVersion = TaggingServiceModifyTagsClientLocalVersion;

export const GenerateClientIdResult = Schema.Object({
  version: TaggingServiceGenerateClientIdServerVersion,
  client_id: TaggingClientId,
  status: StatusCode,
});

export const TaggingServiceGenerateClientIdClientVersion = TaggingServiceGenerateClientIdClientLocalVersion;

export const TaggingServiceCheckCapabilitiesResult = Schema.Object({
  version: TaggingServiceCheckCapabilitiesServerVersion,
  capabilities: TaggingServiceServerCapabilities,
  status: StatusCode,
});

export const TaggingServiceClientCapabilities = TaggingServiceClientLocalCapabilities;

export const TaggingServiceCheckCapabilitiesClientVersion = TaggingServiceCheckCapabilitiesClientLocalVersion;

export const HiddenNamespacePrefix = Schema.Literal(".");

export const TagSuggestionsVersion = Schema.Literal("3.0.0");

export const TagSubscriptionVersion = Schema.Literal("3.0.0");

export const TaggingServiceVersion = Schema.Literal("3.0.0");



export const TagSuggestionsCheckCapabilitiesRequest = Schema.Object({
  version: TagSuggestionsCheckCapabilitiesClientVersion,
  capabilities: TagSuggestionsClientCapabilities,
});

export const TagSuggestionsModifySuggestionsRequest = Schema.Object({
  version: TagSuggestionsModifySuggestionsClientVersion,
  auth_token: Schema.String,
  path: Schema.String,
  categories: Schema.Array(Category),
  modify_type: TagSuggestionsModifyType,
});

export const TagSuggestionsGetSuggestionsRequest = Schema.Object({
  version: TagSuggestionsGetSuggestionsClientVersion,
  auth_token: Schema.String,
  path: Schema.String,
  recursive: Schema.Boolean,
});

export const TagSubscriptionCheckCapabilitiesRequest = Schema.Object({
  version: TagSubscriptionCheckCapabilitiesClientVersion,
  capabilities: TagSubscriptionClientCapabilities,
});

export const TagSubscriptionSubscribeRequest = Schema.Object({
  version: TagSubscriptionSubscribeClientVersion,
  auth_token: Schema.String,
  client_id: Schema.Optional(TaggingClientId),
  query: Schema.Optional(Query),
});

export const TaggingServiceCheckCapabilitiesRequest = Schema.Object({
  version: TaggingServiceCheckCapabilitiesClientVersion,
  capabilities: TaggingServiceClientCapabilities,
});

export const TaggingServiceGenerateClientIdRequest = Schema.Object({
  version: TaggingServiceGenerateClientIdClientVersion,
});

export const TaggingServiceModifyTagsRequest = Schema.Object({
  version: TaggingServiceModifyTagsClientVersion,
  auth_token: Schema.String,
  paths: Schema.Array(Schema.String),
  tags: Schema.Array(Tag),
  modify_type: TagModifyType,
  client_id: Schema.Optional(TaggingClientId),
});

export const TaggingServiceGetTagsRequest = Schema.Object({
  version: TaggingServiceGetTagsClientVersion,
  auth_token: Schema.String,
  paths: Schema.Array(Schema.String),
});

export const TaggingServiceTagQueryRequest = Schema.Object({
  version: TaggingServiceTagQueryClientVersion,
  auth_token: Schema.String,
  query: Schema.Optional(Query),
  ret_filter: Schema.Optional(ReturnFilter),
  max_results: Schema.Optional(Schema.Number),
});
