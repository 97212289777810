import styled from "styled-components";
import Icon, { Icons } from "./Icon";
import Picture from "./Picture";
import { DefaultTheme } from "./Theme";

const DialogTitleBar = styled.h2`
  display: flex;
  background: ${({ theme }) => theme.colors.toolbarBackground};
  color: ${({ theme }) => theme.colors.toolbarForeground};
  white-space: nowrap;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 30px;
  font-size: 12pt;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
  padding: 5px 0;
  box-sizing: border-box;
`;
DialogTitleBar.defaultProps = {
  theme: DefaultTheme
};

export const DialogTitleImage = styled(Picture)`
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  fill: ${({ theme }) => theme.colors.toolbarForeground};
`;
DialogTitleImage.defaultProps = {
  theme: DefaultTheme
};

export const DialogTitleFiller = styled.div`
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
`;

export const DialogTitleIcon = styled(Icon)`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  flex: 0 0 20px;
  margin: 0 5px;
`;

export const DialogCloseIcon = styled(DialogTitleIcon).attrs({
  icon: Icons.Close,
})`
  cursor: pointer;
`;

export const DialogTitleText = styled.span`
  flex: 1;
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default DialogTitleBar;
