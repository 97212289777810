import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import useMouse from "../hooks/useMouse";
import Picture, { PictureProps, PictureSource } from "./Picture";

export interface GhostProps {
  thumbnail?: string | React.ComponentType;
  fallbacks?: Array<PictureSource | undefined>;
  allowed?: boolean;
  className?: string;
}

const Ghost: React.FC<GhostProps> = ({ thumbnail, fallbacks, allowed, className, children }) => {
  const mouse = useMouse();
  const style: React.CSSProperties = { opacity: mouse.ready ? "1" : "0", left: mouse.x + 5, top: mouse.y + 15 };
  return createPortal(
    <StyledGhost className={className} style={style} allowed={allowed}>
      <GhostThumbnail src={thumbnail} fallbacks={fallbacks} allowed={allowed} />
      {children}
    </StyledGhost>,
    document.body
  );
};

const StyledGhost = styled.div<{
  allowed?: boolean;
}>`
  position: fixed;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background: ${({ theme, allowed }) => (allowed ? theme.colors.selectedItemBackground : theme.colors.background)};
  color: ${({ theme, allowed }) => (allowed ? theme.colors.selectedItemForeground : theme.colors.foreground)};
  border: 1px solid ${({ theme, allowed }) => (allowed ? theme.colors.selectedItemBorder : theme.colors.border)};
  border-radius: 5px;
  min-width: 125px;
  transition: 0.3s background-color, 0.3s border-color, 0.3s color;
  z-index: 2;
`;

const GhostThumbnail = styled(({ allowed, ...props }) => <Picture {...props} />)<PictureProps & { allowed?: boolean }>`
  width: 32px;
  margin-right: 0.5rem;
  fill: ${({ theme, allowed }) => (allowed ? theme.colors.selectedItemForeground : theme.colors.foreground)};
`;

export default Ghost;
