import { PathPermission } from "@omniverse/api/data";
import { action, computed, makeObservable, observable } from "mobx";
import Path from "./Path";
import Storage from "./Storage";

type Username = string;
type Group = string;

export type ACLPermissions = Map<Username | Group, Set<PathPermission>>;
export type ACLEntry = [Username | Group, PathPermission[]];

class ACL {
  public map: Map<string, Set<PathPermission>>;
  public loading: boolean;

  constructor(public readonly path: Path, public readonly storage: Storage) {
    this.map = new Map<string, Set<PathPermission>>();
    this.loading = false;

    makeObservable(this, {
      map: observable,
      loading: observable,
      keys: computed,
      entries: computed,
      add: action.bound,
      set: action.bound,
      update: action.bound,
      remove: action.bound,
    });
  }

  public get keys(): string[] {
    return Array.from(this.map.keys());
  }

  public get entries(): ACLEntry[] {
    return Array.from(this.map.entries()).map(([key, permissions]) => [key, Array.from(permissions)]);
  }

  public set(acl: ACLEntry[]) {
    this.map.clear();
    for (const [userOrGroup, permissions] of acl) {
      this.map.set(userOrGroup, new Set(permissions));
    }
  }

  public get(userOrGroup: string): Set<PathPermission> {
    const permissions = this.map.get(userOrGroup);
    return new Set(permissions);
  }

  public add(userOrGroup: string, permissions: Set<PathPermission> = new Set<PathPermission>()): void {
    this.map.set(userOrGroup, permissions);
  }

  public update(userOrGroup: string, permissions: Set<PathPermission>): void {
    this.map.set(userOrGroup, permissions);
  }

  public remove(userOrGroup: string): void {
    this.map.delete(userOrGroup);
  }
}

export default ACL;
