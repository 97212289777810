import { observer, useLocalObservable } from "mobx-react";
import React, { useEffect, useMemo, useRef } from "react";
import useDialog from "../hooks/useDialog";
import useGridSelectionFocus from "../hooks/useGridSelectionFocus";
import useSelectionReset from "../hooks/useSelectionReset";
import Group from "../state/Group";
import Groups from "../state/Groups";
import { ReactComponent as NewGroupIcon } from "../static/new-group.svg";
import Grid, { GridCellProps } from "./Grid";
import GridCell, { GridCellAligner } from "./GridCell";
import GroupFormDialog from "./GroupFormDialog";
import GroupGridCell, { GroupGridCellExtraProps } from "./GroupGridCell";

export interface GroupGridProps {
  groups: Groups;
  selectedGroup?: Group | null;
  canAddGroup?: boolean;
  width?: number;
  height?: number;
  onSelect?(group: Group | null): void;
}

type GroupItem = { type: "group"; group: Group };
type NewGroupItem = { type: "new-group"; groups: Groups };
type GroupGridItemType = GroupItem | NewGroupItem;

const GroupGrid: React.FC<GroupGridProps> = ({ groups, selectedGroup, canAddGroup, width, height, onSelect }) => {
  const grid = useRef<Grid<GroupGridItemType>>(null);
  const state = useLocalObservable(() => {
    return {
      canAddGroup,
      groups,
      get cells() {
        const cells: GroupGridItemType[] = [];
        if (this.canAddGroup) {
          cells.push({ type: "new-group", groups: this.groups });
        }
        return cells.concat(this.groups.items.map((group) => ({ type: "group", group })));
      },

      update(canAddGroup: boolean | undefined, groups: Groups) {
        this.canAddGroup = canAddGroup;
        this.groups = groups;
      },
    };
  });
  useEffect(() => {
    state.update(canAddGroup, groups);
  }, [state, canAddGroup, groups]);

  const selectedGroupIndex = useMemo(() => {
    return state.cells.findIndex((cell) => cell.type === "group" && cell.group === selectedGroup);
  }, [state.cells, selectedGroup]);

  useGridSelectionFocus(grid.current, selectedGroupIndex);

  const cellProps: GroupGridCellExtraProps = useMemo(
    () => ({
      storage: groups.storage,
      selectedGroup,
      onSelect,
    }),
    [groups, selectedGroup, onSelect]
  );

  const resetSelection = useSelectionReset(onSelect);
  return (
    <Grid
      ref={grid}
      cells={state.cells}
      cellProps={cellProps}
      cellWidth={150}
      cellHeight={150}
      cellRenderer={GroupGridCellRenderer}
      gap={20}
      width={width}
      height={height}
      onClick={resetSelection}
    />
  );
};

const GroupGridCellRenderer: React.FC<GridCellProps<GroupGridItemType, GroupGridCellExtraProps>> = ({
  data,
  ...props
}) => {
  if (data.type === "group") {
    return <GroupGridCell data={data.group} {...props} />;
  }
  if (data.type === "new-group") {
    return <NewGroupGridCell data={data} {...props} />;
  }
  return null;
};

const NewGroupGridCell: React.FC<GridCellProps<NewGroupItem>> = ({ data: { groups }, style, width, height }) => {
  const groupDialog = useDialog(GroupFormDialog, { groups });

  function addGroup() {
    groupDialog.show();
  }

  return (
    <GridCellAligner style={style} cellWidth={width} onClick={addGroup}>
      <GridCell
        name={"Add Group"}
        thumbnail={NewGroupIcon}
        bordered={false}
        selected={false}
        highlighted={false}
        cellHeight={150}
        actualHeight={height}
      />
    </GridCellAligner>
  );
};

export default observer(GroupGrid);
