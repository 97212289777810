import React from "react";
import styled from "styled-components";
import useApp from "../hooks/useApp";
import useAppNavigate from "../hooks/useAppNavigate";
import LogoImage from "../static/omniverse-logo.png";
import { PathTreeRootType } from "./PathTree";
import { PathTreeItemEvent, PathTreeItemProps } from "./PathTreeItem";
import TreeItem from "./TreeItem";
import TreeItemName from "./TreeItemName";
import TreeToggle from "./TreeToggle";

const PathTreeRoot: React.FC<PathTreeItemProps<PathTreeRootType>> = ({ item, style, selected, onToggle }) => {
  const app = useApp();
  const navigate = useAppNavigate();

  function toggle(e: React.MouseEvent) {
    const ev = e as React.MouseEvent & PathTreeItemEvent;
    ev.item = item;
    if (onToggle) {
      onToggle(ev);
    }
  }

  function openHomePage() {
    app.storages.select(null);
    navigate("/");
  }

  return (
    <TreeItem level={0} style={style} onClick={openHomePage} selected={selected}>
      <TreeToggle title={item.toggled ? `Hide tree` : `Show tree`} opened={item.toggled} onClick={toggle} />
      <Logo />
      <TreeItemName>Omniverse</TreeItemName>
    </TreeItem>
  );
};

const Logo = styled.img.attrs({ src: LogoImage })`
  width: 25px;
  margin: auto 5px auto 0;
  text-align: center;
  position: relative;
`;

export default PathTreeRoot;
