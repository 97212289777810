import { getName, getParent, isFolder } from "../../../util/Path";
import { NucleusConnection } from "./Nucleus";
import { PathType, StatusType } from "@omniverse/api/data";

export async function listAssetsRecursively(connection: NucleusConnection, path: string): Promise<string[]> {
  const res = await connection.list2({ show_hidden: true, path });
  const files = [];

  let localFolders: string[] = [];
  while (true) {
    const read = await res.read();
    if (typeof read !== "symbol" && read.status === StatusType.OK) {
      for (const path of read.entries!) {
        if (path.path_type! === PathType.Folder) {
          localFolders.push(path.path!);
        } else {
          if (path.path_type === PathType.Asset) {
            files.push(path.path!);
          }
        }
      }
    } else {
      if (typeof read !== "symbol" && ![StatusType.Done, StatusType.Latest].includes(read.status)) {
        throw new ListEntriesError(`Error reading getting entries for ${path}`, read);
      }
      break;
    }
  }

  const innerFiles = (await Promise.all(localFolders.map((local) => listAssetsRecursively(connection, local)))).flat();

  return files.concat(innerFiles).reverse();
}

export type CompareResult = {
  destinationDir: string;
  sourceDir: string;
  sourceFiles: string[];
  destinationFiles: string[];
  intersection: string[];
};

export async function compareFiles(
  connection: NucleusConnection,
  src: string,
  destination: string
): Promise<CompareResult> {
  const sourceFiles: string[] = [];
  const sourceDir = isFolder(src) ? src : getParent(src);
  if (isFolder(src)) {
    sourceFiles.push(...(await listAssetsRecursively(connection, src)).map((path) => path.slice(src.length)));
  } else {
    sourceFiles.push(getName(src));
  }

  const destinationFiles: string[] = [];
  const destinationDir = isFolder(destination) ? destination : getParent(destination);

  try {
    destinationFiles.push(
      ...(await listAssetsRecursively(connection, destinationDir)).map((path) => path.slice(destination.length))
    );
  } catch (e) {
    if (e.result.status !== StatusType.InvalidPath) {
      throw new Error(`Error in comparing files from source ${src} to destination ${destination}, ${e}`);
    }
  }

  const intersection = destinationFiles.filter((val) => sourceFiles.includes(val));

  return {
    destinationFiles,
    destinationDir,
    sourceFiles,
    sourceDir,
    intersection,
  };
}

class ListEntriesError extends Error {
  public readonly result: { status: StatusType };

  constructor(message: string, result: { status: StatusType }) {
    super();
    this.result = result;
    this.message = this.message;
  }
}
