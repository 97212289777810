import React from "react";
import styled from "styled-components";
import useApp from "../hooks/useApp";
import useBrowserClipboard from "../hooks/useBrowserClipboard";
import useDialog from "../hooks/useDialog";
import useNotification from "../hooks/useNotification";
import usePermission from "../hooks/usePermission";
import useSubmit from "../hooks/useSubmit";
import { Commands } from "../state/commands/Provider";
import { IGenerateInvitationLinkCommand } from "../state/commands/types/GenerateInvitationLinkCommand";
import { IGenerateResetPasswordLinkCommand } from "../state/commands/types/GenerateResetPasswordLinkCommand";
import { ISetUserAdminAccessCommand } from "../state/commands/types/SetUserAdminAccessCommand";
import { ISetUserEnabledCommand } from "../state/commands/types/SetUserEnabledCommand";
import { ISetUserReadOnlyAccessCommand } from "../state/commands/types/SetUserReadOnlyAccessCommand";
import User from "../state/User";
import Button from "./Button";
import InviteLinkNotification from "./InviteLinkNotification";
import Loader from "./Loader";
import ResetPasswordLinkNotification from "./ResetPasswordLinkNotification";
import { DefaultTheme } from "./Theme";
import UserDisableConfirmation from "./UserDisableConfirmation";
import UserEnableConfirmation from "./UserEnableConfirmation";
import UserGrantAdminAccessConfirmation from "./UserGrantAdminAccessConfirmation";
import UserRevokeAdminAccessConfirmation from "./UserRevokeAdminAccessConfirmation";
import Warning from "./Warning";

export interface UserAdminPanelProps {
  user: User;
}

const UserAdminPanel: React.FC<UserAdminPanelProps> = ({ user }) => {
  const app = useApp();
  const clipboard = useBrowserClipboard();
  const disableConfirmation = useDialog(UserDisableConfirmation, { user });
  const enableConfirmation = useDialog(UserEnableConfirmation, { user });
  const grantAdminAccessConfirmation = useDialog(UserGrantAdminAccessConfirmation, { user });
  const revokeAdminAccessConfirmation = useDialog(UserRevokeAdminAccessConfirmation, { user });
  const resetPasswordNotification = useNotification(ResetPasswordLinkNotification, { user });
  const inviteNotification = useNotification(InviteLinkNotification, { user });

  const { submit: resetPassword } = useSubmit(
    async () => {
      const command = user.storage.commands.get<IGenerateResetPasswordLinkCommand>(Commands.GenerateResetPasswordLink);

      if (command) {
        const link = await command.execute({ user });
        return clipboard.copy(app.href(link, { absolute: true }));
      }
    },
    {
      onSuccess: () => resetPasswordNotification.show(),
    }
  );

  const { submit: inviteUser } = useSubmit(
    async () => {
      const command = user.storage.commands.get<IGenerateInvitationLinkCommand>(Commands.GenerateInvitationLink);
      if (command) {
        const link = await command.execute({ user });
        return clipboard.copy(app.href(link, { absolute: true }));
      }
    },
    {
      onSuccess: () => inviteNotification.show(),
    }
  );

  function disable() {
    disableConfirmation.show();
  }

  function enable() {
    enableConfirmation.show();
  }

  function grantAdmin() {
    grantAdminAccessConfirmation.show();
  }

  function revokeAdmin() {
    revokeAdminAccessConfirmation.show();
  }

  async function toggleReadOnly() {
    const command = user.storage.commands.get<ISetUserReadOnlyAccessCommand>(Commands.SetUserReadOnlyAccess);
    if (command) {
      await command.execute({ user, isReadonly: !user.profile!.nucleusRo });
    }
  }

  const isAuthenticated = user.name === user.storage.provider.session.username;
  const canResetPassword = usePermission(
    () => user.storage.commands.allowed<IGenerateResetPasswordLinkCommand>(Commands.GenerateResetPasswordLink),
    [user]
  );
  const canInviteUser = usePermission(
    () => user.storage.commands.allowed<IGenerateInvitationLinkCommand>(Commands.GenerateInvitationLink),
    [user]
  );
  const canSetAdminAccess = usePermission(
    () => user.storage.commands.allowed<ISetUserAdminAccessCommand>(Commands.SetUserAdminAccess),
    [user]
  );
  const canSetUserEnabledStatus = usePermission(
    () => user.storage.commands.allowed<ISetUserEnabledCommand>(Commands.SetUserEnabled),
    [user]
  );
  const canSetReadOnly = usePermission(
    () => user.storage.commands.allowed<ISetUserReadOnlyAccessCommand>(Commands.SetUserReadOnlyAccess),
    [user]
  );

  if (!user.profile) {
    return <Loader />;
  }
  return (
    <StyledProfileAdminPanel>
      {!user.canManagePassword && <Warning>Password is managed by external authentication service.</Warning>}

      {user.profile.activated
        ? canResetPassword === "allowed" && (
            <Button disabled={!user.canManagePassword} onClick={resetPassword}>
              Reset password
            </Button>
          )
        : canInviteUser === "allowed" && <Button onClick={inviteUser}>Invite user</Button>}

      {canSetReadOnly === "allowed" &&
        (user.profile.nucleusRo ? (
          <Button onClick={toggleReadOnly}>Revoke Read-only</Button>
        ) : (
          <Button onClick={toggleReadOnly}>Set as Read-only</Button>
        ))}

      {canSetAdminAccess === "allowed" &&
        (user.profile.admin ? (
          <Button disabled={isAuthenticated} onClick={revokeAdmin}>
            Revoke admin access
          </Button>
        ) : (
          <Button disabled={isAuthenticated} onClick={grantAdmin}>
            Grant admin access
          </Button>
        ))}

      {canSetUserEnabledStatus === "allowed" &&
        (user.profile.enabled ? (
          <Button disabled={isAuthenticated} onClick={disable}>
            Disable account
          </Button>
        ) : (
          <EnableAccountButton disabled={isAuthenticated} onClick={enable}>
            Enable account
          </EnableAccountButton>
        ))}
    </StyledProfileAdminPanel>
  );
};

const StyledProfileAdminPanel = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 220px;
  grid-gap: 1em;
  margin: 1.5em auto 1em auto;
  padding-top: 1.5em;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;
StyledProfileAdminPanel.defaultProps = {
  theme: DefaultTheme,
};

const EnableAccountButton = styled(Button)`
  background: ${({ theme }) => theme.colors.selectedItemBackground};
  color: ${({ theme }) => theme.colors.selectedItemForeground};
  border-color: ${({ theme }) => theme.colors.selectedItemBorder};
  box-shadow: 0 1px 2px ${({ theme }) => theme.colors.selectedItemBorder};
`;
EnableAccountButton.defaultProps = {
  theme: DefaultTheme,
};

export default UserAdminPanel;
