import ResizeSensor from "css-element-queries/src/ResizeSensor";
import { Size } from "re-resizable";
import React from "react";
import styled from "styled-components";
import { DropStyle } from "../hooks/useDrop";
import { Omit, StyledRenderer } from "./index";
import ScrollBar from "./ScrollBar";
import Table, {
  DefaultRowHeight,
  TableBody,
  TableBodyComponentProps,
  TableComponentProps,
  TableProps,
  TableRowComponentProps,
} from "./Table";
import { DefaultTheme } from "./Theme";

export interface VirtualizedTableProps<TRow = any, TCellProps = any, TRowProps = any>
  extends Omit<TableProps<TRow, TCellProps, TRowProps>, "bodyComponent"> {
  height?: number;
  rowHeight: number;
  className?: string;
  emptyRows?: number;
  resetScroll?: boolean;
  cellProps?: TCellProps;
  rowComponent?: StyledRenderer<VirtualizedTableRowComponentProps<TRow> & TRowProps>;
  headTableComponent?: StyledRenderer<TableComponentProps>;
  bodyComponent?: StyledRenderer<VirtualizedTableBodyComponentProps<TRow>>;
  bodyContainerComponent?: StyledRenderer<VirtualizedTableBodyContainerProps<TRow>>;
  containerComponent?: StyledRenderer<VirtualizedTableContainerComponentProps>;
}

interface VirtualizedTableState<TRow> {
  rows: TRow[];
  startRow: number;
  containerHeight: number;
  offsetTop: number;
  offsetLeft: number;
  bodyWrapperStyle: React.CSSProperties;
}

export interface VirtualizedTableBodyComponentProps<TRow> extends TableBodyComponentProps<TRow> {
  rowHeight: number;
  offsetTop: number;
}

export interface VirtualizedTableRowComponentProps<TRow = any> extends TableRowComponentProps<TRow> {}

export type VirtualizedTableContainerComponentProps = React.ComponentPropsWithoutRef<"div"> & { height?: number };
export type VirtualizedTableBodyContainerProps<TRow> = React.ComponentPropsWithoutRef<"div"> & { rows: TRow[] };

export default class VirtualizedTable<TRow, TCellProps = any, TRowProps = any> extends React.Component<
  VirtualizedTableProps<TRow, TCellProps, TRowProps>,
  VirtualizedTableState<TRow>
> {
  public static defaultProps: Partial<VirtualizedTableProps> = {
    rowHeight: DefaultRowHeight,
    resetScroll: true,
    emptyRows: 1,
  };

  private readonly headerRef: React.RefObject<HTMLDivElement>;
  private readonly bodyRef: React.RefObject<HTMLDivElement>;
  private resizeSensor?: ResizeSensor;
  private frame?: number;

  private handleScroll = () => {
    this.frame = requestAnimationFrame(() => {
      if (this.headerRef.current && this.bodyRef.current) {
        this.headerRef.current.scrollLeft = this.bodyRef.current.scrollLeft;
      }
      this.recalculateState();
    });
  };

  constructor(props: VirtualizedTableProps<TRow, TCellProps>) {
    super(props);
    this.state = {
      containerHeight: 0,
      offsetTop: 0,
      offsetLeft: 0,
      bodyWrapperStyle: {},
      rows: [],
      startRow: 0,
    };

    this.bodyRef = React.createRef();
    this.headerRef = React.createRef();
  }

  public componentDidMount() {
    this.recalculateState();
    this.resizeSensor = new ResizeSensor(this.bodyRef.current!, (e: Size) => {
      if (e.height !== this.state.containerHeight) {
        this.recalculateState();
      }
    });
  }

  public componentDidUpdate({
    rows: prevRows,
    rowHeight: prevRowHeight,
    ...props
  }: VirtualizedTableProps<TRow, TCellProps>) {
    const { rows = [], rowHeight } = this.props;

    if (prevRows !== rows || prevRowHeight !== rowHeight) {
      if (!prevRows || (prevRows.length !== rows.length && this.props.resetScroll)) {
        this.scrollToTop();
      }

      this.recalculateState();
    }
  }

  public componentWillUnmount() {
    clearTimeout(this.frame);
    this.resizeSensor!.detach();
  }

  public scrollToTop = () => {
    if (this.bodyRef.current) {
      this.bodyRef.current.scrollTop = 0;
    }
  };

  public scrollToBottom = () => {
    if (this.bodyRef.current) {
      this.bodyRef.current.scrollTop = (this.state.bodyWrapperStyle.height as number) - this.props.rowHeight;
    }
  };

  public render() {
    const {
      className,
      cellProps,
      height = 0,
      rowProps,
      containerComponent: Container = StyledVirtualizedTable,
      bodyContainerComponent: Body = VirtualizedBody,
      onClick,
      onContextMenu,
    } = this.props;
    const { rows, bodyWrapperStyle } = this.state;

    return (
      <Container height={height}>
        <VirtualizedHeader ref={this.headerRef}>
          <Table
            columns={this.props.columns}
            showHeaders={this.props.showHeaders}
            tableComponent={this.props.headTableComponent}
            headComponent={this.props.headComponent}
            headerComponent={this.props.headerComponent}
            onHeaderSelect={this.props.onHeaderSelect}
          />
        </VirtualizedHeader>

        <Body
          className={className}
          ref={this.bodyRef}
          rows={rows}
          onScroll={this.handleScroll}
          onClick={onClick}
          onContextMenu={onContextMenu}
        >
          <VirtualizedTableWrapper style={bodyWrapperStyle}>
            <Table
              {...this.props}
              rows={rows}
              rowProps={rowProps}
              cellProps={cellProps}
              showHeaders={false}
              bodyComponent={this.renderBody}
              rowComponent={this.props.rowComponent}
            />
          </VirtualizedTableWrapper>
        </Body>
      </Container>
    );
  }

  protected renderBody = (bodyProps: TableBodyComponentProps<TRow>) => {
    if (!this.bodyRef.current) {
      return <TableBody>{bodyProps.children}</TableBody>;
    }

    if (this.props.bodyComponent) {
      const Body = this.props.bodyComponent;
      return <Body {...bodyProps} offsetTop={this.state.offsetTop} rowHeight={this.props.rowHeight} />;
    }

    return <TableBody>{bodyProps.children}</TableBody>;
  };

  public recalculateState(): void {
    if (!this.bodyRef.current) {
      return;
    }

    const { rows = [], rowHeight, emptyRows = 0 } = this.props;
    const scrollOffsetRows = 10;

    const scrollTop = this.bodyRef.current.scrollTop;
    const scrollLeft = this.bodyRef.current.scrollLeft;
    const containerHeight = this.bodyRef.current.clientHeight;

    const wrapperHeight = rowHeight * (rows.length + emptyRows);
    const startRow = Math.trunc(scrollTop / rowHeight);

    const actualRowCount = Math.trunc(containerHeight / rowHeight);
    const rowCount = actualRowCount + scrollOffsetRows + emptyRows;
    const endRow = startRow + rowCount;

    const top = scrollTop - (scrollTop % rowHeight);

    this.setState({
      containerHeight,
      offsetTop: scrollTop,
      offsetLeft: scrollLeft,
      bodyWrapperStyle: {
        height: wrapperHeight,
        flex: `0 0 ${wrapperHeight}px`,
        paddingTop: top,
      },
      startRow,
      rows: rows.slice(startRow, endRow),
    });
  }
}

const StyledVirtualizedTable = styled(({ height, ...props }) => <div {...props} />)`
  display: flex;
  flex-direction: column;
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  position: relative;
  overflow: hidden;

  ${ScrollBar};
`;
StyledVirtualizedTable.defaultProps = {
  theme: DefaultTheme,
};

const VirtualizedHeader = styled.div`
  flex: 0 0 auto;
  overflow: hidden;
`;

export const VirtualizedBody = styled.div`
  flex: 1 1 auto;
  max-height: 100%;
  position: relative;
  will-change: transform;
  scroll-behavior: smooth;
  overflow: scroll;

  ${ScrollBar};
  ${DropStyle};
`;
VirtualizedBody.defaultProps = {
  theme: DefaultTheme,
};

const VirtualizedTableWrapper = styled.div`
  box-sizing: border-box;
`;
