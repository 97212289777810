import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import Icon, { Icons } from "./Icon";
import Popup from "./Popup";

export interface NotificationProps {
  title: string;
  icon?: string;
  minimized: boolean;
  onToggle(): void;
  onClose?(): void;
}

const Notification: React.FC<NotificationProps> = ({ title, icon, minimized = false, onToggle, onClose, children }) => {
  return (
    <StyledNotification>
      <NotificationHeader>
        {icon && <NotificationIcon icon={icon} />}
        <NotificationTitle>{title}</NotificationTitle>
        <ToggleIcon title={"Minimize"} icon={minimized ? Icons.Maximize : Icons.Minimize} onClick={onToggle} />
        {onClose && <ToggleIcon title={"Continue in the background"} icon={Icons.Close} onClick={onClose} />}
      </NotificationHeader>

      {!minimized && children}
    </StyledNotification>
  );
};

const StyledNotification = styled(Popup)`
  margin-left: auto;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
`;

const NotificationHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 3px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.foreground};
`;

const NotificationIcon = styled(Icon)`
  font-size: 18pt;
  margin: 0 5px 0 0;
`;

const NotificationTitle = styled.div`
  flex: 1 0 auto;
  font-size: 11pt;
`;

const ToggleIcon = styled(Icon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.foreground};
  margin: 0 10px 0 auto;
`;

export default observer(Notification);
