import styled from "styled-components";
import { Icons } from "./Icon";
import PopupIcon from "./PopupIcon";

const PopupCloseIcon = styled(PopupIcon).attrs({ icon: Icons.Close, clickable: true })`
  position: absolute;
  top: 15px;
  right: 5px;
`;

export default PopupCloseIcon;
