import Serializer, {
  fromArrayBufferToString,
  fromStringToArrayBuffer,
  SerializerFactory,
  SerializerName,
} from "./index";

export default class JSONSerializer extends Serializer {
  static [SerializerName] = "json";

  async serialize(data) {
    return fromStringToArrayBuffer(JSON.stringify(data));
  }

  async deserialize(data) {
    return JSON.parse(fromArrayBufferToString(data));
  }
}

SerializerFactory.register(JSONSerializer);
