import { isElectron } from "./Electron";

export function getBaseURL(): string {
  if (isElectron()) {
    return "/";
  }

  const base = document.getElementById("public-url") as HTMLBaseElement;
  const href = base?.href ?? window.location.href;
  const url = new URL(href);

  let pathname = url.pathname;
  if (pathname.includes("__PUBLIC_URL__")) {
    return "/";
  }

  if (pathname.endsWith("/")) {
    pathname = pathname.substr(0, pathname.length - 1);
  }

  return pathname;
}
