import DiscoverySearch, { ClientType, DiscoveryError } from "@omniverse/discovery";
import { SupportedTransport } from "@omniverse/discovery/data";
import { InterfaceCapabilities } from "@omniverse/idl/schema";
import { DiscoveryOptions } from "./Nucleus";

export async function createNucleusServiceClient<T>(
  type: ClientType<T>,
  server: string,
  serviceName: string,
  options: DiscoveryOptions = {}
): Promise<T & { [InterfaceCapabilities]?: Record<string, number> }> {
  let discovery: DiscoverySearch;

  if (server in discoveryByServer) {
    console.log(`[${server}] Reusing the Discovery connection.`);
    discovery = discoveryByServer[server];
  } else {
    console.log(`[${server}] Establish a new Discovery connection...`);
    discovery = discoveryByServer[server] = new DiscoverySearch(server);
  }

  try {
    const supportedTransport: SupportedTransport[] | undefined = undefined;
    const meta = { deployment: "external", ...options.meta };

    console.log(`[${server}] Discover ${serviceName}`);
    return await discovery.find(type, meta, supportedTransport, options.capabilities);
  } catch (error) {
    if (error instanceof DiscoveryError) {
      throw new DiscoveryError(
        `[${server}] (${serviceName}) service is not accessible. Please check your network connection and the server status with administrator.`
      );
    }
    throw error;
  }
}

export function closeDiscoveryConnection(server: string): void {
  const discovery = discoveryByServer[server];
  if (discovery) {
    delete discoveryByServer[server];
    discovery.close();
  }
}

export const discoveryByServer: Record<string, DiscoverySearch> = {};
