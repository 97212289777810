import { PathPermission, StatusType } from "@omniverse/api/data";
import { join } from "../../../util/Path";
import Path, { PathType } from "../../Path";
import { Commands } from "../Provider";
import { IMoveCommandAllowedArguments, IMoveCommandArguments } from "../types/MoveCommand";
import { NucleusCommand } from "./index";
import { compareFiles, CompareResult } from "./util";
import { CopyError } from "../../../util/PathErrors";

export default class NucleusMoveCommand extends NucleusCommand<IMoveCommandArguments, IMoveCommandAllowedArguments> {
  name = Commands.Move;

  public async allowed({ source, destination }: IMoveCommandAllowedArguments): Promise<boolean> {
    return Boolean(source?.every((path) => this.canMove(path) && (!destination || destination.canContain(path))));
  }

  protected canMove(path: Path): boolean {
    return path.path !== "/" && path.type !== PathType.Mount && path.canAdmin();
  }

  public async execute({ source, destination, newName }: IMoveCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Moving ${source.length} to ${destination.path}...`);

    const connection = await this.provider.getConnection();
    const to = destination.path;

    for (const path of source) {
      console.log(`[${this.provider.name}] Move ${path.path} to ${destination.path}`);

      const name = newName || path.name;
      const src = path.type === PathType.Folder ? join(path.path, "*") : path.path;
      const dest = path.type === PathType.Folder ? join(to, name, "/") : join(to, name);

      const aclResult = await connection.getAcl({ uri: path.path });
      if (aclResult.status !== StatusType.OK) {
        throw new Error(`Can't get ACL for ${path.path} -- ${aclResult.status}.`);
      }

      const acl = aclResult.acl!;
      const currentUser = this.provider.session.username!;

      const changeAclResult = await connection.changeAcl({
        uri: path.path,
        acl: {
          [currentUser]: [PathPermission.Read, PathPermission.Write, PathPermission.Admin],
          gm: [],
          others: [],
        },
      });
      if (changeAclResult.status !== StatusType.OK) {
        throw new Error(`Can't change ACL for ${path.path} -- ${changeAclResult.status}.`);
      }

      try {
        let comparedFiles: CompareResult | undefined;
        if (this.provider.supportsVersioning) {
          comparedFiles = await compareFiles(connection, path.path, destination.path);

          for (const file of comparedFiles.intersection) {
            const srcPath = join(comparedFiles.sourceDir, file);
            const dstPath = join(comparedFiles.destinationDir, file);

            console.log(`[${this.provider.name}] Create checkpoint before move for ${dstPath}"`);

            const res = await connection.stat2({ path: { path: dstPath } });
            if (res.status !== StatusType.OK) {
              throw new Error("Error fetching stats for checkpoint file");
            }

            if (!res.checkpointed) {
              await connection.checkpointVersion({
                path: { path: dstPath },
                message: `Backup before move from ${srcPath}`,
              });
            }
          }

          console.log(comparedFiles);
        }

        const transactionResult = await connection.getTransactionId();
        if (transactionResult.status !== StatusType.OK) {
          throw new Error(`Failed to start a transaction for moving ${path.path} -- ${transactionResult.status}.`);
        }

        const transactionId = String(transactionResult.transaction_id);
        const copyResult = await connection.copy({ uri: src, to: dest, transaction_id: transactionId });
        if (copyResult.status !== StatusType.OK) {
          throw new Error(`Failed to copy ${path.path} to ${to} -- ${copyResult.status}.`);
        }

        if (this.provider.supportsVersioning && comparedFiles) {
          const checkpointResults: Promise<void>[] = [];
          for (const file of comparedFiles.sourceFiles) {
            const srcPath = join(comparedFiles.sourceDir, file);
            const dstPath = join(comparedFiles.destinationDir, file);
            const message = newName ? `Renamed from ${srcPath}` : `Replaced by ${srcPath}`;
            checkpointResults.push(
              connection
                .checkpointVersion({
                  path: { path: dstPath },
                  message,
                })
                .then((result) => {
                  if (result.status !== StatusType.OK) {
                    throw new CopyError(result);
                  }
                })
            );
          }
          await Promise.all(checkpointResults);
        }
      } catch (e) {
        await connection.changeAcl({ uri: path.path, acl });
        throw e;
      }

      try {
        const deleteResults = await connection.delete({ uri: path.path });
        for await (const deleted of deleteResults) {
          if (deleted.status === StatusType.Done) {
            break;
          }
          if (deleted.status !== StatusType.OK) {
            throw new Error(`Can't delete ${path.path} after moving -- ${deleted.status}.`);
          }
        }
      } finally {
        const restoreAclResult = await connection.changeAcl({ uri: dest, acl });
        if (restoreAclResult.status !== StatusType.OK) {
          console.error(`Failed to restore ACL for destination ${dest} -- ${restoreAclResult.status}.`);
        }
      }

      if (path.parent) {
        path.parent.remove(path.path);

        const child = new Path(
          dest,
          path.type,
          path.storage,
          path.dateCreated,
          path.dateModified,
          this.provider.session.username,
          this.provider.session.username,
          path.size,
          path.mounted
        );
        destination.add(child);
      }
    }

    await destination.load();
  }
}

// add download close bubble
