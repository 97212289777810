export class OfflineModeError extends Error {
  constructor() {
    super();
    this.message = "You are working in offline mode. Please log in to synchronize your state with the server.";
  }

  public toString() {
    return this.message;
  }
}
