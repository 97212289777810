import React from "react";
import styled from "styled-components";
import { Omit } from "./index";
import Panel, { PanelProps } from "./Panel";
import { DefaultTheme, ThemedComponentProps } from "./Theme";

export interface LayoutProps
  extends ThemedComponentProps,
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  ref?: any;
  fullScreen?: boolean;
}

export const StyledLayout = styled.div<LayoutProps>`
  display: grid;
  grid-template-areas:
    "header header header"
    "left-panel content right-panel"
    "left-panel-footer footer right-panel-footer";
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: 50px 1fr 30px;
  overflow: hidden;
  height: ${({ fullScreen }) => (fullScreen ? "100%" : "auto")};
  flex: 1;
`;

export const Header = styled.header`
  grid-area: header;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;
Header.defaultProps = {
  theme: DefaultTheme,
};
Header.displayName = "Layout.Header";

export const LeftPanelDirections = { right: true };
export const LeftPanel = styled(Panel).attrs({
  directions: LeftPanelDirections,
})<Omit<PanelProps, "directions" | "defaultHeight">>`
  grid-area: left-panel;
  border-right: 1px solid ${({ theme }) => theme.colors.border};
  overflow: hidden;
`;
LeftPanel.defaultProps = {
  theme: DefaultTheme,
};
LeftPanel.displayName = "Layout.LeftPanel";

const RightPanelDirections = { left: true };
export const RightPanel = styled(Panel).attrs({
  directions: RightPanelDirections,
})<Omit<PanelProps, "directions" | "defaultHeight">>`
  grid-area: right-panel;
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  overflow: hidden;
`;
RightPanel.defaultProps = {
  theme: DefaultTheme,
};
RightPanel.displayName = "Layout.RightPanel";

export const Content = styled.main`
  grid-area: content;
  overflow: hidden;
`;
Content.displayName = "Layout.Content";

export const LeftPanelFooter = styled(Panel)`
  grid-area: left-panel-footer;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  overflow: hidden;
`;
LeftPanelFooter.defaultProps = {
  theme: DefaultTheme,
};
LeftPanelFooter.displayName = "Layout.LeftPanelFooter";

export const Footer = styled.div`
  grid-area: footer;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  overflow: hidden;
`;
Footer.defaultProps = {
  theme: DefaultTheme,
};
Footer.displayName = "Layout.Footer";

export const RightPanelFooter = styled(Panel)`
  grid-area: right-panel-footer;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  overflow: hidden;
`;
RightPanelFooter.defaultProps = {
  theme: DefaultTheme,
};
RightPanelFooter.displayName = "Layout.Footer";

export class Layout extends React.Component<LayoutProps> {
  public static displayName = "Layout";
  public static Header = Header;
  public static LeftPanel = LeftPanel;
  public static Content = Content;
  public static RightPanel = RightPanel;
  public static LeftPanelFooter = LeftPanelFooter;
  public static Footer = Footer;
  public static RightPanelFooter = RightPanelFooter;

  public render = () => {
    return <StyledLayout {...this.props} />;
  };
}

export default Layout;
