import { observer } from "mobx-react";
import React from "react";
import useDialog from "../hooks/useDialog";
import usePermission from "../hooks/usePermission";
import { Commands } from "../state/commands/Provider";
import { IRemoveUserFromGroupCommand } from "../state/commands/types/RemoveUserFromGroupCommand";
import Group from "../state/Group";
import User from "../state/User";
import GroupUserDeleteConfirmation from "./GroupUserDeleteConfirmation";
import { Icons } from "./Icon";
import Loader from "./Loader";
import Menu from "./Menu";
import { MenuIconItem } from "./MenuItem";

export interface GroupUserMenuProps {
  group: Group;
  user: User;
}

const GroupUserMenu: React.FC<GroupUserMenuProps> = ({ group, user }) => {
  const removeUserFromGroupDialog = useDialog(GroupUserDeleteConfirmation, { group, user });

  function removeUserFromGroup() {
    removeUserFromGroupDialog.show();
  }

  const canRemoveUserFromGroup = usePermission(
    () => group.storage.commands.allowed<IRemoveUserFromGroupCommand>(Commands.RemoveUserFromGroup),
    [group]
  );

  const loading = canRemoveUserFromGroup === "loading";
  if (loading) {
    return (
      <Menu>
        <Loader />
      </Menu>
    )
  }

  const visible = canRemoveUserFromGroup === "allowed";
  if (!visible) {
    return null;
  }

  return (
    <Menu>
      <MenuIconItem icon={Icons.Delete} visible={canRemoveUserFromGroup === "allowed"} onClick={removeUserFromGroup}>
        Remove User from Group
      </MenuIconItem>
    </Menu>
  );
};

export default observer(GroupUserMenu);
