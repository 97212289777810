import { observer } from "mobx-react";
import React from "react";
import usePermission from "../hooks/usePermission";
import { Commands } from "../state/commands/Provider";
import { ICreateUserCommand } from "../state/commands/types/CreateUserCommand";
import User from "../state/User";
import Users from "../state/Users";
import { ContentBrowserView } from "./ContentBrowserViewToggle";
import UserGrid from "./UserGrid";
import UserTable from "./UserTable";

export interface UserListContentProps {
  view: ContentBrowserView;
  users: Users;
  selectedUser: User | null;
  onSelect(user: User | null): void;
}

const UserListContent: React.FC<UserListContentProps> = ({ view, users, selectedUser, onSelect }) => {
  const supportsRegistration = usePermission(
    () => users.storage.commands.allowed<ICreateUserCommand>(Commands.CreateUser),
    [users]
  );
  if (view === ContentBrowserView.Grid) {
    return (
      <UserGrid
        users={users}
        selectedUser={selectedUser}
        canAddUsers={supportsRegistration === "allowed"}
        onSelect={onSelect}
      />
    );
  }

  if (view === ContentBrowserView.Table) {
    return <UserTable users={users} selectedUser={selectedUser} onSelect={onSelect} />;
  }

  return null;
};

export default observer(UserListContent);
