import { Credentials } from "@omniverse/auth/client";
import { AuthStatus } from "@omniverse/auth/data";
import { useCallback } from "react";
import { Authentication, AuthenticationResult } from "../AuthForm";
import AuthMessages from "../AuthMessages";
import connect, { handleConnectionErrors } from "../Connection";

export default function useCredentialAuth() {
  return useCallback(
    async ({
      username,
      password,
      server,
      nonce
    }: Authentication): Promise<AuthenticationResult> => {
      let credentials: Credentials | null = null;

      if (!server) {
        return {
          errors: ["You have to specify the server."],
          server: "",
        };
      }

      return connect(server, Credentials, { "auth": 0 })
        .then((conn) => (credentials = conn))
        .then((credentials) => credentials.auth({ username, password, nonce }))
        .then((result): AuthenticationResult =>
          result.status === AuthStatus.OK
            ? {
                server,
                status: result.status,
                accessToken: result.access_token,
                refreshToken: result.refresh_token,
                username: result.username,
                profile: result.profile,
                nonce: result.nonce,
              }
            : {
                server,
                status: result.status,
                errors: [AuthMessages[result.status]],
                nonce: result.nonce,
              }
        )
        .catch((error) => {
          return handleConnectionErrors(server, error);
        })
        .finally(() => {
          if (credentials) {
            credentials.transport.close();
          }
        });
    },
    []
  );
}
