export function joinURL(...args: string[]): string {
  return args.reduce((path, value, index) => {
    if (!value) {
      return path;
    }

    if (index !== 0 && !value.startsWith("/")) {
      value = "/" + value;
    }

    if (index !== args.length - 1 && value.endsWith("/")) {
      value = value.substring(0, value.length - 1);
    }

    return path + value;
  }, "");
}

export function getBaseURL(): string {
  const base = document.getElementById("public-url") as HTMLBaseElement;
  let baseURL = base?.href ?? window.location.href;
  if (baseURL) {
    if (baseURL === "/") {
      baseURL = "";
    } else {
      if (baseURL.endsWith("/")) {
        baseURL = baseURL.substr(0, baseURL.length - 1);
      }
    }
  }
  return baseURL;
}