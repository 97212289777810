import { action, computed, makeObservable, observable } from "mobx";

class DND {
  public type: string;
  public data: any;
  public allowed: boolean;

  constructor() {
    this.type = "";
    this.data = null;
    this.allowed = false;

    makeObservable(this, {
      type: observable,
      data: observable,
      allowed: observable,
      set: action.bound,
      setAllowed: action.bound,
      reset: action.bound,
      active: computed,
    });
  }

  public set(type: string, items: any): void {
    this.type = type;
    this.data = items;
  }

  public setAllowed(allowed: boolean) {
    this.allowed = allowed;
  }

  public reset(): void {
    this.type = "";
    this.data = null;
    this.allowed = false;

    try {
      const event = document.createEvent("DragEvent");
      event.initEvent("dragend");
      document.dispatchEvent(event);
    } catch {}
  }

  public get active(): boolean {
    return this.type !== "";
  }
}

const dnd = new DND();

export default dnd;
