import React from "react";
import styled from "styled-components";
import useDialogForm from "../hooks/useDialogForm";
import { Commands } from "../state/commands/Provider";
import { IMoveCommand } from "../state/commands/types/MoveCommand";
import Path from "../state/Path";
import ConfirmationDialog from "./ConfirmationDialog";
import ConfirmationWarning from "./ConfirmationWarning";
import FormErrorList from "./FormErrorList";
import PathTransfer from "./PathTransfer";

export interface PathMoveDialogProps {
  source: Path[];
  destination: Path;
}

const PathMoveDialog: React.FC<PathMoveDialogProps> = ({ source, destination }) => {
  const form = useDialogForm({
    fields: {},
    onSubmit: confirm,
  });

  function confirm() {
    const command = destination.storage.commands.get<IMoveCommand>(Commands.Move);
    if (command) {
      return command.execute({ source, destination });
    } else {
      throw new Error(`${Commands.Move} command is not supported.`);
    }
  }

  return (
    <StyledPathTransferDialog title={"Move"} loading={form.loading} onConfirm={form.submit} onCancel={form.cancel}>
      <FormErrorList errors={form.errors} />
      Do you want to move these resources?
      <ConfirmationWarning>It will replace resources with the same name in {destination.link}.</ConfirmationWarning>
      <PathTransfer source={source} destination={destination} />
    </StyledPathTransferDialog>
  );
};

const StyledPathTransferDialog = styled(ConfirmationDialog)`
  text-align: center;
`;

export default PathMoveDialog;
