import { action, computed, makeObservable, observable } from "mobx";
import { unique } from "../util/Array";
import Storage from "./Storage";

export class Tag {
  public static readonly GeneratedNamespace = "generated";
  public static readonly ExcludedNamespace = "excluded";
  public static readonly AppearanceNamespace = "appearance";
  public static readonly AppearanceExcludedNamespace = `.${Tag.AppearanceNamespace}.${Tag.ExcludedNamespace}`;
  public static readonly AppearanceGeneratedNamespace = `.${Tag.AppearanceNamespace}.${Tag.GeneratedNamespace}`;

  public constructor(public name: string, public namespace: string = "", public value?: string) {
    this.name = name;
    this.namespace = namespace;
    this.value = value;

    makeObservable(this, {
      name: observable,
      namespace: observable,
      value: observable,
      isExcluded: computed,
      isGenerated: computed,
      isAppearance: computed,
      fullName: computed,
    });
  }

  public get isExcluded(): boolean {
    return this.namespace?.endsWith(`.${Tag.ExcludedNamespace}`) ?? false;
  }

  public get isGenerated(): boolean {
    return this.namespace?.endsWith(`.${Tag.GeneratedNamespace}`) ?? false;
  }

  public get isAppearance(): boolean {
    return (
      this.namespace === Tag.AppearanceNamespace ||
      this.namespace === Tag.AppearanceGeneratedNamespace ||
      this.namespace === Tag.AppearanceExcludedNamespace
    );
  }

  public get fullName(): string {
    let fullName = "";
    if (this.name) {
      if (this.namespace) {
        fullName = `${this.namespace}.${this.name}`;
      } else {
        fullName = this.name;
      }

      if (this.value) {
        fullName += `=${this.value}`;
      }

      return fullName;
    } else {
      return this.namespace ?? "";
    }
  }

  public static fromFullName(fullName: string): Tag {
    const match = /((\.?\w+\.)*)(.*)(=(\w+))?/.exec(fullName)!;
    const namespace = match[1].substring(0, match[1].length - 1);
    const name = match[3];
    const value = match[5];
    return new Tag(name, namespace, value);
  }

  public equals(tag: Tag): boolean {
    return tag.name === this.name && (tag.namespace === this.namespace || (!tag.namespace && !this.namespace));
  }
}

class Tags {
  public items: Tag[];
  public suggestions: string[];
  public loading: boolean;

  public constructor(public readonly path: string, public readonly storage: Storage) {
    this.items = [];
    this.suggestions = [];
    this.loading = false;

    makeObservable<this, "setTags">(this, {
      items: observable,
      loading: observable,
      suggestions: observable,
      generated: computed,
      excluded: computed,
      names: computed,
      appearance: computed,
      system: computed,
      add: action.bound,
      edit: action.bound,
      delete: action.bound,
      setLoading: action.bound,
      setTags: action.bound,
      setSuggestions: action.bound,
    });
  }

  public get names(): string[] {
    const excludedNames = this.excluded.map((tag) => tag.name);
    const appearanceNames = this.appearance.map((tag) => tag.name).filter((name) => !excludedNames.includes(name));
    return unique(appearanceNames);
  }

  public get appearance(): Tag[] {
    return this.items.filter((tag) => tag.isAppearance);
  }

  public get system(): Tag[] {
    return this.items.filter((tag) => !tag.isAppearance);
  }

  public get generated(): Tag[] {
    return this.items.filter((tag) => tag.isGenerated);
  }

  public get excluded(): Tag[] {
    return this.items.filter((tag) => tag.isExcluded);
  }

  public static parseNames(value: string): string[] {
    return value
      .split(",")
      .map((tag) => tag.trim())
      .filter((tag) => tag.length > 0);
  }

  public static formatNames(names: string[]): string {
    return names.join(", ");
  }

  public getTagsFromNames(names: string[]): Tag[] {
    const generatedNames = this.generated.map((tag) => tag.name);
    const defined: Tag[] = names.map<Tag>((name) => new Tag(name, Tag.AppearanceNamespace, ""));

    const newlyExcluded = generatedNames
      .filter((name) => !names.includes(name))
      .map<Tag>((name) => new Tag(name, Tag.AppearanceExcludedNamespace, ""));

    const excluded = this.excluded
      .filter((tag) => !newlyExcluded.find((excluded) => excluded.name === tag.name))
      .concat(newlyExcluded)
      .filter((tag) => !defined.find((def) => def.name === tag.name));

    return defined.concat(this.generated).concat(excluded);
  }

  public add(tag: Tag): void {
    this.items.push(tag);
  }

  public edit(oldTag: Tag, newTag: Tag): void {
    this.items = this.items.map((tag) => (tag.equals(oldTag) ? newTag : tag));
  }

  public delete(tag: Tag): void {
    this.items = this.items.filter((t) => !t.equals(tag));
  }

  public setTags(tags: Tag[]): void {
    this.items = tags;
  }

  public setSuggestions(suggestions: string[]): void {
    this.suggestions = suggestions;
  }

  public setLoading(value: boolean): void {
    this.loading = value;
  }
}

export default Tags;
