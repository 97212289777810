import { StatusType } from "@omniverse/api/data";


export class GetACLError extends Error {
  public readonly messages: { [status: string]: string } = {
    [StatusType.Denied]: "You don't have access to read permissions for this path.",
    [StatusType.InvalidPath]: "Path does not exist."
  };
  public readonly result: { status: StatusType };

  constructor(result: { status: StatusType }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}

export class SetACLError extends Error {
  public readonly messages: { [status: string]: string } = {
    [StatusType.Denied]: "You don't have access to set permissions for this path.",
    [StatusType.InvalidPath]: "Path does not exist."
  };
  public readonly result: { status: StatusType };

  constructor(result: { status: StatusType }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}

export const DefaultMessage = (status: string) => `Unknown error, please contact administrator (${status}).`;