import { observer } from "mobx-react";
import React from "react";
import useDialog from "../hooks/useDialog";
import { Commands } from "../state/commands/Provider";
import { ICopyCommand, ICopyCommandAllowedArguments } from "../state/commands/types/CopyCommand";
import { IDeleteCommand } from "../state/commands/types/DeleteCommand";
import Storage from "../state/Storage";
import { Icons } from "./Icon";
import Menu from "./Menu";
import { MenuIconItem } from "./MenuItem";
import PathDeleteListDialog from "./PathDeleteListDialog";
import usePermission from "../hooks/usePermission";
import Loader from "./Loader";

interface PathSelectionMenuProps {
  storage: Storage;
}

const PathSelectionMenu: React.FC<PathSelectionMenuProps> = ({ storage }) => {
  const deleteDialog = useDialog(PathDeleteListDialog);

  function copyPaths() {
    storage.clipboard.copy(storage.selectedPaths.items);
  }

  function deletePaths() {
    deleteDialog.show({ paths: storage.selectedPaths.items });
  }

  const canCopy = usePermission(
    () =>
      storage.commands.allowed<ICopyCommand, ICopyCommandAllowedArguments>(Commands.Copy, {
        source: storage.selectedPaths.items,
      }),
    [storage.selectedPaths.items]
  );

  const canDelete = usePermission(async () => {
    const deleteCommand = storage.commands.get<IDeleteCommand>(Commands.Delete);
    if (!deleteCommand) {
      return false;
    }

    const allowed = await Promise.all(storage.selectedPaths.items.map((path) => deleteCommand.allowed({ path })));
    return allowed.every((item) => item);
  }, [storage.selectedPaths.items]);

  const loading = canCopy === "loading" || canDelete === "loading";
  if (loading) {
    return (
      <Menu>
        <Loader />
      </Menu>
    );
  }

  const visible = canCopy === "allowed" || canDelete === "allowed";
  if (!visible) {
    return null;
  }

  return (
    <Menu>
      <MenuIconItem icon={Icons.Copy} onClick={copyPaths}>
        Copy
      </MenuIconItem>

      <MenuIconItem icon={Icons.Delete} onClick={deletePaths}>
        Delete
      </MenuIconItem>
    </Menu>
  );
};

export default observer(PathSelectionMenu);
