import { TaggingService } from "@omniverse/tagging";
import { TagModifyType } from "@omniverse/tagging/data";
import { TagCommands } from "../../Provider";
import { IAddTagCommandArguments } from "../../types/tags/AddTagCommand";
import { NucleusCommand } from "../index";
import { convertTagToNucleus, ensureNucleusTagResults } from "./index";

export default class NucleusAddTagCommand extends NucleusCommand<IAddTagCommandArguments> {
  name = TagCommands.Add;

  public async allowed(): Promise<boolean> {
    return true;
  }

  public async execute({ tags, tag }: IAddTagCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Add tag ${tag.fullName} to ${tags.path}`);

    tags.add(tag);

    let service;
    try {
      service = await this.provider.createServiceClient(TaggingService, "TAGS", { capabilities: { modify_tags: 1 } });

      const values = [tag];
      const data = values.map(convertTagToNucleus);
      const response = await service.modifyTags({
        paths: [tags.path],
        tags: data,
        auth_token: this.provider.session.accessToken!,
        modify_type: TagModifyType.Add,
      });

      const [result] = response.path_result;
      ensureNucleusTagResults(result, values);
    } finally {
      if (service) {
        await service.transport.close();
      }
    }
  }
}
