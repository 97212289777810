import React, { useCallback } from "react";
import styled from "styled-components";

interface ToolbarTabProps {
  name: string;
  selected: boolean;
  onClick(name: string): void;
}

const ToolbarTab: React.FC<ToolbarTabProps> = ({ name, selected, onClick, children }) => {
  const click = useCallback(() => {
    onClick(name);
  }, [name, onClick]);

  return (
    <StyledToolbarTab title={name} selected={selected} onClick={click}>
      {children}
    </StyledToolbarTab>
  );
};

interface StyledToolbarTabProps {
  selected?: boolean;
}

const StyledToolbarTab = styled.div<StyledToolbarTabProps>`
  background: ${({ selected, theme }) => (selected ? theme.colors.toolbarSelectedBackground : "inherit")};
  color: ${({ selected, theme }) =>
    selected ? theme.colors.toolbarSelectedForeground : theme.colors.toolbarForeground};
  padding: 2px;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  cursor: pointer;
  margin-right: 20px;
  text-align: center;
  line-height: 15px;

  svg {
    fill: ${({ theme, selected }) => (selected ? theme.colors.selectedItemForeground : theme.colors.toolbarForeground)};
  }

  i {
    font-size: 12px;
    line-height: 18px;
    vertical-align: middle;
    color: ${({ theme, selected }) =>
      selected ? theme.colors.selectedItemForeground : theme.colors.toolbarForeground};
  }
`;

export default ToolbarTab;
