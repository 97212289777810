import { AuthStatus } from "@omniverse/auth/data";
import { OfflineModeError } from "../../../util/SessionErrors";

import { Commands } from "../Provider";
import { IDeleteAPITokenCommandArguments } from "../types/DeleteAPITokenCommand";
import { NucleusCommand } from "./index";

export default class NucleusDeleteAPITokenCommand extends NucleusCommand<IDeleteAPITokenCommandArguments> {
  name = Commands.DeleteAPIToken;

  public async allowed(): Promise<boolean> {
    return typeof this.provider.tokensCapabilities?.delete_api_token === "number";
  }

  public async execute({ storage, name }: IDeleteAPITokenCommandArguments): Promise<void> {
    if (!this.provider.session.established) {
      throw new OfflineModeError();
    }

    storage.apiTokens.setLoading(true);
    let tokens;
    try {
      tokens = await this.provider.createTokensClient();

      const response = await tokens.deleteApiToken({ access_token: this.provider.session.accessToken!, name });

      if (response.status === AuthStatus.Expired) {
        await this.provider.session.refresh();
        return this.execute({ storage, name });
      }

      if (response.status !== AuthStatus.OK) {
        throw new Error(`Error deleting API token: ${name}`);
      }

      storage.apiTokens.delete(name);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      storage.apiTokens.setLoading(false);
      await tokens?.transport.close();
    }
  }
}
