import { Path as NucleusPath, PathEvent, StatusType } from "@omniverse/api/data";
import Stream from "@omniverse/idl/stream";
import { join } from "../../../util/Path";
import { ListError } from "../../../util/PathErrors";
import Path from "../../Path";
import { Commands } from "../Provider";
import { ISubscribeCommandArguments } from "../types/SubscribeCommand";
import { NucleusCommand } from "./index";
import { parseNucleusPath } from "./Nucleus";

export let pathSubscriptions = new Map<string, Stream<NucleusPath>>();
export let thumbnailSubscriptions = new Map<string, Stream<NucleusPath>>();

export default class NucleusSubscribeCommand extends NucleusCommand<ISubscribeCommandArguments> {
  public name = Commands.Subscribe;

  public async allowed(): Promise<boolean> {
    return true;
  }

  public async execute({ path }: ISubscribeCommandArguments): Promise<void> {
    console.log(`[${this.provider.name}] Subscribe to ${path.path}`);

    await Promise.all([
      this.subscribeToPathEvents(path),
      this.subscribeToThumbnailEvents(path)
    ]);
  }

  protected generateSubscriptionKey(path: Path): string {
    return `${path.storage.name}@${path.path}`;
  }

  protected async subscribeToPathEvents(path: Path): Promise<void> {
    const key = this.generateSubscriptionKey(path);
    if (pathSubscriptions.has(key)) {
      return;
    }

    let connection;
    let subscription;
    try {
      connection = await this.provider.getConnection();
      subscription = await connection.list({ uri: path.path, recursive: false, show_hidden: false });
      pathSubscriptions.set(key, subscription);

      let result;
      do {
        result = await subscription.read();
        if (typeof result === "symbol") {
          throw new Error(`Unexpected end of subscription.`);
        }
      } while (result.status === StatusType.OK);

      if (result.status !== StatusType.Latest) {
        throw new ListError(result);
      }

      do {
        result = await subscription.read();
        if (typeof result === "symbol") {
          break;
        }

        if (result.event === PathEvent.Create) {
          const data = await parseNucleusPath(result, path, this.provider.linkGenerator);
          const child = path.get(data.path);
          if (child) {
            child.merge(data);
          } else {
            path.add(data);
          }
        } else if (result.event === PathEvent.Delete) {
          path.remove(result.uri!);
        }
      } while (result.status === StatusType.OK);
    } catch (error) {
      if (typeof error === "symbol") {
        return;
      }
      throw error;
    } finally {
      if (subscription) subscription.close();
      pathSubscriptions.delete(key);
    }
  }

  protected async subscribeToThumbnailEvents(path: Path): Promise<void> {
    const key = this.generateSubscriptionKey(path);
    if (thumbnailSubscriptions.has(key)) {
      return;
    }

    let connection;
    let subscription;
    try {
      const thumbnailFolder = this.provider.linkGenerator.getThumbnailFolder(path.path);

      connection = await this.provider.getConnection();
      subscription = await connection.list({ uri: thumbnailFolder, recursive: false, show_hidden: false });
      thumbnailSubscriptions.set(key, subscription);

      let result;
      do {
        result = await subscription.read();
        if (typeof result === "symbol") {
          throw new Error(`Unexpected end of subscription.`);
        }
      } while (result.status === StatusType.OK);

      if (result.status !== StatusType.Latest) {
        throw new ListError(result);
      }

      do {
        result = await subscription.read();
        if (typeof result === "symbol") {
          break;
        }

        if (result.event === PathEvent.Create || result.event === PathEvent.Full || result.event === PathEvent.Copy) {
          let uri = result.destination || result.uri;
          let match =
            this.provider.linkGenerator.generatedThumbnailPattern.exec(uri!) ||
            this.provider.linkGenerator.userThumbnailPattern.exec(uri!);

          if (match) {
            const [, parent, filename] = match;
            const updatedPath = join(parent, filename);
            const updatedFile = path.get(updatedPath);
            if (updatedFile) {
              updatedFile.invalidateThumbnails();
            }
          }
        }
      } while (result.status === StatusType.OK);
    } catch (error) {
      if (typeof error === "symbol") {
        return;
      }
      throw error;
    } finally {
      if (subscription) subscription.close();
      thumbnailSubscriptions.delete(key);
    }
  }
}
