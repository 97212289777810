import {observer} from "mobx-react";
import React from "react";
import Path from "../state/Path";
import RightPanel from "./RightPanel";
import RightPanelHeader from "./RightPanelHeader";
import RightPanelPath from "./RightPanelPath";
import RightPanelTitle from "./RightPanelTitle";
import RightPanelRow from "./RightPanelRow";

export interface PathListPanelProps {
  paths: Path[];
}

const PathListPanel: React.FC<PathListPanelProps> = ({ paths }) => {
  return (
    <RightPanel>
      <RightPanelHeader>
        <RightPanelRow>
          <RightPanelTitle>Multiple paths</RightPanelTitle>
        </RightPanelRow>
      </RightPanelHeader>

      {paths.map(path => <RightPanelPath key={path.path}>{path.path}</RightPanelPath>)}
    </RightPanel>
  );
}

export default observer(PathListPanel);