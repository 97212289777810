import { observer } from "mobx-react";
import React from "react";
import useContextMenu from "../hooks/useContextMenu";
import useTreeItemEvent from "../hooks/useTreeItemEvent";
import GroupListMenu from "./GroupListMenu";
import GroupMenu from "./GroupMenu";
import { Icons } from "./Icon";
import { TreeItemComponentProps } from "./Tree";
import TreeIcon from "./TreeIcon";
import TreeItem from "./TreeItem";
import TreeSpinner from "./TreeSpinner";
import TreeToggle from "./TreeToggle";
import TreeTogglePlaceholder from "./TreeTogglePlaceholder";
import UserListMenu from "./UserListMenu";
import {
  UserManagementGroup,
  UserManagementGroups,
  UserManagementItemType,
  UserManagementUsers,
} from "./UserManagementTree";

export interface UserManagementTreeItemProps extends TreeItemComponentProps<UserManagementItemType> {}

const UserManagementTreeItem: React.FC<UserManagementTreeItemProps> = ({ item, ...props }) => {
  if (item.type === "groups") {
    return <Groups item={item} {...props} />;
  }

  if (item.type === "users") {
    return <Users item={item} {...props} />;
  }

  if (item.type === "group") {
    return <Group item={item} {...props} />;
  }

  return null;
};

let Groups: React.FC<UserManagementTreeItemProps & { item: UserManagementGroups }> = ({
  item,
  onSelect,
  onToggle,
  onContextMenu,
  ...props
}) => {
  const [menu, showMenu] = useContextMenu(GroupListMenu, { props: { groups: item.groups } });
  const handleSelect = useTreeItemEvent(onSelect, item);
  const handleToggle = useTreeItemEvent(onToggle, item);

  return (
    <TreeItem level={0} onClick={handleSelect} onContextMenu={showMenu} {...props} highlighted={Boolean(menu)}>
      {item.groups.loading ? <TreeSpinner /> : <TreeToggle opened={item.toggled} onClick={handleToggle} />}
      <TreeIcon icon={Icons.Users} />
      Groups
      {menu}
    </TreeItem>
  );
};
Groups = observer(Groups);

let Users: React.FC<UserManagementTreeItemProps & { item: UserManagementUsers }> = ({
  item,
  onSelect,
  onToggle,
  onContextMenu,
  ...props
}) => {
  const [menu, showMenu] = useContextMenu(UserListMenu, { props: { users: item.users } });
  const handleSelect = useTreeItemEvent(onSelect, item);

  return (
    <TreeItem level={0} onClick={handleSelect} onContextMenu={showMenu} {...props} highlighted={Boolean(menu)}>
      {item.users.loading ? <TreeSpinner /> : <TreeTogglePlaceholder />}
      <TreeIcon icon={Icons.Users} />
      Users
      {menu}
    </TreeItem>
  );
};
Users = observer(Users);

let Group: React.FC<UserManagementTreeItemProps & { item: UserManagementGroup }> = ({
  item,
  onSelect,
  onToggle,
  onContextMenu,
  ...props
}) => {
  const [menu, showMenu] = useContextMenu(GroupMenu, { props: { group: item.group } });
  const handleSelect = useTreeItemEvent(onSelect, item);

  return (
    <TreeItem level={1} onClick={handleSelect} onContextMenu={showMenu} {...props} highlighted={Boolean(menu)}>
      {item.group.loading ? <TreeSpinner /> : <TreeTogglePlaceholder />}
      <TreeIcon icon={Icons.Users} />
      {item.group.name}
      {menu}
    </TreeItem>
  );
};
Group = observer(Group);

export default UserManagementTreeItem;
