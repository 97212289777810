import { action, computed, makeObservable, observable } from "mobx";
import Storage from "./Storage";
import User from "./User";

class Group {
  public static Admin = "gm";
  public static Users = "users";

  public users: User[];
  public loading: boolean;

  public constructor(public name: string, public readonly storage: Storage) {
    this.users = [];
    this.loading = false;

    makeObservable(this, {
      name: observable,
      users: observable,
      loading: observable,
      readonly: computed,
      usernames: computed,
      rename: action.bound,
      setUsers: action.bound,
      addUser: action.bound,
      removeUser: action.bound,
      clear: action.bound,
    });
  }

  public get readonly(): boolean {
    return this.name === Group.Users;
  }

  public get usernames(): string[] {
    return this.users.map((user) => user.name).sort();
  }

  public rename(groupName: string): void {
    this.name = groupName;
  }

  public setUsers(users: User[]) {
    this.users = users;
  }

  public addUser(user: User): void {
    this.users.push(user);
    this.users = this.users.slice().sort((a, b) => a.name.localeCompare(b.name));
  }

  public removeUser(user: User): void {
    this.users = this.users.filter((member) => member.name !== user.name);
  }

  public clear(): void {
    this.users = [];
  }
}

export default Group;
