import { isElectron } from "./Electron";

export async function confirm(message: string, title?: string): Promise<boolean> {
  if (isElectron()) {
    const res = await window.dialog.showMessageBox({
      title,
      message: message,
      buttons: ["OK", "Cancel"],
      type: "warning",
    });
    return res === 0;
  }
  return window.confirm(message);
}
