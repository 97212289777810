import React from "react";
import { useParams } from "react-router-dom";
import useAppNavigate from "../hooks/useAppNavigate";
import usePathParam from "../hooks/usePathParam";

/**
 * This page redirects users if they try to open a link with slash removed by Microsoft Outlook.
 * Outlook removes any extra slashes from URLs in emails so that our URL
 * http://ov-web/omniverse://ov-content/Users/user/
 * becomes this:
 * http://ov-web/omniverse:/ov-content/Users/user/
 *
 * We want to silently fix this issue by redirecting the user to correct URL.
 */
const MicrosoftOutlookURLFixPage: React.FC = () => {
  const { server } = useParams();
  const path = usePathParam();
  const navigate = useAppNavigate();

  let url = `/omniverse://${server}${path}`;
  if (path && path.endsWith("/") && !url.endsWith("/")) {
    url += "/";
  }

  React.useEffect(() => {
    navigate(url);
  });

  return null;
};

export default MicrosoftOutlookURLFixPage;
