import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useStateOrProp } from "../hooks/useStateOrProp";
import Icon, { Icons } from "./Icon";
import { StyledRenderer } from "./index";

export interface ToggleableProps {
  label: React.ReactNode;
  labelComponent?: StyledRenderer<ToggleableState>;
  toggle?: React.ComponentType<ToggleProps>;
  header?: React.ComponentType<React.HTMLAttributes<HTMLDivElement>>;
  initial?: boolean;
  opened?: boolean;
  className?: string;
  visible?: boolean;
  disabled?: boolean;
  tabIndex?: number;
  children?: React.ReactNode;
  onToggle?(opened: boolean): void;
}

export const Toggleable = ({
  label,
  labelComponent: Label = ToggleableLabel,
  toggle: Toggler = Toggle,
  header: Header = ToggleableHeader,
  initial = false,
  opened: openedProp,
  disabled = false,
  visible = true,
  tabIndex = -1,
  className,
  children,
  onToggle,
  ...props
}: ToggleableProps) => {
  const [opened, setOpened, controlled] = useStateOrProp(openedProp, onToggle, initial);
  const [focused, setFocused] = useState(false);
  const focus = useCallback((e: React.FocusEvent) => {
    setFocused(e.target === container.current);
  }, []);

  const blur = useCallback(() => {
    setFocused(false);
  }, []);

  const state = useMemo(() => ({ opened, focused }), [opened, focused]);

  const toggle = useCallback(() => {
    if (disabled) {
      return;
    }

    setOpened(!opened);
    if (!controlled && onToggle) {
      onToggle(!opened);
    }
  }, [opened, controlled, disabled, setOpened, onToggle]);

  const container = useRef(null);
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (focused && [" ", "Enter"].includes(e.key)) {
      toggle();
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <StyledToggleable
      ref={container}
      {...props}
      className={className}
      tabIndex={tabIndex}
      data-opened={opened}
      onKeyPress={handleKeyPress}
      onFocus={focus}
      onBlur={blur}
    >
      <Header onClick={toggle}>
        <Label {...state}>{label}</Label>

        {Toggler && <Toggler {...state} />}
      </Header>

      {opened && children}
    </StyledToggleable>
  );
};

const StyledToggleable = styled.div`
  display: flex;
  flex-direction: column;
  outline: none;
`;

export const ToggleableHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const ToggleableLabel = styled.div<ToggleableState>`
  flex: 1;
`;

export interface ToggleableState {
  opened: boolean;
  focused: boolean;
}

export interface ToggleProps extends ToggleableState {
  className?: string;
  openIcon?: Icons;
  closeIcon?: Icons;
}

export const Toggle = styled(({ opened, openIcon, closeIcon, className }: ToggleProps) => (
  <Icon className={className} icon={opened ? closeIcon! : openIcon!} />
))`
  flex: 0 0 25px;
  order: 0;
  text-align: center;
`;

Toggle.defaultProps = {
  openIcon: Icons.Plus,
  closeIcon: Icons.Minus,
};

export default Toggleable;
