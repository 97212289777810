import React from "react";
import styled from "styled-components";
import Icon, { Icons } from "./Icon";

export interface ContentBrowserViewToggleProps {
  view: ContentBrowserView;
  onChange(view: ContentBrowserView): void;
}

export enum ContentBrowserView {
  Table,
  Grid,
}

const ContentBrowserViewToggle: React.FC<ContentBrowserViewToggleProps> = ({ view, onChange }) => {
  return (
    <StyledContentBrowserViewToggle>
      <ContentBrowserViewIcon view={view} onChange={onChange} />
    </StyledContentBrowserViewToggle>
  );
}

const StyledContentBrowserViewToggle = styled.div`
  height: 100%;
  display: flex;
  margin-left: 0.5rem;
`;

const ContentBrowserViewIcon: React.FC<{
  view: ContentBrowserView,
  onChange(view: ContentBrowserView): void,
}> = ({ view, onChange }) => {
  function change() {
    onChange(view === ContentBrowserView.Table ? ContentBrowserView.Grid : ContentBrowserView.Table);
  }

  return (
    <StyledContentBrowserViewIcon
      clickable
      icon={`${view === ContentBrowserView.Table ? Icons.List : Icons.Grid} fa-fw`}
      onClick={change}
    />
  )
};

const StyledContentBrowserViewIcon = styled(Icon)`
  display: flex;
  align-items: center;
  font-size: 18pt;
  margin: 0 5px;
  cursor: pointer;
`;

export default ContentBrowserViewToggle;