import styled, { keyframes } from "styled-components";
import Icon from "./Icon";

const SpinnerKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(Icon).attrs({
  icon: "fa fa-spinner",
  "aria-busy": true,
  "aria-live": "polite",
})`
  animation: ${SpinnerKeyframes} 1s ease-in-out infinite;
  cursor: default;
`;

export default Spinner;
