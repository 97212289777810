import { SSO } from "@omniverse/auth";
import { AuthStatus, SSOSettings } from "@omniverse/auth/data";
import React, { useEffect } from "react";
import { AuthenticationResult } from "./AuthForm";
import connect from "./Connection";

export interface SSOPageProps {
  params: string;
  search?: string;
  onAuth(result: AuthenticationResult): void;
  onError(error: Error): void;
}

const SSOAuth: React.FC<SSOPageProps> = ({
  params,
  search = "",
  children,
  onAuth,
  onError,
}) => {
  if (!search) {
    search = window.location.search;
  }

  useEffect(() => {
    authenticate(params, search).then(onAuth).catch(onError);
  }, [params, search, onAuth, onError]);

  return <>{children}</>;
};

export default SSOAuth;

interface SSORedirectOptions {
  settings: SSOSettings;
  server: string;
  redirectBackTo: string;
  extras?: SSOExtras;
  nonce?: string;
}

interface EncodedSSOParams {
  type: string;
  server: string;
  redirectBackTo: string;
  nonce?: string;
  extras?: SSOExtras;
}

export interface SSOExtras {
  [key: string]: string;
}

export async function redirectToSSO({
  settings,
  server,
  redirectBackTo,
  extras,
  nonce,
}: SSORedirectOptions) {
  if (!redirectBackTo.endsWith("/")) {
    redirectBackTo += "/";
  }

  const type = settings.type;
  const params: EncodedSSOParams = {
    type,
    redirectBackTo,
    server,
    extras,
    nonce,
  };
  const json = JSON.stringify(params);
  const state = await new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onloadend = () => {
      const result = (reader.result as string).split(",");
      const data = result[1];
      resolve(data.replace(/=/g, ""));
    };
    reader.readAsDataURL(new Blob([json]));
  });

  redirectBackTo += state;

  let url;
  const sso = await connect(server, SSO, { "redirect": 0 });
  try {
    const result = await sso.redirect({ type, state });
    if (result.status !== AuthStatus.OK) {
      throw result;
    }

    url = result.redirect;
  } finally {
    await sso.transport.close();
  }

  url = url.replace("{redirect_url}", encodeURIComponent(redirectBackTo));
  url = url.replace("{redirect_url_state}", btoa(redirectBackTo));
  window.location.assign(url);
}

export async function authenticate(
  encodedSSO: string,
  urlSearchParams: string
): Promise<AuthenticationResult> {
  const json = atob(encodedSSO);
  const { type, server, extras, nonce }: EncodedSSOParams = JSON.parse(json);

  const search = new URLSearchParams(urlSearchParams);
  const sso = await connect(server, SSO, { "auth": 0 });
  try {
    const params = Array.from(search.entries()).reduce<{
      [key: string]: string;
    }>((all, [name, value]) => {
      all[name] = value.replace(/ /g, "+");
      return all;
    }, {});

    const result = await sso.auth({ type, params, nonce });
    return {
      server,
      accessToken: result.access_token,
      refreshToken: result.refresh_token,
      status: result.status,
      username: result.username,
      profile: result.profile,
      extras,
      nonce: result.nonce,
    };
  } finally {
    await sso.transport.close();
  }
}
