import React from "react";
import styled from "styled-components";
import { Icons } from "./Icon";
import { DefaultTheme } from "./Theme";
import Toggleable, { Toggle, ToggleableProps } from "./Toggleable";

const PathContentGroup: React.FC<ToggleableProps> = ({ children, ...props }) => {
  return (
    <StyledPathContentGroup labelComponent={PathContentGroupLabel} toggle={PathContentGroupToggle} {...props}>
      <PathContentGroupContainer>{children}</PathContentGroupContainer>
    </StyledPathContentGroup>
  );
};

const StyledPathContentGroup = styled(Toggleable)`
  padding: 0.5em;
  min-width: 0;
  min-height: 0;
  flex: 1;
`;

export const PathContentGroupLabel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 10pt;
  color: ${({ theme }) => theme.colors.foreground};
  order: 2;
  text-transform: uppercase;
  flex: 1;
  padding-bottom: 0.5em;

  &::after {
    margin-left: 1em;
    content: " ";
    flex: 1;
    height: 2px;
    box-sizing: border-box;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }
`;
PathContentGroupLabel.defaultProps = {
  theme: DefaultTheme,
};

export const PathContentGroupToggle = styled(Toggle)`
  flex: 0 0 10px;
  margin: 0 0.5em 0 0;
  color: ${({ theme }) => theme.colors.foreground};
  order: 1;
  padding-bottom: 0.5em;
`;
PathContentGroupToggle.defaultProps = {
  theme: DefaultTheme,
  openIcon: Icons.Right,
  closeIcon: Icons.Down,
};

const PathContentGroupContainer = styled.div`
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export default PathContentGroup;
