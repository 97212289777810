import { useCallback, useMemo, useRef, useState } from "react";
import useMounted from "./useMounted";

export interface FormSettings<F, T> {
  fields: F;
  onStart?: FormStartCallback<F>;
  onFail?: FormFailCallback;
  onSuccess?: FormSuccessCallback<T>;
  onSubmit: FormSubmitCallback<F, T>;
  onCancel?: FormCancelCallback;
}

export type FormStartCallback<F> = (fields: F) => boolean;
export type FormFailCallback = (errors: string[]) => void;
export type FormSuccessCallback<T> = (result: T) => void;
export type FormSubmitCallback<F, T> = (fields: F) => Promise<T | FormErrors>;
export type FormCancelCallback = () => void;
export type FormErrors = {
  errors?: string[];
};

export default function useForm<F, T>({
  fields: formFields,
  onStart,
  onSuccess,
  onFail,
  onSubmit,
  onCancel,
}: FormSettings<F, T>) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const mounted = useMounted();

  const fields = useRef<F>(formFields);
  fields.current = formFields;

  const callbacks = useRef({
    onStart,
    onSuccess,
    onFail,
    onSubmit,
    onCancel,
  });
  callbacks.current = { onStart, onSuccess, onFail, onSubmit, onCancel };

  const submit = useCallback(async () => {
    const { onStart, onSuccess, onFail, onSubmit } = callbacks.current;

    setLoading(true);
    setErrors([]);
    try {
      if (onStart) {
        const proceed = onStart(fields.current);
        if (!proceed) {
          return;
        }
      }

      const result = await onSubmit(fields.current);
      const errors = (result as FormErrors)?.errors;

      if (mounted.current) {
        setLoading(false);
        if (errors?.length) {
          setErrors(errors);
          if (onFail) {
            onFail(errors);
          }
        } else if (onSuccess) {
          onSuccess(result as T);
        }
      }
    } catch (e: any) {
      if (mounted.current) {
        setLoading(false);
        setErrors([e.message]);
      }

      if (process.env.NODE_ENV !== "test") {
        console.error(e);
      }
    }
  }, [mounted]);

  const cancel = useCallback(() => {
    const { onCancel } = callbacks.current;
    setLoading(false);
    if (onCancel) {
      onCancel();
    }
  }, []);

  return useMemo(
    () => ({
      fields: fields.current,
      submit,
      cancel,
      loading,
      setLoading,
      errors,
      setErrors,
    }),
    [loading, errors, submit, cancel]
  );
}
