import Icon, { IconProps, Icons } from "./Icon";
import styled from "styled-components";

export interface RightPanelHeaderIconProps extends Omit<IconProps, "icon"> {
  icon?: Icons;
}

const RightPanelHeaderIcon = styled(Icon).attrs({ clickable: true })<RightPanelHeaderIconProps>`
  flex: 0 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  margin-left: 1em;
  text-align: right;
  color: ${({ theme }) => theme.colors.selectedItemForeground};
`;

RightPanelHeaderIcon.defaultProps = {
  icon: Icons.Close,
};

export default RightPanelHeaderIcon;
