import { StatusType } from "@omniverse/api/data";


export class ListError extends Error {
  public readonly messages: { [status: string]: string } = {
    [StatusType.Denied]: "You don't have access to list files for this path.",
    [StatusType.InvalidPath]: "Path does not exist.",
    [StatusType.ConnectionLost]: "Lost connection to the server.",
  }
  public readonly result: { status: StatusType };

  constructor(result: { status: StatusType }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}


export class NewFolderError extends Error {
  public readonly messages: { [status: string]: string } = {
    [StatusType.Denied]: "You don't have access to create folder here.",
    [StatusType.AlreadyExists]: "This folder already exists.",
  }
  public readonly result: { status: StatusType };

  constructor(result: { status: StatusType }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}


export class DeleteError extends Error {
  public readonly messages: { [status: string]: string } = {
    [StatusType.Denied]: "You don't have access to delete this path.",
    [StatusType.InvalidPath]: "Path does not exist."
  }
  public readonly result: { status: StatusType };

  constructor(result: { status: StatusType }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}


export class CopyError extends Error {
  public readonly messages: { [status: string]: string } = {
    [StatusType.Denied]: "You don't have access to copy this path.",
    [StatusType.InvalidPath]: "Path does not exist."
  }
  public readonly result: { status: StatusType };

  constructor(result: { status: StatusType }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}


export class MountError extends Error {
  public readonly messages: { [status: string]: string } = {
    [StatusType.Denied]: "You don't have access to manage mounts in this path.",
    [StatusType.InvalidPath]: "Path does not exist.",
    [StatusType.InvalidParameters]: "Invalid parameters for a mount.",
  }
  public readonly result: { status: StatusType };

  constructor(result: { status: StatusType }) {
    super();
    this.result = result;
    this.message = this.messages[result.status] || DefaultMessage(result.status);
  }
}

export const DefaultMessage = (status: string) => `Unknown error, please contact administrator (${status}).`;
