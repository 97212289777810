import { useEffect, useState } from "react";
import useMounted from "./useMounted";
import useRefCallback from "./useRefCallback";

export interface UseQueryOptions<T = any> {
  keys?: any[];
  onSubmit?(): void;
  onSuccess?(data: T): void;
  onError?(error: string): void;
}

export default function useQuery<
  F extends (...args: any[]) => Promise<T>,
  A extends Parameters<F>,
  T = F extends (...args: A) => Promise<infer U> ? U : unknown
>(queryFunc: F, { keys, onSubmit, onSuccess, onError = console.error }: UseQueryOptions<T> = {}) {
  const query = useRefCallback(queryFunc);
  const submitCallback = useRefCallback(onSubmit);
  const successCallback = useRefCallback(onSuccess);
  const errorCallback = useRefCallback(onError);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    let querying = true;
    setError("");
    setLoading(true);

    if (submitCallback) {
      submitCallback();
    }

    query()
      .then((data: T) => {
        if (querying) {
          setData(data);
          if (successCallback) {
            successCallback(data);
          }
        }
      })
      .catch((error) => {
        if (querying) {
          setError(error.message ?? "");
          if (errorCallback) {
            errorCallback(error.message ?? "");
          }
        }
      })
      .finally(() => {
        if (querying) {
          setLoading(false);
        }
      });

    return () => {
      querying = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, keys);

  function refresh() {
    setError("");
    setLoading(true);

    if (submitCallback) {
      submitCallback();
    }

    query()
      .then((data: T) => {
        setData(data);
        if (successCallback) {
          successCallback(data);
        }
      })
      .catch((error) => {
        setError(error.message ?? "");
        if (errorCallback) {
          errorCallback(error.message ?? "");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return {
    loading,
    ok: !loading && !error,
    data,
    error,
    setData,
    setError,
    refresh,
  };
}
